<div class="input-field col s6">
  <input placeholder="Link (URL)"
         id="{{ key + '-link' }}"
         type="text"
         class="validate"
         [(ngModel)]="control.link" />
  <label for="{{ key + '-link' }}">Link (URL)</label>
</div>
<div class="input-field col s6">
  <input placeholder="Action"
         id="{{ key + '-action' }}"
         type="text"
         class="validate"
         [(ngModel)]="control.action" />
  <label for="{{ key + '-action' }}">Action</label>
</div>
