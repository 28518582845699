import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ConsentRequest,
  PaymentOption
} from '../../consents-request/consent-request.model';
import {
  ExtraType,
  RadioQuestion
} from '../../../../app/forms/form/controls/radio-control/radio-question.model';
import {
  PaymentPlanScheduleItem,
  PaymentPlanScheduleItemType
} from './consent-payment-plan-sign.types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AlertsService } from '../../../../app/common/alerts/alerts.service';
import { BehaviorSubject } from 'rxjs';
import { ConsentSubmit } from '../../consents-request/consent-submit.model';
import { DecimalPipe } from '@angular/common';
import { DeviceService } from '../../../../app/common/device-service/device.service';
import { InputType } from '../../../../app/forms/form/controls/input-control/input-question.model';
import { Patient } from 'src/app/common/patient/patient.model';
import { PaymentPlansService } from '../../payment-plan-consent/payment-plan-consent.service';
import { PracticeService } from '../../../../app/common/practice';
import { SigningAs } from '../../payment-plan-consent/payment-plan-consent.types';
import { TerminalType } from '../../../../app/common/clearent/clearent.types';
import { TranslateService } from '@ngx-translate/core';
import { dataURLToBlob } from 'src/app/common/toBlob/toBlob.helper';

@UntilDestroy()
@Component({
  selector: 'app-consent-payment-plan-sign',
  templateUrl: './consent-payment-plan-sign.component.html',
  styleUrls: ['./consent-payment-plan-sign.component.scss'],
  providers: [DecimalPipe]
})
export class ConsentPaymentPlanSignComponent implements OnInit {
  @Input() consent: ConsentRequest;
  @Input() output: ConsentSubmit;
  @Input() paymentOption: PaymentOption;
  @Output() control = new EventEmitter<AbstractControl>();

  loading$ = new BehaviorSubject<boolean>(true);

  paymentPlanSignForm: FormGroup = this.formBuilder.group({
    agreeTerms: [false, Validators.requiredTrue]
  });
  patient: Patient;
  errorMessage: string | null = null;
  signingAsType = SigningAs;
  signingAs: string | null = null;
  formGroup = new FormGroup({});
  signatureFile: File | null = null;

  signAsQuestion = {
    type: 'radio',
    key: 'signature_entity',
    optional: false,
    title: this.translationService.instant('CONSENTS.PATIENT_SIGN.SIGN_AS'),
    section: null,
    control: {
      options: [
        {
          name: this.translationService.instant(
            'CONSENTS.PATIENT_SIGN.PATIENT'
          ),
          value: 'patient'
        },
        {
          name: this.translationService.instant(
            'CONSENTS.PATIENT_SIGN.GUARDIAN'
          ),
          value: 'guardian'
        }
      ],
      extra: {
        value: 'guardian',
        type: ExtraType.Input,
        hint: this.translationService.instant(
          'CONSENTS.PATIENT_SIGN.GUARDIAN_NAME'
        ),
        input_type: InputType.Name
      }
    }
  } as RadioQuestion;

  signatureQuestion = {
    type: 'signature',
    key: 'signature',
    optional: false,
    title: this.translationService.instant('CONSENTS.PATIENT_SIGN.SIGNATURE'),
    section: null
  } as RadioQuestion;

  terminalType = TerminalType;

  paymentPlanSchedule: PaymentPlanScheduleItem[] = [];
  paymentPlanSchedulePresenter: PaymentPlanScheduleItem[] = [];
  paymentPlanScheduleItemType = PaymentPlanScheduleItemType;

  readonly cardControl: FormControl = new FormControl(
    null,
    Validators.required
  );

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly alertService: AlertsService,
    private readonly deviceService: DeviceService,
    private readonly practiceService: PracticeService,
    private readonly translationService: TranslateService,
    private readonly paymentPlanService: PaymentPlansService,
    private readonly formBuilder: FormBuilder
  ) {}

  get signature() {
    return this.formGroup.get('signature').value;
  }

  get agreeTerms(): FormControl {
    return this.paymentPlanSignForm.get('agreeTerms') as FormControl;
  }

  ngOnInit(): void {
    if (this.consent) {
      this.patient = this.consent.patient;
    }
    if (this.paymentOption) {
      this.createPaymentSchedule();
    }
    this.onControlChanges();
  }

  createPaymentSchedule() {
    const schedule: PaymentPlanScheduleItem[] = [];

    for (let i = 0; i < this.paymentOption.installments; i++) {
      schedule.push({
        text: `${this.ordinalSuffix(i + 1)} ${this.translationService.instant(
          'PAYMENT_PLAN_CONSENT.PAYMENT'
        )}`,
        amount: this.paymentOption.monthly_payment,
        kind: PaymentPlanScheduleItemType.Row
      });
    }

    this.paymentPlanSchedule = schedule;
    this.createPaymentSchedulePresenter();
  }

  createPaymentSchedulePresenter() {
    const schedule: PaymentPlanScheduleItem[] = [];

    if (this.paymentPlanSchedule.length > 4) {
      schedule.push(this.paymentPlanSchedule[0]);
      schedule.push(this.paymentPlanSchedule[1]);
      schedule.push({
        text: this.translationService.instant(
          'PAYMENT_PLAN_CONSENT.PLUS_N_MORE_PAYMENTS',
          { numMorePayments: this.paymentOption.installments - 4 }
        ),
        amount: null,
        kind: PaymentPlanScheduleItemType.Separator
      });
      schedule.push(
        this.paymentPlanSchedule[this.paymentPlanSchedule.length - 2]
      );
      schedule.push(
        this.paymentPlanSchedule[this.paymentPlanSchedule.length - 1]
      );
    } else {
      schedule.push(...this.paymentPlanSchedule);
    }

    this.paymentPlanSchedulePresenter = schedule;
  }

  isInApp(): boolean {
    return this.deviceService.isInApp();
  }

  isInKiosk(): boolean {
    return this.deviceService.isInKiosk();
  }

  onSigningAsChange(value: SigningAs): void {
    this.signingAs = value;
    this.output.payment_plan_signing_as = value;
    this.errorMessage = null;
  }

  onSignatureChange(key: string, data: FormControl): void {
    this.errorMessage = null;

    if (key === 'signature') {
      const signatureBlob = dataURLToBlob(this.signature);
      this.signatureFile = new File([signatureBlob], 'signature.png', {
        type: 'image/png'
      });

      const formData = new FormData();
      formData.append('attachment', this.signatureFile);
      formData.append('patient_id', this.patient.id.toString());
      formData.append('filename', 'signature.png');

      this.output.payment_plan_signature = formData;
    }
  }

  onFormControlSet(key: string, control: FormControl): void {
    setTimeout(() => {
      if (this.formGroup.contains(key)) {
        this.formGroup.removeControl(key);
      }
      if (this.formGroup.contains('card')) {
        this.formGroup.removeControl('card');
      }
      if (this.formGroup.contains('agreeTerms')) {
        this.formGroup.removeControl('agreeTerms');
      }
      this.formGroup.addControl(key, control);
      this.formGroup.addControl('card', this.cardControl);
      this.formGroup.addControl('agreeTerms', this.agreeTerms);
      this.control.emit(this.formGroup);
    }, 0);
  }

  ordinalSuffix(i: number): string {
    if (i === 0) return '';
    let j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) return i + 'st';
    if (j == 2 && k != 12) return i + 'nd';
    if (j == 3 && k != 13) return i + 'rd';
    return i + 'th';
  }

  trackByPaymentPlanScheduleItemText(
    index: number,
    item: PaymentPlanScheduleItem
  ): string {
    return item.text;
  }

  onControlChanges(): void {
    this.cardControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.output.card = value;
      });

    this.agreeTerms.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.errorMessage = null;
      });
  }
}
