import { Injectable } from '@angular/core';
import { AuthService } from '../../common/authentication/auth.service';
import { nonEmpty } from '../../common/utils/string-utils.helper';
import { FormSpec } from '../../forms/form/form-spec.model';
import { MobiatiHttpService } from '../../common/mobiati-http/mobiati-http.service';
import { CookieService } from 'ngx-cookie';
import { ActionResponse } from '../../common/mobiati-http/action-response.model';
import { MedicalCondition } from '../medical-condition.model';
import { HttpParams } from '@angular/common/http';
import { NewConsentSpec } from '../create-consent/new-consent-spec.model';
import { TwoFaService } from "../../common/authentication/two-fa.service";

@Injectable({
  providedIn: 'root'
})
export class OnboardingToolsService {
  private authToken: string;

  constructor(
    private http: MobiatiHttpService,
    private authService: AuthService,
    private cookieService: CookieService,
    private twoFaService: TwoFaService
  ) {
    this.authToken = this.cookieService.get('onboarding_auth_token');
    this.http.setAuthToken(this.authToken);
  }

  async logIn(email: string, password: string): Promise<any> {
    const loginResponse = await this.authService.logInPassword(
      email,
      password,
      'admin',
      this.twoFaService.getDeviceUuid(email)
    );
    this.authToken = loginResponse.access_token;
    this.http.setAuthToken(this.authToken);
    const exp = new Date();
    exp.setHours(exp.getHours() + 24);
    this.cookieService.put('onboarding_auth_token', this.authToken, {
      expires: exp
    });
    if (loginResponse.device_uuid) {
      this.twoFaService.setDeviceUuid(loginResponse.device_uuid, email);
    }
  }

  isAuthorized(): boolean {
    return nonEmpty(this.authToken);
  }

  async getFormSpecs(loadConsents: boolean): Promise<FormSpec[]> {
    const params = new HttpParams().append(
      'consents',
      loadConsents ? '1' : '0'
    );
    return this.http.get<FormSpec[]>(`/onboarding/form-spec`, params);
  }

  async getMedicalConditions(): Promise<MedicalCondition[]> {
    return this.http.get<MedicalCondition[]>(`/onboarding/medical-conditions`);
  }

  async createMedicalCondition(
    condition: MedicalCondition
  ): Promise<MedicalCondition> {
    return this.http.post(`/onboarding/medical-conditions`, condition);
  }

  async saveFormSpec(formSpec: FormSpec): Promise<ActionResponse> {
    return this.http.post(`/onboarding/form-spec`, formSpec);
  }

  async deleteFormSpec(formSpec: FormSpec): Promise<ActionResponse> {
    return this.http.delete(`/onboarding/form-spec/${formSpec.id}`);
  }

  async restoreDefaultFormSpec(formSpec: FormSpec): Promise<ActionResponse> {
    return this.http.post(
      `/onboarding/form-spec/${formSpec.id}/restore-default`
    );
  }

  async createConsentSpec(newConsent: NewConsentSpec): Promise<any> {
    return this.http.post(`/onboarding/form-spec/create-consent`, newConsent);
  }

  async logOut(): Promise<any> {
    try {
      await this.authService.logout();
      this.http.setAuthToken(undefined);
      delete this.authToken;
      this.cookieService.remove('onboarding_auth_token');
    } catch (e) {}
  }
}
