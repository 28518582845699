import { Component, Input, OnInit } from '@angular/core';
import { ExtraType, RadioQuestion } from './radio-question.model';
import {
  InputQuestion,
  InputQuestionParams
} from '../input-control/input-question.model';
import {
  DropdownQuestion,
  DropdownQuestionParams
} from '../dropdown-control/dropdown-question.model';
import { AbstractControlComponent } from '../abstract-control/abstract-control.component';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { nonEmpty } from '../../../../common/utils/string-utils.helper';
import { TranslateService } from '@ngx-translate/core';
import { AnswerOption } from '../../answer-option.model';

@Component({
  selector: 'app-radio-control',
  templateUrl: './radio-control.component.html',
  styleUrls: ['./radio-control.component.scss']
})
export class RadioControlComponent
  extends AbstractControlComponent<any, RadioQuestion, FormGroup>
  implements OnInit {
  @Input() compact = false;
  @Input() highlight = false;
  value: any;
  extra: any;
  showExtra = false;
  extraFormControl: AbstractControl;

  constructor(private translateService: TranslateService) {
    super();
  }

  async ngOnInit(): Promise<any> {
    if (this.data !== undefined) {
      if (this.data === Object(this.data)) {
        this.value =
          this.data.value !== undefined ? this.data.value : undefined;
        this.extra =
          this.data.extra !== undefined ? this.data.extra : undefined;
      } else {
        // is primitive
        this.value = this.data;
      }
    } else if (
      this.question.control.default !== null &&
      this.question.control.default !== undefined
    ) {
      this.value = this.question.control.default;
    }

    this.updateShowExtra(this.value);
    this.setupFormGroupWithExtra();
    await this.updateTextFromOptions();
    // FIXME: requires more test
    // this.updateCompactForYesNoQuestion();
  }

  private setupFormGroupWithExtra(): void {
    if (this.extraFormControl !== undefined) {
      this.formControl = new FormGroup({
        [this.question.key]: new FormControl(this.value, super.getValidators()),
        [this.question.key + '__extra']: this.extraFormControl
      });
    } else {
      this.formControl = new FormGroup({
        [this.question.key]: new FormControl(this.value, super.getValidators())
      });
    }
    this.control.emit(this.formControl);
  }

  private async updateTextFromOptions(): Promise<any> {
    if (
      !nonEmpty(this.question.text) &&
      this.question.control.extra &&
      this.question.control.extra.type === ExtraType.MultiSelect
    ) {
      // for multiselect extra and empty text, determine text from options
      this.question.text = this.question.control.extra.options
        .map((opt) => opt.name)
        .join(', ');
      if (this.question.control.extra.other) {
        const other = await this.translateService
          .get('FORMS.CONTROLS.DROPDOWN.OTHER')
          .toPromise();
        this.question.text += ', ' + other;
      }
    }
  }

  private updateCompactForYesNoQuestion(): void {
    const options: AnswerOption[] = this.question.control.options;
    if (
      this.compact === false &&
      options.length === 2 &&
      (options[0].value === true || options[0].value === false) &&
      (options[1].value === true || options[1].value === false)
    ) {
      this.compact = true;
    }
  }

  private getMainFormControlValue(): any {
    return (this.formControl as FormGroup).controls[this.question.key].value;
  }

  private getExtraFormControlValue(): any {
    return this.showExtra && this.extraFormControl
      ? this.extraFormControl.value
      : undefined;
  }

  emitOutput(): void {
    this.value = this.formControl ? this.getMainFormControlValue() : undefined;
    this.extra = this.formControl ? this.getExtraFormControlValue() : undefined;

    this.updateShowExtra(this.value);
    this.output.emit(
      this.question.control.extra !== undefined
        ? {
            value: this.value !== undefined ? this.value : null,
            extra: this.showExtra
              ? this.extra !== undefined
                ? this.extra
                : null
              : null
          }
        : this.value !== undefined
        ? this.value
        : null
    );
  }

  private updateShowExtra(value: any): void {
    const prevShowExtra = this.showExtra;
    this.showExtra =
      this.question.control.extra !== undefined &&
      this.question.control.extra !== null &&
      this.question.control.extra.value === value;

    if (prevShowExtra !== this.showExtra && !this.showExtra) {
      this.extraFormControl = undefined;
      this.setupFormGroupWithExtra();
    }
  }

  toExtraInputQuestion(): InputQuestion {
    return {
      key: this.question.key + '__extra',
      type: 'input',
      section: null,
      title: this.question.control.extra.hint,
      optional: this.question.control.extra.optional,
      control: {
        hint: null,
        input_type: this.question.control.extra.input_type
      } as InputQuestionParams
    } as InputQuestion;
  }

  toExtraDropdownQuestion(): DropdownQuestion {
    return {
      key: this.question.key + '__extra',
      type: 'dropdown',
      section: null,
      title: '',
      optional: this.question.control.extra.optional,
      control: {
        options: this.question.control.extra.options,
        default: null,
        hint: this.question.control.extra.hint,
        multi: this.question.control.extra.single_choice !== true,
        other: this.question.control.extra.other
      } as DropdownQuestionParams
    } as DropdownQuestion;
  }

  onExtraDataChange(value: any): void {
    // do we need?
    this.emitOutput();
  }

  onExtraFormControlSet(extraControl: AbstractControl): void {
    this.extraFormControl = extraControl;
    this.setupFormGroupWithExtra();
  }
}
