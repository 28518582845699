import { Injectable } from '@angular/core';
import { FormSpec } from '../../forms/form/form-spec.model';
import { TermsQuestion } from '../../forms/form/controls/terms-control/terms-question.model';
import { TextQuestion } from '../../forms/form/controls/text-control/text-question.model';
import { uniqueArray } from '../utils/array-utils.helper';

@Injectable({
  providedIn: 'root'
})
export class PlaceholdersService {
  private placeholdersMap: object = {};

  constructor() {}

  private static getPlaceholdersUsedInQuestion(
    q: TermsQuestion | TextQuestion
  ): string[] {
    let placeholders = [];
    if (q.control.text) {
      const matched = q.control.text.match(/{{[a-z0-9_]*}}/g);
      if (matched !== null) {
        placeholders = placeholders.concat(matched);
      }
    }
    if (q.control.html_text) {
      const matched = q.control.html_text.match(/{{[a-z0-9_]*}}/g);
      if (matched !== null) {
        placeholders = placeholders.concat(matched);
      }
    }
    return placeholders;
  }

  clearPlaceholderMap(): void {
    this.placeholdersMap = {};
  }

  setPlaceholderMap(map: object): void {
    this.placeholdersMap = map;
  }

  replacePlaceholders(text: string): string {
    Object.keys(this.placeholdersMap).forEach((key) => {
      text = text
        .replace(new RegExp(key, 'g'), this.placeholdersMap[key])
        .replace(new RegExp('&amp;', 'g'), '&');
    });
    return text;
  }

  getUsedPlaceholdersIn(formSpecs: FormSpec[]): string[] {
    // find all placeholders in all text/terms controls
    let placeholdersUsed = [];
    formSpecs.forEach((formSpec) => {
      formSpec.spec
        .filter((q) => {
          return q.type === 'terms' || q.type === 'text';
        })
        .forEach((q) => {
          placeholdersUsed = placeholdersUsed.concat(
            PlaceholdersService.getPlaceholdersUsedInQuestion(q)
          );
        });
    });
    // return only unique occurences
    return uniqueArray(placeholdersUsed);
  }
}
