import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Question } from '../question.model';
import { isArray } from 'util';
import { LiteEditorService } from '../../../+lite-editor/services/lite-editor.service';
import { fromEvent, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  skip,
  tap
} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-control',
  templateUrl: './control.component.html',
  styleUrls: ['./control.component.scss']
})
export class ControlComponent implements OnInit {
  @Input() question: Question;
  @Input() formData: any;
  @Output() output = new EventEmitter<any>();
  @Output() multiOutput = new EventEmitter<any>();
  @Output() control = new EventEmitter<AbstractControl>();
  @Output() hiddenKey = new EventEmitter<string>();

  recalculateControl = false;

  recalculateOnResize$: Subscription;

  lastWindowWidth = window.innerWidth;

  constructor(
    public liteEditorService: LiteEditorService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    // fix PHP issue where it can map empty objects into empty arrays :|
    if (
      Array.isArray(this.question.control) &&
      this.question.control.length === 0
    ) {
      this.question.control = {};
    }

    this.setRecalculationLogicOnResize(this.question.type);
  }

  // This is fix for label which sometimes overlap input
  setRecalculationLogicOnResize(questionType: string): void {
    const shouldSetRecalculate = ['input'].indexOf(questionType) !== -1;
    if (shouldSetRecalculate) {
      this.recalculateOnResize$ = fromEvent(window, 'resize')
        .pipe(
          debounceTime(700),
          filter(() => this.lastWindowWidth !== window.innerWidth),
          tap(() => (this.lastWindowWidth = window.innerWidth)),
          map(() => window.innerWidth),
          distinctUntilChanged(),
          tap(() => (this.recalculateControl = true)),
          tap(() => this.cdr.detectChanges()),
          tap(() => (this.recalculateControl = false)),
          untilDestroyed(this)
        )
        .subscribe();
    }
  }
}
