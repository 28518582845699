<div class="row">
  <div class="col s12 center-align">
    <h4 class="center">{{ titleLabel }}</h4>
    <br />
    <p *ngIf="!useHtmlDescription"
       class="center">{{ descriptionLabel }}</p>
    <p *ngIf="useHtmlDescription"
       class="center"
       [innerHtml]="descriptionLabel"></p>
    <a *ngIf="!shouldClose" class="btn close-button" (click)="onClose()">{{ closeLabel }}</a>
    <p *ngIf="shouldClose"
       class="text-error center">
      {{ 'COMMON.FINAL_PAGE.POST_CLOSE_WINDOW' | translate }}
    </p>
  </div>
</div>
