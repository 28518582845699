import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TermsQuestionParams } from '../../../forms/form/controls/terms-control/terms-question.model';
import { Materialize } from '../../../materialize';
import { nl2br, nonEmpty } from '../../../common/utils/string-utils.helper';
import { environment } from 'src/environments/environment';
import * as htmlToText from 'html-to-text';
import { htmlToTextConfig } from './html-to-text.config';

declare const M: Materialize;

@Component({
  selector: 'app-edit-terms',
  templateUrl: './edit-terms.component.html',
  styleUrls: ['./edit-terms.component.scss']
})
export class EditTermsComponent implements OnInit {
  useHTML = false;

  @Input() control: TermsQuestionParams;
  @Input() key: string;

  @ViewChild('terms_area')
  private termsArea: ElementRef;

  readonly environment: typeof environment = environment;

  ngOnInit(): void {
    this.useHTML = nonEmpty(this.control.html_text);
    this.control.temporary_html_text = `${this.control.html_text}`;
  }

  onUseHTMLChange(): void {
    if (this.useHTML) {
      this.control.html_text = this.control.text
        ? nl2br(this.control.text)
        : '';
      this.control.text = '';

      this.control.temporary_html_text = `${this.control.html_text}`;
    } else {
      /**
       * docs: https://github.com/html-to-text/node-html-to-text
       * default formatters templates: https://github.com/html-to-text/node-html-to-text/blob/master/lib/formatter.js
       */
      this.control.text = htmlToText.htmlToText(
        this.control.html_text,
        htmlToTextConfig
      );
      this.control.html_text = '';
      this.control.temporary_html_text = '';
    }
  }

  onEditorValueChange({ editorContent }, control): void {
    control.html_text = editorContent;
  }

  onPasteText(): void {
    /**
     * this is because on paste to textarea, there are additional lines added
     * and also some wrapping limits which are not needed here (we want to to spread to max width).
     * Because changeModel event is after paste, below code is in setTimeout block
     * to be sure that it is the last version used as value
     */
    setTimeout(() => {
      const textWithoutWordWrap = htmlToText.htmlToText(this.control.text, {
        wordwrap: null,
        preserveNewlines: true
      });
      this.control.text = textWithoutWordWrap.replace(
        new RegExp('\n\n', 'g'),
        '\n'
      ); // reduce unnecessary additional line spaces
    });
  }
}
