import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Modal } from '../../materialize';

@Component({
  selector: 'app-check-qrcode',
  templateUrl: './check-qrcode.component.html',
  styleUrls: ['./check-qrcode.component.scss']
})
export class CheckQrcodeComponent {
  @Input() qrcodeUrl: string;
  @Input() headerTitle: string;
  @Output() closeClick = new EventEmitter<boolean>();
  @Output() cancelled = new EventEmitter();
  confirmModal: Modal;

  prevPage(): void {
    this.confirmModal.open();
  }
}
