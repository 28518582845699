import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { CheckinEligiblePatient } from '../checkin-eligible-patient.model';
import { AbstractControl, FormGroup } from '@angular/forms';
import {
  DateQuestion,
  DateQuestionParams,
  InputType as DateInputType
} from '../../../forms/form/controls/date-control/date-question.model';
import { TranslateService } from '@ngx-translate/core';
import {
  InputQuestion,
  InputQuestionParams,
  InputType
} from '../../../forms/form/controls/input-control/input-question.model';
import { Materialize } from '../../../materialize';
import { AlertsService } from '../../../common/alerts/alerts.service';
import { KioskCheckinService } from '../kiosk-checkin.service';
import { CheckinResponse } from '../checkin-response.model';
import { CheckinRequest } from '../checkin-request.model';

declare const M: Materialize;

@Component({
  selector: 'app-checkin-verify',
  templateUrl: './checkin-verify.component.html',
  styleUrls: ['./checkin-verify.component.scss']
})
export class CheckinVerifyComponent implements OnInit, AfterViewInit {
  @Input() selectedPatient: CheckinEligiblePatient;
  @Input() nextPageEvents: EventEmitter<any>;
  @Output() checkInResult = new EventEmitter<CheckinResponse>();
  isLoading: boolean;
  private formData: any = {};
  private formGroup: FormGroup;

  constructor(
    private translateService: TranslateService,
    private alertsService: AlertsService,
    private checkinService: KioskCheckinService
  ) {}

  ngOnInit(): void {
    this.formGroup = new FormGroup({});
    this.nextPageEvents.subscribe(() => this.onContinue());
  }

  ngAfterViewInit(): void {
    this.updateControlsUI();
  }

  updateControlsUI(): void {
    M.updateTextFields();
  }

  onFormDataChange(questionKey: string, value: any): void {
    this.formData[questionKey] = value;
  }

  onFormControlSet(questionKey: string, value: AbstractControl): void {
    this.formGroup.setControl(questionKey, value);
  }

  async onContinue(): Promise<any> {
    if (this.formGroup === undefined) {
      return true;
    } else {
      this.formGroup.markAllAsTouched();
      if (!this.formGroup.valid) {
        this.alertsService.showError(
          this.translateService.instant('KIOSK.CHECKIN.VALIDATION.GENERAL')
        );
        return true;
      }

      // valid, let's try checkin
      try {
        this.isLoading = true;
        this.checkInResult.emit(
          await this.checkinService.checkInPatient({
            patient_id: this.selectedPatient.id,
            date_of_birth: this.formData.date_of_birth,
            mobile_phone: this.formData.mobile_phone
          } as CheckinRequest)
        );
      } catch (e) {
        this.alertsService.showApiError(e);
      } finally {
        this.isLoading = false;
      }
    }
  }

  dobVerifyQuestion(): DateQuestion {
    return {
      key: 'date_of_birth',
      title: this.translateService.instant('KIOSK.CHECKIN.VERIFY.DOB_HINT'),
      control: {
        input_type: DateInputType.Past
      } as DateQuestionParams
    } as DateQuestion;
  }

  phoneVerifyQuestion(): InputQuestion {
    return {
      key: 'mobile_phone',
      title: this.translateService.instant('KIOSK.CHECKIN.VERIFY.PHONE_HINT'),
      control: {
        input_type: InputType.Phone
      } as InputQuestionParams
    } as InputQuestion;
  }
}
