<form [formGroup]="form" [ngClass]="{ disabled: form.disabled }">
  <div class="quill-overlay" *ngIf="form.disabled"></div>
  <div class="custom-toolbar">
    <div class="back-next"></div>
    <div class="formats"></div>
    <div class="dropdown-format"></div>
    <div class="alignment"></div>
    <div class="lists"></div>
    <div class="indents"></div>
    <div class="coloring"></div>
    <div class="image"></div>
    <button
      class="custom-dropdown"
      mat-button
      [matMenuTriggerFor]="placeholders.menu"
    >
      <span>{{
        'HTML_EDITOR.PLACEHOLDERS.INSERT_PLACEHOLDERS' | translate
      }}</span>
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <app-menu-item
      #placeholders
      [items]="items$ | async"
      (itemSelected)="onPlaceholderSelected($event)"
    >
    </app-menu-item>
  </div>
  <quill-editor
    #editor
    format="html"
    class="quill-message"
    [formControl]="form.controls['editorContent']"
    [modules]="modules"
    (onEditorCreated)="onEditorCreated($event)"
  >
  </quill-editor>
</form>
