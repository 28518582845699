import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Materialize, Modal } from '../../materialize';

declare const M: Materialize;

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements AfterViewInit {
  @Input() modalId: string;
  @Input() startOpened = false;
  @Output() modalPluginSet = new EventEmitter<Modal>();
  @Output() modalClosed = new EventEmitter<boolean>();
  @Output() modalOpened = new EventEmitter<void>();
  @ViewChild('modal')
  private modal: ElementRef;
  private modalPlugin: Modal;

  ngAfterViewInit(): void {
    this.modalPlugin = M.Modal.init(this.modal.nativeElement, {
      onOpenStart: () => this.modalOpened.emit(),
      onCloseEnd: () => this.modalClosed.emit(true)
    });
    if (this.startOpened) {
      this.modalPlugin.open();
    }
    this.modalPluginSet.emit(this.modalPlugin);
  }

  closeModal(): void {
    this.modalPlugin.close();
  }
}
