import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { HtmlEditorComponent } from './html-editor.component';
import { HtmlViewComponent } from './html-view/html-view.component';
import { MenuItemModule } from 'src/app/common/menu-item/menu-item.module';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { SingleInputDialogModule } from './single-input-dialog/single-input-dialog.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MenuItemModule,
    MatMenuModule,
    MatIconModule,
    TranslateModule,
    SingleInputDialogModule,
    QuillModule.forRoot()
  ],
  exports: [HtmlEditorComponent, HtmlViewComponent],
  declarations: [HtmlEditorComponent, HtmlViewComponent],
  providers: []
})
export class HtmlEditorModule {}
