import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { QuillEditorComponent, QuillModules } from 'ngx-quill';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {
  concatMap,
  filter,
  finalize,
  map,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as Quill from 'quill';
import {
  defer,
  from,
  fromEvent,
  Observable,
  of,
  Subject,
  Subscription
} from 'rxjs';
import { TableBlockEmbed, HighlightBlockEmbed } from './custom-blocks';
import {
  MenuItem,
  SingleMenuItem
} from 'src/app/common/menu-item/menu-item.model';
import { DropdownService } from './custom-dropdown/dropdown.service';
import { MatDialog } from '@angular/material/dialog';
import { SingleInputDialogComponent } from './single-input-dialog/single-input-dialog.component';
import _snakeCase from 'lodash.snakecase';
import { SingleInputDialogData } from './single-input-dialog/single-input-dialog.model';
import { TranslateService } from '@ngx-translate/core';
import { quillDecodeIndent } from './encode-decode-indent-sublists';
import ImageResize from 'quill-image-resize-module';
import { ImageDrop } from 'quill-image-drop-module';
import { AlertsService } from '../../../common/alerts/alerts.service';
import { getQuillModulesConfig } from './quill-modules.config';
const QuillAlignClasses = Quill.import('attributors/style/align');
const Delta = Quill.import('delta');

@UntilDestroy()
@Component({
  selector: 'app-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss', './html-unify-styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HtmlEditorComponent),
      multi: true
    }
  ]
})
export class HtmlEditorComponent
  implements OnInit, OnChanges, ControlValueAccessor {
  @ViewChild('editor', {
    static: true
  })
  editor: QuillEditorComponent;

  get quill(): Quill {
    return this.editor && (this.editor.quillEditor as Quill);
  }

  @Input()
  initialValue: string;

  @Output()
  valueChanges: EventEmitter<{ editorContent: string }> = new EventEmitter<{
    editorContent: string;
  }>();

  form: FormGroup = new FormGroup({
    editorContent: new FormControl('')
  });

  quillClipboardImageOff = true;

  quillEditor: Quill;

  private editorCreated$: Subject<Quill> = new Subject<Quill>();

  private value: string;

  items$: Observable<MenuItem[]>;

  modules: QuillModules = getQuillModulesConfig(this);

  valueChanges$: Subscription = this.form.valueChanges
    .pipe(
      tap(({ editorContent }) => {
        this.valueChanges.emit({
          editorContent: quillDecodeIndent(editorContent)
        });
      }),
      tap(({ editorContent }) =>
        this.emitChangeAsFormControl(quillDecodeIndent(editorContent))
      ),
      untilDestroyed(this)
    )
    .subscribe();

  editorSetupAfterInit$: Subscription = this.editorCreated$
    .pipe(
      take(1),
      tap((quill) => {
        this.quillEditor = quill;
      }),
      tap(() => {
        this.quillEditor.clipboard.addMatcher('TABLE', (node, delta) => {
          const tableTagStyles = node.getAttribute('style');

          /*
           * These styles need to be added here, not just in css because we want it
           * also the same way in generated PDF
           * */
          const forceStyles = `margin: 0 auto !important;`; // force table to be always at the center.
          return new Delta([
            {
              insert: {
                TableBlockEmbed:
                  `<style>#tableId {${tableTagStyles} ${forceStyles}  }</style>` +
                  delta.ops[0].insert.TableBlockEmbed
              }
            }
          ]);
        });

        this.editor.elementRef.nativeElement
          .querySelector('.ql-toolbar')
          .addEventListener('click', () => {
            if (
              this.editor.elementRef.nativeElement.querySelector(
                '.ql-container div[style*="position: absolute"]'
              )
            ) {
              this.editor.elementRef.nativeElement
                .querySelector('.ql-editor')
                .click();
            }
          });
      })
    )
    .subscribe();

  constructor(
    private cd: ChangeDetectorRef,
    public readonly translateService: TranslateService,
    private readonly matDialog: MatDialog,
    private readonly dropdownService: DropdownService,
    public readonly alertService: AlertsService
  ) {
    Quill.register('modules/ImageResize', ImageResize);
    Quill.register('modules/imageDrop', ImageDrop);
    // do not use classes, only inline styles (this is due to pdf)
    Quill.register(QuillAlignClasses, false);
    // allow at least pasting tables
    Quill.register(TableBlockEmbed, true);
    Quill.register('formats/mark', HighlightBlockEmbed);
  }

  ngOnInit(): void {
    this.cd.detectChanges();
    this.items$ = this.dropdownService.placeholders$;
    this.pasteImagesFromClipboard();
  }

  ngOnChanges({ initialValue }: SimpleChanges): void {
    if (initialValue && initialValue.firstChange) {
      setTimeout(() => {
        // this setTimeout is because of expressionChangeAfterItHasBeenChecked
        this.form.setControl(
          'editorContent',
          new FormControl(initialValue.currentValue)
        );
      });
    }
  }

  onEditorCreated(quill: Quill): void {
    this.editorCreated$.next(quill);
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  emitChangeAsFormControl(value): void {
    this.value = value;
    this.onChange(value);
    this.onTouched(value);
  }

  writeValue(value: string): void {
    const newValue = this.dropdownService.highlightPlaceholders(value);
    this.value = newValue;
    this.form.get('editorContent').setValue(newValue);
  }

  registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
    this.cd.markForCheck();
  }

  pasteImagesFromClipboard(): void {
    // source: https://gist.github.com/dusanmarsa/2ca9f1df36e14864328a2bb0b353332e
    const IMAGE_MIME_REGEX = /^image\/(p?jpeg|png)$/i;

    fromEvent(window, 'paste')
      .pipe(
        switchMap((e: ClipboardEvent) => {
          this.quillEditor.clipboard.addMatcher('IMG', (node, delta) => {
            return new Delta().insert('');
          });
          const items = e.clipboardData.items;

          return from(Array.from(items)).pipe(
            concatMap((item) => {
              if (IMAGE_MIME_REGEX.test(item.type)) {
                return this.loadImage(item.getAsFile());
              } else {
                return of({});
              }
            }),
            finalize(() => {
              this.quillEditor.clipboard.matchers = this.quillEditor.clipboard.matchers.filter(
                (matcher) => {
                  const matcherElName = matcher[0];
                  return matcherElName !== 'IMG';
                }
              );
            })
          );
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  onPlaceholderSelected(item: SingleMenuItem): void {
    const { value, isOpenable = false, category } = item;
    const dialog$ = defer(() =>
      this.matDialog
        .open<
          SingleInputDialogComponent,
          Partial<SingleInputDialogData> | null,
          string | null
        >(SingleInputDialogComponent, {
          data: {
            title: this.translateService.instant(
              'HTML_EDITOR.PLACEHOLDERS.CREATE_PLACEHOLDER_DIALOG.TITLE'
            ),
            description: this.translateService.instant(
              'HTML_EDITOR.PLACEHOLDERS.CREATE_PLACEHOLDER_DIALOG.DESCRIPTION'
            )
          }
        })
        .afterClosed()
        .pipe(
          filter((result): result is string => !!result),
          map((v) => _snakeCase(v.trim().toLowerCase()))
        )
    );

    const obs$: Observable<{ value: string; category: string }> = isOpenable
      ? dialog$.pipe(map((str) => ({ value: str, category })))
      : of({ value, category });

    obs$.subscribe(({ value, category }) => {
      const injectedValue = `{{${value}}}`;

      /* getSelection works only if editor is focused */
      this.quill.focus();
      const { index = 0 } = this.quill.getSelection();

      this.quill.insertEmbed(
        index,
        'highlight',
        { value: injectedValue, category },
        'user'
      );
      this.quill.insertText(index + 1, ' ');
      this.quill.setSelection(index + 2, 'silent');
    });
  }

  loadImage(file: File): Promise<string | ArrayBuffer> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const img = document.createElement('img');
        // @ts-ignore
        img.src = e.target.result;

        const range = window.getSelection().getRangeAt(0);
        range.deleteContents();
        range.insertNode(img);
        resolve(e.target.result);
      };
      reader.readAsDataURL(file);
    });
  }
}
