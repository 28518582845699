<div class="row">
  <div class="col s12">
    <p class="question-title">{{ question.title }}</p>
    <span class="helper-text light"
          *ngIf="question.text">{{
      question.text
    }}</span>
    <a class="btn mb-1" target="_blank" href="{{ question.control.link }}">
    {{
        question.control.action
          ? question.control.action
          : question.control.link
      }}
    </a>
  </div>
</div>
