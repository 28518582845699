import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppCommonModule } from '../common/common.module';
import { KioskMainComponent } from './kiosk-main/kiosk-main.component';
import { CookieModule, CookieService } from 'ngx-cookie';
import { RegisterComponent } from './register/register.component';
import { KioskEntryComponent } from './kiosk-entry/kiosk-entry.component';
import { FormsModule } from '@angular/forms';
import { AuthService } from '../common/authentication/auth.service';
import { PusherService } from '../common/pusher/pusher.service';
import { TranslateModule } from '@ngx-translate/core';
import { VideoComponent } from './video/video.component';
import { KioskRequestsService } from './kiosk-service/kiosk-requests.service';
import { KioskCheckinModule } from './kiosk-checkin/kiosk-checkin.module';
import { GetAppModule } from './get-app/get-app.module';
import { ConnectionStatusBadgeComponent } from './connection-status-badge/connection-status-badge.component';
import { TextMaskModule } from 'angular2-text-mask';
import { TwoFaService } from '../common/authentication/two-fa.service';

@NgModule({
  declarations: [
    KioskMainComponent,
    RegisterComponent,
    KioskEntryComponent,
    VideoComponent,
    ConnectionStatusBadgeComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    FormsModule,
    TranslateModule,
    KioskCheckinModule,
    CookieModule.forRoot(),
    GetAppModule,
    TextMaskModule
  ],
  providers: [
    AuthService,
    PusherService,
    KioskRequestsService,
    CookieService,
    TwoFaService
  ]
})
export class KioskModule {}
