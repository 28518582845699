import { Inject, Injectable } from '@angular/core';
import { API_URL } from '../../app.config';
import { DeviceRegisterRequest } from './device-register-request.model';
import { PracticeService } from '../../common/practice';
import { ActionResponse } from '../../common/mobiati-http/action-response.model';
import { AuthService } from '../../common/authentication/auth.service';
import { MobiatiHttpService } from '../../common/mobiati-http/mobiati-http.service';
import { KioskDeviceConfig } from '../kiosk-device/kiosk-device-config.model';
import { RegisterDeviceResponse } from './register-device-response.model';
import { KioskDeviceService } from '../kiosk-device/kiosk-device.service';
import { PendingFormOrConsentRequest } from './pending-form-or-consent-request.model';
import { CookieService } from 'ngx-cookie';
import { RegisterDeviceUsingPinCodeResponse } from './register-device-using-pin-code-response.model';
import { PairDeviceUsingPinCodeResponse } from './pair-device-using-pin-code.response.model';
import { RegisterDeviceUsingPinCodeRequest } from './register-device-using-pin-code-request.model';

@Injectable({
  providedIn: 'root'
})
export class KioskService {
  private kioskAuthToken: string;

  constructor(
    protected http: MobiatiHttpService,
    protected practiceService: PracticeService,
    protected kioskDeviceService: KioskDeviceService,
    protected authService: AuthService,
    protected cookieService: CookieService,
    @Inject(API_URL) protected apiUrl: string
  ) {
    this.kioskAuthToken = this.cookieService.get('kiosk_auth_token');
  }

  async registerDevice(
    deviceInfo: DeviceRegisterRequest
  ): Promise<RegisterDeviceResponse> {
    this.http.setAuthToken(await this.getKioskAuthToken());
    return this.http.post<RegisterDeviceResponse>(
      this.coreUrl() + 'devices',
      deviceInfo
    );
  }

  async registerDeviceUsingPinCode(
    registerDeviceUsingPinCodeRequest: RegisterDeviceUsingPinCodeRequest
  ): Promise<RegisterDeviceUsingPinCodeResponse> {
    return this.http.post<RegisterDeviceUsingPinCodeResponse>(
      `${this.apiUrl}/kiosk-devices-pin-code`,
      registerDeviceUsingPinCodeRequest
    );
  }

  pairDeviceUsingPinCode(
    kioskDeviceId: number
  ): Promise<PairDeviceUsingPinCodeResponse> {
    return this.http.post<PairDeviceUsingPinCodeResponse>(
      `${this.apiUrl}/kiosk-devices/${kioskDeviceId}/pair`
    );
  }

  async getDeviceConfig(): Promise<KioskDeviceConfig> {
    this.http.setAuthToken(await this.getKioskAuthToken());
    return this.http.get<KioskDeviceConfig>(
      this.coreUrl() + `devices/${this.kioskDeviceService.getDeviceId()}/config`
    );
  }

  async checkFormRequestForDevice(): Promise<PendingFormOrConsentRequest> {
    this.http.setAuthToken(await this.getKioskAuthToken());
    return this.http.get<PendingFormOrConsentRequest>(
      this.coreUrl() + 'request-patient-forms/' + this.deviceId()
    );
  }

  async ackFormRequest(code: string, busy: boolean): Promise<ActionResponse> {
    this.http.setAuthToken(await this.getKioskAuthToken());
    return this.http.patch<ActionResponse>(
      this.coreUrl() + 'request-patient-forms/' + this.deviceId() + '/' + code,
      { busy }
    );
  }

  async checkConsentRequestForDevice(): Promise<PendingFormOrConsentRequest> {
    this.http.setAuthToken(await this.getKioskAuthToken());
    return this.http.get<PendingFormOrConsentRequest>(
      this.coreUrl() + 'consent_requests/' + this.deviceId()
    );
  }

  async ackConsentRequest(
    code: string,
    busy: boolean
  ): Promise<ActionResponse> {
    this.http.setAuthToken(await this.getKioskAuthToken());
    return this.http.patch<ActionResponse>(
      this.coreUrl() + 'consent_requests/' + this.deviceId() + '/' + code,
      { busy }
    );
  }

  protected coreUrl(): string {
    return `${this.apiUrl}/${this.practiceService.getPracticeGuid()}/kiosk/`;
  }

  protected deviceId(): string {
    return this.kioskDeviceService.getDeviceId();
  }

  async getKioskAuthToken(): Promise<string> {
    if (!this.kioskAuthToken) {
      try {
        const kioskLogin = await this.authService.logInClient(
          'kiosk',
          this.practiceService.getPracticeGuid()
        );
        this.kioskAuthToken = kioskLogin.access_token;
        const expires = new Date(new Date().getTime() + 86400000);
        this.cookieService.put('kiosk_auth_token', this.kioskAuthToken, {
          expires
        });
      } catch (e) {
        this.kioskAuthToken = null;
      }
    }

    return Promise.resolve(this.kioskAuthToken);
  }
}
