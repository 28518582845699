import { Question } from '../../question.model';

export enum InputType {
  Number = 'number',
  Name = 'name',
  Ssn = 'ssn',
  Phone = 'phone',
  Email = 'email',
  Zip = 'zip',
  Initials = 'initials'
}

export interface InputQuestionParams {
  hint: string;
  input_type: InputType;
  phone_prefix?: string;
}

export interface InputQuestion extends Question {
  control?: InputQuestionParams;
}
