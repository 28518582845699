import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ConsentPaymentOptionSelectionHookComponent } from './consent-payment-option-selection-hook.component';
import { SafePipeModule } from 'safe-pipe';
import { LoaderModule } from '../../../+lite-editor/components';

@NgModule({
  declarations: [ConsentPaymentOptionSelectionHookComponent],
  exports: [ConsentPaymentOptionSelectionHookComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SafePipeModule,
    LoaderModule
  ]
})
export class ConsentPaymentOptionSelectionHookModule {}
