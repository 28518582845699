import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { SectionGroup } from '../../../../forms/form/section-group.model';
import { AbstractControl } from '@angular/forms';
import { AlertsService } from '../../../../common/alerts/alerts.service';
import { TranslateService } from '@ngx-translate/core';
import { Question } from '../../../../forms/form/question.model';
import { agregateQuestionsToSections } from '../../../../forms/utils';
import { PlaceholdersService } from '../../../../common/placeholders-service/placeholders.service';
import { RejectTpConsent } from '../reject-tp-consent.types';

@Component({
  selector: 'app-refuse-consent-preview',
  templateUrl: './refuse-consent-preview.component.html',
  styleUrls: ['./refuse-consent-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RefuseConsentPreviewComponent implements OnInit {
  @Input() formSpec: RejectTpConsent;
  @Input() accepted: boolean = false;

  @Output() consentConfirmed: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();

  @Output() submitClicked: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('body')
  private bodyElement: ElementRef;

  groups: SectionGroup[];
  currentGroupIdx = 0;
  formData: object = {};

  private groupFormControls: object = {};

  constructor(
    private readonly alertsService: AlertsService,
    private readonly translateService: TranslateService,
    private readonly placeholderService: PlaceholdersService
  ) {}

  ngOnInit(): void {
    this.transformFormSpecToClassInstances();
    this.groups = this.createFormGroups(this.formSpec.spec);

    this.placeholderService.setPlaceholderMap(this.formSpec.placeholders);

    if (this.accepted) {
      this.consentConfirmed.next(true);
    }
  }

  /**
   *  Create form groups based on section field
   */
  createFormGroups(questions: Question[]): SectionGroup[] {
    return agregateQuestionsToSections(questions);
  }

  nextSection(): void {
    this.checkGroupValidation();
    if (!this.isGroupValid()) {
      this.alertsService.showError(
        this.translateService.instant('FORMS.VALIDATION.GENERAL')
      );
      return;
    }

    if (this.currentGroupIdx < this.groups.length - 1) {
      this.groupFormControls = {};
      this.currentGroupIdx++;

      if (this.bodyElement && this.bodyElement.nativeElement) {
        this.bodyElement.nativeElement.scrollTop = 0;
      }
    } else {
      this.submitForm();
    }
  }

  submitForm(): void {
    this.submitClicked.next();
  }

  prevSection(): void {
    if (this.currentGroupIdx > 0) {
      this.groupFormControls = {};
      this.currentGroupIdx--;
      this.bodyElement.nativeElement.scrollTop = 0;
    }
  }

  onFormDataChange(questionKey: string, value: any): void {
    this.formData[questionKey] = value;

    if (questionKey === 'consent') {
      this.refuseConsentChanged();
    }
  }

  onFormControlSet(questionKey: string, value: AbstractControl): void {
    if (questionKey === 'consent') {
      value.setValue(this.accepted);
    }

    this.groupFormControls[questionKey] = value;
  }

  isGroupValid(): boolean {
    return this.allGroupControls().reduce((valid, control) => {
      return valid && control.valid;
    }, true);
  }

  checkGroupValidation(): void {
    return this.allGroupControls().forEach((control: AbstractControl) =>
      control.markAllAsTouched()
    );
  }

  private allGroupControls(): any[] {
    return Object.keys(this.groupFormControls).map(
      (key) => this.groupFormControls[key]
    );
  }

  private transformFormSpecToClassInstances(): void {
    this.formSpec.spec = this.formSpec.spec
      .filter((question) => question.type !== 'block_signature')
      .map((question) => Object.assign(new Question(), question));
  }

  private refuseConsentChanged(): void {
    this.consentConfirmed.next(this.formData['consent']?.value);
  }
}
