<div class="col s12">
  <div class="row">
    <div class="col s12">
      <p class="text-primary">Options</p>
      <div class="list-options"
           cdkDropList
           (cdkDropListDropped)="drop($event, control.options)">
        <div class="list-options__item"
             *ngFor="let answerOption of control.options; index as i"
             cdkDrag>
          <app-edit-option [answerOption]="answerOption"
                           [questionKey]="key"
                           [index]="i"
                           [isPMSProblem]="false"
                           (deleting)="onDeleteOption($event)">
          </app-edit-option>
        </div>
      </div>
      <div>
        <button class="btn"
                (click)="onAddOption()">
          <i class="material-icons">add</i>
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="input-field col s6">
      <input placeholder="Hint"
             id="{{ key + '-control-hint' }}"
             type="text"
             class="validate"
             [(ngModel)]="control.hint"
             (ngModelChange)="onHintChanged()" />
      <label for="{{ key + '-control-hint' }}">Hint</label>
    </div>
    <div class="input-field col s6">
      <input placeholder="Default value (key)"
             id="{{ key + '-control-default' }}"
             type="text"
             class="validate"
             [(ngModel)]="control.default"
             (ngModelChange)="onDefaultChange()" />
      <label for="{{ key + '-control-default' }}">Default value (key)</label>
    </div>
  </div>
  <div class="row">
    <div class="input-field col s6">
      <label>
        <input type="checkbox"
               class="filled-in"
               [(ngModel)]="control.other"
               [value]="true" />
        <span>"Other" option</span>
      </label>
    </div>
    <div class="input-field col s6">
      <label>
        <input type="checkbox"
               class="filled-in"
               [(ngModel)]="control.multi"
               [value]="true" />
        <span>Multi-select</span>
      </label>
    </div>
  </div>
</div>
