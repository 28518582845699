import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { DeviceService } from '../device-service/device.service';

@Injectable({ providedIn: 'root' })
export class ModentoClientHeaderInterceptor implements HttpInterceptor {
  constructor(private deviceService: DeviceService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const modentoClient = this.deviceService.getFullModentoClientInfo();

    request = request.clone({
      setHeaders: {
        'X-Modento-Client': modentoClient
      }
    });
    return next.handle(request);
  }
}
