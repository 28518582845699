import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CheckinResponse } from '../checkin-response.model';
import { PracticeService } from '../../../common/practice';
import { nonEmpty } from '../../../common/utils/string-utils.helper';
import { Router } from '@angular/router';
import { AlertsService } from '../../../common/alerts/alerts.service';
import { KioskCheckinService } from '../kiosk-checkin.service';
import { FormsRequestRequest } from '../forms-request-request.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-checked-in',
  templateUrl: './checked-in.component.html',
  styleUrls: ['./checked-in.component.scss']
})
export class CheckedInComponent implements OnInit {
  @Input() checkInResult: CheckinResponse;
  @Output() getAppClicked = new EventEmitter();
  @Output() submitting = new EventEmitter<boolean>();
  practiceHasApp: boolean;
  hasMissingForms: boolean;
  hasOptionalForms = false;
  hasMissingConsents: boolean;
  selectedFormSpecIds: object = {};
  showFormsList = false;
  showFinalThankYou = false;

  constructor(
    private practiceService: PracticeService,
    private alertService: AlertsService,
    private checkinService: KioskCheckinService,
    private translateService: TranslateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const practice = this.practiceService.getPractice();
    this.practiceHasApp = practice.published && nonEmpty(practice.app_url);
    this.checkInResult.form_specs.forEach(
      (formSpec) => (this.selectedFormSpecIds[formSpec.id] = formSpec.required)
    );
    this.hasMissingForms = !!Object.values(this.selectedFormSpecIds).find(
      (required) => !!required
    );

    if (!this.hasMissingForms) {
      this.hasOptionalForms = !!this.checkInResult.form_specs.length;
    }

    this.hasMissingConsents = !!this.checkInResult.required_consents?.length;

    if (
      !this.hasMissingForms &&
      !this.hasMissingConsents &&
      !this.hasOptionalForms
    ) {
      this.onExit();
    }
  }

  getSelectedFormIds(): number[] {
    if (this.hasMissingForms || this.hasMissingConsents) {
      return [
        ...this.checkInResult.form_specs,
        ...this.checkInResult.required_consents
      ]
        .filter((spec) => !!spec.required || !!spec.is_consent)
        .map((spec) => spec.id);
    } else if (this.hasOptionalForms) {
      return Object.keys(this.selectedFormSpecIds)
        .filter((key) => !!this.selectedFormSpecIds[key])
        .map((key) => +key);
    } else {
      return [];
    }
  }

  async onAccessForms(): Promise<any> {
    if (this.getSelectedFormIds().length === 0) {
      this.alertService.showError(
        this.translateService.instant(
          'KIOSK.CHECKIN.CHECKED_IN.NO_FORMS_SELECTED'
        )
      );
      return;
    }

    this.submitting.emit(true);
    try {
      const payload = {
        patient_id: this.checkInResult.patient_id,
        forms: this.getSelectedFormIds()
      } as FormsRequestRequest;
      const result = await this.checkinService.createPatientFormsRequest(
        payload,
        this.checkInResult.access_token
      );
      await this.router.navigate(
        [`f/${this.practiceService.getPracticeGuid()}/${result.code}`],
        {
          state: {
            checkedIn: true
          }
        }
      );
    } catch (e) {
      this.alertService.showApiError(e);
    } finally {
      this.submitting.emit(false);
    }
  }

  onGetApp(): void {
    this.getAppClicked.emit(true);
  }

  onExit(): void {
    this.showFinalThankYou = true;
  }

  onShowFormsListClick(showFormsList): void {
    this.showFormsList = showFormsList;
  }
}
