import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import * as moment from 'moment';
import { now } from 'moment';
import { nonEmpty } from '../utils/string-utils.helper';
import { isString } from 'util';

export class CustomValidators {
  static validDate(): ValidatorFn {
    return (fg: FormGroup): ValidationErrors => {
      const dateValue = fg.value;
      if (!dateValue) {
        return null;
      }

      if (/^.*_+.*$/.test(dateValue)) {
        // date contains unexpected mask symbol (_)
        return {
          validDate: {}
        };
      }

      if (!moment(fg.value, ['MM/DD/YYYY', 'YYYY-MM-DD 00:00:00']).isValid()) {
        return {
          validDate: {}
        };
      }
      return null;
    };
  }

  static required(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const val = isString(control.value)
        ? control.value.toString().trim()
        : control.value;
      if (nonEmpty(val)) {
        return null;
      } else {
        return { required: true };
      }
    };
  }

  static validEmail(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      // email validation RegExp based on new RFC 5322. Ts-ignore used since RegExp is too long and new RegExp() gives wrong results
      // tslint:disable-next-line:max-line-length
      const isValid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        control.value
      );
      return !isValid ? { email: true } : null;
    };
  }

  static validSelect(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      return Validators.required(control);
    };
  }

  static validPhone(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const isValid =
        /^\(\d{3}\) \d{3}-\d{4}$/.test(control.value) ||
        /^\d{10}$/.test(control.value);
      return !isValid ? { phone: true } : null;
    };
  }

  static validSSN(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const isValid =
        /^\d{3}-\d{2}-\d{4}$/.test(control.value) ||
        /^\d{9}$/.test(control.value);
      return !isValid ? { ssn: true } : null;
    };
  }

  static validInitials(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const isValid = /^[a-zA-Z]{2}$/.test(control.value);
      return !isValid ? { initials: true } : null;
    };
  }

  /**
   * Check if value is number
   */
  static isNumber(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const n = /^[.\d]+$/.test(control.value) ? +control.value : NaN;

      if (n !== n) {
        return { isNumber: true };
      }

      return null;
    };
  }

  /**
   * Check if value is boolean true
   */
  static isTrue(): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value !== true) {
        return { isTrue: true };
      }

      return null;
    };
  }

  static exactLength(requiredLength: number, errorKey: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const actualLength: number = control.value ? control.value.length : 0;
      return actualLength !== requiredLength
        ? {
            [errorKey]: {
              requiredLength,
              actualLength
            }
          }
        : null;
    };
  }

  static validFutureDate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      return moment(control.value, [
        'MM/DD/YYYY',
        'YYYY-MM-DD 00:00:00'
      ]).isBefore(now())
        ? { futureDate: true }
        : null;
    };
  }

  static validPastDate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const validatedDate = moment(control.value, [
        'MM/DD/YYYY',
        'YYYY-MM-DD 00:00:00'
      ]);
      return validatedDate.isAfter(now()) ||
        validatedDate.isBefore('1900-01-01')
        ? { pastDate: true }
        : null;
    };
  }
}
