import { CommonModule } from '@angular/common';
import { PaymentCardDetailsComponent } from './payment-card-details.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { PaymentCardDetailModule } from './payment-card-detail/payment-card-detail.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DeleteCardConfirmDialogModule } from './delete-card-confirm-dialog/delete-card-confirm-dialog.module';

@NgModule({
  declarations: [PaymentCardDetailsComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatIconModule,
    TranslateModule,
    ReactiveFormsModule,
    DeleteCardConfirmDialogModule,
    PaymentCardDetailModule
  ],
  exports: [PaymentCardDetailsComponent]
})
export class PaymentCardDetailsModule {}
