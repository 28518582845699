<div *ngIf="isSubmitting"
     class="screen-full-modal"
     (click)="(false)">
  <app-spinner class="screen-centered"></app-spinner>
</div>
<app-page-header class="page-header--treatment-plan"
                 [title]="consent.title"
                 [subtitle]="getPageName()"
                 [showCounter]="true"
                 [canGoBack]="true"
                 [canGoNext]="canGoNext"
                 [backIconName]="this.currentPageIdx > 0 ? 'chevron_left' : 'exit_to_app'"
                 (goBack)="prevPage()"
                 (goNext)="nextPage()"></app-page-header>
<div class="section no-pad-bot body"
     id="scroll-container"
     #body>

  <ng-container [ngSwitch]="pages[currentPageIdx]">
    <app-consent-content *ngSwitchCase="0"
                         [consent]="consent"
                         [agreed]="agreed"
                         (control)="onPageControlSet($event)">
    </app-consent-content>

    <app-consent-sign *ngSwitchCase="1"
                      [consent]="consent"
                      [output]="output"
                      (control)="onPageControlSet($event)"></app-consent-sign>
    <app-consent-payment-plans *ngSwitchCase="2"
                               [consent]="consent"
                               [output]="output"
                               [loanApplicationDoneEvent]="loanPreQualificationDoneEvent"
                               (paymentOptionHook)="onPaymentOptionHook($event)"
                               (control)="onPageControlSet($event)">
    </app-consent-payment-plans>
    <app-consent-payment-option-selection-hook *ngSwitchCase="3"
                                               [consent]="consent"
                                               [output]="output"
                                               (control)="onPageControlSet($event)"
                                               (nextPageDisabledEvent)="onNextPageDisabled($event)"
                                               (loanApplicationDoneEvent)="onLoanPreQualificationDone($event)"
                                               (showWisetackWarning)="onWisetackWarning($event)">
    </app-consent-payment-option-selection-hook>
    <app-reject-tp-consent *ngSwitchCase="4"
                           [refuseConsent]="consent.refuse_consent"
                           [accepted]="rejectConsentAccepted"
                           (consentConfirmed)="consentConfirmed($event)"
                           (submitClicked)="nextPage()">
    </app-reject-tp-consent>
    <app-consent-payment-plan-sign *ngSwitchCase="5"
                                   [consent]="consent"
                                   [paymentOption]="selectedPaymentOption"
                                   [output]="output"
                                   (control)="onPageControlSet($event)">
    </app-consent-payment-plan-sign>
  </ng-container>

  <div class="row">
    <div class="col s12 center-align">
      <button type="button"
              class="btn btn-primary"
              *ngIf="!nextPageDisabled && pages[currentPageIdx] !== 4"
              (click)="nextPage()">
        {{continueButtonLabel}}
      </button>
    </div>
    <div class="col s12 center-align">
      <button *ngIf="pages[currentPageIdx] === 5 && !nextPageDisabled"
              type="button"
              class="btn btn-secondary skip-button"
              (click)="submitWithoutPaymentPlan()">
        Choose later
      </button>
    </div>
  </div>
</div>
<app-confirm-modal (modalPluginSet)="confirmModal = $event"
                   (confirm)="cancelled.emit()">
  <p>{{ 'CONSENTS.EXIT.CONFIRM_MESSAGE' | translate }}</p>
</app-confirm-modal>
<app-confirm-modal (modalPluginSet)="confirmWisetackModal = $event"
                   (confirm)="nextPageAndSkipWarning()">
  <p>{{ 'CONSENTS.PAYMENT_OPTIONS.WISETACK.EXIT_CONFIRMATION' | translate }}</p>
</app-confirm-modal>

<app-drawing-canvas-menu
                         *ngIf="consent.status === 3 && consent.type === 1 && pages[currentPageIdx] === 0">
</app-drawing-canvas-menu>
