import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { KioskDeviceService } from '../../kiosk/kiosk-device/kiosk-device.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionTimeoutService {
  barHidden$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // timeout events
  private timeoutId: number;
  private active: boolean;
  private timeoutRoutes: string[];
  private readonly SOON_WARNING_SEC = 30;
  private timeoutSoonCallback: () => void;
  timeoutValue$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private onlyManualTimeoutReset = false;

  constructor(
    private router: Router,
    private kioskDeviceService: KioskDeviceService
  ) {
    this.active = false;

    this.addResetListeners();
  }

  getSessionTimeout(): number {
    return this.kioskDeviceService.getConfig().timeout;
  }

  private onInteraction(): void {
    if (!this.active) {
      return;
    }
    this.clearTimeout();
    this.timeoutId = this.setTimeout();
  }

  private sessionTimeout(): void {
    if (!this.active) {
      return;
    }
    this.endSession();
    this.router.navigate(this.timeoutRoutes, {
      replaceUrl: true
    } as NavigationExtras);
  }

  private sessionTimeoutSoon(): void {
    if (!this.active) {
      return;
    }
    if (this.timeoutSoonCallback) {
      this.timeoutSoonCallback();
    }
  }

  startSessionForTimeouts(
    timeoutRoutes: string[],
    timeoutSoonWarning: () => void
  ): void {
    this.endSession();
    this.active = true;
    this.timeoutRoutes = timeoutRoutes;
    this.timeoutSoonCallback = timeoutSoonWarning;
    this.onInteraction();
  }

  endSession(): void {
    this.barHidden$.next(false);
    this.active = false;
    this.clearTimeout();
  }

  allowResetTimeoutOnlyManually(): void {
    this.onlyManualTimeoutReset = true;
  }

  resetTimeoutManually(bringBackAutoReset: boolean = true): void {
    this.onInteraction();
    setTimeout(() => {
      if (bringBackAutoReset) {
        this.onlyManualTimeoutReset = false;
      }
    }, 500);
  }

  isOnlyManualTimeoutResetActive(): boolean {
    return this.onlyManualTimeoutReset;
  }

  private addResetListeners(): void {
    ['click', 'keydown', 'touchstart'].forEach((eventName) => {
      window.addEventListener(eventName, () => {
        if (!this.onlyManualTimeoutReset) {
          this.onInteraction();
        }
      });
    });
  }

  // below are timeout functions implemented as intervals, to work even when window and JS execution is paused in meantime (like on iOS)
  private setTimeout(): number {
    this.timeoutValue$.next(this.getSessionTimeout());

    return window.setInterval((value) => {
      this.timeoutValue$.next(this.timeoutValue$.value - 1);
      if (this.timeoutValue$.value === 0) {
        this.sessionTimeout();
      } else if (this.timeoutValue$.value === this.SOON_WARNING_SEC) {
        this.sessionTimeoutSoon();
      }
    }, 1000);
  }

  private clearTimeout(): void {
    window.clearInterval(this.timeoutId);
  }
}
