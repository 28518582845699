import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Materialize, Modal } from '../../materialize';
import { SectionGroup } from './section-group.model';
import { FormSpec } from './form-spec.model';
import { FormsRequestService } from '../forms-request/forms-request.service';
import { FormOutputModel } from './form-output.model';
import clonedeep from '../../../../node_modules/lodash.clonedeep';
import flatmap from '../../../../node_modules/lodash.flatmap';
import { Question } from './question.model';
import { MultiradioQuestion } from './controls/multiradio-control/multiradio-question.model';
import { AbstractControl } from '@angular/forms';
import { AlertsService } from '../../common/alerts/alerts.service';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { isNumber, isString } from 'util';
import { LiteEditorService } from '../../+lite-editor/services/lite-editor.service';
import { agregateQuestionsToSections } from '../utils';
import { SessionTimeoutService } from '../../common/session-timeout/session-timeout.service';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';
import * as momentDurationFormatSetup from 'moment-duration-format';
import { Practice } from 'src/app/common/practice';

momentDurationFormatSetup(moment);

declare const M: Materialize;

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent
  implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  @Input() formSpec: FormSpec;
  @Input() formData: object = {};
  @Input() practice: Practice;
  @Input() isPreview: boolean;
  @Input() isInactivityBarHidden: boolean;
  @Output() submitted = new EventEmitter<boolean>();
  @Output() cancelled = new EventEmitter<boolean>();
  @ViewChild('body')
  private bodyElement: ElementRef;
  groups: SectionGroup[];
  currentGroupIdx = 0;
  isSubmitting = false;
  private groupFormControls: object = {};
  confirmModal: Modal;
  feedbackModal: Modal;
  private previewURLs: string[] = [];
  validationEnabled: boolean;
  isHeaderVisible = false;
  languageLoaded = false;

  constructor(
    private formsService: FormsRequestService,
    private alertsService: AlertsService,
    private translateService: TranslateService,
    public liteEditorService: LiteEditorService,
    private sessionTimeoutService: SessionTimeoutService
  ) {}

  ngOnInit(): void {
    // for preview, don't validation by default
    this.validationEnabled = !this.isPreview && environment.formValidation;
    // assign form spec questions to class object instances
    this.transformFormSpecToClassInstances();
    this.groups = this.createFormGroups(this.formSpec.spec);
    this.isHeaderVisible = this.checkIfIsHeaderVisible();

    // translate UI to language of the form
    if (this.formSpec.language) {
      this.translateService
        .use(this.formSpec.language)
        .pipe(tap((_) => (this.languageLoaded = true)))
        .subscribe();
    }
  }

  ngAfterViewInit(): void {
    this.updateControlsUI();
    if (this.isPreview) {
      const fab: any[] = M.FloatingActionButton.init(
        document.querySelectorAll('.fixed-action-btn'),
        null
      );
      if (fab.length > 0) {
        fab[0].open();
      }
    }
  }

  ngAfterViewChecked(): void {
    // TODO: this is called too often
    this.updateControlsUI();
  }

  ngOnDestroy(): void {
    this.sessionTimeoutService.barHidden$.next(false);
    this.previewURLs.forEach((url) => {
      URL.revokeObjectURL(url);
    });
    // translate UI to default language of the navigator (browser)
  }

  updateControlsUI(): void {
    M.updateTextFields();
  }

  private transformFormSpecToClassInstances(): void {
    this.formSpec.spec = this.formSpec.spec.map((question) => {
      const questionInstance = Object.assign(new Question(), question);
      if (questionInstance.type === 'multiradio') {
        (questionInstance as MultiradioQuestion).control.questions = (questionInstance as MultiradioQuestion).control.questions.map(
          (subq) => Object.assign(new Question(), subq)
        );
      }
      return questionInstance;
    });
  }

  /**
   *  Create form groups based on section field
   */
  createFormGroups(questions: Question[]): SectionGroup[] {
    return agregateQuestionsToSections(questions);
  }

  nextSection(): void {
    if (this.validationEnabled) {
      this.checkGroupValidation();
      if (!this.isGroupValid()) {
        this.alertsService.showError(
          this.translateService.instant('FORMS.VALIDATION.GENERAL')
        );
        return;
      }
    }

    if (this.currentGroupIdx < this.groups.length - 1) {
      this.groupFormControls = {};
      this.currentGroupIdx++;

      if (this.bodyElement && this.bodyElement.nativeElement) {
        this.bodyElement.nativeElement.scrollTop = 0;
      }
    } else {
      if (this.isPreview) {
        this.downloadPDF();
      } else {
        this.submitForm();
      }
    }
  }

  prevSection(): void {
    if (this.currentGroupIdx > 0) {
      this.groupFormControls = {};
      this.currentGroupIdx--;
      this.bodyElement.nativeElement.scrollTop = 0;
    }
  }

  onCancel(): void {
    this.confirmModal.open();
  }

  async submitForm(): Promise<any> {
    this.isSubmitting = true;
    try {
      const output = this.prepareOutput();

      // save attachments, get their guids
      const attachments: FormData[] = this.prepareAttachments();
      for (const key in attachments) {
        if (attachments.hasOwnProperty(key)) {
          const response = await this.formsService.saveAttachment(
            attachments[key]
          );
          output.payload.answers[key] = response.attachment.guid;
        }
      }

      await this.formsService.saveForm(output);
      this.submitted.emit(true);
    } catch (e) {
      this.alertsService.showApiError(e);
    }
    this.isSubmitting = false;
  }

  private prepareOutput(): FormOutputModel {
    // work on copy of formData and only limited to keys actually defined in form spec JSON
    const questionKeys = this.getAllQuestionKeys();
    const formDataCopy: object = clonedeep(this.formData);

    const output = {
      form_spec_id: this.formSpec.id > 0 ? this.formSpec.id : undefined,
      form_spec:
        !isNumber(this.formSpec.id) || this.formSpec.id === 0
          ? this.formSpec
          : undefined,
      payload: {
        answers: Object.keys(formDataCopy)
          .filter((key) => questionKeys.indexOf(key) >= 0)
          .reduce(
            (obj, key) =>
              Object.assign(obj, {
                [key]: isString(formDataCopy[key])
                  ? formDataCopy[key].trim()
                  : formDataCopy[key]
              }),
            {}
          )
      }
    } as FormOutputModel;

    console.log(output);
    return output;
  }

  /**
   * Collect all attachments in the current form
   */
  private prepareAttachments(): FormData[] {
    return Object.keys(this.formData)
      .filter((key) => {
        return this.formData[key] instanceof FormData;
      })
      .reduce((obj, key) => {
        obj[key] = this.formData[key];
        // add patient to link attachment with given patient if possible
        if ((this.formData as any).id !== undefined) {
          obj[key].append('patient_id', (this.formData as any).id);
        }
        return obj;
      }, []) as FormData[];
  }

  /**
   * Return all questions keys in the form spec, including ones embedded inside multiradio control.
   * Also filters out keys from hidden controls.
   */
  private getAllQuestionKeys(): string[] {
    return flatmap(
      this.formSpec.spec.filter((q) => q.isVisible(this.formData)),
      (q: Question) => {
        if (q.type === 'multiradio') {
          return [
            q.key,
            ...(q as MultiradioQuestion).control.questions.map(
              (subq) => subq.key
            )
          ];
        } else {
          return [q.key];
        }
      }
    );
  }

  onFormDataChange(questionKey: string, value: any): void {
    this.formData[questionKey] = value;
  }

  onFormDataMultiChange(valuesMapping: object): void {
    this.formData = { ...this.formData, ...valuesMapping };
  }

  onFormControlSet(questionKey: string, value: AbstractControl): void {
    this.groupFormControls[questionKey] = value;
  }

  onHiddenFormControl(questionKey: string): void {
    delete this.groupFormControls[questionKey];
  }

  isGroupValid(): boolean {
    return this.allGroupControls().reduce((valid, control) => {
      return valid && control.valid;
    }, true);
  }

  checkGroupValidation(): void {
    return this.allGroupControls().forEach((control: AbstractControl) =>
      control.markAllAsTouched()
    );
  }

  private allGroupControls(): any[] {
    return Object.keys(this.groupFormControls).map(
      (key) => this.groupFormControls[key]
    );
  }

  checkV1InsuranceModal(insuranceV1Modal: Modal): void {
    // V1 backwards support for insurance forms
    if (
      this.formSpec.type === 'insurance_card' ||
      this.formSpec.type === 'insurance_text'
    ) {
      insuranceV1Modal.open();
    }
  }

  onNoInsurance(): void {
    this.submitted.emit();
  }

  async downloadPDF(): Promise<any> {
    this.isSubmitting = true;

    try {
      this.alertsService.showInfo(
        'Creating PDF file. This may take a while, please wait...'
      );
      const output = this.prepareOutput();
      // we don't send attachments here, just clear them from output
      const attachments: FormData[] = this.prepareAttachments();
      for (const key in this.prepareAttachments()) {
        if (attachments.hasOwnProperty(key)) {
          // TODO: saving could be here
          // const response = await this.formsService.saveAttachment(attachments[key]);
          output.payload.answers[key] = null;
        }
      }
      const data = await this.formsService.getPreviewFormPDF(output);
      const fileURL = URL.createObjectURL(data);
      window.open(fileURL);
      this.previewURLs.push(fileURL);
    } catch (e) {
      this.alertsService.showApiError(e);
    } finally {
      this.isSubmitting = false;
    }
  }

  toggleValidation(): void {
    this.validationEnabled = !this.validationEnabled;
    this.alertsService.showInfo(
      this.translateService.instant(
        this.validationEnabled
          ? 'FORMS.TOOLBAR.VALIDATION_ENABLED'
          : 'FORMS.TOOLBAR.VALIDATION_DISABLED'
      )
    );
  }

  sendFeedback(): void {
    this.feedbackModal.open();
  }

  private checkIfIsHeaderVisible(): boolean {
    return !(
      this.groups.length === 1 &&
      this.groups[0].groupTitle === this.formSpec.title
    );
  }
  // tslint:disable-next-line:max-file-line-count
}
