<app-page-header [title]="headerTitle"
                 [subtitle]=""
                 [canGoBack]="true"
                 [canGoNext]="false"
                 [backIconName]="'exit_to_app'"
                 (goBack)="prevPage()"></app-page-header>
<div class="section no-pad-bot body"
     id="scroll-container">
  <div class="container">
    <div class="row">
      <div class="col s8 offset-s2 center-align">
        <h4>
          {{ 'KIOSK.GET_APP.INSTRUCTION' | translate }}
        </h4>
        <p class="mb-3">
          {{ 'KIOSK.GET_APP.INSTRUCTION_SCANNER' | translate }}
        </p>
        <qr-code [value]="qrcodeUrl"
                 [size]="250"></qr-code>
      </div>
    </div>
  </div>
</div>
<app-confirm-modal (modalPluginSet)="confirmModal = $event"
                   (confirm)="cancelled.emit()">
  <p>{{ 'CONSENTS.EXIT.CONFIRM_MESSAGE' | translate }}</p>
</app-confirm-modal>
