import { Color } from './color.model';

export const ColorPickerConfig = [
  new Color('#FF0000', false),
  new Color('#00FF00', false),
  new Color('#6600FF', false),
  new Color('#000000', false),
  new Color('#FFFFFF', false),
  new Color('#FFFF00', true)
];
