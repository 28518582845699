import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { SessionTimeoutService } from '../session-timeout/session-timeout.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageHeaderComponent implements OnChanges {
  @Input() canGoBack: boolean;
  @Input() canGoNext: boolean;
  @Input() showCounter: boolean = false;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() backIconName = 'chevron_left';
  @Output() goBack = new EventEmitter();
  @Output() goNext = new EventEmitter();

  counter$: Observable<string> = of(null);
  barHidden$: BehaviorSubject<boolean> = this.sessionTimeoutService.barHidden$;

  constructor(private readonly sessionTimeoutService: SessionTimeoutService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.showCounter) {
      this.toggleCounter();
    }
  }

  onBack(): void {
    this.goBack.emit();
  }

  onNext(): void {
    this.goNext.emit();
  }

  private toggleCounter(): void {
    if (this.showCounter) {
      this.counter$ = this.sessionTimeoutService.timeoutValue$.pipe(
        map((seconds) => this.formatTime(seconds))
      );
    } else {
      this.counter$ = of(null);
    }
  }

  private formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;

    return `${minutes < 10 ? '0' + minutes : minutes}:${
      seconds < 10 ? '0' + seconds : seconds
    }`;
  }
}
