import { SectionGroup } from '../form/section-group.model';
import { Question } from '../form/question.model';
import { LiteEditorQuestion } from '../../+lite-editor/models/lite-editor-question';

export function agregateQuestionsToSections<
  T extends Question | LiteEditorQuestion
>(data): SectionGroup<T>[] {
  return data.reduce((acc, curr): any => {
    let group = '';

    // Get current group name, or add default
    if (curr.section !== null && curr.key !== 'signature') {
      group = curr.section;
    }

    if (curr.key === 'signature') {
      group = 'Signature';
    }

    // If this type wasn't previously stored
    if (!acc.sections) {
      acc.sections = [];
    }
    if (
      acc.sections.length > 0 &&
      acc.sections[acc.sections.length - 1].actualKey === curr.section
    ) {
      acc.sections[acc.sections.length - 1].items.push(curr);
    } else {
      const sectionGroup = {
        actualKey: curr.section,
        groupTitle: group,
        items: [curr]
      } as SectionGroup<T>;

      acc.sections.push({
        id: acc.sections.length,
        ...sectionGroup
      });
    }

    return acc as SectionGroup<T>[];
  }, {}).sections;
}
