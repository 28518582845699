export const environment = {
  envName: 'production',
  production: true,
  serverUrl: 'https://api.modento.com',
  apiUrl: 'https://api.modento.com/api/v1',
  clientId: 94,
  clientSecret: 'oRzNqLu0oZN3novaywMy3paIstUnc6QfrPfkbwe1',
  formValidation: true,
  pusher: {
    APP_KEY: '5ab86bbc42a3e6e03cc7',
    logToConsole: false,
    options: {
      cluster: 'us3',
      forceTLS: true
    }
  },
  sentry: {
    DSN: 'https://02660bf555104da08a69a7c3d05b8b1a@sentry.io/1827605'
  },
  development: {
    forceFormEditorInPreview: false,
    debugLiteEditor: false
  },
  postMessage: {
    allowedPatientPortalUrls: [
      'https://app.modento.io',
      'https://app.modento.com'
    ]
  },
  wisetack: {
    widerWisetackPaymentOption: true
  },
  clearent: {
    sdk: 'https://gateway.clearent.net/js-sdk/js/clearent-host.js',
    baseUrl: 'https://gateway.clearent.net'
  }
};
