import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AnswerOption } from '../../../forms/form/answer-option.model';
import { nonEmpty } from '../../../common/utils/string-utils.helper';
import { ProblemsMapperService } from '../problems-mapper/problems-mapper.service';

@Component({
  selector: 'app-edit-option',
  templateUrl: './edit-option.component.html',
  styleUrls: ['./edit-option.component.scss']
})
export class EditOptionComponent {
  @Input() isPMSProblem: boolean;
  @Input() answerOption: AnswerOption;
  @Input() index: number;
  @Input() questionKey: string;
  @Output() deleting = new EventEmitter<number>();

  constructor(public mapperService: ProblemsMapperService) {}

  onValueChange(): void {
    if (this.answerOption.value === 'true') {
      this.answerOption.value = true;
    }
    if (this.answerOption.value === 'false') {
      this.answerOption.value = false;
    }
  }

  onDelete(): void {
    this.deleting.emit(this.index);
  }

  onCopyNameToValue(): void {
    this.answerOption.value = this.answerOption.name;
  }
}
