import { InjectionToken } from '@angular/core';
import { DropdownPlaceholder } from './placeholder.model';

const PLACEHOLDERS: DropdownPlaceholder[] = [
  {
    name: 'patient',
    translateKey: 'HTML_EDITOR.PLACEHOLDERS.PATIENT_TITLE',
    category: 'standard',
    data: [
      {
        name: 'full name',
        value: 'patient_name',
        category: 'standard'
      },
      {
        name: 'first name',
        value: 'patient_first_name',
        category: 'standard'
      },
      {
        name: 'preferred name',
        value: 'patient_preferred_name',
        category: 'standard'
      },
      {
        name: 'initials',
        value: 'patient_initials',
        category: 'standard'
      },
      {
        name: 'dob',
        value: 'patient_dob',
        category: 'standard'
      },
      {
        name: 'address',
        value: 'patient_address',
        category: 'standard'
      },
      {
        name: 'email',
        value: 'patient_email',
        category: 'standard'
      },
      {
        name: 'phone',
        value: 'patient_phone',
        category: 'standard'
      },
      {
        name: 'guardian name',
        value: 'patient_guardian_name',
        category: 'standard'
      },
      {
        name: 'guardian initials',
        value: 'patient_guardian_initials',
        category: 'standard'
      }
    ]
  },
  {
    name: 'practice',
    translateKey: 'HTML_EDITOR.PLACEHOLDERS.PRACTICE_TITLE',
    category: 'standard',
    data: [
      {
        name: 'name',
        value: 'practice_name',
        category: 'standard'
      },
      {
        name: 'address',
        value: 'practice_address',
        category: 'standard'
      },
      {
        name: 'email',
        value: 'practice_email',
        category: 'standard'
      },
      {
        name: 'phone',
        value: 'practice_phone',
        category: 'standard'
      },
      {
        name: 'doctor name',
        value: 'practice_doctor',
        category: 'standard'
      },
      {
        name: 'all doctors',
        value: 'practice_all_doctors',
        category: 'standard'
      }
    ]
  },
  {
    name: 'other',
    translateKey: 'HTML_EDITOR.PLACEHOLDERS.OTHER_TITLE',
    category: 'standard',
    shouldFlattenItems: true,
    data: [
      {
        name: "Today's date",
        value: 'today_date',
        category: 'standard'
      }
    ]
  }
];

export const consentsStandardPlaceholders = {
  name: 'standard',
  translateKey: 'HTML_EDITOR.PLACEHOLDERS.STANDARD_TITLE',
  category: 'standard',
  data: PLACEHOLDERS
};

export const consentsAutomatedConsents = {
  name: 'automated_consents',
  translateKey: 'HTML_EDITOR.PLACEHOLDERS.AUTOMATED_CONSENTS_TITLE',
  category: 'auto',
  data: [
    {
      name: 'appointment date',
      value: 'appointment_date',
      category: 'auto'
    },
    {
      name: 'provider',
      value: 'provider',
      category: 'auto'
    },
    {
      name: 'tooth/site',
      value: 'tooth_or_site',
      category: 'auto'
    }
  ]
};

export const consentsCustomPlaceholder = {
  name: 'custom',
  translateKey: 'HTML_EDITOR.PLACEHOLDERS.CUSTOM_TITLE',
  category: 'custom',
  isOpenable: true
};

export const DROPDOWN_PLACEHOLDERS = new InjectionToken<DropdownPlaceholder[]>(
  'Placeholders to be consumed by the HTML editor',
  {
    providedIn: 'root',
    factory: () => {
      return PLACEHOLDERS;
    }
  }
);
