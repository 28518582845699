import { NgModule } from '@angular/core';
import { ConsentPaymentPlansComponent } from './consent-payment-plans.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PaymentOptionComponent } from './payment-option/payment-option.component';
import { ChooseLaterComponent } from './choose-later/choose-later.component';

@NgModule({
  declarations: [
    ConsentPaymentPlansComponent,
    PaymentOptionComponent,
    ChooseLaterComponent
  ],
  exports: [
    ConsentPaymentPlansComponent,
    PaymentOptionComponent,
    ChooseLaterComponent
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule]
})
export class ConsentPaymentPlansModule {}
