<div *ngIf="isSubmitting || liteEditorService.isSubmitting"
     class="screen-full-modal"
     (click)="(false)">
  <app-spinner class="screen-centered"></app-spinner>
</div>

<app-page-header class="page-header--form"
                 [title]="formSpec.title"
                 [canGoBack]="false"
                 [canGoNext]="false"
                 [showCounter]="true">
  <div left-corner>
    <div class="practice-info">
      <img *ngIf="practice?.logo"
           [src]="practice.logo" />
      <span>{{ practice?.name | ellipsize: 32 }}</span>
    </div>
  </div>
  <div right-corner>
    <button type="button"
            class="cancel-button"
            (click)="onCancel()">
      <i class="material-icons">close</i>
      <span>{{ 'FORMS.BUTTONS.CANCEL' | translate }}</span>
    </button>
  </div>
</app-page-header>

<div class="section no-pad-bot body"
     *ngIf="languageLoaded"
     id="scroll-container"
     #body>
  <header class="form-container__header container"
          *ngIf="isHeaderVisible">
    <div class="form-container__title">
      {{ groups[currentGroupIdx].groupTitle }}
    </div>
    <div class="form-container__progress-step">
      {{ 'FORMS.STEP' | translate }}
      {{ currentGroupIdx + 1 }}<small>/{{groups.length}}</small>
    </div>
  </header>
  <div class="container form-container__card">
    <div class="row">
      <form class="col s12"
            *ngFor="let question of groups[currentGroupIdx].items"
            role="form">
        <app-control [question]="question"
                     [formData]="formData"
                     (output)="onFormDataChange(question.key, $event)"
                     (multiOutput)="onFormDataMultiChange($event)"
                     (control)="onFormControlSet(question.key, $event)"
                     (hiddenKey)="onHiddenFormControl($event)">
        </app-control>
      </form>
    </div>
    <div class="row">
      <div class="form-container__mandatory-fields-explanation">
        {{ 'FORMS.FEEDBACK.MANDATORY_FIELDS' | translate }}
      </div>
    </div>
    <div class="row">
      <div class="col s12 form-container__nav-buttons">
        <button *ngIf="currentGroupIdx > 0"
                type="button"
                class="modento-btn text nav-buttons__prev"
                (click)="prevSection()">
          <i class="material-icons prefix">west</i>
          {{ 'FORMS.BUTTONS.BACK' | translate }}
        </button>
        <button type="button"
                class="modento-btn primary-variant nav-buttons__next"
                (click)="nextSection()">
          {{
          currentGroupIdx < groups.length - 1
            ? ('FORMS.BUTTONS.NEXT' | translate)
            : ('FORMS.BUTTONS.SUBMIT' | translate)
          }}
          <i
            *ngIf="currentGroupIdx < groups.length - 1"
            class="material-icons suffix"
          >east</i>
        </button>
      </div>
    </div>
    <div class="row"
         *ngIf="!validationEnabled">
      <div class="col s12 center-align">
        <p class="text-error">{{ 'FORMS.VALIDATION.DISABLED' | translate }}</p>
      </div>
    </div>
    <div class="row"
         *ngIf="liteEditorService.editMode">
      <div class="col s12 center-align">
        <p class="text-error">
          {{ 'FORMS.LITE_EDITOR.MODE_INFO' | translate }}
        </p>
      </div>
    </div>
  </div>
</div>

<!-- Confirm exit message modal -->
<app-confirm-modal (modalPluginSet)="confirmModal = $event"
                   (confirm)="cancelled.emit()">
  <p>{{ 'FORMS.EXIT.CONFIRM_MESSAGE' | translate }}</p>
</app-confirm-modal>

<!-- V1 insurance support modal to allow skipping the form if insurance is missing -->
<app-confirm-modal (modalPluginSet)="checkV1InsuranceModal($event)"
                   (cancel)="onNoInsurance()"
                   [cancelMessage]="'COMMON.MODAL.NO' | translate">
  <p>{{ 'FORMS.V1.HAS_INSURANCE' | translate }}</p>
</app-confirm-modal>

<!-- Modal to submit forms feedback in preview mode -->
<app-form-feedback-tool *ngIf="isPreview"
                        [formSpecId]="formSpec.id"
                        [section]="
    groups[currentGroupIdx] !== undefined
      ? groups[currentGroupIdx].groupTitle
      : null
  "
                        (modalPluginSet)="feedbackModal = $event">
</app-form-feedback-tool>

<!-- Floating menu (zoom toolbar) -->
<app-font-size-toggle *ngIf="!isPreview"></app-font-size-toggle>

<!-- Floating menu (preview toolbar) -->
<div class="floating-menu"
     *ngIf="isPreview && !liteEditorService.editMode">
  <a class="btn btn-floating btn-large prev-page" [ngClass]="{disabled: currentGroupIdx === 0}" (click)="prevSection()"
     title="{{ 'FORMS.TOOLBAR.GO_TO_PREVIOUS_PAGE' | translate }}">
    <i class="material-icons">west</i>
  </a>
  <a class="btn btn-floating btn-large next-page" [ngClass]="{disabled: currentGroupIdx === groups.length - 1}"
     (click)="nextSection()"
     title="{{ 'FORMS.TOOLBAR.GO_TO_NEXT_PAGE' | translate }}">
    <i class="material-icons">east</i>
  </a>
  <div class="fixed-action-btn">
    <a class="btn-floating btn-large">
      <i class="large material-icons">more_vert</i>
    </a>
    <ul>
      <!--<li><a class="btn-floating red"><i class="material-icons">insert_chart</i></a></li>-->
      <li>
        <a
          class="btn-floating deep-orange"
          (click)="toggleValidation()"
          title="{{
            validationEnabled
              ? ('FORMS.TOOLBAR.VALIDATION_DISABLE' | translate)
              : ('FORMS.TOOLBAR.VALIDATION_ENABLE' | translate)
          }}"
        >
          <i class="material-icons">{{
            validationEnabled ? 'block' : 'done_all'
            }}</i>
        </a>
      </li>
      <li>
        <a
          class="btn-floating green"
          (click)="downloadPDF()"
          title="{{ 'FORMS.TOOLBAR.PDF_HINT' | translate }}"
        >
          <i class="material-icons">picture_as_pdf</i>
        </a>
      </li>
    </ul>
  </div>
</div>

<div class="fixed-action-btn"
     title="{{ 'FORMS.TOOLBAR.SAVE_SPEC_HINT' | translate }}"
     *ngIf="liteEditorService.editMode">
  <a class="btn-floating btn-large" (click)="liteEditorService.saveForm()">
    <i class="large material-icons">save</i>
  </a>
</div>

<!-- Modal to edit questions/controls in lite edit mode -->
<app-lite-edit-control-modal *ngIf="liteEditorService.editMode"
                             [formSpecId]="formSpec.id"
                             [question]="liteEditorService.editedQuestion"
                             (modalPluginSet)="liteEditorService.editControlModal = $event">
</app-lite-edit-control-modal>

<app-confirm-modal *ngIf="liteEditorService.editMode"
                   (modalPluginSet)="liteEditorService.confirmSaveModal = $event"
                   (confirm)="liteEditorService.saveFormConfirmed(formSpec)">
  <h4>{{ 'FORMS.LITE_EDITOR.SAVE.CONFIRM_TITLE' | translate }}</h4>
  <p>{{ 'FORMS.LITE_EDITOR.SAVE.CONFIRM_TEXT' | translate }}</p>
</app-confirm-modal>
