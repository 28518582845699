<div class="center-align">
  <ng-container *ngIf="showFinalThankYou; else mainContent">
    <app-final-page *ngIf="showFinalThankYou"
                    [titleLabel]="'FORMS.ALL_IS_UP_TO_DATE.KIOSK_TITLE' | translate"
                    [descriptionLabel]="'FORMS.ALL_IS_UP_TO_DATE.CLOSE_HINT' | translate"
                    [closeLabel]="'FORMS.ALL_IS_UP_TO_DATE.CLOSE' | translate"
                    [autoCloseAfter]="10">
    </app-final-page>
  </ng-container>
  <ng-template #mainContent>

    <p class="show-newlines font-increased">
      {{ checkInResult.message }}
    </p>
    <ul class="checked-in__alerts-list left-align browser-default mt-2">
      <li *ngFor="let alert of checkInResult.alerts"
          class="checked-in__alert">
        <span
              class="checked-in__alert-icon material-icons">arrow_right_alt</span>
        <span class="checked-in__alert-message">{{ alert }}</span>
      </li>
    </ul>
    <ng-container
                  *ngIf="hasMissingForms || hasMissingConsents; else updatePatient">
      <ng-container *ngIf="hasMissingForms && !hasMissingConsents">
        <ng-container
                      *ngTemplateOutlet="fillRequiredItems; context: {buttonLabel: ('KIOSK.CHECKIN.CHECKED_IN.ACCESS_FORMS' | translate), hasAppLabel: ('KIOSK.CHECKIN.CHECKED_IN.FORMS_ACCESS' | translate), noAppLabel: ('KIOSK.CHECKIN.CHECKED_IN.FORMS_ACCESS_NO_APP' | translate)}">
        </ng-container>
      </ng-container>
      <ng-container *ngIf="hasMissingForms && hasMissingConsents">
        <ng-container
                      *ngTemplateOutlet="fillRequiredItems; context: {buttonLabel: 'KIOSK.CHECKIN.CHECKED_IN.ACCESS_FORMS_AND_CONSENTS' | translate, hasAppLabel: 'KIOSK.CHECKIN.CHECKED_IN.FORMS_AND_CONSENTS_ACCESS' | translate, noAppLabel: 'KIOSK.CHECKIN.CHECKED_IN.FORMS_AND_CONSENTS_ACCESS_NO_APP' | translate}">
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!hasMissingForms && hasMissingConsents">
        <ng-container
                      *ngTemplateOutlet="fillRequiredItems; context: {buttonLabel: 'KIOSK.CHECKIN.CHECKED_IN.ACCESS_CONSENTS' | translate, hasAppLabel: 'KIOSK.CHECKIN.CHECKED_IN.CONSENTS_ACCESS' | translate, noAppLabel: 'KIOSK.CHECKIN.CHECKED_IN.CONSENTS_ACCESS_NO_APP' | translate}">
        </ng-container>
      </ng-container>
      <ng-template #fillRequiredItems
                   let-buttonLabel="buttonLabel"
                   let-hasAppLabel="hasAppLabel"
                   let-noAppLabel="noAppLabel">
        <p class="mt-4">
          {{
          practiceHasApp
            ? hasAppLabel
            : noAppLabel
          }}
        </p>
        <div class="mt-2 fill-forms-button-container">
          <button type="button"
                  class="btn btn-primary btn-large waves-effect waves-primary"
                  (click)="onAccessForms()">
            {{ buttonLabel }}
          </button>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #updatePatient>
      <ng-container *ngIf="hasOptionalForms">
        <p class="mt-2">
          {{ 'KIOSK.CHECKIN.CHECKED_IN.FORMS_UPDATE_TITLE' | translate }}
        </p>
        <div class="checked-in__buttons-update valign-wrapper mt-2">
          <button type="button"
                  class="checked-in__btn btn btn-primary waves-effect waves-primary"
                  (click)="onExit()">
            {{ 'KIOSK.CHECKIN.CHECKED_IN.SKIP_UPDATE' | translate }}
          </button>
          <button type="button"
                  class="checked-in__btn mt-2 btn btn-primary waves-effect waves-primary"
                  [hidden]="getSelectedFormIds().length === 0"
                  (click)="onShowFormsListClick(true)">
            {{ 'KIOSK.CHECKIN.CHECKED_IN.FORMS_UPDATE_ON_DEMAND' | translate }}
          </button>
        </div>

        <ng-container *ngIf="showFormsList">
          <p class="checked-in__update-info mt-4">
            {{ 'KIOSK.CHECKIN.CHECKED_IN.FORMS_UPDATE' | translate }}
          </p>

          <div class="mt-2">
            <ng-container
                          *ngFor="let formSpec of checkInResult.form_specs; index as i">
              <div class="row">
                <div class="col s12 left-align">
                  <label class="for-checkbox"
                         for="form_spec_{{ formSpec.id }}">
                    <input id="form_spec_{{ formSpec.id }}"
                           type="checkbox"
                           class="filled-in"
                           [disabled]="formSpec.required"
                           [name]="'form_spec_' + formSpec.id"
                           [value]="true"
                           [(ngModel)]="selectedFormSpecIds[formSpec.id]" />
                    <span class="text-break">{{ formSpec.title }}</span>
                  </label>
                </div>
              </div>
            </ng-container>
          </div>

          <div class="row mt-4">
            <div class="col s8 offset-s2 center-align">
              <button type="button"
                      class="btn btn-primary waves-effect waves-primary"
                      [hidden]="getSelectedFormIds().length === 0"
                      (click)="onAccessForms()">
                {{ 'KIOSK.CHECKIN.CHECKED_IN.ACCESS_FORMS_UPDATE' | translate }}
              </button>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-template>
  </ng-template>
</div>
