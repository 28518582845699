<div class="row"
     [ngClass]="{ compact: compact }">
  <div class="input-field col s12"
       [ngClass]="{ selected: currentSelection }">
    <p class="fixed-label question-title"
       *ngIf="fixedLabel"
       [ngClass]="{ focused: fixedLabelFocused }">
      {{ question.title?.trim()
      }}<span class="required"
            *ngIf="!question.optional && !compact">*</span>
    </p>
    <div *ngIf="autocompleteEnabled"
         class="select-wrapper">
      <input #autocomplete
             id="{{ question.key }}"
             [name]="question.key"
             type="text"
             class="autocomplete"
             [placeholder]="'FORMS.CONTROLS.STATES.SEARCH_STATE' | translate"
             (blur)="onAutocompleteBlur()"
             (focusin)="fixedLabelFocused = true"
             (focusout)="fixedLabelFocused = false"
             (change)="onAutocompleteChange(autocomplete)"
             autocomplete="off" />
    </div>
    <select *ngIf="!autocompleteEnabled"
            id="{{ question.key }}"
            [name]="question.key"
            (change)="selectionChanged($event.target)"
            (focusin)="fixedLabelFocused = true"
            (focusout)="fixedLabelFocused = false"
            [attr.multiple]="question.control.multi == true ? 'multiple' : null"
            #select>
      <!-- prettier-ignore -->
      <option [value]="''"
              disabled
              [attr.selected]="isOptionSelected('') ? 'selected' : null"
              #firstDisabledOpt>{{ question.control.hint }}
        <ng-container *ngIf="!question.optional && compact">*</ng-container>
      </option>
      <!-- prettier-ignore -->
      <option *ngFor="let option of question.control.options; trackBy: trackOptionsByValue"
              [value]="option.value"
              [attr.selected]="isOptionSelected(option.value) ? 'selected' : null">
        {{ option.name }}
      </option>
      <!-- prettier-ignore -->
      <option *ngIf="question.control.other"
              [value]="OTHER_VALUE">
        {{ 'FORMS.CONTROLS.DROPDOWN.OTHER' | translate }}
      </option>
    </select>

    <label #label
           *ngIf="!fixedLabel"
           for="{{ question.key }}">{{ question.title?.trim()
      }}<span class="required"
            *ngIf="!question.optional && !compact">*</span></label>
    <span class="helper-text light"
          *ngIf="question.text">{{
      question.text
    }}</span>
    <ng-container *ngIf="
        !formControl.valid &&
        formControl.touched &&
        formControl.errors &&
        !this.isOptionSelected(OTHER_VALUE)
      ">
      <span class="helper-text invalid"
            *ngIf="formControl.errors.required">{{
        'FORMS.VALIDATION.DROPDOWN.REQUIRED' | translate
      }}</span>
    </ng-container>
  </div>
  <div class="input-field input-field-other col s12"
       *ngIf="showOther">
    <input #otherInput
           id="{{ question.key + '__other' }}"
           type="text"
           class="validate"
           [name]="question.key + '__other'"
           [ngModel]="other"
           (change)="onOtherChanged($event)" />
    <label
           for="{{ question.key + '__other' }}">{{ 'FORMS.CONTROLS.DROPDOWN.OTHER_HINT' | translate }}
      <span class="required"
            *ngIf="!question.optional && !compact">*</span></label>
    <ng-container *ngIf="
        !formControl.valid &&
        formControl.touched &&
        formControl.errors &&
        this.isOptionSelected(OTHER_VALUE)
      ">
      <span class="helper-text invalid"
            *ngIf="formControl.errors.required">{{
        'FORMS.VALIDATION.DROPDOWN.REQUIRED' | translate
      }}</span>
    </ng-container>
  </div>
</div>
<div class="dropdown-close"
     *ngIf="showDropdownClose">
  <p class="text-primary">
    {{ 'FORMS.CONTROLS.DROPDOWN.TAP_TO_CLOSE' | translate }}
  </p>
</div>
