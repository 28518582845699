<div class="col s12">
  <div class="row">
    <div class="col s12">
      <div class="row">
        <div class="input-field col s6">
          <label>
            <input type="checkbox"
                   class="filled-in"
                   [(ngModel)]="useHTML"
                   (change)="onUseHTMLChange()"
                   [value]="true" />
            <span>Provide as HTML <small>(will convert text to
                HTML)</small></span>
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col s12"
         *ngIf="useHTML; else nonHTML">
      <app-html-editor class="app-editor--autoheight"
                       [initialValue]="control?.temporary_html_text"
                       (valueChanges)="onEditorValueChange($event, control)">
      </app-html-editor>
    </div>
    <ng-template #nonHTML>
      <div class="col s12">
        <label for="{{ key + '-control-text' }}"
               class="text-primary">Block (consent-like) text</label>
        <textarea (paste)="onPasteText()"
                  #terms_area
                  id="{{ key + '-control-text' }}"
                  class=""
                  [(ngModel)]="control.text"></textarea>
      </div>
    </ng-template>
  </div>
</div>
