import { Range } from 'ngx-quill';
import { ContextQuill } from './models';
import { HtmlEditorComponent } from './html-editor.component';
import Emitter from 'quill/core/emitter';
import * as Quill from 'quill';
import { QuillModules } from 'ngx-quill/lib/quill-editor.interfaces';
import { HighlightBlockEmbed } from './custom-blocks';
const Delta = Quill.import('delta');

const MB = 1000000;
const IMAGE_SIZE_LIMIT = 5 * MB;

export function getQuillModulesConfig(
  htmlEditorComponent: HtmlEditorComponent
): QuillModules {
  return {
    history: {
      userOnly: true // leaves programmatical changes intact, including init'ing the content
    },
    clipboard: {
      matchVisual: false
    },
    ImageResize: {
      modules: ['Resize', 'DisplaySize']
    },
    imageDrop: true,
    toolbar: {
      container: [
        ['undo', 'redo'],
        ['bold', 'italic', 'underline', 'strike'],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }
        ],
        [{ list: 'bullet' }, { list: 'ordered' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ color: [] }, { background: [] }, 'image']
      ],
      handlers: {
        redo: () => {
          htmlEditorComponent.quillEditor.history.redo();
        },
        undo: () => {
          htmlEditorComponent.quillEditor.history.undo();
        },
        image: () => {
          let fileInput = htmlEditorComponent.editor.quillEditor.container.querySelector(
            'input.ql-image[type=file]'
          );
          if (fileInput == null) {
            fileInput = document.createElement('input');
            fileInput.setAttribute('type', 'file');
            fileInput.setAttribute('accept', 'image/png, image/jpeg');
            fileInput.classList.add('ql-image');
            fileInput.addEventListener('change', () => {
              if (fileInput.files != null && fileInput.files[0] != null) {
                if (fileInput.files[0].size > IMAGE_SIZE_LIMIT) {
                  fileInput.value = null;
                  htmlEditorComponent.alertService.showWarning(
                    htmlEditorComponent.translateService.instant(
                      'HTML_EDITOR.WARNINGS.LARGE_IMAGE'
                    )
                  );
                  window.dispatchEvent(new Event('click'));

                  return;
                }

                const reader = new FileReader();
                reader.onload = (e) => {
                  const range = htmlEditorComponent.quill.getSelection(true);
                  htmlEditorComponent.quill.updateContents(
                    new Delta()
                      .retain(range.index)
                      .delete(range.length)
                      .insert({ image: e.target.result }),
                    Emitter.sources.USER
                  );
                  fileInput.value = '';
                };
                reader.readAsDataURL(fileInput.files[0]);
              }
            });
            htmlEditorComponent.editor.quillEditor.container.appendChild(
              fileInput
            );
          }
          fileInput.click();
        }
      }
    },
    keyboard: {
      bindings: {
        customBackspace: {
          key: 'backspace',
          handler: (range: Range, context: ContextQuill) =>
            customBackspaceHandler(range, context, htmlEditorComponent)
        },
        customEnter: {
          key: 'enter',
          handler: (range: Range, context: ContextQuill) =>
            customEnterHandler(range, context, htmlEditorComponent)
        }
      }
    }
  };
}

function customBackspaceHandler(
  range: Range,
  context: ContextQuill,
  htmlEditorComponent: HtmlEditorComponent
): boolean {
  if (context.format?.['data-placeholder']) {
    const [placeholder] = htmlEditorComponent.quill.getLeaf(range.index);
    if (isCaretAtTheEndOfPlaceholder(placeholder)) {
      const delta = new Delta();
      delta.retain(range.index - 1).delete(1);
      htmlEditorComponent.quill.updateContents(delta, Quill.sources.USER);
      return false;
    } else {
      return true;
    }
  } else if (
    document
      .getSelection()
      .focusNode.parentElement.hasAttribute('data-placeholder')
  ) {
    const delta = new Delta();
    // @ts-ignore
    if (document.getSelection().focusNode.previousElementSibling) {
      delta.retain(range.index).delete(range.length + 1);
    } else {
      delta.retain(range.index - 1).delete(range.length + 1);
    }

    htmlEditorComponent.quill.updateContents(delta, Quill.sources.USER);
    return false;
  }
  return true;
}

function customEnterHandler(
  range: Range,
  context: ContextQuill,
  htmlEditorComponent: HtmlEditorComponent
): boolean {
  // when focus is set on placeholder and its not in some text
  if (context.format?.['data-placeholder'] && context.offset === 0) {
    const [placeholder] = htmlEditorComponent.quill.getLeaf(range.index);
    if (isCaretAtTheEndOfPlaceholder(placeholder)) {
      htmlEditorComponent.quill.insertText(range.index + 1, '\n', 'user');
      htmlEditorComponent.quill.setSelection(range.index + 2, 0, 'silent');
    } else {
      htmlEditorComponent.quill.insertText(range.index, '\n', 'user');
      htmlEditorComponent.quill.setSelection(range.index + 1, 0, 'silent');
    }
    htmlEditorComponent.quill.focus();
  } else {
    const [focusedElement] = htmlEditorComponent.quill.getLeaf(range.index);
    const highlightBlockEmbed =
      focusedElement instanceof HighlightBlockEmbed
        ? focusedElement
        : focusedElement.next instanceof HighlightBlockEmbed
        ? focusedElement.next
        : false;
    // when focus is set on placeholder but its inside text (so not directly)
    if (
      highlightBlockEmbed &&
      !(focusedElement?.prev instanceof HighlightBlockEmbed) &&
      context.offset !== 0
    ) {
      if (isCaretAtTheEndOfPlaceholder(highlightBlockEmbed)) {
        htmlEditorComponent.quill.insertText(range.index + 1, '\n', 'user');
        htmlEditorComponent.quill.setSelection(range.index + 2, 0, 'silent');
      } else {
        return true;
      }
    } else {
      // last hope for hopeless cases
      // examples:
      // 1. when caret is at the end of text and there is placeholder:
      // 2, when there are 2 placeholders close to each and user want to go to new line when caret is at the end second placeholder
      if (
        document
          .getSelection()
          ?.focusNode.parentElement.hasAttribute('data-placeholder') &&
        // @ts-ignore
        document.getSelection()?.focusNode.parentNode.__blot.blot &&
        isCaretAtTheEndOfPlaceholder(
          // @ts-ignore
          document.getSelection().focusNode.parentNode.__blot.blot
        )
      ) {
        htmlEditorComponent.quill.insertText(range.index + 1, '\n', 'user');
        htmlEditorComponent.quill.setSelection(range.index + 2, 0, 'silent');
      } else {
        return true;
      }
    }
  }
}

function isCaretAtTheEndOfPlaceholder(
  placeholder: HighlightBlockEmbed
): boolean {
  // @ts-ignore
  if (!placeholder.domNode.getBoundingClientRect) {
    return false;
  }
  const placeholderPositionX = Math.round(
    // @ts-ignore
    placeholder.domNode.getBoundingClientRect().x
  );
  const caretPositionX = Math.round(getCaretTopPoint().left);

  return caretPositionX !== placeholderPositionX;
}

/*
 * original code source: https://gist.github.com/nothingismagick/642861242050c1d5f3f1cfa7bcd2b3fd
 * */
function getCaretTopPoint(): { left: number; top: number } {
  const sel = document.getSelection();
  const r = sel.getRangeAt(0);
  let rect;
  let r2;
  // supposed to be textNode in most cases
  // but div[contenteditable] when empty
  const node = r.startContainer;
  const offset = r.startOffset;
  if (offset > 0) {
    // new range, don't influence DOM state
    r2 = document.createRange();
    r2.setStart(node, offset - 1);
    r2.setEnd(node, offset);
    rect = r2.getBoundingClientRect();
    return { left: rect.right, top: rect.top };
  } else {
    r2 = document.createRange();
    // similar but select next on letter
    r2.setStart(node, offset);
    r2.setEnd(node, offset + 1);
    rect = r2.getBoundingClientRect();
    return { left: rect.left, top: rect.top };
  }
}
