import { Component, Input, OnInit } from '@angular/core';
import { BlockQuestionParams } from '../../../forms/form/controls/block-control/block-question.model';
import { isEmpty } from '../../../common/utils/string-utils.helper';

@Component({
  selector: 'app-edit-block',
  templateUrl: './edit-block.component.html',
  styleUrls: ['./edit-block.component.scss']
})
export class EditBlockComponent implements OnInit {
  @Input() control: BlockQuestionParams;
  @Input() key: string;

  constructor() {}

  ngOnInit(): void {
    // convert for_minor to variant which we use now
    if (isEmpty(this.control.variant)) {
      this.control.variant = this.control.for_minor ? 'minor' : 'adult';
    }
  }

  onVariantChanged(): void {
    this.control.for_minor = this.control.variant === 'minor';
  }
}
