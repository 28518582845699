import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TimeoutError } from 'rxjs';

@Injectable()
export class ErrorMessageService {
  unknownErrorMessage: string;
  connectionFailedError: string;

  constructor(private translationService: TranslateService) {
    this.translationService
      .get('ERROR.UNKNOWN')
      .subscribe((text) => (this.unknownErrorMessage = text));
    this.translationService
      .get('ERROR.CONNECTION_FAILED')
      .subscribe((text) => (this.connectionFailedError = text));
  }

  getErrorMessage(error): string {
    try {
      if (!navigator.onLine) {
        return this.connectionFailedError;
      }

      if (error instanceof TimeoutError) {
        return error.message;
      }

      if (error.error && error.error.errors) {
        const serverErrors = Object.keys(error.error.errors).map(
          (x) => error.error.errors[x]
        );
        const message = serverErrors[0].join('<br>');
        return message;
      } else if (
        error.error &&
        error.error.message &&
        error.error.message !== ''
      ) {
        return error.error.message;
      } else {
        return this.unknownErrorMessage;
      }
    } catch (e) {
      return this.unknownErrorMessage;
    }
  }
}
