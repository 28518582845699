import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CustomValidators } from '../../../common/validators/custom-validators.helper';
import { ConsentRequest } from '../../consents-request/consent-request.model';
import { DeviceService } from '../../../common/device-service/device.service';
import { Practice, PracticeService } from '../../../common/practice';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-consent-content',
  templateUrl: './consent-content.component.html',
  styleUrls: ['./consent-content.component.scss']
})
export class ConsentContentComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() consent: ConsentRequest;
  @Input() agreed: boolean;
  @Output() control = new EventEmitter<FormControl>();
  @ViewChild('iframeContent') contentIframe: ElementRef;

  agreeFormControl: FormControl;
  tpRejectedControl = new FormControl(null, Validators.required);
  isPhone;
  isTreatmentPlan: boolean;
  canRefuseTP = false;

  private contentDiv;
  private isAfterInit = false;
  private iframeLoaded = false;
  private iframeContentRefreshSubscription: Subscription;

  practice: Practice;
  loadCanvas = false;
  CanvasParentElement: HTMLElement;
  resizeObserver: ResizeObserver;

  constructor(
    private deviceService: DeviceService,
    private practiceService: PracticeService
  ) {}

  ngOnInit(): void {
    this.isTreatmentPlan = this.consent.type === 1;
    this.practice = this.practiceService.getPractice();

    this.canRefuseTP =
      this.isTreatmentPlan &&
      this.practice.refuse_tp_enabled === '1' &&
      !!this.consent.refuse_consent;

    if (this.canRefuseTP) {
      this.tpRejectedControl.setValue(this.agreed);
      this.control.emit(this.tpRejectedControl);
    } else {
      this.agreeFormControl = new FormControl(
        this.agreed,
        CustomValidators.isTrue()
      );
      this.control.emit(this.agreeFormControl);
    }

    this.isPhone = this.deviceService.isMobile();
  }

  ngAfterViewInit(): void {
    this.isAfterInit = true;
    if (this.iframeLoaded) {
      this.setIframeContent();
    }
  }

  ngOnDestroy(): void {
    if (this.iframeContentRefreshSubscription) {
      this.iframeContentRefreshSubscription.unsubscribe();
    }

    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  onResize(): void {
    if (this.contentDiv !== undefined) {
      this.contentIframe.nativeElement.height = this.contentDiv.clientHeight;
    }
  }

  onIframeLoaded(): void {
    this.iframeLoaded = true;
    if (this.isAfterInit) {
      this.setIframeContent();
    }
  }

  private setIframeContent(): void {
    const padding = 20;

    this.contentDiv = document.createElement('div');

    if (window.ResizeObserver) {
      this.resizeObserver = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          this.contentIframe.nativeElement.height =
            Math.round(entry.contentRect.height) + 1 + 2 * padding;
        });
      });

      this.resizeObserver.observe(this.contentDiv);
    }

    this.contentDiv.innerHTML = this.consent.content;
    this.contentDiv.style.paddingTop = `${padding}px`;
    this.contentDiv.style.paddingBottom = `${padding}px`;
    const style = document.createElement('style');
    style.innerHTML = this.isTreatmentPlan
      ? this.getTreatmentPlanStyle()
      : this.getConsentStyle();

    this.contentIframe.nativeElement.contentWindow.document.body.appendChild(
      style
    );
    this.contentIframe.nativeElement.contentWindow.document.body.appendChild(
      this.contentDiv
    );

    if (!window.ResizeObserver) {
      // the timeout below is need to set a proper height for Firefox/Safari/Opera because we use custom font for consent
      setTimeout(() => {
        this.contentIframe.nativeElement.height = this.contentDiv.clientHeight;
      }, 200);
    }

    this.setupImageInIFrameWorkaround();

    this.setLoadCanvas();
  }

  private setLoadCanvas(): void {
    setTimeout(() => {
      this.CanvasParentElement = document.getElementById('main-row');
      this.loadCanvas = true;
    }, 0);
  }

  /**
   * When there are images in iframe content, they load with a delay and the calculated iframe height is wrong.
   * It is hard to react on changes in images inside iframe, so instead we just make sure iframe height is aligned with
   * content height periodically.
   * This is lighweight operation, and should not afect performance much.
   */

  private setupImageInIFrameWorkaround(): void {
    if (this.consent.content.includes('<img ')) {
      this.iframeContentRefreshSubscription = interval(500).subscribe(() => {
        this.onResize();
      });
    }
  }

  private getConsentStyle(): string {
    return (
      'body {\n' +
      '        font-size: 110%;\n' +
      '        line-height: 1.4;\n' +
      '      }\n' +
      'img {\n' +
      '    object-fit: cover;\n' +
      '    width: 100%;\n' +
      '}'
    );
  }

  private getTreatmentPlanStyle(): string {
    return (
      "@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text:wght@300;400;500;600;700&display=swap');\n" +
      'body {\n' +
      '        color: #1d3243;\n' +
      '      }\n' +
      '\n' +
      'div.treat-plan {\n' +
      '        background: transparent !important;\n' +
      '        display: block;\n' +
      "        font-family: 'Red Hat Text', sans-serif !important;\n" +
      '        padding: 0 10px;\n' +
      '      }\n' +
      '\n' +
      '      .treat-plan th {\n' +
      '        text-align: left;\n' +
      '      }\n' +
      '\n' +
      '      p {' +
      '        color: #0a131e;' +
      '      }\n' +
      '      .treat-plan .title {\n' +
      '        text-transform: uppercase;\n' +
      '        text-align: center;\n' +
      '        margin-bottom: 3px;\n' +
      '        font-size: 26px;\n' +
      '      }\n' +
      '\n' +
      '      .treat-plan .subtitle {\n' +
      '        text-transform: uppercase;\n' +
      '        text-align: center;\n' +
      '        margin-top: 2px;\n' +
      '      }\n' +
      '\n' +
      '      .treat-plan .column {\n' +
      '        float: left;\n' +
      '        width: 50%;\n' +
      '      }\n' +
      '\n' +
      '      /* Clear floats after the columns */\n' +
      '      .treat-plan .row:after {\n' +
      '        content: "";\n' +
      '        display: table;\n' +
      '        clear: both;\n' +
      '      }\n' +
      '\n' +
      '      .treat-plan .row {\n' +
      '        margin-top: 20px;\n' +
      '      }\n' +
      '      .tp-summary .tp-summary-cost, .tp-summary .tp-summary-insurance {\n' +
      '        padding: 0 8px;\n' +
      '        box-sizing: border-box;\n' +
      '      }\n' +
      '\n' +
      '      .treat-plan table.procedures {\n' +
      '        width: 100%;\n' +
      '        border: 1px solid #888888;\n' +
      '        border-radius: 10px;\n' +
      '        border-spacing: 0;\n' +
      '      }\n' +
      '\n' +
      '      .treat-plan .procedures th,\n' +
      '      .procedures td {\n' +
      '        padding: 8px;\n' +
      '        font-size: 13px;\n' +
      '        border-bottom: 1px solid #888888;\n' +
      '      }\n' +
      '      .treat-plan table.procedures tr:not(.procedure-subtotal) {\n' +
      '        background-color: #fff;\n' +
      '      }\n' +
      '      .treat-plan table.procedures th {\n' +
      '        color: #324354;\n' +
      '      }\n' +
      '      .treat-plan table.procedures td {\n' +
      '        color: #0a131e;\n' +
      '      }\n' +
      '      .treat-plan table.procedures tr.procedure-subtotal td {\n' +
      '        font-weight: 600 !important;\n' +
      '      }\n' +
      '\n' +
      '.treat-plan .procedures tr.procedure-priority td {\n' +
      'text-transform: uppercase; padding: 5px !important; font-size: 12px !important; background-color: rgba(255, 255, 255, 0.1);' +
      '}\n' +
      '.treat-plan .procedures tr.procedure-subtotal td:first-of-type { text-align: right !important; }\n' +
      '.treat-plan .procedures tr.procedure-subtotal td { border-bottom: none !important; font-weight: bold !important; padding-bottom: 30px !important; }\n' +
      '      .treat-plan .procedures tr:last-of-type>td {\n' +
      '        border-bottom: 0 !important;\n' +
      '      }\n' +
      '\n' +
      '      .treat-plan table.costs {\n' +
      '        width: 100%;\n' +
      '        border: 1px solid #888888;\n' +
      '        border-radius: 10px;\n' +
      '        border-spacing: 0;\n' +
      '        overflow: hidden;\n' +
      '        box-shadow: 0 1px 8px 2px rgba(29, 50, 67, 0.1);\n' +
      '      }\n' +
      '\n' +
      '      .treat-plan .costs th {\n' +
      '        padding: 10px;\n' +
      '        font-size: 15px;\n' +
      '      }\n' +
      '\n' +
      '      .treat-plan .costs td {\n' +
      '        padding: 10px;\n' +
      '        font-size: 14px;\n' +
      '      }\n' +
      '\n' +
      '      .treat-plan .costs td:nth-of-type(2) {\n' +
      '        color: #0a131e;\n' +
      '        font-weight: 600;\n' +
      '        text-align: right;\n' +
      '      }\n' +
      '\n' +
      '      .treat-plan .costs th.subheader {\n' +
      '        font-size: 12px;\n' +
      '        text-align: right;\n' +
      '      }\n' +
      '\n' +
      '\n' +
      '      /* New styles */\n' +
      '      .app-treat-plan.treat-plan {\n' +
      '        background-color: transparent;\n' +
      '      }\n' +
      '\n' +
      '      .app-treat-plan.treat-plan .plan-info {\n' +
      '        margin-bottom: 20px;\n' +
      '        text-align: center;\n' +
      '      }\n' +
      '\n' +
      '      .app-treat-plan.treat-plan .title {\n' +
      '        margin-top: 0;\n' +
      '        margin-bottom: 5px;\n' +
      '        font-size: 18px;\n' +
      '        font-weight: 700;\n' +
      '      }\n' +
      '\n' +
      '      .app-treat-plan.treat-plan.treat-plan .subtitle {\n' +
      '        font-size: 15px;\n' +
      '      }\n' +
      '\n' +
      '      .app-treat-plan.treat-plan .overview {\n' +
      '        margin-top: 20px;\n' +
      '        margin-bottom: 10px;\n' +
      '        font-size: 14px;\n' +
      '      }\n' +
      '\n' +
      '      .app-treat-plan.treat-plan p {\n' +
      '        margin: 0;\n' +
      '        font-size: 14px;\n' +
      '        line-height: 1.2;\n' +
      '      }\n' +
      '\n' +
      '      .app-treat-plan.treat-plan table.procedures,\n' +
      '      .app-treat-plan.treat-plan table.procedures tr,\n' +
      '      .app-treat-plan.treat-plan table.procedures th {\n' +
      '        border: none;\n' +
      '      }\n' +
      '\n' +
      '      .app-treat-plan.treat-plan table.procedures {\n' +
      '        border-radius: 0;\n' +
      '      }\n' +
      '\n' +
      '      .app-treat-plan.treat-plan table.procedures th {\n' +
      '        text-align: center;\n' +
      '        border-bottom: 1px solid #d8d8d8;\n' +
      '      }\n' +
      '\n' +
      '      .app-treat-plan.treat-plan table.procedures td {\n' +
      '        padding: 15px;\n' +
      '        text-align: center;\n' +
      '        border-bottom: 1px solid #d8d8d8;\n' +
      '      }\n' +
      '\n' +
      '.treat-plan .row .column:last-of-type div {\n' +
      '      margin-left: 0px !important;\n' +
      '    }' +
      '      .costs-wrapper .column {\n' +
      '        width: 100% !important;\n' +
      '        margin-bottom: 20px !important;\n' +
      '        padding: 0 20px !important;\n' +
      '        box-sizing: border-box !important;\n' +
      '        float: none !important;\n' +
      '      }\n' +
      '\n' +
      '      .costs-wrapper .column.col-1 {\n' +
      '        padding: 0 20px 0 0 !important;\n' +
      '      }\n' +
      '\n' +
      '      .costs-wrapper .column.col-2 {\n' +
      '        padding: 0 0 0 20px !important;\n' +
      '      }\n' +
      '\n' +
      '      .app-treat-plan.treat-plan .costs-wrapper table.costs, .app-treat-plan.treat-plan .tp-summary table.costs {\n' +
      '        padding-bottom: 20px;\n' +
      '        border: none;\n' +
      '        background-color: #fff;\n' +
      '        background-repeat: no-repeat;\n' +
      '        background-position: right top;\n' +
      '      }\n' +
      '\n' +
      '      .app-treat-plan.treat-plan .costs-wrapper .column:first-child table.costs, .app-treat-plan.treat-plan .tp-summary .tp-summary-cost table.costs {\n' +
      "        background-image: url('assets/images/cost-icon1.png');\n" +
      '      }\n' +
      '\n' +
      '      .app-treat-plan.treat-plan .costs-wrapper .column:nth-child(2) table.costs, .app-treat-plan.treat-plan .tp-summary .tp-summary-insurance table.costs {\n' +
      "        background-image: url('assets/images/cost-icon2.png');\n" +
      '      }\n' +
      '\n' +
      '      .app-treat-plan.treat-plan .costs-wrapper table.costs th, .app-treat-plan.treat-plan .tp-summary table.costs th {\n' +
      '        padding: 24px 50px 24px 30px;\n' +
      '        color: #1d3243;\n' +
      '        font-weight: 700;\n' +
      '      }\n' +
      '\n' +
      '      .app-treat-plan.treat-plan .costs-wrapper table.costs td, .app-treat-plan.treat-plan .tp-summary table.costs td {\n' +
      '        padding: 12px 8px;\n' +
      '        color: #0a131e;\n' +
      '      }\n' +
      '\n' +
      '      .app-treat-plan.treat-plan .costs-wrapper table.costs td:first-child, .app-treat-plan.treat-plan .tp-summary table.costs td:first-child {\n' +
      '        padding-left: 30px;\n' +
      '      }\n' +
      '\n' +
      '      .app-treat-plan.treat-plan .costs-wrapper table.costs td:last-child, .app-treat-plan.treat-plan .tp-summary table.costs td:last-child {\n' +
      '        padding-right: 30px;\n' +
      '      }\n' +
      '\n' +
      '      .app-treat-plan.treat-plan .notes-title {\n' +
      '        margin-bottom: 10px;\n' +
      '      }\n' +
      '\n' +
      '      /* Responsive styles */\n' +
      '      @media (min-width:480px) {\n' +
      '        .costs-wrapper {\n' +
      '          display: -webkit-box !important;\n' +
      '          display: -webkit-flex !important;\n' +
      '          display: -ms-flexbox !important;\n' +
      '          display: flex !important;\n' +
      '        }\n' +
      '\n' +
      '        .costs-wrapper .column {\n' +
      '          width: 50%;\n' +
      '          margin-bottom: 0;\n' +
      '          float: left !important;\n' +
      '        }\n' +
      '      }\n' +
      '\n' +
      '      @media (max-width:480px) {\n' +
      ' .treat-plan .tp-summary > div:first-of-type { display: flex !important; flex-direction: column; }' +
      '.treat-plan .tp-summary .tp-summary-cost { width: 100% !important; }' +
      '.treat-plan .tp-summary .tp-summary-insurance { width: 100% !important; }' +
      '         .costs-wrapper .column.col-1 {\n' +
      '           padding: 0 !important;\n' +
      '         }\n' +
      '\n' +
      '         .costs-wrapper .column.col-2 {\n' +
      '           padding: 0 !important;\n' +
      '         }\n' +
      '\n' +
      '      .treat-plan table.procedures {\n' +
      '        table-layout: auto !important;\n' +
      '      }\n' +
      '\n' +
      '      }\n' +
      '\n' +
      '      @media (max-width:600px) {\n' +
      '        .app-treat-plan.treat-plan table.responsive span {\n' +
      '          font-weight: bold;\n' +
      '        }\n' +
      '      }\n' +
      '\n' +
      '      @media (min-width:600px) {\n' +
      '        .app-treat-plan.treat-plan {\n' +
      '          padding: 0;\n' +
      '        }\n' +
      '\n' +
      '        .app-treat-plan.treat-plan .title {\n' +
      '          font-size: 22px;\n' +
      '        }\n' +
      '\n' +
      '        .app-treat-plan.treat-plan.treat-plan .subtitle {\n' +
      '          font-size: 15px;\n' +
      '        }\n' +
      '\n' +
      '        .app-treat-plan.treat-plan table.procedures td {\n' +
      '          padding: 15px 8px;\n' +
      '          text-align: center;\n' +
      '        }\n' +
      '      }\n' +
      '\n' +
      '\n' +
      '      @media (min-width:769px) {\n' +
      '        .app-treat-plan.treat-plan .title {\n' +
      '          font-size: 26px;\n' +
      '        }\n' +
      '\n' +
      '        .app-treat-plan.treat-plan.treat-plan .subtitle {\n' +
      '          font-size: 18px;\n' +
      '        }\n' +
      '      }\n' +
      '\n' +
      '\n' +
      '      @media (min-width:993px) {}\n' +
      '\n' +
      '\n' +
      '      @media (min-width:1201px) {\n' +
      '        .app-treat-plan.treat-plan .title {\n' +
      '          font-size: 22px;\n' +
      '        }\n' +
      '      }' +
      '.table-wrapper {\n' +
      '   overflow-x: auto;\n' +
      '}\n' +
      '::-webkit-scrollbar {\n' +
      '    -webkit-appearance: none;\n' +
      '    width: 6px;\n' +
      '}\n' +
      '::-webkit-scrollbar-thumb {\n' +
      '    border-radius: 3px;\n' +
      '    background-color: rgba(0,0,0,.5);\n' +
      '    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);\n' +
      '}\n' +
      'img {\n' +
      '    object-fit: cover;\n' +
      '    width: 100%;\n' +
      '}'
    );
  }

  // tslint:disable-next-line:max-file-line-count
}
