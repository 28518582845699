import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Question } from '../../../forms/form/question.model';
import { QuestionCondition } from '../../../forms/form/question-condition.model';
import { isEmpty, nonEmpty } from '../../../common/utils/string-utils.helper';
import { EditOptions } from './edit-question-options.model';
import {
  getSameKeyPrefixAs,
  isPMSProblemQuestion
} from '../questions-factory.helper';
import { RadioQuestionParams } from '../../../forms/form/controls/radio-control/radio-question.model';
import { ProblemsMapperService } from '../problems-mapper/problems-mapper.service';

@Component({
  selector: 'app-edit-question',
  templateUrl: './edit-question.component.html',
  styleUrls: ['./edit-question.component.scss']
})
export class EditQuestionComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('collapsibleElement') collapsibleElement: ElementRef;

  @Input() question: Question;
  @Input() index: number;
  @Input() editOptions: EditOptions = {
    editType: true,
    editSection: true,
    editOptional: true
  };
  @Output() deleteQuestion = new EventEmitter<number>();
  @Output() cloneQuestion = new EventEmitter<number>();
  @Output() uiStructureChange = new EventEmitter();
  @Output() sectionChange = new EventEmitter<string>();

  isExpanded = false;

  mutationObserver = new MutationObserver((mutations) => {
    mutations.forEach(({ attributeName, target }) => {
      if (attributeName === 'class') {
        // @ts-ignore - target has classList property and it says that it doesnt.
        this.isExpanded = target.classList.contains('active');
      }
    });
  });

  constructor(public mapperService: ProblemsMapperService) {}

  ngOnInit(): void {
    // fix PHP issue where it can map empty objects into empty arrays :|
    if (
      Array.isArray(this.question.control) &&
      this.question.control.length === 0
    ) {
      this.question.control = {};
    }
  }

  ngAfterViewInit(): void {
    this.mutationObserver.observe(this.collapsibleElement.nativeElement, {
      attributes: true
    });
  }

  ngOnDestroy(): void {
    this.mutationObserver.disconnect();
  }

  onDeleteQuestion(): void {
    this.deleteQuestion.emit(this.index);
  }

  onCloneQuestion(event): void {
    event.stopPropagation();
    this.cloneQuestion.emit(this.index);
  }

  onUIStructureChange(): void {
    this.uiStructureChange.emit();
  }

  onTypeChange(): void {
    // on type change, let's make sure 'control' field exists so it can be populated with control-specific fields
    if (!this.question.control) {
      this.question.control = {};
    }
    this.onUIStructureChange();
  }

  onSectionChange(question: Question): void {
    if (question.section === '') {
      question.section = null;
    }
    this.sectionChange.emit(question.section);
  }

  onCreateKeyFromQuestion(question: Question): void {
    question.key = (
      getSameKeyPrefixAs(question) +
      question.title.toLowerCase().replace(/\s/g, '_')
    ).substr(0, 128);
  }

  isPMSProblemQuestion(questionKey: string): boolean {
    return isPMSProblemQuestion(questionKey);
  }

  isPMSMappedProblem(question: Question): boolean {
    if (this.isRadioWithMultiSelect(question)) {
      const radioControl: RadioQuestionParams = question.control;
      const notMappedOption =
        radioControl.extra.options !== undefined
          ? radioControl.extra.options.find(
              (opt) => isEmpty(opt.pms_id) && isEmpty(opt.pms_name)
            )
          : undefined;
      return notMappedOption === undefined;
    } else {
      return (
        question.meta !== undefined &&
        (nonEmpty(question.meta.pms_id) || nonEmpty(question.meta.pms_name))
      );
    }
  }

  onPMSProblemQuestionShown(question: Question): void {
    if (
      question.meta === undefined ||
      (Array.isArray(question.meta) && question.meta.length === 0)
    ) {
      question.meta = {};
    }
  }

  isRadioWithMultiSelect(question: Question): boolean {
    return (
      question.type === 'radio' &&
      question.control !== undefined &&
      question.control.extra !== undefined &&
      question.control.extra.type === 'multi_select'
    );
  }

  toConditionalsDesc(question: Question): string {
    const ifs = [];
    if (question.if) {
      question.if.forEach((condition: QuestionCondition) => {
        ifs.push(
          `${condition.key} ${condition.op ? condition.op : '='} ${
            condition.value
          }`
        );
      });
    }
    if (question.if_or) {
      const ifsOr = [];
      question.if_or.forEach((condition: QuestionCondition) => {
        ifsOr.push(
          `${condition.key} ${condition.op ? condition.op : '='} ${
            condition.value
          }`
        );
      });
      ifs.push('( ' + ifsOr.join(' OR ') + ' )');
    }
    return 'SHOWN IF: ' + ifs.join(' AND ');
  }

  onToggleVisibility(event): void {
    event.stopPropagation();
    event.preventDefault();
    this.question.disabled = !this.question.disabled;
  }
}
