import { AppCommonModule } from '../../common/common.module';
import { CommonModule } from '@angular/common';
import { GetAppComponent } from './get-app.component';
import { NgModule } from '@angular/core';
import { QRCodeModule } from 'angular2-qrcode';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [GetAppComponent],
  imports: [CommonModule, QRCodeModule, TranslateModule, AppCommonModule],
  exports: [GetAppComponent]
})
export class GetAppModule {}
