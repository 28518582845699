import { Question } from '../../question.model';

export enum InputType {
  Past = 'past',
  Future = 'future',
  Any = 'any'
}

export interface DateQuestion extends Question {
  control?: DateQuestionParams;
}

export interface DateQuestionParams {
  input_type: InputType;
}
