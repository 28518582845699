import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppCommonModule } from '../../common/common.module';
import { KioskCheckinComponent } from './kiosk-checkin.component';
import { TranslateModule } from '@ngx-translate/core';
import { CheckinSearchComponent } from './checkin-search/checkin-search.component';
import { CheckinVerifyComponent } from './checkin-verify/checkin-verify.component';
import { CheckedInComponent } from './checked-in/checked-in.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KioskCheckinService } from './kiosk-checkin.service';
import { PatientFormsModule } from '../../forms/patient-forms.module';
import { GetAppModule } from '../get-app/get-app.module';
import { ReactiveComponentModule } from '@ngrx/component';

@NgModule({
  declarations: [
    KioskCheckinComponent,
    CheckinSearchComponent,
    CheckinVerifyComponent,
    CheckedInComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    PatientFormsModule,
    GetAppModule,
    ReactiveComponentModule
  ],
  providers: [KioskCheckinService]
})
export class KioskCheckinModule {}
