<ng-container
              *ngIf="question.isVisible(formData) && question.key; else noControl">
  <app-input-control [question]="question"
                     [data]="formData[question.key]"
                     (output)="output.emit($event)"
                     (control)="control.emit($event)"
                     *ngIf="question.type === 'input' && !recalculateControl">
  </app-input-control>
  <app-date-input-control [question]="question"
                          [data]="formData[question.key]"
                          (output)="output.emit($event)"
                          (control)="control.emit($event)"
                          *ngIf="question.type === 'date'">
  </app-date-input-control>
  <app-dropdown-control [question]="question"
                        [data]="formData[question.key]"
                        (output)="output.emit($event)"
                        (control)="control.emit($event)"
                        *ngIf="question.type === 'dropdown'">
  </app-dropdown-control>
  <app-state-control [question]="question"
                     [data]="formData[question.key]"
                     [autocompleteEnabled]="true"
                     (output)="output.emit($event)"
                     (control)="control.emit($event)"
                     *ngIf="question.type === 'states'"></app-state-control>
  <app-multiradio-control [question]="question"
                          [data]="formData"
                          (output)="multiOutput.emit($event)"
                          (control)="control.emit($event)"
                          *ngIf="question.type === 'multiradio'">
  </app-multiradio-control>
  <app-photo-control [question]="question"
                     [data]="formData[question.key]"
                     (output)="output.emit($event)"
                     (control)="control.emit($event)"
                     *ngIf="question.type === 'photo'"></app-photo-control>
  <app-radio-control [question]="question"
                     [data]="formData[question.key]"
                     (output)="output.emit($event)"
                     (control)="control.emit($event)"
                     *ngIf="question.type === 'radio'"></app-radio-control>
  <app-signature-control [question]="question"
                         [data]="formData[question.key]"
                         (output)="output.emit($event)"
                         (control)="control.emit($event)"
                         *ngIf="question.type === 'signature'">
  </app-signature-control>
  <app-terms-control [question]="question"
                     [data]="formData[question.key]"
                     (output)="output.emit($event)"
                     (control)="control.emit($event)"
                     *ngIf="question.type === 'terms'"></app-terms-control>
  <app-text-control [question]="question"
                    *ngIf="question.type === 'text'"></app-text-control>
  <app-link-control [question]="question"
                    *ngIf="question.type === 'link'"></app-link-control>
  <!-- Button enabling lite-edit mode for staff -->
  <app-lite-edit-control *ngIf="liteEditorService.editMode"
                         [question]="question"></app-lite-edit-control>
</ng-container>
<ng-template #noControl
             onload="">
  <app-existence [data]="question.key"
                 (created)="hiddenKey.emit($event)"></app-existence>
</ng-template>
