import { NgModule } from '@angular/core';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AlertsService } from './alerts/alerts.service';
import { ExistenceComponent } from './existence/existence.component';
import { ModalComponent } from './modal/modal.component';
import { ExternalUrlViewerComponent } from './external-url-viewer/external-url-viewer.component';
import { SafePipeModule } from 'safe-pipe';
import { TranslateModule } from '@ngx-translate/core';
import { SessionTimeoutService } from './session-timeout/session-timeout.service';
import { SessionTimeoutComponent } from './session-timeout/session-timeout.component';
import { CommonModule } from '@angular/common';
import { FinalPageComponent } from './final-page/final-page.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { ErrorMessageService } from './error-message/error-message.service';
import { ModentoClientHeaderInterceptor } from './interceptors/modento-client-header-interceptor.service';
import { FormsModule } from '@angular/forms';
import { CheckPatientInitialsComponent } from './check-patient-initials/check-patient-initials.component';
import { AcceptLanguageHeaderInterceptor } from './interceptors/accept-language-header-interceptor.service';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { AccessibilityService } from './accessibility-service/accessibility.service';
import { FontSizeToggleComponent } from './font-size-toggle/font-size-toggle.component';
import { PhonePipe } from './pipes/phone/phone.pipe';
import { PlaceholdersService } from './placeholders-service/placeholders.service';
import { CheckQrcodeComponent } from './check-qrcode/check-qrcode.component';
import { QRCodeModule } from 'angular2-qrcode';
import { InactivityBarComponent } from './inactivity-bar/inactivity-bar.component';
import { EllipsizePipe } from './pipes/ellipsize/ellipsize.pipe';

@NgModule({
  declarations: [
    PageNotFoundComponent,
    SpinnerComponent,
    ExistenceComponent,
    ModalComponent,
    ExternalUrlViewerComponent,
    SessionTimeoutComponent,
    FinalPageComponent,
    PageHeaderComponent,
    CheckPatientInitialsComponent,
    ConfirmModalComponent,
    FontSizeToggleComponent,
    CheckQrcodeComponent,
    InactivityBarComponent,
    EllipsizePipe
  ],
  imports: [
    SafePipeModule,
    TranslateModule,
    CommonModule,
    FormsModule,
    QRCodeModule
  ],
  exports: [
    SpinnerComponent,
    ExistenceComponent,
    ModalComponent,
    ExternalUrlViewerComponent,
    FinalPageComponent,
    PageHeaderComponent,
    CheckPatientInitialsComponent,
    ConfirmModalComponent,
    FontSizeToggleComponent,
    CheckQrcodeComponent,
    InactivityBarComponent,
    EllipsizePipe
  ],
  providers: [
    DeviceDetectorService,
    AlertsService,
    SessionTimeoutService,
    ErrorMessageService,
    AccessibilityService,
    PlaceholdersService,
    ModentoClientHeaderInterceptor,
    AcceptLanguageHeaderInterceptor,
    PhonePipe
  ]
})
export class AppCommonModule {}
