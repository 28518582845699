import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
} from '@angular/core';

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkeletonComponent {
  @HostBinding('style.--skeleton-width')
  @Input()
  width: string;

  @HostBinding('style.--skeleton-height')
  @Input()
  height: string;

  @HostBinding('style.--skeleton-layerColor')
  @Input()
  layerColor: string;

  @HostBinding('style.--skeleton-waveColor')
  @Input()
  waveColor: string;
}
