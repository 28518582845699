export interface Clearent {
  initialized: boolean;
  init: (config: ClearentInitializationConfig) => Clearent;
  reset: () => void;
  getPaymentToken: () => Promise<ClearentPaymentToken>;
  frame: HTMLIFrameElement;
}

export enum ClearentInitialMode {
  READER = 'reader',
  MANUAL = 'manual'
}

export enum TerminalType {
  VIRTUAL = 'virtual',
  PHYSICAL = 'physical'
}

export interface ClearentInitializationConfig {
  baseUrl: string;
  pk: string;
  initialMode: ClearentInitialMode;
  enableReader: boolean;
  deviceType: string;
  styles: string;
  validationCallback: string;
  entryModeChangeCallback: string;
  showValidationMessages: boolean;
}

export enum ClearentValidationMessageType {
  CARD_NUMBER_REQUIRED = 'Card number is required',
  CARD_NUMBER_INVALID = 'Card number is not valid',
  EXPIRATION_REQUIRED = 'Expiration date is required',
  EXPIRATION_INVALID = 'Expiration date is not valid',
  CSC_REQUIRED = 'CSC is required',
  CSC_INVALID = 'CSC is not valid'
}

export enum ClearentEntryModeType {
  MANUAL = 'manual',
  EMV_KB_READER = 'emv_kb_reader'
}

export enum ClearentCallbackMethod {
  CLEARENT_VALIDATION_MESSAGES = 'clearentValidationMessages',
  CLEARENT_ON_ENTRY_MODE_CHANGE = 'clearentOnEntryModeChange'
}

export interface ClearentPayloadData {
  enabled: boolean;
  payload: {
    token: string;
    expiry: string;
  };
}

export interface ClearentError {
  type: ClearentValidationMessageType;
  message: string;
}

/* eslint-disable @typescript-eslint/naming-convention */
export interface ClearentPaymentToken {
  payload: {
    'mobile-jwt': {
      jwt: string;
      'exp-date': string;
    };
  };
}
