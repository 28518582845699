import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccessibilityService {
  private fontsSizeChangesSubject = new Subject<boolean>();

  constructor(private deviceDetectorService: DeviceDetectorService) {
    if (!this.deviceDetectorService.isDesktop()) {
      document.body.classList.add('accessibility-mobile-font');
    }
  }

  hasBigFont(): boolean {
    return document.body.classList.contains('accessibility-big-font');
  }

  toggleBigFont(): void {
    if (this.hasBigFont()) {
      document.body.classList.remove('accessibility-big-font');
      this.fontsSizeChangesSubject.next(false);
    } else {
      document.body.classList.add('accessibility-big-font');
      this.fontsSizeChangesSubject.next(true);
    }
  }

  setBigFont(set: boolean): void {
    if (this.hasBigFont()) {
      if (!set) {
        document.body.classList.remove('accessibility-big-font');
        this.fontsSizeChangesSubject.next(false);
      }
    } else {
      if (set) {
        document.body.classList.add('accessibility-big-font');
        this.fontsSizeChangesSubject.next(true);
      }
    }
  }

  getFontChangesObservable(): Observable<boolean> {
    return this.fontsSizeChangesSubject;
  }
}
