import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InitialsStoreService {
  initials: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor() {}

  initialsChange(initials: string): void {
    this.initials.next(initials);
  }

  initialsTyped(): string {
    return this.initials.getValue();
  }
}
