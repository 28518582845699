import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-signature',
  templateUrl: './edit-signature.component.html',
  styleUrls: ['./edit-signature.component.scss']
})
export class EditSignatureComponent {
  @Input() key: string;
}
