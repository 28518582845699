<div class="close-container">
  <mat-icon mat-dialog-close>close</mat-icon>
</div>
<div class="dialog-container">
  <div class="title">
    {{ data?.title || ('FORMS.LITE_EDITOR.SINGLE_INPUT_DIALOG.TITLE' | translate) }}
  </div>

  <form [formGroup]="form">
    <mat-form-field>
      <mat-label>{{
        data?.description || ('FORMS.LITE_EDITOR.SINGLE_INPUT_DIALOG.DESCRIPTION' | translate)
      }}</mat-label>
      <input matInput
             formControlName="itemName"
             (window:keyup.enter)="createItem()" />
    </mat-form-field>
  </form>

  <mat-dialog-actions align="end">
    <button mat-flat-button
            mat-dialog-close>
      {{ data?.cancelBtn || ('FORMS.LITE_EDITOR.SINGLE_INPUT_DIALOG.CANCEL' | translate) }}
    </button>
    <button mat-flat-button
            color="primary"
            class="primary-variant"
            [disabled]="!form.valid"
            (click)="createItem()">
      {{ data?.createBtn || ('FORMS.LITE_EDITOR.SINGLE_INPUT_DIALOG.CREATE' | translate) }}
    </button>
  </mat-dialog-actions>
</div>
