import { Pipe, PipeTransform } from '@angular/core';
import { TransactionCardType } from '../../clearent-card-payment/clearent-card-payment.types';

@Pipe({
  name: 'cardIconName'
})
export class PaymentCardIconNamePipe implements PipeTransform {
  transform(cardType: TransactionCardType, darken: boolean): string {
    return (darken ? 'dark-' : '') + this.transformCardTypeToIconName(cardType);
  }

  transformCardTypeToIconName(cardType: TransactionCardType): string {
    switch (cardType) {
      case TransactionCardType.MASTERCARD: {
        return 'mastercard';
      }
      case TransactionCardType.VISA: {
        return 'visa';
      }
      case TransactionCardType.AMEX: {
        return 'amex';
      }
      case TransactionCardType.DISCOVER: {
        return 'discover';
      }
      case TransactionCardType.JCB: {
        return 'jcb';
      }
      case TransactionCardType.DINERS: {
        return 'diners';
      }
      default: {
        return 'default-card';
      }
    }
  }
}
