import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditFormsComponent } from './edit-forms/edit-forms.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppCommonModule } from '../common/common.module';
import { EditInputComponent } from './edit-forms/edit-input/edit-input.component';
import { EditDateComponent } from './edit-forms/edit-date/edit-date.component';
import { EditRadioComponent } from './edit-forms/edit-radio/edit-radio.component';
import { EditDropdownComponent } from './edit-forms/edit-dropdown/edit-dropdown.component';
import { EditSignatureComponent } from './edit-forms/edit-signature/edit-signature.component';
import { EditTermsComponent } from './edit-forms/edit-terms/edit-terms.component';
import { EditPhotoComponent } from './edit-forms/edit-photo/edit-photo.component';
import { EditOptionComponent } from './edit-forms/edit-option/edit-option.component';
import { EditMultiradioComponent } from './edit-forms/edit-multiradio/edit-multiradio.component';
import { EditQuestionComponent } from './edit-forms/edit-question/edit-question.component';
import { FormSpecPickerComponent } from './edit-forms/form-spec-picker/form-spec-picker.component';
import { OnboardingToolsService } from './onboarding-tools-service/onboarding-tools.service';
import { EditLinkComponent } from './edit-forms/edit-link/edit-link.component';
import { EditTextComponent } from './edit-forms/edit-text/edit-text.component';
import { ProblemsMapperComponent } from './edit-forms/problems-mapper/problems-mapper.component';
import { CreateConsentComponent } from './create-consent/create-consent.component';
import { EditBlockComponent } from './edit-forms/edit-block/edit-block.component';
import { HtmlEditorComponent } from './edit-forms/html-editor/html-editor.component';
import { HtmlEditorModule } from './edit-forms/html-editor/html-editor.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TwoFactorAuthenticationDialogModule } from './edit-forms/form-spec-picker/two-factor-authentication-dialog/two-factor-authentication-dialog.module';

@NgModule({
  declarations: [
    EditFormsComponent,
    EditInputComponent,
    EditDateComponent,
    EditRadioComponent,
    EditDropdownComponent,
    EditSignatureComponent,
    EditTermsComponent,
    EditPhotoComponent,
    EditOptionComponent,
    EditMultiradioComponent,
    EditQuestionComponent,
    FormSpecPickerComponent,
    EditLinkComponent,
    EditTextComponent,
    ProblemsMapperComponent,
    CreateConsentComponent,
    EditBlockComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppCommonModule,
    HtmlEditorModule,
    DragDropModule,
    ScrollingModule,
    TwoFactorAuthenticationDialogModule
  ],
  providers: [OnboardingToolsService],
  exports: [HtmlEditorComponent]
})
export class ToolsModule {}
