<li class="payment_option-item"
    (click)="onClick()">
  <div></div>
  <div class="header">
    <h3 class="payment_option-item_title">
      {{ 'CONSENTS.PAYMENT_OPTIONS.CHOOSE_LATER' | translate }}
    </h3>
  </div>
  <div class="payment_option-item_footer">
    <input type="button"
           value="{{ 'CONSENTS.PAYMENT_OPTIONS.SELECT' | translate }}"
           class="payment_option-item_footer_button"
           [class.active]="active" />
  </div>
</li>
