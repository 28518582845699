import {
  AddCardPayload,
  CreditCardDetail,
  UpdateCardPayload
} from '../../common/clearent/clearent-card-payment/clearent-card-payment.types';

import { Patient } from '../../../app/common/patient/patient.model';

export enum PaymentPlanStatus {
  ACTIVE = 'active',
  PENDING = 'pending',
  CANCELLED = 'cancelled',
  FAILED = 'failed',
  COMPLETED = 'completed',
  EXPIRED = 'expired'
}

export enum PaymentPlanScheduledPaymentStatus {
  QUEUED = 'queued',
  CANCELLED = 'cancelled',
  FAILED = 'failed',
  COMPLETED = 'completed'
}

export interface PaymentPlanScheduledPayment {
  id: number;
  payment_date: string;
  amount: number;
  payment_number: number;
  status: PaymentPlanScheduledPaymentStatus;
  created_at: string;
  updated_at: string;
}

export interface PaymentPlan {
  id: number;
  start_date: string;
  total_amount: number;
  number_of_payments: number;
  contact_email: string;
  frequency: string;
  status: PaymentPlanStatus;
  patient: Patient;
  practice_id: number;
  cancelled_by_user_id: number;
  created_by_user_id: number;
  credit_card_detail: CreditCardDetail;
  created_at: string;
  updated_at: string;
  scheduled_payments: PaymentPlanScheduledPayment[];
}

export interface PaymentPlanResponse {
  payment_plan: PaymentPlan;
  message: string;
  success: boolean;
}

export enum SigningAs {
  PATIENT = 'patient',
  GUARDIAN = 'guardian'
}

export interface AttachSignaturePayload {
  guid: string;
  patient_id: number;
  attachment: File;
  filename: string;
}

export interface AttachSignatureResponse {
  success: boolean;
  message: string;
  type: string;
  attachment: {
    attachment: string;
    created_at: string;
    filename: string;
    guid: string;
    patient_id: string;
    practice_id: number;
    updated_at: string;
  };
}

export interface ActivatePlanPayload
  extends Partial<AddCardPayload & UpdateCardPayload> {
  signature: string;
}

export interface SavePaymentPlanResponse {
  message: string;
  payment_plan: PaymentPlan;
  success: boolean;
  type: string;
}
