import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponent } from './form/form.component';
import { InputControlComponent } from './form/controls/input-control/input-control.component';
import { PhotoControlComponent } from './form/controls/photo-control/photo-control.component';
import { DateControlComponent } from './form/controls/date-control/date-control.component';
import { RadioControlComponent } from './form/controls/radio-control/radio-control.component';
import { DropdownControlComponent } from './form/controls/dropdown-control/dropdown-control.component';
import { MultiradioControlComponent } from './form/controls/multiradio-control/multiradio-control.component';
import { TextControlComponent } from './form/controls/text-control/text-control.component';
import { TermsControlComponent } from './form/controls/terms-control/terms-control.component';
import { SignatureControlComponent } from './form/controls/signature-control/signature-control.component';
import { FormsRequestComponent } from './forms-request/forms-request.component';
import { AllCompletedComponent } from './all-completed/all-completed.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignaturePadModule } from 'angular2-signaturepad';
import { HttpClientModule } from '@angular/common/http';
import { TextMaskModule } from 'angular2-text-mask';
import { AppCommonModule } from '../common/common.module';
import { KioskDeviceService } from '../kiosk/kiosk-device/kiosk-device.service';
import { TranslateModule } from '@ngx-translate/core';
import { DateInputControlComponent } from './form/controls/date-input-control/date-input-control.component';
import { AbstractInputControlComponent } from './form/controls/abstract-input-control/abstract-input-control.component';
import { AbstractControlComponent } from './form/controls/abstract-control/abstract-control.component';
import { StateControlComponent } from './form/controls/state-control/state-control.component';
import { FormFeedbackToolComponent } from './form/form-feedback-tool/form-feedback-tool.component';
import { LinkControlComponent } from './form/controls/link-control/link-control.component';
import { ControlComponent } from './form/control/control.component';
import { GetAppModule } from '../kiosk/get-app/get-app.module';
import { InitialsStoreService } from '../common/initials-store/initials-store.service';
import { LiteEditControlComponent } from './lite-editor/lite-edit-control/lite-edit-control.component';
import { LiteEditControlModalComponent } from './lite-editor/lite-edit-control-modal/lite-edit-control-modal.component';
import { QuillModule } from 'ngx-quill';
import { HtmlEditorModule } from '../tools/edit-forms/html-editor/html-editor.module';
import { MatRippleModule } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveComponentModule } from '@ngrx/component';
import { PhotoPreviewModule } from '../common/photo-preview/photo-preview.module';
import { OnlyNumberModule } from '../common/only-number/only-number.module';

@NgModule({
  declarations: [
    FormComponent,
    InputControlComponent,
    PhotoControlComponent,
    DateControlComponent,
    RadioControlComponent,
    DropdownControlComponent,
    MultiradioControlComponent,
    TextControlComponent,
    TermsControlComponent,
    SignatureControlComponent,
    FormsRequestComponent,
    AllCompletedComponent,
    DateInputControlComponent,
    AbstractInputControlComponent,
    AbstractControlComponent,
    StateControlComponent,
    FormFeedbackToolComponent,
    LinkControlComponent,
    ControlComponent,
    LiteEditControlComponent,
    LiteEditControlModalComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    FormsModule,
    ReactiveFormsModule,
    SignaturePadModule,
    HttpClientModule,
    TextMaskModule,
    TranslateModule,
    GetAppModule,
    ReactiveComponentModule,
    HtmlEditorModule,
    PhotoPreviewModule,
    OnlyNumberModule
  ],
  exports: [
    RadioControlComponent,
    SignatureControlComponent,
    InputControlComponent,
    DateInputControlComponent,
    MultiradioControlComponent,
    ControlComponent
  ],
  providers: [KioskDeviceService, InitialsStoreService]
})
export class PatientFormsModule {}
