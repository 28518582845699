<div class="row">
  <div class="col s10 offset-s1">
    <app-date-input-control [question]="dobVerifyQuestion()"
                            [data]="undefined"
                            (output)="onFormDataChange('date_of_birth', $event)"
                            (control)="onFormControlSet('date_of_birth', $event)"
                            *ngIf="selectedPatient.method === 'date_of_birth'">
    </app-date-input-control>
    <app-input-control [question]="phoneVerifyQuestion()"
                       [data]="undefined"
                       (output)="onFormDataChange('mobile_phone', $event)"
                       (control)="onFormControlSet('mobile_phone', $event)"
                       *ngIf="selectedPatient.method === 'mobile_phone'">
    </app-input-control>
  </div>
</div>
<div class="row"
     *ngIf="isLoading">
  <div class="col s12 center-align">
    <app-spinner></app-spinner>
  </div>
</div>
<div class="row">
  <div class="col s12 center-align">
    <button type="button"
            class="btn btn-primary mt-2"
            (click)="onContinue()">
      {{ 'KIOSK.CHECKIN.VERIFY.CONTINUE' | translate }}
    </button>
  </div>
</div>
