<div class="col s12">
  <div class="row">
    <div class="col s12">
      <p class="text-primary">Options</p>
      <div class="list-options"
           cdkDropList
           (cdkDropListDropped)="drop($event, control.options)">
        <div class="list-options__item"
             *ngFor="let answerOption of control.options; index as i"
             cdkDrag>
          <app-edit-option [answerOption]="answerOption"
                           [questionKey]="key"
                           [index]="i"
                           [isPMSProblem]="false"
                           (deleting)="onDeleteOption($event)">
          </app-edit-option>
        </div>
      </div>
      <div>
        <button class="btn"
                (click)="onAddOption()">
          <i class="material-icons">add</i>
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="input-field col s6">
      <label>
        <input type="checkbox"
               class="filled-in"
               [(ngModel)]="extraEnabled"
               (change)="onExtraChanged($event)"
               [value]="true" />
        <span>Has extra question</span>
      </label>
    </div>
    <div class="input-field col s6">
      <input placeholder="Default value (key)"
             id="{{ key + '-control-default' }}"
             type="text"
             class="validate"
             [(ngModel)]="control.default"
             (ngModelChange)="onDefaultChange()" />
      <label for="{{ key + '-control-default' }}">Default value (key)</label>
    </div>
  </div>
  <div class="row"
       *ngIf="control.extra">
    <div class="input-field col s6">
      <input placeholder="Trigger value (key)"
             id="{{ key + '-control-extra-value' }}"
             type="text"
             class="validate"
             [(ngModel)]="control.extra.value"
             (ngModelChange)="onExtraValueChange()" />
      <label for="{{ key + '-control-extra-value' }}">Trigger value
        (key)</label>
    </div>
    <div class="input-field col s6">
      <label>
        <input type="checkbox"
               class="filled-in"
               [(ngModel)]="control.extra.optional"
               [value]="true" />
        <span>Extra is optional</span>
      </label>
    </div>
  </div>
  <div class="row"
       *ngIf="control.extra">
    <div class="input-field col s6">
      <select [(ngModel)]="control.extra.type">
        <option value="input">Text input</option>
        <option value="multi_select">Multi-dropdown</option>
      </select>
      <label>Extra type</label>
    </div>
    <div class="input-field col s6">
      <input placeholder="Hint"
             id="{{ key + '-control-extra-hint' }}"
             type="text"
             class="validate"
             [(ngModel)]="control.extra.hint"
             (ngModelChange)="onExtraHintChanged()" />
      <label for="{{ key + '-control-extra-hint' }}">Hint</label>
    </div>
  </div>
  <div class="row">
    <ng-container
                  *ngIf="control.extra && control.extra.type === 'input'; else noExtra">
      <div class="input-field col s6">
        <select [(ngModel)]="control.extra.input_type">
          <option value="name">Name / text</option>
          <option value="phone">Phone number</option>
          <option value="email">Email</option>
          <option value="ssn">SSN</option>
          <option value="zip">ZIP</option>
          <option value="number">Numerical</option>
        </select>
        <label>Input type</label>
      </div>
    </ng-container>
    <ng-container *ngIf="
        control.extra && control.extra.type === 'multi_select';
        else noExtra
      ">
      <div class="col s12">
        <div class="row"
             *ngIf="isPMSProblem">
          <div class="col s12">
            <button class="btn btn-primary"
                    (click)="
                mapperService.performDefaultMappingForAnswers(
                  control.extra.options,
                  key
                )
              ">
              MAP ALL ANSWERS - CREATE IN PMS IF MISSING
            </button>
          </div>
        </div>
        <div class="row">
          <div class="input-field col s6">
            <label>
              <input type="checkbox"
                     class="filled-in"
                     [(ngModel)]="control.extra.other"
                     [value]="true" />
              <span>"Other" option</span>
            </label>
          </div>
          <div class="input-field col s6">
            <label>
              <input type="checkbox"
                     class="filled-in"
                     [(ngModel)]="control.extra.single_choice" />
              <span>Limit to single answer only</span>
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col s11 offset-s1">
            <p class="text-primary">Extra options</p>
            <div class="list-options"
                 cdkDropList
                 (cdkDropListDropped)="drop($event, control.extra.options)">
              <div class="list-options__item"
                   *ngFor="let answerOption of control.extra.options; index as i"
                   cdkDrag>
                <app-edit-option [answerOption]="answerOption"
                                 [questionKey]="key"
                                 [index]="i"
                                 [isPMSProblem]="isPMSProblem"
                                 (deleting)="onDeleteExtraOption($event)">
                </app-edit-option>
              </div>
            </div>
            <div>
              <button class="btn"
                      (click)="onAddExtraOption()">
                <i class="material-icons">add</i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #noExtra>
      <app-existence (created)="onUIChanged()"></app-existence>
    </ng-template>
  </div>
</div>
