<app-modal id="problem-mapper"
           [startOpened]="true"
           (modalPluginSet)="modal = $event"
           (modalClosed)="mapperService.hideMapper()">
  <app-spinner *ngIf="mapperService.isSubmitting"></app-spinner>
  <div class="row"
       *ngIf="bulkAnswers !== undefined">
    <div class="col s12">
      <p>
        Bulk mapping in progress: {{ bulkProgress }} / {{ bulkAnswers.length }}
      </p>
    </div>
  </div>
  <div class="row dark-bg"
       *ngIf="!mapperService.isSubmitting">
    <div class="input-field col s12">
      <input type="text"
             id="problem-mapper-autocomplete"
             class="autocomplete"
             [(ngModel)]="searchInput"
             autofocus
             #condition_autocomplete />
      <label for="problem-mapper-autocomplete">Search in PMS</label>
    </div>
  </div>
  <div class="row"
       *ngIf="!mapperService.isSubmitting">
    <div class="col s12">
      <button type="button"
              class="btn btn-primary waves-effect waves-primary"
              (click)="createInPMS()">
        Create in PMS
      </button>
      <p>
        If supported by PMS, will create condition with name:
        <strong>{{ searchInput }}</strong>
      </p>
      <p>WARNING: Cannot be deleted from PMS by Engagement</p>
    </div>
  </div>
</app-modal>
