import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RefuseConsentPreviewComponent } from './refuse-consent-preview.component';
import { FormsModule } from '@angular/forms';
import { PatientFormsModule } from '../../../../forms/patient-forms.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [RefuseConsentPreviewComponent],
  exports: [RefuseConsentPreviewComponent],
  imports: [CommonModule, FormsModule, PatientFormsModule, TranslateModule]
})
export class RefuseConsentPreviewModule {}
