import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-check-patient-initials',
  templateUrl: './check-patient-initials.component.html',
  styleUrls: ['./check-patient-initials.component.scss']
})
export class CheckPatientInitialsComponent implements OnInit {
  @Input() patient: any;
  @Output() verified = new EventEmitter<boolean>();
  private patientInitials;
  private triesCnt = 0;
  private readonly MAX_TRIES = 3;
  errorText: string;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.patientInitials = this.patient
      ? (this.patient.first_name
          ? this.patient.first_name.substring(0, 1)
          : '') +
        (this.patient.last_name ? this.patient.last_name.substring(0, 1) : '')
      : '';

    this.patientInitials = this.patientInitials.toUpperCase();
  }

  checkInitials(initials: string): void {
    if (initials.trim().toUpperCase() === this.patientInitials) {
      this.errorText = '';
      this.verified.emit(true);
    } else {
      this.triesCnt++;
      if (this.triesCnt >= this.MAX_TRIES) {
        this.errorText = this.translateService.instant(
          'COMMON.INITIALS.WRONG_TOO_MANY'
        );
        this.verified.emit(false);
      } else {
        this.errorText = this.translateService.instant(
          'COMMON.INITIALS.WRONG',
          {
            left: this.MAX_TRIES - this.triesCnt
          }
        );
      }
    }
  }

  onInputChange(text: string): void {
    if (text && text.length === 2) {
      this.checkInitials(text);
    }
  }
}
