<div class="row">
  <div class="col s12">
    <p class="question-title">
      {{ question.title?.trim()
      }}<span class="required"
            *ngIf="!question.optional">*</span>
    </p>
    <span class="helper-text light"
          *ngIf="question.text">{{
      question.text
    }}</span>
    <div class="file-field input-field">
      <button type="button"
              class="modento-btn secondary photo-control__upload-btn">
        <span>{{ 'FORMS.BUTTONS.PHOTO' | translate }}</span>
        <input type="file"
               #fileInput
               id="{{ question.key }}"
               [name]="question.key"
               (change)="onFileChange($event)"
               [attr.capture]="takePhotoOnly ? captureMode : null"
               accept="image/*" />
      </button>
      <div class="file-path-wrapper">
        <input class="file-path validate"
               type="text"
               (click)="onFilePathInputClick()"
               [ngModel]="fileName" />
        <i
          *ngIf="photo != null"
          class="material-icons remove-photo"
          (click)="onFileRemove()"
          title="{{ 'FORMS.CONTROLS.PHOTO.REMOVE' | translate }}"
          >remove_circle</i>
        <app-photo-preview class="file-path-wrapper__preview"
                           [file]="file"></app-photo-preview>
      </div>
      <ng-container *ngIf="!formControl.valid && formControl.touched">
        <span class="helper-text invalid"
              *ngIf="formControl.errors.required">{{
          'FORMS.VALIDATION.PHOTO.REQUIRED' | translate
        }}</span>
      </ng-container>
    </div>
  </div>
</div>
