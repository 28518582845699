import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { PracticeService } from '../../common/practice';
import { AlertsService } from '../../common/alerts/alerts.service';
import { OnboardingToolsService } from '../onboarding-tools-service/onboarding-tools.service';
import { NewConsentSpec } from './new-consent-spec.model';
import { Materialize } from '../../materialize';
import { isEmpty } from '../../common/utils/string-utils.helper';
import { NavigationExtras, Router } from '@angular/router';

declare const M: Materialize;

@Component({
  selector: 'app-create-consent',
  templateUrl: './create-consent.component.html',
  styleUrls: ['./create-consent.component.scss']
})
export class CreateConsentComponent
  implements OnInit, AfterViewInit, OnDestroy {
  isSubmitting: boolean;
  newConsent: NewConsentSpec = {
    type: '',
    title: '',
    category: '',
    sub_category: '',
    language: 'en',
    for_minor: false
  };

  constructor(
    private router: Router,
    private practiceService: PracticeService,
    private alertsService: AlertsService,
    private toolsService: OnboardingToolsService,
    private readonly renderer2: Renderer2
  ) {}

  ngOnInit(): void {
    this.renderer2.addClass(document.body, 'gradient-background');
  }

  ngAfterViewInit(): void {
    M.updateTextFields();
  }

  ngOnDestroy(): void {
    this.renderer2.removeClass(document.body, 'gradient-background');
  }

  async onCreateConsent(): Promise<any> {
    if (!this.validate()) {
      return;
    }
    try {
      this.isSubmitting = true;
      await this.toolsService.createConsentSpec(this.newConsent);
      this.alertsService.showInfo('Consent spec created.');
      this.router
        .navigate(['tools', 'forms'], { replaceUrl: true } as NavigationExtras)
        .then();
    } catch (e) {
      this.alertsService.showApiError(e);
    } finally {
      this.isSubmitting = false;
    }
  }

  private validate(): boolean {
    if (isEmpty(this.newConsent.title)) {
      this.alertsService.showError('Title field is required');
      return false;
    }

    return true;
  }
}
