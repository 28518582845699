import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  MultiradioQuestion,
  MultiradioQuestionParams
} from '../../../forms/form/controls/multiradio-control/multiradio-question.model';
import clonedeep from '../../../../../node_modules/lodash.clonedeep';
import {
  buildYesNoMultiQuestion,
  buildYesNoQuestion,
  getSameKeyPrefixAs
} from '../questions-factory.helper';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-edit-multiradio',
  templateUrl: './edit-multiradio.component.html',
  styleUrls: ['./edit-multiradio.component.scss']
})
export class EditMultiradioComponent implements OnInit {
  @Input() key: string;
  @Input() question: MultiradioQuestion;
  @Output() uiChange = new EventEmitter();
  control: MultiradioQuestionParams;

  ngOnInit(): void {
    // this is provided for case when multiradio is created from cloned radio question. (more: MD-3786 ad.4)
    // @ts-ignore
    if (this.question.control.options) {
      // @ts-ignore
      delete this.question.control.options;
    }
    this.control = this.question.control;
  }

  onDeleteQuestion(index: number): void {
    this.control.questions.splice(index, 1);
    this.uiChange.emit();
  }

  onCloneQuestion(index: number): void {
    this.control.questions.splice(
      index,
      0,
      clonedeep(this.control.questions[index])
    );
    this.uiChange.emit();
  }

  onAddYesNoQuestion(): void {
    const q = buildYesNoQuestion();
    q.section = this.question.section;
    q.key = getSameKeyPrefixAs(this.question);

    if (!this.control.questions) {
      this.control.questions = [];
    }
    this.control.questions.push(q);
    this.uiChange.emit();
  }

  onAddYesNoMultiQuestion(): void {
    const q = buildYesNoMultiQuestion();
    q.section = this.question.section;
    q.key = getSameKeyPrefixAs(this.question);

    if (!this.control.questions) {
      this.control.questions = [];
    }
    this.control.questions.push(q);
    this.uiChange.emit();
  }

  drop(event: CdkDragDrop<string[]>, controlOptions): void {
    moveItemInArray(controlOptions, event.previousIndex, event.currentIndex);
  }
}
