<div class="dialog-close">
  <mat-icon (click)="cancelClicked()">close</mat-icon>
</div>
<div class="confirmation-dialog">
  <div class="title">
    {{ title }}
  </div>
  <div class="message">
    {{ message }}
    <span class="patient-name">{{ patientName }}</span>
  </div>
  <app-payment-card-detail [card]="cardOnFile"></app-payment-card-detail>
  <div class="buttons text-right">
    <button mat-flat-button
            (click)="cancelClicked()">
      {{ 'COMMON.BUTTONS.CANCEL' | translate }}
    </button>
    <button mat-flat-button
            color="primary"
            (click)="confirmClicked()">
      {{ 'COMMON.BUTTONS.CONFIRM' | translate }}
    </button>
  </div>
</div>
