import { AttachmentResponse } from './attachment-response.model';
import { ConsentRequest } from './consent-request.model';
import { ConsentSubmit } from './consent-submit.model';
import { Injectable } from '@angular/core';
import { MobiatiHttpService } from '../../common/mobiati-http/mobiati-http.service';
import { PracticeService } from '../../common/practice';

@Injectable({
  providedIn: 'root'
})
export class ConsentsRequestService {
  private code: string;

  constructor(
    private http: MobiatiHttpService,
    private practiceService: PracticeService
  ) {}

  setConsentRequestCode(code: string): void {
    this.code = code;
  }

  private getPracticeGuid(): string {
    return this.practiceService.getPractice().guid;
  }

  async getConsentRequestData(): Promise<ConsentRequest> {
    return this.http.get<ConsentRequest>(`/c/${this.code}`);
  }

  getPrePaymentOptionSelectionHook(
    formData: FormData
  ): Promise<{ link: string }> {
    return this.http.post<{ link: string }>(
      `/c/${this.code}/pre-selection-hook`,
      formData
    );
  }

  async saveAttachment(formData: FormData): Promise<AttachmentResponse> {
    return this.http.post<any>(`/c/${this.code}/attachment`, formData);
  }

  async saveConsent(consentOutput: ConsentSubmit): Promise<any> {
    return this.http.post<any>(`/c/${this.code}`, consentOutput);
  }
}
