<div class="fixed-action-btn menu-buttons"
     #menuButtons
     (touchstart)="menuOnClick($event, menuButtons)"
     (mousedown)="menuOnClick($event, menuButtons)">
  <ng-container *ngIf="isDrawingMode">
    <a class="btn-floating btn mr-1" (click)="clear()">
      <i class="large material-icons">clear</i>
    </a>
    <div class="mr-1">
      <div *ngIf="showLineColorPicker"
           class="mb-1 color-picker-colors">
        <ng-container *ngFor="let color of colorPickerColors">
          <div class="mt-05 padding-05">
            <button mat-mini-fab
                    [ngStyle]="{backgroundColor: color.hex, width: '35px', height: '35px'}"
                    [ngClass]="{'color-picked': color.picked}"
                    (click)="changeColor(color)">
            </button>
          </div>
        </ng-container>
      </div>
      <a class="btn-floating btn" (click)="toggleShowLineColorPicker()">
        <i class="large material-icons icon-color">palette</i>
      </a>
    </div>
    <div>
      <mat-slider *ngIf="showLineThicknessSlider"
                  vertical
                  min="1"
                  max="5"
                  thumbLabel
                  style="display: block"
                  class="mb-1"
                  [value]="lineThickness"
                  (valueChange)="changeLineThickness($event)"></mat-slider>
      <a class="btn-floating btn mr-1" (click)="toggleLineThicknessSlider()">
        <i class="large material-icons icon-color">show_chart</i>
      </a>
    </div>
  </ng-container>
  <a class="btn-floating btn" (click)="toggleDrawing()">
    <i class="large material-icons">{{
      isDrawingMode ? 'edit_off' : 'edit'
      }}</i>
  </a>
</div>
