import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { MenuItem, SingleMenuItem } from './menu-item.model';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuItemComponent {
  @ViewChild('menu', { static: true }) menu: MatMenu;

  @Input() items: MenuItem[];

  @Output()
  itemSelected: EventEmitter<SingleMenuItem> = new EventEmitter<
    SingleMenuItem
  >();

  trackByItemIdx(idx: number): number {
    return idx;
  }

  itemClicked(item: MenuItem): void {
    this.itemSelected.next({
      name: item.name,
      value: item.value,
      isOpenable: item.isOpenable,
      category: item.category
    });
  }
}
