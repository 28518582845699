<ng-container *ngrxLet="data$ as data">
  <app-skeleton *ngIf="data.loading; else cardDetails"></app-skeleton>

  <ng-template #cardDetails>
    <app-payment-card-details *ngIf="
        !data.clearentFormEnabled &&
          data.terminalType === terminalTypes.VIRTUAL;
        else clearentForm
      "
                              [cards]="data.cards"
                              [patient]="patient"
                              [disabled]="data.disabled"
                              (newMethodClicked)="openClearentForm()"
                              (cardSelected)="selectCard($event)"
                              (cardDeleted)="deleteCard($event)">
    </app-payment-card-details>

    <ng-template #clearentForm>
      <app-skeleton *ngIf="!data.clearentInitialized"
                    height="96px"></app-skeleton>

      <div #clearentHost
           id="payment-form"
           [class.hide]="!data.clearentInitialized"></div>

      <mat-error *ngIf="data.validationErrors"
                 class="mt-1">{{
        data.validationErrors
      }}</mat-error>

      <form [formGroup]="detailsForm"
            class="mt-2">
        <mat-form-field *ngIf="enableZipCode && data.terminalType === terminalTypes.VIRTUAL"
                        appearance="outline"
                        class="payment-field">
          <mat-label>{{
            'CLEARENT_CARD_PAYMENT.FORM.CARD_ZIP_CODE' | translate
          }}</mat-label>
          <input matInput
                 formControlName="zipCode" />
          <mat-error *ngIf="zipCode.errors?.pattern">{{
            'CLEARENT_CARD_PAYMENT.FORM.CARD_ZIP_CODE_ERROR_MESSAGE' | translate
          }}</mat-error>
        </mat-form-field>

        <div *ngIf="enableSaveCard"
             class="modento-checkbox mb-2">
          <input id="save-card"
                 type="checkbox"
                 formControlName="saveCard" />
          <label for="save-card">{{
            'CLEARENT_CARD_PAYMENT.FORM.SAVE_CARD_FOR_FUTURE_PAYMENTS'
              | translate
          }}</label>
        </div>

        <mat-form-field *ngIf="!enableSaveCard || saveCard.value"
                        appearance="outline"
                        class="payment-field nickname">
          <mat-label>{{
            'CLEARENT_CARD_PAYMENT.FORM.CARD_NICKNAME' | translate
          }}</mat-label>
          <input matInput
                 formControlName="name" />
        </mat-form-field>
      </form>
    </ng-template>
  </ng-template>
</ng-container>
