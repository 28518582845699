import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import {
  ResendPayload,
  TwoFactorAuthenticationState,
  VerifyPayload
} from './two-factor-authentication.state';
import {
  ErrorState,
  getErrorState,
  LoadingState
} from '../../../../../common/mobiati-http/call-state.types';
import { Observable, pipe, Subject } from 'rxjs';
import {
  decreaseLoadingState,
  errors,
  increaseLoadingState,
  isLoaded,
  isLoading
} from '../../../../../common/utils/selectors';
import { map, tap, withLatestFrom, switchMap } from 'rxjs/operators';
import { TwoFactorAuthenticationService } from '../data-access/two-factor-authentication.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertsService } from '../../../../../common/alerts/alerts.service';

@Injectable()
export class TwoFactorAuthenticationFacade extends ComponentStore<
  TwoFactorAuthenticationState
> {
  readonly loading$: Observable<boolean> = this.select(
    ({ callState }) => callState
  ).pipe(map(isLoading));
  readonly loaded$: Observable<boolean> = this.select(
    ({ callState }) => callState
  ).pipe(map(isLoaded));
  readonly errors$: Observable<ErrorState> = this.select(
    ({ callState }) => callState
  ).pipe(map(errors));

  verified$: Subject<void> = new Subject<void>();

  readonly verify = this.effect<VerifyPayload>(
    pipe(
      withLatestFrom(this.select(({ callState }) => callState)),
      tap(([action, callState]) => {
        this.patchState({ callState: increaseLoadingState(callState) });
      }),
      switchMap(([{ deviceUUID, code }, callState]) =>
        this.twoFactorAuthenticationService.verify(deviceUUID, code).pipe(
          tapResponse(
            (response) => {
              this.verified$.next();
              this.alertService.showSuccess(response.message);

              this.patchState({ callState: decreaseLoadingState(callState) });
            },
            (error: HttpErrorResponse) => {
              this.alertService.showError(error.error.message);

              this.patchState({ callState: getErrorState(error) });
            }
          )
        )
      )
    )
  );

  readonly resend = this.effect<ResendPayload>(
    pipe(
      withLatestFrom(this.select(({ callState }) => callState)),
      tap(([action, callState]) => {
        this.patchState({ callState: increaseLoadingState(callState) });
      }),
      switchMap(([{ deviceUUID }, callState]) =>
        this.twoFactorAuthenticationService.resend(deviceUUID).pipe(
          tapResponse(
            (response) => {
              this.alertService.showInfo(response.message);

              this.patchState({ callState: decreaseLoadingState(callState) });
            },
            (error: HttpErrorResponse) => {
              this.alertService.showError(error.error.message);

              this.patchState({ callState: getErrorState(error) });
            }
          )
        )
      )
    )
  );

  constructor(
    private readonly twoFactorAuthenticationService: TwoFactorAuthenticationService,
    private readonly alertService: AlertsService
  ) {
    super({
      callState: LoadingState.INIT
    });
  }
}
