import { Component, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { DrawingCanvasService } from '../drawing-canvas.service';
import { ColorPickerConfig } from './color-picker.config';
import { Color } from './color.model';

@Component({
  selector: 'app-drawing-canvas-menu',
  templateUrl: './drawing-canvas-menu.component.html',
  styleUrls: ['./drawing-canvas-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrawingCanvasMenuComponent implements OnDestroy {
  showLineThicknessSlider: boolean;
  isDrawingMode: boolean;
  lineThickness = 3;
  showLineColorPicker: boolean;
  lineColor = '#FFFF00';
  colorPickerColors = ColorPickerConfig;

  constructor(private drawingCanvasService: DrawingCanvasService) {}

  ngOnDestroy(): void {
    this.resetColors();
  }

  toggleDrawing(): void {
    this.isDrawingMode = !this.isDrawingMode;
    this.drawingCanvasService.updateDrawingMode(this.isDrawingMode);
  }

  changeLineThickness($event: number): void {
    this.lineThickness = $event;
    this.drawingCanvasService.updateLineThickness(this.lineThickness);
  }

  toggleLineThicknessSlider(): void {
    this.showLineThicknessSlider = !this.showLineThicknessSlider;
  }

  toggleShowLineColorPicker(): void {
    this.showLineColorPicker = !this.showLineColorPicker;
  }

  changeLineColor($event: string): void {
    this.lineColor = $event;
    this.drawingCanvasService.lineColor.next(this.lineColor);
  }

  changeColor(color: Color): void {
    this.colorPickerColors.map((color1) => (color1.picked = false));
    color.picked = true;
    this.drawingCanvasService.lineColor.next(color.hex);
  }

  clear(): void {
    this.drawingCanvasService.clear.next();
  }

  resetColors(): void {
    this.changeColor(this.colorPickerColors[this.colorPickerColors.length - 1]);
  }

  menuOnClick($event, menuButtons: HTMLDivElement): void {
    if ($event.target === menuButtons) {
      this.highlightBackgroundMenu(menuButtons);
    }
  }

  private highlightBackgroundMenu(menuButtons: HTMLDivElement): void {
    menuButtons.classList.remove('highlight-animation');
    setInterval(() => {
      menuButtons.classList.add('highlight-animation');
    }, 0);
  }
}
