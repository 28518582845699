import { Component, OnInit } from '@angular/core';
import { DeviceService } from '../../common/device-service/device.service';

@Component({
  selector: 'app-consent-completed',
  templateUrl: './consent-completed.component.html',
  styleUrls: ['./consent-completed.component.scss']
})
export class ConsentCompletedComponent {
  constructor(private deviceService: DeviceService) {}

  isInApp(): boolean {
    return this.deviceService.isInApp();
  }

  isInKiosk(): boolean {
    return this.deviceService.isInKiosk();
  }
}
