<app-spinner *ngIf="!formReady && !allCompleted && !cancelled"
             class="screen-centered"></app-spinner>
<app-check-patient-initials *ngIf="needToAuthorize"
                            [patient]="patient"
                            (verified)="onVerifiedInitials($event)">
</app-check-patient-initials>
<app-check-qrcode *ngIf="formSpecs && showQRCode && !needToAuthorize"
                  [qrcodeUrl]="qrcodeUrl"
                  [headerTitle]="'FORMS.HEADER.TITLE' | translate"
                  (cancelled)="onCancel()"></app-check-qrcode>
<app-form *ngIf="showForm"
          [formSpec]="formSpecs[currentIdx]"
          [formData]="currentFormData"
          [practice]="practice"
          [isPreview]="preview"
          [isInactivityBarHidden]="hideInactivityBar"
          (submitted)="loadNextForm()"
          (cancelled)="onCancel()"></app-form>
<app-all-completed *ngIf="allCompleted"
                   class="screen-centered"
                   [hasUserCheckedIn]="hasUserCheckedIn"></app-all-completed>
<app-final-page *ngIf="cancelled"
                class="screen-centered"
                [titleLabel]="'FORMS.CANCELLED.TITLE' | translate"
                [descriptionLabel]="''"
                [closeLabel]="'FORMS.CANCELLED.CLOSE' | translate"
                [autoCloseAfter]="10"></app-final-page>
<app-modal *ngIf="showForm"
           (modalClosed)="onTimeoutModalClose()"
           (modalOpened)="onTimeoutModalOpen()"
           (modalPluginSet)="timeoutModal = $event">
  <ng-container *ngIf="timeoutModal?.isOpen === true">
    <h3>{{ 'COMMON.MODAL.TIMEOUT_SOON_TITLE' | translate }}</h3>
    <ng-container *ngrxLet="countDownSessionLeft$ as countDownSessionLeft">
      <p>
        {{ 'COMMON.MODAL.TIMEOUT_SOON' | translate: {sessionLeft: countDownSessionLeft} }}
      </p>
    </ng-container>
  </ng-container>
</app-modal>
<app-inactivity-bar *ngIf="showForm"
                    [hideBar]="hideInactivityBar"
                    (hideBarChange)="onHideBarChange($event)">
</app-inactivity-bar>
