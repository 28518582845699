import { Injectable } from '@angular/core';
import { CookieOptions, CookieService } from 'ngx-cookie';
import { environment } from '../../../environments/environment';
import { MD5 } from 'crypto-js';

@Injectable()
export class TwoFaService {
  constructor(private cookieService: CookieService) {}

  getDeviceUuidCookieName(username: string): string {
    const env = environment.production ? '' : environment.envName + '.';
    const name = username === '' ? '' : username + '.';
    return 'modento.forms.' + env + MD5(name) + 'device_uuid';
  }

  setDeviceUuid(deviceUuid: string, username: string): void {
    if (this.getDeviceUuid(username) === null) {
      const exp = new Date();
      exp.setFullYear(exp.getFullYear() + 1);
      const cookieOptions = {
        expires: exp,
        storeUnencoded: false
      } as CookieOptions;
      this.cookieService.put(
        this.getDeviceUuidCookieName(username),
        deviceUuid,
        cookieOptions
      );
    }
  }

  getDeviceUuid(username: string): string | null {
    return (
      this.cookieService.get(this.getDeviceUuidCookieName('')) ||
      this.cookieService.get(this.getDeviceUuidCookieName(username)) ||
      null
    );
  }
}
