import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit
} from '@angular/core';
import { Practice, PracticeService } from '../../common/practice/index';
import { ActivatedRoute, Router } from '@angular/router';
import { log } from 'util';
import { FormsRequestService } from './forms-request.service';
import { FormSpec } from '../form/form-spec.model';
import { SessionTimeoutService } from '../../common/session-timeout/session-timeout.service';
import { KioskDeviceService } from '../../kiosk/kiosk-device/kiosk-device.service';
import { Question } from '../form/question.model';
import { Patient } from '../../common/patient/patient.model';
import { DeviceService } from '../../common/device-service/device.service';
import { nonEmpty } from '../../common/utils/string-utils.helper';
import { Modal } from '../../materialize';
import { MultiradioQuestionParams } from '../form/controls/multiradio-control/multiradio-question.model';
import { PlaceholdersService } from '../../common/placeholders-service/placeholders.service';
import { SentryErrorHandler } from '../../sentry/sentry.service';
import * as questionBlocksModule from '../../_files/question-blocks.json';
import clonedeep from '../../../../node_modules/lodash.clonedeep';
import { BlockQuestion } from '../form/controls/block-control/block-question.model';
import { LiteEditorService } from '../../+lite-editor/services/lite-editor.service';
import { environment } from '../../../environments/environment';
import { PatientPortalService } from '../../common/patient-portal/patient-portal.service';
import * as Sentry from '@sentry/browser';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from '../../common/alerts/alerts.service';

@Component({
  selector: 'app-forms-request',
  templateUrl: './forms-request.component.html',
  styleUrls: ['./forms-request.component.scss']
})
export class FormsRequestComponent implements OnInit {
  // preview mode that does not load actual form request but empty forms
  preview = false;
  private previewPlaceholders: {};
  formSpecs: FormSpec[];
  currentFormData: object;
  currentIdx = -1;
  formReady = false;
  allCompleted = false;
  cancelled = false;
  needToAuthorize = false;
  patient: Patient;
  timeoutModal: Modal;
  showQRCode = false;
  qrcodeUrl: string;
  patientPortalSession = false;
  hideInactivityBar = false;
  practice: Practice;
  patientId: number;

  countDownSessionLeft$: Observable<number> = this.sessionTimeoutService
    .timeoutValue$;
  hasUserCheckedIn = false;

  get showForm(): boolean {
    return (
      this.formReady &&
      !this.allCompleted &&
      !this.showQRCode &&
      !this.needToAuthorize &&
      !this.cancelled
    );
  }

  @HostListener('window:message', ['$event'])
  onPostMessage(event): void {
    if (this.checkIfOriginIsCorrect(event.origin)) {
      try {
        if (typeof event.data !== 'string') {
          return;
        }

        const data = JSON.parse(event.data);

        if (data && data.patientPortalSession) {
          this.patientPortalService.setPatientPortalVersion({
            version: data.version,
            hash: data.hash
          });
          this.patientPortalSession = true;
        }
      } catch (error) {
        Sentry.captureMessage(
          'Failed to parse event data: ' + JSON.stringify(event)
        );
      }
    }
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private practiceService: PracticeService,
    private formRequestService: FormsRequestService,
    private kioskDeviceService: KioskDeviceService,
    private deviceService: DeviceService,
    private sessionTimeoutService: SessionTimeoutService,
    private errorHandler: SentryErrorHandler,
    private placeholderService: PlaceholdersService,
    private liteEditorService: LiteEditorService,
    private readonly patientPortalService: PatientPortalService,
    private cdr: ChangeDetectorRef,
    private readonly translateService: TranslateService,
    private readonly alertsService: AlertsService
  ) {
    this.hasUserCheckedIn = !!this.router.getCurrentNavigation().extras.state
      ?.checkedIn;
  }

  async ngOnInit(): Promise<any> {
    if (window.location.pathname.endsWith('tools/forms/preview')) {
      await this.setLocalPreviewForms();
      return;
    }

    if (this.kioskDeviceService.isInKiosk()) {
      this.sessionTimeoutService.startSessionForTimeouts(
        ['kiosk', 'video'],
        () => {
          if (!this.sessionTimeoutService.isOnlyManualTimeoutResetActive()) {
            this.openTimeoutModal();
          }
        }
      );
    } else if (!this.liteEditorService.editMode) {
      this.sessionTimeoutService.startSessionForTimeouts(['timeout'], () =>
        this.openTimeoutModal()
      );
    }

    if (window.location.pathname.includes('__TEST__')) {
      this.route.params.subscribe((params) => {
        this.setDevForms(
          params.form_type,
          window.location.pathname.includes('v2')
        );
      });
      return;
    }

    this.route.data.subscribe((data) => {
      this.preview = data.preview !== undefined ? data.preview : false;
      if (this.preview) {
        this.route.queryParams.subscribe((params) => {
          this.previewPlaceholders = params.placeholders
            ? JSON.parse(atob(params.placeholders))
            : {};
        });
      }
      this.route.params.subscribe((params) => {
        this.practiceService.setPracticeGuid(
          params.practice_guid.toLowerCase()
        );
        this.formRequestService.setFormsRequestCode(params.form_request_code);
        this.initData(params);
      });
    });
  }

  private async initData(routeParams: object): Promise<any> {
    try {
      this.practice = await this.practiceService.loadPracticeIfNeeded();
      this.setFormSpec(await this.loadFormSpecs(routeParams));
      // @ts-ignore
      this.patientId = routeParams.patient;
      await this.loadNextForm();
    } catch (e) {
      this.alertsService.showApiError(e);
      this.errorHandler.captureError(e);
      this.router.navigate([''], { replaceUrl: true });
    }
  }

  async loadFormSpecs(params: any): Promise<FormSpec[]> {
    try {
      if (!this.preview) {
        const formRequestData = await this.formRequestService.getFormRequestData();
        this.showQRCode =
          this.kioskDeviceService.isInKiosk() && formRequestData.qrcode;
        if (formRequestData.authorize) {
          this.patient = formRequestData.patient;
          this.needToAuthorize = true;
        }
        if (this.showQRCode) {
          this.qrcodeUrl = formRequestData.qrcode_url;
        }
        return formRequestData.form_specs;
      } else {
        return [
          await this.formRequestService.getPreviewPublishedFormSpec(
            params.type,
            params.minor,
            params.language
          )
        ];
      }
    } catch (e) {
      this.errorHandler.captureError(e);
      this.router.navigate([''], { replaceUrl: true });
      return [];
    }
  }

  async loadNextForm(): Promise<any> {
    this.formReady = false;
    this.currentIdx++;
    if (this.currentIdx < this.formSpecs.length) {
      this.currentFormData = this.prepareFormData(await this.loadPrefillData());
      if (this.patientId && this.preview) {
        await this.loadPlaceholdersForPatient();
      } else {
        await this.loadPlaceholders();
      }
      this.formReady = true;
    } else {
      this.allCompleted = true;
      this.placeholderService.clearPlaceholderMap();
    }
  }

  async loadPrefillData(): Promise<any> {
    try {
      if (!this.preview) {
        return await this.formRequestService.getFormData(
          this.formSpecs[this.currentIdx].id
        );
      } else {
        return {
          practice_ask_membership: nonEmpty(
            this.practiceService.getPractice().membership_url
          )
        };
      }
    } catch (e) {
      // tslint:disable-next-line
      log(e);
      return {};
    }
  }

  onHideBarChange(event): void {
    this.hideInactivityBar = event;
  }

  private async loadPlaceholders(): Promise<any> {
    const formSpec = this.formSpecs[this.currentIdx];
    const backendPlaceholdersMap = formSpec.id
      ? await this.formRequestService.getPlaceholdersMap(formSpec.id)
      : await this.formRequestService.getPlaceholdersMapByKeys(
          this.placeholderService.getUsedPlaceholdersIn([formSpec])
        );
    this.placeholderService.setPlaceholderMap({
      ...backendPlaceholdersMap,
      ...this.previewPlaceholders
    });
  }

  private async loadPlaceholdersForPatient(): Promise<void> {
    const formSpec = this.formSpecs[this.currentIdx];
    const backendPlaceholdersMap = await this.formRequestService.getPlaceholdersMapForPatient(
      formSpec.id,
      this.patientId
    );
    this.placeholderService.setPlaceholderMap({
      ...backendPlaceholdersMap,
      ...this.previewPlaceholders
    });
  }

  /**
   * Pre-processes form data retrieved from backend. Will add default value where applicable.
   */
  private prepareFormData(backendFormData: object): object {
    this.formSpecs[this.currentIdx].spec.forEach((q: Question) => {
      if (
        backendFormData[q.key] == null &&
        q.control &&
        q.control.hasOwnProperty('default')
      ) {
        backendFormData[q.key] = q.control.default;
      }
    });
    return backendFormData;
  }

  // Create dummy data for easier form controls development and testing
  private async setDevForms(
    type: string = 'test',
    v2: boolean = true
  ): Promise<any> {
    const url =
      '/assets/dev-forms/' +
      (v2 || type === 'test' ? 'v2/' : '') +
      type +
      '.json';
    const response = await fetch(url);
    this.setFormSpec([await response.json()]);
    this.currentIdx++;
    this.currentFormData = this.prepareFormData({
      mobile_phone: '+15556667788',
      extra_data: 'custom',
      sex: 'male'
    });
    this.formReady = true;
  }

  private async setLocalPreviewForms(): Promise<any> {
    this.preview = true;
    const previewSpec = sessionStorage.getItem('tools.preview-spec');
    if (nonEmpty(previewSpec)) {
      this.setFormSpec([JSON.parse(previewSpec)]);
      this.currentIdx++;
      this.currentFormData = this.prepareFormData({
        practice_ask_membership: true
      });
      this.formReady = true;
    }
    this.cdr.detectChanges();
  }

  onVerifiedInitials(verified: boolean): void {
    if (verified) {
      this.needToAuthorize = false;
    } else {
      if (this.deviceService.isInKiosk()) {
        this.router.navigate(['kiosk'], { replaceUrl: true });
      } else {
        this.router.navigate([''], { replaceUrl: true });
      }
    }
  }

  onCancel(): void {
    this.cancelled = true;
    this.setFormSpec(null);
    this.patient = null;
    this.placeholderService.clearPlaceholderMap();
  }

  onTimeoutModalOpen(): void {
    this.sessionTimeoutService.allowResetTimeoutOnlyManually();
  }

  onTimeoutModalClose(): void {
    this.sessionTimeoutService.resetTimeoutManually();
  }

  /**
   * Processing before we consider contents input to actual form filling session
   */
  private setFormSpec(formSpec: FormSpec[]): void {
    this.formSpecs =
      formSpec == null
        ? null
        : formSpec.map((spec) => {
            if (!spec.language) {
              spec.language = this.translateService.currentLang;
            }
            spec.spec = spec.spec.filter(
              (question: Question) => !question.disabled
            );
            spec.spec.forEach((question: Question) => {
              if (question.type === 'multiradio') {
                const control: MultiradioQuestionParams = question.control;
                control.questions = control.questions.filter(
                  (subQuestion: Question) => !subQuestion.disabled
                );
              }
            });
            this.resolveQuestionBlocks(spec);
            return spec;
          });
  }

  /**
   * Check if some questions needs to be replaced with some questions set (template); e.g. signature resolving to more complex structure
   * formSpec
   */
  private resolveQuestionBlocks(formSpec: FormSpec): void {
    const questionBlocks = (questionBlocksModule as any).default;
    const blockTypes = Object.keys(questionBlocks);
    formSpec.spec
      .filter((question: Question) => {
        return blockTypes.indexOf(question.type) >= 0;
      })
      .forEach((question: BlockQuestion) => {
        const idx = formSpec.spec.indexOf(question);
        const language =
          question.control && question.control.language
            ? question.control.language
            : 'en';
        let variant =
          question.control && question.control.variant
            ? question.control.variant
            : null;
        // if no variant defined, use for_minor flag to determine as fallback
        variant = variant
          ? variant
          : question.control && question.control.for_minor
          ? 'minor'
          : 'adult';
        const blockQuestions = clonedeep(
          questionBlocks[question.type][language][variant]
        );
        // update Section for whole block from section of block question
        blockQuestions.forEach((q: Question) => {
          q.section = question.section;
        });
        formSpec.spec.splice(idx, 1, ...blockQuestions);
      });
  }

  /**
   * Workaround for iOS and Safari 7
   */
  private checkIfOriginIsCorrect(origin: string): boolean {
    for (const url of environment.postMessage.allowedPatientPortalUrls) {
      if (url === origin) {
        return true;
      }
    }

    return false;
  }

  private openTimeoutModal(): void {
    if (!this.timeoutModal) {
      return;
    }

    this.timeoutModal.open();
  }

  // tslint:disable-next-line:max-file-line-count
}
