export const htmlToTextConfig = {
  decodeOptions: {
    isAttributeValue: true,
    strict: false
  },
  formatters: {
    pFormatter: (elem, walk, builder) => {
      if (elem.children && elem.children[0] && elem.children[0].name === 'br') {
        walk(elem.children, builder);
      } else {
        builder.openBlock({ leadingLineBreaks: 1 });
        walk(elem.children, builder);
        builder.closeBlock({ trailingLineBreaks: 1 });
      }
    },
    headingFormatter: (elem, walk, builder, formatOptions) => {
      builder.openBlock({ leadingLineBreaks: 1 });
      if (formatOptions.uppercase !== false) {
        builder.pushWordTransform((str) => str.toUpperCase());
        walk(elem.children, builder);
        builder.popWordTransform();
      } else {
        walk(elem.children, builder);
      }
      builder.closeBlock({ trailingLineBreaks: 1 });
    }
  },
  tags: {
    h1: {
      format: 'headingFormatter'
    },
    h2: {
      format: 'headingFormatter'
    },
    h3: {
      format: 'headingFormatter'
    },
    h4: {
      format: 'headingFormatter'
    },
    h5: {
      format: 'headingFormatter'
    },
    h6: {
      format: 'headingFormatter'
    },
    p: {
      format: 'pFormatter'
    }
  },
  wordwrap: null
};
