import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { Practice, PracticeService } from '../common/practice';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PracticeGuidGuard implements CanActivate {
  constructor(
    private practiceService: PracticeService,
    private readonly router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    const practiceGuidParam = route.params.practice_guid;
    return this.practiceService.checkPracticeExist(practiceGuidParam).pipe(
      tap((practice: Practice) => {
        practice
          ? this.practiceService.setPractice(practice)
          : this.router.navigate(['/']);
      }),
      map((practice) => !!practice)
    );
  }
}
