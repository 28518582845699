import { Component, Input } from '@angular/core';
import { DateQuestionParams } from '../../../forms/form/controls/date-control/date-question.model';

@Component({
  selector: 'app-edit-date',
  templateUrl: './edit-date.component.html',
  styleUrls: ['./edit-date.component.scss']
})
export class EditDateComponent {
  @Input() control: DateQuestionParams;
  @Input() key: string;
}
