import Quill from 'quill';
const BlockEmbed = Quill.import('blots/block/embed');

// inspiration from: https://developpaper.com/detailed-explanation-of-example-of-quill-editor-inserting-custom-html-record/

export class TableBlockEmbed extends BlockEmbed {
  static blotName = 'TableBlockEmbed';
  static tagName = 'table';

  static create(value): any {
    const node = super.create();
    let valueToReturn = value;
    if (!value.includes('assignedTableId')) {
      const tableId = `assignedTableId-${Date.now()}`;
      valueToReturn = value
        .replace('#tableId', `#${tableId}`)
        .replace('table-layout: fixed;', ''); // remove this css declaration because its not required but breaks generating pdfs
      node.setAttribute('id', tableId);
    } else {
      const existedId = valueToReturn.match(/#assignedTableId-(\d+)/i)[1];
      node.setAttribute('id', `assignedTableId-${existedId}`);
    }
    node.innerHTML = this.transformValue(valueToReturn);
    return node;
  }

  static transformValue(value): string {
    let handleArr = value.split('\n');
    handleArr = handleArr.map((e) =>
      e.replace(/^[\s]+/, '').replace(/[\s]+$/, '')
    );
    return handleArr.join('');
  }

  // Returns the value of the node itself for undo operation
  static value(node): unknown {
    return node.innerHTML;
  }
}
