import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  Input,
  AfterViewInit
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { pairwise, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DrawingCanvasService } from './drawing-canvas.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-drawing-canvas',
  templateUrl: './drawing-canvas.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrawingCanvasComponent implements AfterViewInit {
  @ViewChild('canvas', { static: false }) canvas: ElementRef;
  @Input() parentElement: HTMLElement;
  private canvasEl: HTMLCanvasElement;
  private cx: CanvasRenderingContext2D;

  constructor(public drawingCanvasService: DrawingCanvasService) {}

  ngAfterViewInit(): void {
    this.setCanvas();

    this.drawingCanvasService
      .getLineThickness()
      .pipe(
        tap((lineThickness) => (this.cx.lineWidth = lineThickness)),
        untilDestroyed(this)
      )
      .subscribe();

    this.drawingCanvasService.lineColor
      .pipe(
        tap((strokeStyle) => (this.cx.strokeStyle = strokeStyle)),
        untilDestroyed(this)
      )
      .subscribe();

    this.drawingCanvasService.clear
      .pipe(
        tap(() =>
          this.cx.clearRect(0, 0, this.canvasEl.width, this.canvasEl.height)
        ),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private setCanvas(): void {
    this.canvasEl = this.canvas.nativeElement;
    this.cx = this.canvasEl.getContext('2d');

    this.canvasEl.width = this.parentElement
      ? this.parentElement.offsetWidth
      : 1280;
    this.canvasEl.height = this.parentElement
      ? this.parentElement.offsetHeight
      : 1200;

    this.cx.lineWidth = 3;
    this.cx.lineCap = 'round';
    this.cx.strokeStyle = '#FFFF00';

    this.captureEvents(this.canvasEl);
  }

  private captureEvents(canvasEl: HTMLCanvasElement): void {
    // to avoid scrolling on mobile devices and enable drawing
    // this will capture all touchstart events from the canvas element and dispatch as mousedown event
    fromEvent(canvasEl, 'touchstart')
      .pipe(untilDestroyed(this))
      .subscribe((e: TouchEvent) => {
        e.preventDefault();
        canvasEl.dispatchEvent(this.newMouseEvent(e, 'mousedown'));
      });

    // this will capture all touchmove events from the canvas element and dispatch as mousemove event
    fromEvent(canvasEl, 'touchmove')
      .pipe(untilDestroyed(this))
      .subscribe((e: TouchEvent) => {
        e.preventDefault();
        canvasEl.dispatchEvent(this.newMouseEvent(e, 'mousemove'));
      });

    // this will capture all mousedown events from the canvas element
    fromEvent(canvasEl, 'mousedown')
      .pipe(
        switchMap((e) => {
          // after a mouse down, we'll record all mouse moves
          return fromEvent(canvasEl, 'mousemove').pipe(
            // we'll stop (and unsubscribe) once the user releases the mouse
            // this will trigger a 'mouseup' event
            takeUntil(fromEvent(canvasEl, 'mouseup')),
            // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
            takeUntil(fromEvent(canvasEl, 'mouseleave')),
            // pairwise lets us get the previous value to draw a line from
            // the previous point to the current point
            pairwise()
          );
        })
      )
      .subscribe((res: [MouseEvent, MouseEvent]) => {
        const rect = canvasEl.getBoundingClientRect();

        // previous and current position with the offset
        const prevPos = {
          x: res[0].clientX - rect.left,
          y: res[0].clientY - rect.top
        };

        const currentPos = {
          x: res[1].clientX - rect.left,
          y: res[1].clientY - rect.top
        };

        this.drawOnCanvas(prevPos, currentPos);
      });
  }

  private newMouseEvent(e: TouchEvent, eventName: string): MouseEvent {
    const touch = e.touches[0];
    return new MouseEvent(eventName, {
      clientX: touch.clientX,
      clientY: touch.clientY
    });
  }

  private drawOnCanvas(
    prevPos: { x: number; y: number },
    currentPos: { x: number; y: number }
  ): void {
    if (!this.cx) {
      return;
    }

    this.cx.beginPath();

    if (prevPos) {
      this.cx.moveTo(prevPos.x, prevPos.y);
      this.cx.lineTo(currentPos.x, currentPos.y);
      this.cx.stroke();
    }
  }
}
