import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  forwardRef,
  OnDestroy,
  Input,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Patient } from '../../patient/patient.model';
import { TerminalType } from '../clearent.types';
import { BehaviorSubject, Observable, combineLatest, iif } from 'rxjs';
import {
  filter,
  map,
  startWith,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { ClearentService } from '../clearent.service';
import {
  ClearentCardPaymentData,
  CreditCardDetail
} from './clearent-card-payment.types';
import { ClearentCardPaymentService } from './clearent-card-payment.service';

@UntilDestroy()
@Component({
  selector: 'app-clearent-card-payment',
  templateUrl: './clearent-card-payment.component.html',
  styleUrls: ['./clearent-card-payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ClearentCardPaymentComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ClearentCardPaymentComponent),
      multi: true
    }
  ]
})
export class ClearentCardPaymentComponent
  implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() patient!: Patient;
  @Input() enableZipCode: boolean;
  @Input() enableSaveCard: boolean;

  @Input() set terminalType(type: TerminalType) {
    if (type) {
      this.selectedTerminalType$.next(type);
    }
  }

  /* eslint-disable @typescript-eslint/member-ordering */
  @ViewChild('clearentHost') set paymentForm(form: HTMLDivElement) {
    if (form) {
      this.clearentHostRendered$.next(true);
    }
  }

  readonly clearentHostRendered$: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean>(false);

  readonly clearentFormEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);

  readonly selectedCard$: BehaviorSubject<
    CreditCardDetail
  > = new BehaviorSubject<CreditCardDetail>(null);

  readonly selectedTerminalType$: BehaviorSubject<
    TerminalType
  > = new BehaviorSubject<TerminalType>(TerminalType.VIRTUAL);

  readonly disabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  readonly detailsForm = new FormGroup({
    name: new FormControl(null)
  });

  readonly cards$: Observable<
    CreditCardDetail[]
  > = this.clearentCardPaymentService.cards$.pipe(
    map((cards) =>
      cards.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      )
    )
  );

  readonly data$: Observable<ClearentCardPaymentData> = combineLatest([
    this.cards$,
    this.clearentCardPaymentService.loading$.asObservable(),
    this.clearentFormEnabled$.asObservable(),
    this.clearentService.initialized$,
    this.clearentService.error$,
    this.selectedTerminalType$.asObservable(),
    this.disabled$.asObservable()
  ]).pipe(
    map(
      ([
        cards,
        loading,
        clearentFormEnabled,
        clearentInitialized,
        validationErrors,
        terminalType,
        disabled
      ]) =>
        ({
          cards,
          loading,
          clearentFormEnabled,
          clearentInitialized,
          validationErrors,
          terminalType,
          disabled
        } as ClearentCardPaymentData)
    )
  );

  clearentKey: string;
  terminalTypes = TerminalType;

  constructor(
    private readonly clearentService: ClearentService,
    private readonly clearentCardPaymentService: ClearentCardPaymentService
  ) {}

  get zipCode(): FormControl {
    return this.detailsForm.get('zipCode') as FormControl;
  }

  get saveCard(): FormControl {
    return this.detailsForm.get('saveCard') as FormControl;
  }

  get name(): FormControl {
    return this.detailsForm.get('name') as FormControl;
  }

  get formEnabled$(): Observable<boolean> {
    return this.clearentFormEnabled$.asObservable();
  }

  ngOnInit(): void {
    if (this.enableZipCode) {
      this.detailsForm.addControl(
        'zipCode',
        new FormControl(null, [
          Validators.required,
          Validators.pattern(/^\d{5}$/)
        ])
      );
    }

    if (this.enableSaveCard) {
      this.detailsForm.addControl('saveCard', new FormControl(false));
    }

    this.clearentCardPaymentService.loadClearentKey();
    this.clearentCardPaymentService.loadCards(this.patient.id);

    this.listenForClearentData();
    this.listenForClearentFormStatus();
    this.listenForTerminalTypeChange();
    this.listenForReaderModeChange();

    this.clearentService.addCallbacks();
  }

  ngOnDestroy(): void {
    this.clearentService.removeCallbacks();
  }

  registerOnChange(fn: any): void {
    this.clearentFormEnabled$
      .pipe(
        switchMap((enabled) =>
          iif(
            () => enabled,
            this.detailsForm.valueChanges.pipe(
              startWith(this.detailsForm.value)
            ),
            this.selectedCard$.asObservable()
          )
        ),
        untilDestroyed(this)
      )
      .subscribe((value) => fn(value));
  }

  registerOnTouched(fn: any): void {}

  writeValue(value: any): void {}

  validate(): ValidationErrors {
    if (this.clearentFormEnabled$.value) {
      return (
        !this.detailsForm.valid && {
          valid: false
        }
      );
    }

    return (
      !this.selectedCard$.value && {
        valid: false
      }
    );
  }

  setDisabledState(disabled: boolean): void {
    this.disabled$.next(disabled);
  }

  selectCard(card: CreditCardDetail): void {
    this.selectedCard$.next(card);
  }

  deleteCard(cardId: number): void {
    this.clearentCardPaymentService.deleteCard(cardId, this.patient.id);
  }

  openClearentForm(): void {
    this.clearentFormEnabled$.next(true);
  }

  private listenForClearentData(): void {
    this.clearentCardPaymentService.clearentKey$
      .pipe(
        filter((key) => !!key),
        take(1),
        tap((key) => (this.clearentKey = key)),
        switchMap(() =>
          this.clearentCardPaymentService.loaded$.pipe(
            filter((loaded) => loaded),
            withLatestFrom(
              this.clearentCardPaymentService.cards$,
              this.selectedTerminalType$.asObservable()
            )
          )
        ),
        untilDestroyed(this)
      )
      .subscribe(([, cards, terminalType]) => {
        if (!cards.length || terminalType === TerminalType.PHYSICAL) {
          this.openClearentForm();
        }
      });
  }

  private listenForClearentFormStatus(): void {
    this.clearentFormEnabled$
      .pipe(
        filter((enabled) => enabled),
        switchMap(() =>
          this.clearentHostRendered$.pipe(filter((initialized) => initialized))
        ),
        withLatestFrom(
          this.clearentCardPaymentService.clearentKey$,
          this.selectedTerminalType$.asObservable()
        ),
        untilDestroyed(this)
      )
      .subscribe(([, key, terminalType]) =>
        this.clearentService.initialize(key, terminalType)
      );
  }

  private listenForTerminalTypeChange(): void {
    this.selectedTerminalType$
      .pipe(
        withLatestFrom(this.clearentCardPaymentService.cards$),
        untilDestroyed(this)
      )
      .subscribe(([type, cards]) => {
        if (type === TerminalType.VIRTUAL && !!cards.length) {
          this.clearentService.reset();
          this.clearentFormEnabled$.next(false);
        }

        if (type === TerminalType.PHYSICAL) {
          if (!this.detailsForm.contains('zipCode')) {
            return;
          }

          if (this.enableZipCode) {
            this.detailsForm.removeControl('zipCode');
          }
        } else {
          if (this.detailsForm.contains('zipCode')) {
            return;
          }

          if (this.enableZipCode) {
            this.detailsForm.addControl(
              'zipCode',
              new FormControl(null, [
                Validators.required,
                Validators.pattern(/^\d{5}$/)
              ])
            );
          }
        }
      });
  }

  private listenForReaderModeChange(): void {
    this.clearentService.terminalTypeChanged$
      .pipe(untilDestroyed(this))
      .subscribe((type) => this.selectedTerminalType$.next(type));
  }
}
