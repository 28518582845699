<div class="input-field col s6">
  <input placeholder="Hint"
         id="{{ key + '-control-hint' }}"
         type="text"
         class="validate"
         [(ngModel)]="control.hint"
         (ngModelChange)="onHintChanged()" />
  <label for="{{ key + '-control-hint' }}">Hint</label>
</div>
<div class="input-field col s6">
  <select [(ngModel)]="control.input_type">
    <option value="name">Name / text</option>
    <option value="phone">Phone number</option>
    <option value="email">Email</option>
    <option value="ssn">SSN</option>
    <option value="zip">ZIP</option>
    <option value="number">Numerical</option>
    <option value="initials">Initials</option>
  </select>
  <label>Input type</label>
</div>
