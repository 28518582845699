import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KioskDeviceService } from '../kiosk-device/kiosk-device.service';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {
  showVideo: boolean;
  videoUrl: string;

  constructor(
    private router: Router,
    private kioskDeviceService: KioskDeviceService
  ) {}

  ngOnInit(): void {
    const config = this.kioskDeviceService.getConfig();
    this.showVideo = config.show_video;
    if (!this.showVideo) {
      this.skipVideo();
    } else {
      this.videoUrl = config.video_url ? config.video_url : '/assets/promo.mp4';
    }
  }

  skipVideo(): void {
    console.log('skipVideo');
    this.router.navigate([`kiosk`], { replaceUrl: true });
  }
}
