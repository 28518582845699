import { Injectable } from '@angular/core';
import { CanLoad, Route, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { LiteEditorService } from '../+lite-editor/services/lite-editor.service';

@Injectable({ providedIn: 'root' })
export class LiteEditorGuard implements CanLoad {
  constructor(
    private router: Router,
    private cookieService: CookieService,
    private liteEditorService: LiteEditorService
  ) {}

  canLoad(route: Route): boolean {
    const cookieTokenFound = this.liteEditorService.initEditModeAuth();
    if (cookieTokenFound) {
      return true;
    } else {
      this.router.navigate(['/incorrect-cookie-settings']);
      return false;
    }
  }
}
