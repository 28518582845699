export const CLEARENT_STYLES = `
  :root {
    --di-blue: #5494d9;
    --di-cyan-blue: #415467;
    --di-white: #f8f8f9;
    --di-red: #e4414b;
    --di-grey: #a7a7a7;
    --di-light-grey: #ccc;
    --di-dark-grey: #1d3243;
  }

  .readerStatusWrapper {
    padding: 0 1px 0 0 !important;
  }

  .readerStatus {
    font-size: 14px !important;
    border-radius: 4px !important;
  }

  .readerStatus > .text {
    padding-block: 7px !important;
  }

  .readerStatus.readerNotReady {
    border: 1px solid var(--di-light-grey) !important;
    font-weight: 400 !important;
  }

  .form-control {
    height: 32px !important;
    font-size: 14px !important;
    border: 1px solid var(--di-light-grey);
  }

  .form-control:focus {
    border: 1px solid var(--di-blue) !important;
  }

  .has-error .form-control:focus {
    border-left: 0 !important;
  }

  #reader-entry-button {
    font-size: 14px;
    border-bottom-right-radius: 0;
    border-color: var(--di-light-grey) !important;
  }

  .form-control,
  #reader-entry-button,
  #manual-entry-button {
    color: var(--di-dark-grey) !important;
  }

  .form-control::placeholder {
    color: var(--di-grey) !important;
  }

  :is(#reader-entry-button, #manual-entry-button):is(:hover, :active) {
    background-color: var(--di-white) !important;
    border-color: var(--di-light-grey) !important;
  }

  :is(#reader-entry-button, #manual-entry-button):focus {
    box-shadow: 0 0 0 0.1rem rgba(95, 116, 148, 0.15) !important;
  }

  .has-error #reader-entry-button:hover {
    border-left-color: var(--di-red) !important;
  }

  .input-group-text {
    width: 50px;
    border-color: var(--di-light-grey) !important;
  }

  .input-group-text > i {
    color: var(--di-cyan-blue) !important;
  }

  .input-group.has-error {
    border: none !important;
  }

  #card-group.has-error {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  #cvc-group.has-error {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .has-error :is(#card, #card-image, #card-icon, span, #exp-date, #date-icon, #csc, #cvc-icon) {
    color: var(--di-red) !important;
    border-color: var(--di-red) !important;
  }

  #card-group:not(.has-error) .input-group-append.card-entry-group {
    margin-left: 0;
  }

  :is(#exp-date-group, #cvc-group).has-error :is(.form-control, .input-group-text) {
    border-top-width: 1px;
  }
`;
