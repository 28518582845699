import { OperatorFunction, SchedulerLike, concat } from 'rxjs';
import { asyncScheduler } from 'rxjs';
import { debounceTime, publish, take } from 'rxjs/operators';

// source: https://stackoverflow.com/questions/55130781/debouncetime-only-after-first-value
function debounceTimeAfter(
  amount: number,
  dueTime: number,
  scheduler: SchedulerLike = asyncScheduler
): OperatorFunction<number, number> {
  return publish((value) =>
    concat(
      value.pipe(take(amount)),
      value.pipe(debounceTime(dueTime, scheduler))
    )
  );
}

export function debounceTimeAfterFirst(
  dueTime: number,
  scheduler: SchedulerLike = asyncScheduler
): OperatorFunction<number, number> {
  return debounceTimeAfter(1, dueTime, scheduler);
}
