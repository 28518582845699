import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { CustomValidators } from '../../../../common/validators/custom-validators.helper';
import { Question } from '../../question.model';

@Component({ template: '' })
export class AbstractControlComponent<
  T,
  QUESTION extends Question,
  FORM_CONTROL extends AbstractControl
> implements OnDestroy {
  @Input() question: QUESTION;
  @Input() data: T;
  @Output() output = new EventEmitter<T>();
  @Output() control = new EventEmitter<AbstractControl>();

  formControl: FORM_CONTROL;

  setupSimpleFormControl(initValue: any): void {
    // @ts-ignore
    this.formControl = new FormControl(initValue, this.getValidators());
    this.control.emit(this.formControl);
  }

  getValidators(): any[] {
    const validators = [];
    if (!this.question.optional) {
      validators.push(CustomValidators.required());
    }
    return validators;
  }

  ngOnDestroy(): void {
    this.emitOutput();
  }

  protected emitOutput(): void {
    if (this.formControl !== undefined) {
      this.output.emit(this.formControl.value);
    }
  }
}
