import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { CameraFacing, PhotoQuestion } from './photo-question.model';
import { AbstractControlComponent } from '../abstract-control/abstract-control.component';
import { DeviceService } from '../../../../common/device-service/device.service';
import { FormControl } from '@angular/forms';
import { dataURLToBlob } from '../../../../common/toBlob/toBlob.helper';
import { nonEmpty } from '../../../../common/utils/string-utils.helper';
import { AlertsService } from '../../../../common/alerts/alerts.service';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/browser';

declare function loadImage(file: any, img: any, options: object): void; // from load-image.all.min.js

@Component({
  selector: 'app-photo-control',
  templateUrl: './photo-control.component.html',
  styleUrls: ['./photo-control.component.scss']
})
export class PhotoControlComponent
  extends AbstractControlComponent<
    FormData | string,
    PhotoQuestion,
    FormControl
  >
  implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;

  photo?: FormData = null;
  fileName: string = null;
  takePhotoOnly: boolean;
  captureMode: string;
  file: File;

  constructor(
    private deviceService: DeviceService,
    private alertService: AlertsService,
    private translateService: TranslateService,
    private renderer: Renderer2
  ) {
    super();
  }

  ngOnInit(): void {
    this.takePhotoOnly = this.deviceService.isInKiosk();
    const prefCamera: CameraFacing = this.question.control.preferred_camera
      ? this.question.control.preferred_camera
      : this.question.key === 'image'
      ? CameraFacing.front
      : CameraFacing.back;
    this.captureMode =
      prefCamera === CameraFacing.front ? 'user' : 'environment';
    if (
      this.data !== undefined &&
      this.data !== null &&
      this.data instanceof FormData
    ) {
      this.photo = this.data;
      this.updateCurrentFileName();
    }

    this.setupSimpleFormControl(this.photo ? this.photo : '');
  }

  emitOutput(): void {
    this.output.emit(this.photo);
  }

  private setSelectedFile(blob: Blob, file: File): void {
    this.photo = new FormData();
    this.photo.append(
      'attachment',
      blob,
      nonEmpty(file.name) ? file.name : 'attachment.jpeg'
    );
    this.updateCurrentFileName();
    this.formControl.setValue(this.photo);
    this.formControl.markAsTouched();
    this.emitOutput();
  }

  /**
   * Handle on file change
   */
  onFileChange(event): void {
    const fileList: FileList = event.target.files;

    const that = this;
    const longerSize =
      this.question.control.longer_size !== undefined
        ? this.question.control.longer_size
        : 0;

    const processImage = (img, file: File) => {
      if (img.toDataURL !== undefined) {
        const blob = dataURLToBlob(img.toDataURL('image/jpeg'));
        that.setSelectedFile(blob, file);
      } else {
        // image processing not supported
        that.alertService.showError(
          this.translateService.instant(
            'FORMS.CONTROLS.PHOTO.FAILED_PROCESSING'
          )
        );
        that.onFileRemove();
        Sentry.withScope((scope) => {
          scope.setContext('photoNotLoadedData', {
            img,
            toDataURL: img.toDataURL,
            originalImg: event.target.files[0],
            type: file.type,
            size: file.size,
            isImgLongerSize: img.width > longerSize || img.height > longerSize
          });
          Sentry.captureMessage('Failed processing photo.');
        });
      }
    };

    if (fileList.length > 0) {
      const file: File = event.target.files[0];
      loadImage(
        file,
        (img) => {
          if (img.width > longerSize || img.height > longerSize) {
            loadImage(
              file,
              (smallerImg) => {
                processImage(smallerImg, file);
              },
              {
                canvas: true,
                orientation: true,
                crop: false,
                maxWidth: longerSize,
                maxHeight: longerSize,
                crossOrigin: 'Anonymous'
              }
            );
          } else {
            processImage(img, file);
          }
        },
        {
          canvas: true,
          orientation: true,
          crossOrigin: 'Anonymous'
        }
      );
    } else {
      this.onFileRemove();
    }
  }

  onFileRemove(): void {
    this.file = null;
    this.photo = null;
    this.updateCurrentFileName();
    this.formControl.setValue(null);
    this.formControl.markAsTouched();
    this.renderer.setProperty(this.fileInput.nativeElement, 'value', null);
    this.emitOutput();
  }

  onFilePathInputClick(): void {
    this.fileInput.nativeElement.click();
    this.fileInput.nativeElement.focus();
  }

  updateCurrentFileName(): void {
    if (this.photo != null) {
      if (typeof this.photo.get === 'function') {
        const file: File = this.photo.get('attachment') as File;
        this.fileName = nonEmpty(file.name) ? file.name : 'attachment.jpeg';
        this.file = file;
      } else {
        // apparently some browsers (Safari) does not support this function
        this.fileName = 'attachment.jpeg';
      }
    } else {
      this.fileName = null;
    }
  }
}
