import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TwoFactorAuthenticationDialogComponent } from './two-factor-authentication-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { ReactiveComponentModule } from '@ngrx/component';
import { AppCommonModule } from '../../../../common/common.module';

@NgModule({
  declarations: [TwoFactorAuthenticationDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    TranslateModule,
    MatIconModule,
    ReactiveFormsModule,
    ReactiveComponentModule,
    AppCommonModule
  ]
})
export class TwoFactorAuthenticationDialogModule {}
