import { Component, Input, OnInit } from '@angular/core';
import { createAutoCorrectedDatePipe } from 'text-mask-addons/dist/textMaskAddons';
import * as moment from 'moment';
import { CustomValidators } from '../../../../common/validators/custom-validators.helper';
import { DateQuestion, InputType } from '../date-control/date-question.model';
import { AbstractInputControlComponent } from '../abstract-input-control/abstract-input-control.component';
import { FormControl } from '@angular/forms';
import { nonEmpty } from '../../../../common/utils/string-utils.helper';
import { AccessibilityService } from '../../../../common/accessibility-service/accessibility.service';

@Component({
  selector: 'app-date-input-control',
  templateUrl: './date-input-control.component.html',
  styleUrls: [
    './date-input-control.component.scss',
    '../abstract-input-control/abstract-input-control.scss'
  ]
})
export class DateInputControlComponent
  extends AbstractInputControlComponent<string, DateQuestion, FormControl>
  implements OnInit {
  @Input() compact = false;
  dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy');

  constructor(protected accessibilityService: AccessibilityService) {
    super(accessibilityService);
  }

  ngOnInit(): void {
    // adjust init value for timezone if needed
    let currentValue;
    if (this.data !== undefined && this.data !== null) {
      currentValue = moment(this.data);
      const utcOffset = currentValue.utcOffset();
      if (utcOffset < 0) {
        currentValue.utc().add(-utcOffset, 'minute');
      }
    }

    this.setupSimpleFormControl(
      currentValue !== undefined
        ? moment(currentValue).format('MM/DD/YYYY')
        : undefined
    );
  }

  emitOutput(): void {
    let value = nonEmpty(this.formControl.value)
      ? moment(this.formControl.value, [
          'MM/DD/YYYY',
          'YYYY-MM-DD 00:00:00'
        ]).format('YYYY-MM-DD 00:00:00')
      : null;
    if (value === 'Invalid date') {
      value = null;
    }
    this.output.emit(value);
    const isFullDateProvided = this.formControl.value
      ? (this.formControl.value.match(/_/g) || []).length === 0
      : false;
    if (!this.formControl.value || isFullDateProvided) {
      this.formControl.markAsTouched();
    }
  }

  getValidators(): any[] {
    const validators = super.getValidators();
    validators.push(CustomValidators.validDate());
    switch (this.question.control.input_type) {
      case InputType.Past:
        validators.push(CustomValidators.validPastDate());
        break;
      case InputType.Future:
        validators.push(CustomValidators.validFutureDate());
        break;
    }
    return validators;
  }
}
