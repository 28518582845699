import { LiteEditorGuard, PracticeGuidGuard } from './guards';
import { RouterModule, Routes } from '@angular/router';

import { ConsentsRequestComponent } from './consents/consents-request/consents-request.component';
import { CreateConsentComponent } from './tools/create-consent/create-consent.component';
import { EditFormsComponent } from './tools/edit-forms/edit-forms.component';
import { FormsRequestComponent } from './forms/forms-request/forms-request.component';
import { KioskCheckinComponent } from './kiosk/kiosk-checkin/kiosk-checkin.component';
import { KioskEntryComponent } from './kiosk/kiosk-entry/kiosk-entry.component';
import { NgModule } from '@angular/core';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { SessionTimeoutComponent } from './common/session-timeout/session-timeout.component';
import { VideoComponent } from './kiosk/video/video.component';
import { environment } from '../environments/environment';

const routes: Routes = [
  { path: 'kiosk', component: KioskEntryComponent },
  { path: 'kiosk/video', component: VideoComponent },
  { path: 'kiosk/checkin', component: KioskCheckinComponent },
  { path: 'timeout', component: SessionTimeoutComponent },
  { path: 'tools/forms', component: EditFormsComponent },
  { path: 'tools/forms/create-consent', component: CreateConsentComponent },
  { path: 'tools/forms/preview', component: FormsRequestComponent },
  {
    path: 'pp',
    loadChildren: (): Promise<any> =>
      import(
        './consents/payment-plan-consent/payment-plan-consent.module'
      ).then((m) => m.PaymentPlanConsentComponentModule)
  },
  {
    path: 'p/:practice_guid/:type',
    component: FormsRequestComponent,
    data: { preview: true }
  },
  {
    path: 'f/:practice_guid/:form_request_code',
    component: FormsRequestComponent
  },
  {
    path: 'c/:practice_guid/:consent_request_code',
    component: ConsentsRequestComponent
  },
  {
    path: 'p/:practice_guid/:type/:language',
    component: FormsRequestComponent,
    data: { preview: true }
  },
  {
    path: 'p/:practice_guid/:type/:language/:minor',
    component: FormsRequestComponent,
    data: { preview: true }
  },
  {
    path: 'p/:practice_guid/patients/:patient/:type/:language',
    component: FormsRequestComponent,
    data: { preview: true }
  },
  {
    path: 'p/:practice_guid/patients/:patient/:type/:language/:minor',
    component: FormsRequestComponent,
    data: { preview: true }
  },
  {
    path: 'e/:practice_guid/:type/:language',
    loadChildren: () =>
      import('./+lite-editor/lite-editor.module').then(
        (m) => m.LiteEditorModule
      ),
    canLoad: [LiteEditorGuard],
    canActivate: [PracticeGuidGuard]
  },
  {
    path: 'incorrect-cookie-settings',
    loadChildren: () =>
      import(
        './error-pages/+incorrect-cookie-settings/incorrect-cookie-settings.module'
      ).then((m) => m.IncorrectCookieSettingsModule)
  },
  { path: '', component: PageNotFoundComponent },
  { path: '**', component: PageNotFoundComponent }
];
const devRoutes: Routes = [
  { path: '__TEST__', component: FormsRequestComponent },
  { path: '__TEST__/consent', component: ConsentsRequestComponent },
  { path: '__TEST__/tp', component: ConsentsRequestComponent },
  { path: '__TEST__/:form_type', component: FormsRequestComponent },
  { path: '__TEST__/v2/:form_type', component: FormsRequestComponent },
  ...routes
];

@NgModule({
  imports: [
    RouterModule.forRoot(environment.production ? routes : devRoutes, {
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
