import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeleteCardConfirmDialogComponent } from './delete-card-confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PaymentCardDetailModule } from '../payment-card-detail/payment-card-detail.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [DeleteCardConfirmDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    PaymentCardDetailModule
  ]
})
export class DeleteCardConfirmDialogModule {}
