import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { API_URL } from '../../../../../app.config';
import { VerifyResponse } from '../two-factor-authentication-dialog.types';
import { ActionResponse } from '../../../../../common/mobiati-http/action-response.model';

@Injectable({
  providedIn: 'root'
})
export class TwoFactorAuthenticationService {
  private readonly VERIFY_URL = 'oauth/otp/verify';
  private readonly RESEND_URL = 'oauth/otp/resend';

  constructor(
    private readonly httpClient: HttpClient,
    @Inject(API_URL) private readonly apiUrl: string
  ) {}

  verify(deviceUUID: string, code: string): Observable<VerifyResponse> {
    return this.httpClient.post<VerifyResponse>(
      `${this.apiUrl}/${this.VERIFY_URL}`,
      {
        device_uuid: deviceUUID,
        code
      }
    );
  }

  resend(deviceUUID: string): Observable<ActionResponse> {
    return this.httpClient.put<ActionResponse>(
      `${this.apiUrl}/${this.RESEND_URL}`,
      {
        device_uuid: deviceUUID
      }
    );
  }
}
