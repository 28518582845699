import { Inject, Injectable } from '@angular/core';
import { API_URL } from '../../app.config';
import { PracticeService } from '../../common/practice';
import { PendingFormOrConsentRequest } from './pending-form-or-consent-request.model';
import { PusherService } from '../../common/pusher/pusher.service';
import { Router } from '@angular/router';
import { KioskService } from './kiosk.service';
import { Observable, Subscription } from 'rxjs';
import { FormsRequestService } from '../../forms/forms-request/forms-request.service';
import { ConsentsRequestService } from '../../consents/consents-request/consents-request.service';

@Injectable({
  providedIn: 'root'
})
export class KioskRequestsService {
  private formRequestSub: Subscription;
  private consentRequestSub: Subscription;

  constructor(
    private router: Router,
    private kioskService: KioskService,
    private practiceService: PracticeService,
    private pusherService: PusherService,
    @Inject(API_URL) private apiUrl: string,
    private formRequestService: FormsRequestService,
    private consentRequestService: ConsentsRequestService
  ) {}

  async init(): Promise<any> {
    await this.pusherService.init();
    if (this.formRequestSub === undefined) {
      this.formRequestSub = this.pusherService.formRequestArrived$.subscribe(
        async (event) => {
          await this.onFormRequest(event, this);
        }
      );
    }
    if (this.consentRequestSub === undefined) {
      this.consentRequestSub = this.pusherService.consentRequestArrived$.subscribe(
        async (event) => {
          await this.onConsentRequest(event, this);
        }
      );
    }
  }

  connectionStatusObservable(): Observable<string> {
    return this.pusherService.connectionState$.asObservable();
  }

  async checkPendingRequests(): Promise<any> {
    try {
      await this.onFormRequest(
        await this.kioskService.checkFormRequestForDevice(),
        this
      );
      return; // if form request opened, don't check further
    } catch (e) {
      /* ignore, there is just nothing pending */
    }
    try {
      await this.onConsentRequest(
        await this.kioskService.checkConsentRequestForDevice(),
        this
      );
    } catch (e) {
      /* ignore, there is just nothing pending */
    }
  }

  private async onFormRequest(
    formRequest: PendingFormOrConsentRequest,
    that: KioskRequestsService
  ): Promise<any> {
    if (!this.isKioskBusy(that)) {
      await that.kioskService.ackFormRequest(formRequest.code, false);
      this.playIncomingRequestNotification(true);
      this.formRequestService.setFormsRequestCode(formRequest.code);
      that.router.navigate(
        [`f/${this.practiceService.getPracticeGuid()}/${formRequest.code}`],
        {}
      );
    } else {
      await that.kioskService.ackFormRequest(formRequest.code, true);
      this.playIncomingRequestNotification(false);
    }
  }

  private async onConsentRequest(
    consentRequest: PendingFormOrConsentRequest,
    that: KioskRequestsService
  ): Promise<any> {
    if (!this.isKioskBusy(that)) {
      await that.kioskService.ackConsentRequest(consentRequest.code, false);
      this.playIncomingRequestNotification(true);
      this.consentRequestService.setConsentRequestCode(consentRequest.code);
      that.router.navigate(
        [`c/${this.practiceService.getPracticeGuid()}/${consentRequest.code}`],
        {}
      );
    } else {
      await that.kioskService.ackConsentRequest(consentRequest.code, true);
      this.playIncomingRequestNotification(false);
    }
  }

  private isKioskBusy(kioskRequestService: KioskRequestsService): boolean {
    if (!kioskRequestService.router.url.startsWith('/kiosk')) {
      return true;
    }

    if (kioskRequestService.router.url.startsWith('/kiosk/checkin')) {
      return true;
    }

    return false;
  }

  getConnectionState(): string {
    return this.pusherService.getConnectionState();
  }

  private playIncomingRequestNotification(handled: boolean): void {
    const audio = new Audio();
    audio.src =
      'assets/sounds/' + (handled ? 'request-arrived.mp3' : 'request-busy.mp3');
    try {
      audio.load();
      audio.play();
    } catch (error) {}
  }
}
