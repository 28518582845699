<mat-form-field appearance="outline"
                class="payment-card-details mat-form-field--thin">
  <mat-select disableOptionCentering
              class="payment-card-details-select"
              panelClass="payment-card-details-dropdown native-scroll"
              [formControl]="cardControl"
              [placeholder]="
      'CLEARENT_CARD_PAYMENT.DROPDOWN.SELECT_PAYMENT_METHOD' | translate
    ">
    <mat-select-trigger>
      <app-payment-card-detail *ngIf="!!cardControl.value"
                               [card]="cardControl.value">
      </app-payment-card-detail>
    </mat-select-trigger>
    <mat-option *ngFor="let card of cards$ | async; trackBy: trackById"
                [value]="card">
      <div class="card-item">
        <app-payment-card-detail [card]="card"></app-payment-card-detail>
        <mat-icon class="delete-icon"
                  svgIcon="delete-credit-card-icon"
                  (click)="$event.stopPropagation(); deleteCard(card)">
        </mat-icon>
      </div>
    </mat-option>
    <mat-option [value]=""
                (click)="selectNewMethod()">
      <div class="new-method-item">
        <mat-icon svgIcon="plus-sign-in-box"></mat-icon>
        <span>{{
          'CLEARENT_CARD_PAYMENT.DROPDOWN.NEW_PAYMENT_METHOD' | translate
        }}</span>
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
