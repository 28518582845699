import { Question } from '../../forms/form/question.model';
import { AnswerOption } from '../../forms/form/answer-option.model';
import {
  ExtraRadioQuestion,
  ExtraType,
  RadioQuestionParams
} from '../../forms/form/controls/radio-control/radio-question.model';
import { TermsQuestionParams } from '../../forms/form/controls/terms-control/terms-question.model';
import { BlockQuestionParams } from '../../forms/form/controls/block-control/block-question.model';
import { isString } from 'util';
import { LiteEditorQuestion } from '../../+lite-editor/models/lite-editor-question';

const RESERVED_PREFIXES = [
  '4.',
  '5.',
  '6.',
  'consent_',
  'custom_',
  'relation_',
  'personal_',
  'dental_'
];
const PMS_PROBLEM_PREFIXES = ['4.', '5.', '6.'];

export function getSameKeyPrefixAs(question: Question): string {
  let matchingPrefix = '';
  RESERVED_PREFIXES.forEach((prefix) => {
    if (isString(question.key) && question.key.startsWith(prefix)) {
      matchingPrefix = prefix;
    }
  });
  return matchingPrefix;
}

export function isPMSProblemQuestion(questionKey: string): boolean {
  if (!isString(questionKey)) {
    return false;
  }
  // check if we need to show PMS integration fields
  let isProblem = false;
  PMS_PROBLEM_PREFIXES.forEach((prefix) => {
    if (questionKey.startsWith(prefix)) {
      isProblem = true;
    }
  });
  return isProblem;
}

/**
 * Maps key into type of BOMedicalCondition as returned from backend
 */
export function getConditionTypeForProblemQuestion(
  questionKey: string
): number {
  if (questionKey.startsWith('4.')) {
    return 1;
  }
  if (questionKey.startsWith('5.')) {
    return 2;
  }
  if (questionKey.startsWith('6.')) {
    return 0;
  }
  return -1;
}

export function buildQuestion(): Question {
  return {
    key: 'custom_',
    title: '',
    section: '',
    optional: false,
    type: null,
    control: {}
  } as Question;
}

export function buildLiteEditorQuestion(): LiteEditorQuestion {
  return {
    key: 'custom_',
    title: '',
    section: '',
    optional: false,
    type: null,
    control: {}
  } as LiteEditorQuestion;
}

export function buildYesNoQuestion(): Question {
  return {
    key: 'custom_',
    title: '',
    section: '',
    optional: false,
    type: 'radio',
    control: {
      options: [
        { name: 'Yes', value: true } as AnswerOption,
        { name: 'No', value: false } as AnswerOption
      ],
      extra: {
        value: true,
        type: ExtraType.Input,
        hint: 'Type in details here',
        optional: true
      } as ExtraRadioQuestion
    } as RadioQuestionParams
  } as Question;
}

export function buildYesNoMultiQuestion(): Question {
  return {
    key: 'custom_',
    title: '',
    section: '',
    optional: false,
    type: 'radio',
    control: {
      options: [
        { name: 'Yes', value: true } as AnswerOption,
        { name: 'No', value: false } as AnswerOption
      ],
      extra: {
        value: true,
        type: ExtraType.MultiSelect,
        hint: 'Please select',
        popup_title: 'Please select',
        optional: false,
        other: true,
        options: []
      } as ExtraRadioQuestion
    } as RadioQuestionParams
  } as Question;
}

export function buildConsentQuestion(): Question {
  return {
    key: 'consent_',
    title: '',
    section: '',
    optional: false,
    type: 'terms',
    control: {
      agree_text: 'I confirm and agree',
      text: ''
    } as TermsQuestionParams
  } as Question;
}

export function buildSignatureQuestion(): Question {
  return {
    key: 'signature',
    title: '',
    section: 'Signature',
    optional: false,
    type: 'block_signature',
    control: {
      language: 'en',
      variant: 'adult_no_guardian_details'
    } as BlockQuestionParams
  } as Question;
}
