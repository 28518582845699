import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { PaymentCardDetailComponent } from './payment-card-detail.component';
import { PaymentCardIconNamePipe } from './payment-card-icon-name.pipe';

@NgModule({
  declarations: [PaymentCardDetailComponent, PaymentCardIconNamePipe],
  imports: [CommonModule, MatIconModule],
  exports: [PaymentCardDetailComponent]
})
export class PaymentCardDetailModule {}
