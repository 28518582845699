import { ErrorHandler, NgModule } from '@angular/core';
import { SentryErrorHandler } from './sentry.service';

@NgModule({
  providers: [
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    SentryErrorHandler
  ]
})
export class SentryModule {}
