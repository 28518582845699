import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { InputQuestion, InputType } from './input-question.model';
import { isString } from 'util';
import { CustomValidators } from '../../../../common/validators/custom-validators.helper';
import { AbstractInputControlComponent } from '../abstract-input-control/abstract-input-control.component';
import { FormControl } from '@angular/forms';
import { AccessibilityService } from '../../../../common/accessibility-service/accessibility.service';
import { InitialsStoreService } from '../../../../common/initials-store/initials-store.service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-input-control',
  templateUrl: './input-control.component.html',
  styleUrls: [
    './input-control.component.scss',
    '../abstract-input-control/abstract-input-control.scss'
  ]
})
export class InputControlComponent
  extends AbstractInputControlComponent<string, InputQuestion, FormControl>
  implements OnInit, OnDestroy {
  @Input() compact = false;

  inputType = InputType;
  mask: any;
  autocapitalize?: string;
  maxLength: number;
  typedInitials: string;

  private phoneMask = [
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];
  private ssnMask = [
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];
  private subscriptionTypedInitials: Subscription;

  constructor(
    protected accessibilityService: AccessibilityService,
    private initialsStoreService: InitialsStoreService
  ) {
    super(accessibilityService);
  }

  ngOnInit(): void {
    const inputType = this.question.control.input_type;
    let inputText = '';
    if (isString(this.data)) {
      if (this.question.control.input_type === InputType.Phone) {
        inputText = this.data.replace(this.phonePrefix(), '');
      } else {
        inputText = this.data;
      }
    }

    this.mask = this.getInputMask(this.question.control.input_type);
    this.autocapitalize = this.toAutocapitalize(
      this.question.control.input_type
    );
    this.maxLength = this.toMaxLength(this.question.control.input_type);
    this.setupSimpleFormControl(inputText);

    if (inputType === 'initials') {
      this.subscriptionTypedInitials = this.initialsStoreService.initials.subscribe(
        (value) => {
          this.typedInitials = value;
        }
      );
    }

    this.markAsTouchedWhenValueChangeAndEmpty();
  }

  ngOnDestroy(): void {
    if (this.subscriptionTypedInitials !== undefined) {
      this.subscriptionTypedInitials.unsubscribe();
    }
  }

  getValidators(): any[] {
    const validators = super.getValidators();
    switch (this.question.control.input_type) {
      case InputType.Phone:
        validators.push(CustomValidators.validPhone());
        break;
      case InputType.Number:
        validators.push(CustomValidators.isNumber());
        break;
      case InputType.Zip:
        validators.push(CustomValidators.isNumber());
        validators.push(CustomValidators.exactLength(5, 'zip'));
        break;
      case InputType.Ssn:
        validators.push(CustomValidators.validSSN());
        break;
      case InputType.Email:
        validators.push(CustomValidators.validEmail());
        break;
      case InputType.Initials:
        validators.push(CustomValidators.validInitials());
        break;
    }
    return validators;
  }

  emitOutput(): void {
    let value = this.formControl.value;
    if (isString(value)) {
      switch (this.question.control.input_type) {
        case InputType.Phone:
          value =
            value.length > 0
              ? this.phonePrefix() + value.replace(/\D+/g, '')
              : null;
          break;
        case InputType.Ssn:
          value = value.replace(/\D+/g, '');
          break;
        case InputType.Initials:
          value = value.toUpperCase();
          this.initialsStoreService.initialsChange(value);
          break;
      }
    }

    this.output.emit(isString(value) && value.length > 0 ? value : null);
  }

  private phonePrefix(): string {
    return this.question.control.phone_prefix !== undefined
      ? this.question.control.phone_prefix
      : '+1';
  }

  private getInputMask(controlInputType: InputType): (string | RegExp)[] {
    switch (controlInputType) {
      case InputType.Phone:
        return this.phoneMask;
      case InputType.Ssn:
        return this.ssnMask;
      default:
        return null;
    }
  }

  toInputElementType(controlInputType: InputType): string {
    switch (controlInputType) {
      case InputType.Number:
        return 'number';
      case InputType.Phone:
        return 'tel';
      case InputType.Email:
        return 'email';
      case InputType.Zip:
      default:
        return 'text';
    }
  }

  private toMaxLength(controlInputType: InputType): number {
    switch (controlInputType) {
      case InputType.Zip:
        return 5;
      case InputType.Initials:
        return 2;
      default:
        return 2048;
    }
  }

  private toAutocapitalize(controlInputType: InputType): string {
    if (controlInputType === InputType.Initials) {
      return 'characters';
    }

    return null;
  }

  fillOutInitials(inputId): void {
    const initials = this.initialsStoreService.initialsTyped();
    this.formControl.setValue(initials);
    this.emitOutput();
  }

  private markAsTouchedWhenValueChangeAndEmpty(): void {
    this.formControl.valueChanges
      .pipe(
        tap((value) => {
          if (!value) {
            this.formControl.markAsTouched();
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
