<div class="row"
     [ngClass]="{ compact: compact }">
  <div class="input-field col s12">
    <p class="fixed-label question-title"
       *ngIf="fixedLabel"
       [ngClass]="{ focused: fixedLabelFocused }">
      {{ question.title?.trim()
      }}<span class="required"
            *ngIf="!question.optional && !compact">*</span>
    </p>
    <input id="{{ question.key }}"
           type="text"
           inputmode="numeric"
           class="validate"
           [name]="question.key"
           (ngModelChange)="emitOutput()"
           (focusin)="fixedLabelFocused = true"
           (focusout)="fixedLabelFocused = false"
           [textMask]="{ mask: dateMask, pipe: autoCorrectedDatePipe }"
           [attr.placeholder]="'MM/DD/YYYY'"
           [formControl]="formControl" />
    <label #label
           *ngIf="!fixedLabel"
           for="{{ question.key }}">{{ question.title?.trim()
      }}<span class="required"
            *ngIf="!question.optional && !compact">*</span></label>
    <span class="helper-text light"
          *ngIf="question.text">{{
      question.text
    }}</span>
    <ng-container *ngIf="!formControl.valid && formControl.touched">
      <span class="helper-text invalid"
            *ngIf="formControl.errors.required">{{
        'FORMS.VALIDATION.DATE.REQUIRED' | translate
      }}</span>
      <span class="helper-text invalid"
            *ngIf="formControl.errors.pastDate">{{
        'FORMS.VALIDATION.DATE.PAST_DATE' | translate
      }}</span>
      <span class="helper-text invalid"
            *ngIf="formControl.errors.futureDate">{{
        'FORMS.VALIDATION.DATE.FUTURE_DATE' | translate
      }}</span>
      <span class="helper-text invalid"
            *ngIf="formControl.errors.validDate">{{
        'FORMS.VALIDATION.DATE.VALID_DATE' | translate
      }}</span>
    </ng-container>
  </div>
</div>
