import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppCommonModule } from '../common/common.module';
import { CommonModule } from '@angular/common';
import { ConsentCompletedComponent } from './consent-completed/consent-completed.component';
import { ConsentComponent } from './consent/consent.component';
import { ConsentContentComponent } from './consent/consent-content/consent-content.component';
import { ConsentPaymentOptionSelectionHookModule } from './consent/consent-payment-option-selection-hook/consent-payment-option-selection-hook.module';
import { ConsentPaymentPlanSignComponentModule } from './consent/consent-payment-plan-sign/consent-payment-plan-sign.module';
import { ConsentPaymentPlansModule } from './consent/consent-payment-plans/consent-payment-plans.module';
import { ConsentSignComponent } from './consent/consent-sign/consent-sign.component';
import { ConsentsRequestComponent } from './consents-request/consents-request.component';
import { DrawingCanvasModule } from './drawing-canvas/drawing-canvas.module';
import { NgModule } from '@angular/core';
import { NoBreaksPipe } from '../common/pipes/noBreaks/noBreaks.pipe';
import { PatientFormsModule } from '../forms/patient-forms.module';
import { PhonePipe } from '../common/pipes/phone/phone.pipe';
import { ReactiveComponentModule } from '@ngrx/component';
import { RejectTpConsentModule } from './consent/reject-tp-consent/reject-tp-consent.module';
import { TranslateModule } from '@ngx-translate/core';
import { WwwUrlPipe } from '../common/pipes/wwwUrl/wwwUrl.pipe';

@NgModule({
  declarations: [
    ConsentsRequestComponent,
    ConsentComponent,
    ConsentCompletedComponent,
    ConsentContentComponent,
    ConsentSignComponent,
    PhonePipe,
    WwwUrlPipe,
    NoBreaksPipe
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    PatientFormsModule,
    ConsentPaymentPlansModule,
    DrawingCanvasModule,
    ReactiveComponentModule,
    ConsentPaymentOptionSelectionHookModule,
    ConsentPaymentPlanSignComponentModule,
    RejectTpConsentModule
  ]
})
export class ConsentsModule {}
