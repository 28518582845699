<ul class="collapsible">
  <li #collapsibleElement>
    <div class="handle collapsible-header"
         [ngClass]="{ disabled: question.disabled }">
      <a (click)="onDeleteQuestion()" class="delete-icon"
        ><i class="material-icons">delete_forever</i></a>
      <a (click)="onCloneQuestion($event)" class="clone-icon"
        ><i class="material-icons">file_copy</i></a>
      <div class="col s12">
        <div class="row row-no-margin">{{ question.title }}</div>
        <div class="row row-no-margin"
             *ngIf="editOptions.editSection">
          <small>{{ editOptions.editSection ? question.section : '' }}</small>
        </div>
        <div class="row row-no-margin">
          <span><small><code>{{
                question.type ? question.type.toUpperCase() : ''
              }}</code></small></span>
          <span
                *ngIf="question.optional === true">&nbsp;<small>(optional)</small></span>
          <span
                *ngIf="question.if !== undefined || question.if_or !== undefined">&nbsp;<small>{{ toConditionalsDesc(question) }}</small></span>
        </div>
      </div>
      <a
        *ngIf="isPMSProblemQuestion(question.key)"
        class="problem-icon"
        [ngClass]="{ 'pms-mapped': isPMSMappedProblem(question) }"
        title="{{ 'Question indicates medical problem/allergy/medication' }}"
      >
        <i class="material-icons">local_hospital</i>
      </a>
      <a
        (click)="onToggleVisibility($event)"
        class="visibility-icon"
        title="{{
          question.disabled
            ? 'Question is hidden and will be ignored'
            : 'Question is visible'
        }}"
      >
        <i class="material-icons">{{
          question.disabled ? 'visibility_off' : 'visibility'
        }}</i>
      </a>
    </div>
    <div class="collapsible-body">
      <div class="row">
        <div class="input-field col s5">
          <input placeholder="Key"
                 id="{{ question.key + '-key' }}"
                 type="text"
                 class="validate"
                 maxlength="128"
                 [(ngModel)]="question.key" />
          <label for="{{ question.key + '-key' }}">Key</label>
        </div>
        <div class="col s1">
          <a (click)="onCreateKeyFromQuestion(question)"
            ><i class="material-icons">arrow_back</i></a>
        </div>
        <div class="input-field col s6">
          <input placeholder="Question"
                 id="{{ question.key + '-title' }}"
                 type="text"
                 class="validate"
                 [(ngModel)]="question.title" />
          <label for="{{ question.key + '-title' }}">Question</label>
        </div>
      </div>
      <div class="row">
        <div class="input-field col s6"
             *ngIf="editOptions.editSection">
          <input placeholder="Section"
                 id="{{ question.key + '-section' }}"
                 type="text"
                 class="validate"
                 [(ngModel)]="question.section"
                 (ngModelChange)="onSectionChange(question)" />
          <label for="{{ question.key + '-section' }}">Section</label>
        </div>
        <div class="input-field col s6"
             *ngIf="editOptions.editOptional">
          <label>
            <input type="checkbox"
                   class="filled-in"
                   checked="checked"
                   [(ngModel)]="question.optional"
                   [value]="true" />
            <span>Optional</span>
          </label>
        </div>
      </div>
      <div class="row">
        <div class="input-field col s12">
          <input placeholder="Extra description"
                 id="{{ question.key + '-text' }}"
                 type="text"
                 class="validate"
                 [(ngModel)]="question.text" />
          <label for="{{ question.key + '-text' }}">Extra description (not
            printed in PDF)</label>
          <span class="helper-text text-light">For radio + multiselect controls
            will
            list all options if not
            provided.</span>
        </div>
      </div>
      <div class="row"
           *ngIf="
          isPMSProblemQuestion(question.key) &&
          !isRadioWithMultiSelect(question) &&
          question.type !== 'multiradio'
        ">
        <app-existence [data]="question"
                       (created)="onPMSProblemQuestionShown(question)">
        </app-existence>
        <div class="col s1">
          <a
            class="btn btn-primary btn-circle right"
            (click)="mapperService.showMapperForQuestion(question)"
          >
            <i class="material-icons">search</i>
          </a>
        </div>
        <div class="input-field col s3">
          <input placeholder="PMS ID"
                 id="{{ question.key + '-meta-pms-id' }}"
                 type="text"
                 class="validate"
                 [(ngModel)]="question.meta.pms_id" />
          <label for="{{ question.key + '-meta-pms-id' }}">PMS ID</label>
        </div>
        <div class="input-field col s4">
          <input placeholder="PMS Name"
                 id="{{ question.key + '-meta-pms-name' }}"
                 type="text"
                 class="validate"
                 [(ngModel)]="question.meta.pms_name" />
          <label for="{{ question.key + '-meta-pms-name' }}">PMS Name</label>
        </div>
        <div class="input-field col s4">
          <input placeholder="Problem label for summary"
                 id="{{ question.key + '-meta-pms-problem' }}"
                 type="text"
                 class="validate"
                 [(ngModel)]="question.meta.problem" />
          <label for="{{ question.key + '-meta-pms-problem' }}">Problem label
            for summary</label>
        </div>
      </div>
      <div class="row"
           *ngIf="editOptions.editType">
        <div class="input-field col s12">
          <select [(ngModel)]="question.type"
                  (ngModelChange)="onTypeChange()">
            <option disabled
                    value="null">Select type</option>
            <option value="input">Input</option>
            <option value="date">Date</option>
            <option value="dropdown">Dropdown</option>
            <option value="states">Dropdown (state)</option>
            <option value="radio">Radio</option>
            <option value="photo">Photo</option>
            <option value="multiradio">Multiradio</option>
            <option value="terms">Terms</option>
            <option value="signature">Signature</option>
            <option value="block_signature">Signature Block</option>
            <option value="text">Text</option>
            <option value="link">External link</option>
          </select>
          <label>Type</label>
        </div>
      </div>
      <div class="row">
        <app-edit-input *ngIf="question.type === 'input'; else notControl"
                        [control]="question.control"
                        [key]="question.key"></app-edit-input>
        <app-edit-date *ngIf="question.type === 'date'; else notControl"
                       [control]="question.control"
                       [key]="question.key"></app-edit-date>
        <app-edit-dropdown *ngIf="question.type === 'dropdown'; else notControl"
                           [control]="question.control"
                           [key]="question.key"></app-edit-dropdown>
        <app-edit-radio *ngIf="question.type === 'radio'; else notControl"
                        [control]="question.control"
                        [key]="question.key"
                        [isPMSProblem]="isPMSProblemQuestion(question.key)"
                        (uiChange)="onUIStructureChange()"></app-edit-radio>
        <app-edit-photo *ngIf="question.type === 'photo'; else notControl"
                        [control]="question.control"
                        [key]="question.key"></app-edit-photo>
        <app-edit-terms *ngIf="question.type === 'terms'; else notControl"
                        [control]="question.control"
                        [key]="question.key"></app-edit-terms>
        <app-edit-signature *ngIf="question.type === 'signature'; else notControl"
                            [key]="question.key"></app-edit-signature>
        <app-edit-multiradio *ngIf="question.type === 'multiradio'; else notControl"
                             [question]="question"
                             [key]="question.key"
                             (uiChange)="onUIStructureChange()">
        </app-edit-multiradio>
        <app-edit-link *ngIf="question.type === 'link'; else notControl"
                       [control]="question.control"
                       [key]="question.key"></app-edit-link>
        <app-edit-text *ngIf="question.type === 'text'; else notControl"
                       [control]="question.control"
                       [key]="question.key"></app-edit-text>
        <app-edit-block *ngIf="
            question.type && question.type.startsWith('block_');
            else notControl
          "
                        [control]="question.control"
                        [key]="question.key"></app-edit-block>
        <ng-template #notControl>
          <app-existence (created)="onUIStructureChange()"></app-existence>
        </ng-template>
      </div>
    </div>
  </li>
</ul>
