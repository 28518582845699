import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ellipsize' })
export class EllipsizePipe implements PipeTransform {
  transform(value: string, limit: number): string {
    return value && value.length > limit
      ? `${value.slice(0, limit - 1)}...`
      : value;
  }
}
