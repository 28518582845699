<li class="payment_option-item"
    (click)="onClick()"
    xmlns="http://www.w3.org/1999/html">
  <div class="header">
    <h3 *ngIf="!isTypeWisetack"
        class="payment_option-item_title">{{ paymentOption.title }}</h3>
    <h3 *ngIf="isTypeWisetack"
        class="payment_option-item_title">
      {{ 'CONSENTS.PAYMENT_OPTIONS.WISETACK.SEE_FINANCING_OPTIONS' | translate }}
    </h3>
    <ng-container *ngIf="paymentOption.icon">
      <img src="{{ paymentOption.icon }}"
           class="payment_option-item_icon"
           [ngClass]="{ 'wisetack-icon': wisetackWiderGrid }">
    </ng-container>
    <p class="payment_option-item_calculations"
       *ngIf="paymentOption.calculations && !isWisetackLoanPreQualificationDone"
       [innerHTML]="paymentOption.calculations">
    </p>

    <div *ngIf="isWisetackLoanPreQualificationDone"
         class="mt-2 mb-1">
      <ng-container [ngSwitch]="loanPreQualificationStatus">
        <div *ngSwitchCase="wisetackLoanPreQualificationStatuses.APPROVED"
             class="green-text flex-column-center text-small">
          <div class="flex-justify-content-center">
            <span class="material-icons-outlined smaller-icon">done</span>
            <span
                  class="strong">{{ 'CONSENTS.PAYMENT_OPTIONS.WISETACK.APPROVED' | translate }}</span>
          </div>

          <div class="text-small mt-1">
            {{ 'CONSENTS.PAYMENT_OPTIONS.WISETACK.UP_TO' | translate }}
            ${{ patient.max_qualified_amount }}
          </div>
        </div>

        <div *ngSwitchCase="wisetackLoanPreQualificationStatuses.NOT_APPROVED"
             class="red-text flex-justify-content-center text-small">
          <span class="material-icons-outlined smaller-icon">close</span>
          <span
                class="strong">{{ 'CONSENTS.PAYMENT_OPTIONS.WISETACK.NOT_APPROVED' | translate }}</span>
        </div>

        <div *ngSwitchCase="wisetackLoanPreQualificationStatuses.PARTIALLY_APPROVED"
             class="orange-text flex-column-center text-small">
          <div class="flex-justify-content-center">
            <span
                  class="material-icons-outlined smaller-icon">report_problem</span>
            <span
                  class="strong">{{ 'CONSENTS.PAYMENT_OPTIONS.WISETACK.APPROVED' | translate }}</span>
          </div>
          <div class="text-small mt-1">
            {{ 'CONSENTS.PAYMENT_OPTIONS.WISETACK.UP_TO' | translate }}
            ${{ patient.max_qualified_amount }}
          </div>
        </div>

        <div *ngSwitchCase="wisetackLoanPreQualificationStatuses.ERROR"
             class="red-text flex-justify-content-center text-small">
          <span class="material-icons-outlined smaller-icon">close</span>
          <span
                class="strong">{{ 'CONSENTS.PAYMENT_OPTIONS.WISETACK.ERROR' | translate }}</span>
        </div>
      </ng-container>
    </div>

    <div *ngIf="isWisetackLoanApplicationDone"
         class="mt-2 mb-1">
      <ng-container [ngSwitch]="loanApplicationStatus">
        <div *ngSwitchCase="wisetackLoanPreQualificationStatuses.APPROVED"
             class="green-text flex-column-center text-small">
          <div class="flex-justify-content-center">
            <span class="material-icons-outlined smaller-icon">done</span>
            <span class="strong">You already finished loan application</span>
          </div>
        </div>

        <div *ngSwitchCase="wisetackLoanPreQualificationStatuses.DECLINED"
             class="red-text flex-justify-content-center text-small">
          <span class="material-icons-outlined smaller-icon">close</span>
          <span class="strong">Loan application declined</span>
        </div>

        <div *ngSwitchCase="wisetackLoanPreQualificationStatuses.ERROR"
             class="red-text flex-justify-content-center text-small">
          <span class="material-icons-outlined smaller-icon">close</span>
          <span
                class="strong">{{ 'CONSENTS.PAYMENT_OPTIONS.WISETACK.ERROR' | translate }}</span>
        </div>
      </ng-container>
    </div>

  </div>
  <div class="payment_option-item_footer">
    <p class="payment_option-item_footer_description"
       *ngIf="paymentOption.show_description">
      {{ paymentOption.description }}
    </p>
    <div class="payment_option-item_footer_divider {{ paymentOption.type }}">
    </div>
    <ng-template [ngIf]="isTypeWisetack && paymentOption.from_monthly_cost"
                 [ngIfElse]="totalCost">
      <p *ngIf="loanPreQualificationStatus === wisetackLoanPreQualificationStatuses.NOT_APPROVED"
         class="payment_option-item_footer_total-cost-header">
        {{ 'CONSENTS.PAYMENT_OPTIONS.FROM_COST_LABEL' | translate }}
      </p>
      <div>
        <p *ngIf="loanPreQualificationStatus === wisetackLoanPreQualificationStatuses.NOT_APPROVED"
           class="payment_option-item_footer_total-cost-value">
          {{ paymentOption.from_monthly_cost }}
        </p>
        <p *ngIf="loanPreQualificationStatus === wisetackLoanPreQualificationStatuses.APPROVED"
           class="payment_option-item_footer_disclaimer">
          {{ 'CONSENTS.PAYMENT_OPTIONS.WISETACK.FROM_COST_EXPLAINER_FOR_APPROVED' | translate }}
        </p>
        <p *ngIf="loanPreQualificationStatus === wisetackLoanPreQualificationStatuses.PARTIALLY_APPROVED"
           class="payment_option-item_footer_disclaimer">
          {{ 'CONSENTS.PAYMENT_OPTIONS.WISETACK.FROM_COST_EXPLAINER_FOR_PARTIALLY_APPROVED' | translate }}
        </p>
      </div>
    </ng-template>
    <ng-template #totalCost>
      <p class="payment_option-item_footer_total-cost-header">
        {{ 'CONSENTS.PAYMENT_OPTIONS.TOTAL_COST_LABEL' | translate }}
      </p>
      <h5 class="payment_option-item_footer_total-cost-value">
        {{ paymentOption.total_cost }}
      </h5>
    </ng-template>
    <p class="payment_option-item_footer_disclaimer"
       *ngIf="paymentOption.disclaimer"
       [innerHTML]="paymentOption.disclaimer"></p>

    <ng-template [ngIf]="isTypeWisetack"
                 [ngIfElse]="selectButton">
      <input type="button"
             value="{{ 'CONSENTS.PAYMENT_OPTIONS.WISETACK.SEE_FINANCING_OPTIONS' | translate }}"
             class="payment_option-item_footer_button"
             [class.active]="active"
             [class.disabled]="loanApplicationStatus === wisetackLoanPreQualificationStatuses.DECLINED ||
              loanApplicationStatus === wisetackLoanPreQualificationStatuses.ERROR ||
              loanPreQualificationStatus === wisetackLoanApplicationStatuses.NOT_APPROVED"
             [ngClass]="{ 'wisetack-footer-button': wisetackWiderGrid }">
    </ng-template>
    <ng-template #selectButton>
      <input type="button"
             value="{{ 'CONSENTS.PAYMENT_OPTIONS.SELECT' | translate }}"
             class="payment_option-item_footer_button"
             [class.active]="active">
    </ng-template>
  </div>
</li>
