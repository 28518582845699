<div class="container">
  <div class="row mt-2">
    <div class="col s12">
      <h1 class="patient-name">{{ patient.first_name }},</h1>
      <p class="light-info-text">
        {{ 'PAYMENT_PLAN_CONSENT.PLEASE_REVIEW' | translate }}
      </p>

      <div class="info-surface">
        <div class="total-amount">
          <p class="total-amount-txt">
            {{ 'PAYMENT_PLAN_CONSENT.TOTAL_AMOUNT' | translate }}
          </p>
          <p class="total-amount-number">
            {{ (paymentOption.total_cost_int / 100) | currency: 'USD'  }}
          </p>
        </div>
        <p class="payment-each-month-txt">
          {{ 'PAYMENT_PLAN_CONSENT.EACH_MONTH_FOR_N_MONTHS' | translate: { amount: (paymentOption.monthly_payment / 100) | currency: 'USD' , numMonths: paymentOption.installments } }}
        </p>
      </div>

      <ng-container *ngIf="paymentOption.down_payment > 0">
        <p class="light-info-text">
          {{ 'PAYMENT_PLAN_CONSENT.DOWN_PAYMENT' | translate }}
        </p>

        <div class="info-surface">
          <div class="total-amount">
            <p class="total-amount-txt">
              {{ 'PAYMENT_PLAN_CONSENT.DOWN_PAYMENT_TODAY' | translate }}
            </p>
            <p class="total-amount-number">
              {{ paymentOption.down_payment / 100 | currency: 'USD'  }}
            </p>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="paymentOption.installments > 0">
        <p class="light-info-text">
          {{ 'PAYMENT_PLAN_CONSENT.PAYMENT_PLAN_SCHEDULE' | translate: { numPayments: paymentOption.installments } }}
        </p>

        <div class="info-surface">
          <div class="d-flex flex-col">
            <ul class="custom-list">

              <ng-container
                            *ngFor="let scheduled_payment of paymentPlanSchedulePresenter; trackBy: trackByPaymentPlanScheduleItemText">
                <li
                    *ngIf="scheduled_payment.kind === paymentPlanScheduleItemType.Row">
                  <div class="d-flex justify-space-between">
                    <p class="payment-each-month-txt">
                      {{ scheduled_payment.text }}
                    </p>
                    <p class="total-amount-number">
                      {{ (scheduled_payment.amount / 100) | currency: 'USD' }}
                    </p>
                  </div>
                </li>

                <li class="hidden-rows"
                    *ngIf="scheduled_payment.kind === paymentPlanScheduleItemType.Separator">
                  <div class="d-flex justify-center">
                    {{ 'PAYMENT_PLAN_CONSENT.PLUS_N_MORE_PAYMENTS' | translate: { numMorePayments: paymentOption.installments - 4 } }}
                  </div>
                </li>

              </ng-container>

            </ul>
          </div>
        </div>
      </ng-container>

      <p class="bold-info-text">
        {{ 'PAYMENT_PLAN_CONSENT.PAYMENT_METHOD' | translate }}
      </p>
      <app-clearent-card-payment [formControl]="cardControl"
                                 [patient]="patient"
                                 [enableZipCode]="true"
                                 [terminalType]="terminalType.VIRTUAL">
      </app-clearent-card-payment>

      <div class="row row-extra mt-1"
           *ngIf="!cardControl.valid && cardControl.touched && cardControl.errors">
        <span class="helper-text invalid"
              *ngIf="cardControl.errors.required">{{ 'FORMS.VALIDATION.RADIO.REQUIRED' | translate }}
        </span>
      </div>

      <p class="terms-and-conditions-text">
        {{ 'PAYMENT_PLAN_CONSENT.BY_CONTINUING_1' | translate }}
        <a class="terms-conditions-link" href="https://www.dentalintel.com/dental-payments/terms" target="_blank" rel="nofollow">
        {{ 'PAYMENT_PLAN_CONSENT.TERMS_AND_CONDITIONS' | translate }}
        </a>
        {{ 'PAYMENT_PLAN_CONSENT.BY_CONTINUING_2' | translate }}
      </p>

      <div class="d-flex align-center">
        <form [formGroup]="paymentPlanSignForm">

          <label class="for-checkbox"
                 for="agree-checkbox">
            <input type='checkbox'
                   formControlName="agreeTerms"
                   id="agree-checkbox"
                   class="filled-in" />
            <span>{{ 'PAYMENT_PLAN_CONSENT.TERMS_AND_CONDITIONS_CHECKBOX' | translate }}</span>
          </label>
          <div class="row row-extra mt-1"
               *ngIf="!agreeTerms.valid && agreeTerms.touched  && agreeTerms.errors">
            <span class="helper-text invalid"
                  *ngIf="agreeTerms.errors.required">{{ 'FORMS.VALIDATION.RADIO.REQUIRED' | translate }}
            </span>
          </div>
        </form>

      </div>

      <app-radio-control [question]="signAsQuestion"
                         (output)="onSigningAsChange($event)"
                         (control)="onFormControlSet('signature_entity', $event)">
      </app-radio-control>
      <p class="error-message-txt">{{ errorMessage }}</p>
      <app-signature-control [question]="signatureQuestion"
                             [data]="null"
                             (output)="onSignatureChange('signature', $event)"
                             (control)="onFormControlSet('signature', $event)"
                             [note]="'FORMS.CONTROLS.SIGNATURE.NOTE' | translate">
      </app-signature-control>
    </div>
  </div>
</div>
