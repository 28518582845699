import Quill from 'quill';

const Delta = Quill.import('delta');
const Break = Quill.import('blots/break');
const Embed = Quill.import('blots/embed');

/**
 * inspiration:
 * https://codepen.io/mackermedia/pen/gmNwZP
 */

export function lineBreakMatcher(): any {
  const newDelta = new Delta();
  newDelta.insert({ break: '' });
  return newDelta;
}

export class SmartBreakBlock extends Break {
  blotName = 'break';
  tagName = 'BR';

  length(): number {
    return 1;
  }

  value(): string {
    return '\n';
  }

  insertInto(parent, ref): void {
    Embed.prototype.insertInto.call(this, parent, ref);
  }
}
