import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output
} from '@angular/core';
import { FormSpec } from '../../../forms/form/form-spec.model';
import { AlertsService } from '../../../common/alerts/alerts.service';
import { OnboardingToolsService } from '../../onboarding-tools-service/onboarding-tools.service';
import { PracticeService } from '../../../common/practice';
import { nonEmpty } from '../../../common/utils/string-utils.helper';
import { Materialize, Modal } from '../../../materialize';
import { Router } from '@angular/router';
import { TwoFaService } from '../../../common/authentication/two-fa.service';
import { from, Subject } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TwoFactorAuthenticationDialogService } from './two-factor-authentication-dialog/two-factor-authentication-dialog.service';
import { TwoFactorAuthenticationDialogCloseReason } from './two-factor-authentication-dialog/two-factor-authentication-dialog.types';

declare const M: Materialize;

@UntilDestroy()
@Component({
  selector: 'app-form-spec-picker',
  templateUrl: './form-spec-picker.component.html',
  styleUrls: ['./form-spec-picker.component.scss']
})
export class FormSpecPickerComponent implements OnInit, AfterViewInit {
  email: string;
  password: string;
  authorized: boolean;
  practiceGuid: string;
  practiceFormSpecs: FormSpec[];
  isSubmitting: boolean;
  deleteConfirmModal: Modal;
  restoreConfirmModal: Modal;
  formSpecToDelete: FormSpec;
  formSpecToRestore: FormSpec;
  loadConsents: boolean;

  @Output() formSpecPicked = new EventEmitter<FormSpec>();

  private readonly mfaRequired$: Subject<string> = new Subject<string>();

  constructor(
    private toolsService: OnboardingToolsService,
    private alertsService: AlertsService,
    private practiceService: PracticeService,
    private twoFaService: TwoFaService,
    private router: Router,
    private readonly twoFactorAuthenticationDialogService: TwoFactorAuthenticationDialogService
  ) {}

  async ngOnInit(): Promise<any> {
    this.authorized = this.toolsService.isAuthorized();
    this.practiceGuid = this.practiceService.getPracticeGuid();
    this.loadConsents = Boolean(sessionStorage.getItem('tools.loadConsents'));
    if (nonEmpty(this.practiceGuid)) {
      await this.onLoadPractice();
    }

    this.subscribeToMFARequired();
  }

  ngAfterViewInit(): void {
    setTimeout(() => M.updateTextFields(), 0);
  }

  async onLogIn(): Promise<any> {
    this.isSubmitting = true;
    try {
      await this.toolsService.logIn(this.email, this.password);
      this.authorized = this.toolsService.isAuthorized();
    } catch (e) {
      if (e.error && e.error.device_uuid) {
        this.twoFaService.setDeviceUuid(e.error.device_uuid, this.email);
      }
      if (e.error.error === 'unknown_device') {
        this.alertsService.showApiWarning(e);
      } else if (e.error?.error !== 'mfa_required') {
        this.alertsService.showApiError(e);
      } else {
        this.mfaRequired$.next(e.error.email);
      }
    } finally {
      // stop spinner
      this.isSubmitting = false;
    }
  }

  async onPasswordKeyDown($event): Promise<any> {
    if ($event.key === 'Enter') {
      await this.onLogIn();
    }
  }

  async onLoadPractice(): Promise<any> {
    this.isSubmitting = true;
    this.practiceService.setPracticeGuid(this.practiceGuid);
    try {
      await this.practiceService.loadPracticeIfNeeded();
      await this.onLoadFormSpecs();
    } catch (e) {
      this.alertsService.showApiError(e);
    } finally {
      // stop spinner
      this.isSubmitting = false;
    }
  }

  async onLoadFormSpecs(): Promise<any> {
    this.isSubmitting = true;
    try {
      this.practiceFormSpecs = await this.toolsService.getFormSpecs(
        this.loadConsents
      );
    } catch (e) {
      this.alertsService.showApiError(e);
    } finally {
      // stop spinner
      this.isSubmitting = false;
    }
  }

  onLoadSpec(formSpec: FormSpec): void {
    this.formSpecPicked.emit(formSpec);
  }

  onPreviewSpec(formSpec: FormSpec): void {
    window.open(
      `/p/${this.practiceService.getPracticeGuid()}/${formSpec.type}/${
        formSpec.language
      }` + (formSpec.for_minor ? '/minor' : '')
    );
  }

  onDeleteSpec(formSpec: FormSpec): void {
    this.formSpecToDelete = formSpec;
    this.deleteConfirmModal.open();
  }

  onRestoreDefaultSpec(formSpec: FormSpec): void {
    this.formSpecToRestore = formSpec;
    this.restoreConfirmModal.open();
  }

  async onDeleteSpecConfirmed(): Promise<any> {
    this.isSubmitting = true;
    try {
      await this.toolsService.deleteFormSpec(this.formSpecToDelete);
      await this.onLoadFormSpecs();
    } catch (e) {
      this.alertsService.showApiError(e);
    } finally {
      // stop spinner
      this.isSubmitting = false;
    }
  }

  async onRestoreDefaultSpecConfirmed(): Promise<any> {
    this.isSubmitting = true;
    try {
      await this.toolsService.restoreDefaultFormSpec(this.formSpecToRestore);
      await this.onLoadFormSpecs();
    } catch (e) {
      this.alertsService.showApiError(e);
    } finally {
      // stop spinner
      this.isSubmitting = false;
    }
  }

  async logOut(): Promise<any> {
    this.isSubmitting = true;
    try {
      await this.toolsService.logOut();
      this.authorized = false;
    } catch (e) {
      this.alertsService.showApiError(e);
    } finally {
      // stop spinner
      this.isSubmitting = false;
    }
  }

  onLoadConsentsChanged(): void {
    sessionStorage.setItem('tools.loadConsents', String(this.loadConsents));
    this.onLoadPractice().then();
  }

  onCreateConsent(): void {
    this.router.navigate(['tools', 'forms', 'create-consent']).then();
  }

  private subscribeToMFARequired(): void {
    this.mfaRequired$
      .pipe(
        switchMap((email) =>
          this.twoFactorAuthenticationDialogService
            .open(email, this.email)
            .afterClosed()
            .pipe(
              filter(
                (closeReason: TwoFactorAuthenticationDialogCloseReason) =>
                  closeReason ===
                  TwoFactorAuthenticationDialogCloseReason.VERIFIED
              ),
              switchMap(() => from(this.onLogIn()))
            )
        ),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
