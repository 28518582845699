import {
  ActivatePlanPayload,
  AttachSignatureResponse,
  PaymentPlanResponse,
  SavePaymentPlanResponse
} from './payment-plan-consent.types';
import { Observable, from } from 'rxjs';

import { Injectable } from '@angular/core';
import { MobiatiHttpService } from '../../../app/common/mobiati-http/mobiati-http.service';
import { SavePaymentPlanRequest } from '../consent/consent-payment-plan-sign/consent-payment-plan-sign.types';

@Injectable({
  providedIn: 'root'
})
export class PaymentPlansService {
  constructor(private readonly httpClient: MobiatiHttpService) {}

  getPlan(code: string): Observable<PaymentPlanResponse> {
    return from(
      this.httpClient.get<PaymentPlanResponse>(`/payment-plans/${code}`)
    );
  }

  activatePlan(
    code: string,
    payload: ActivatePlanPayload
  ): Observable<PaymentPlanResponse> {
    return from(
      this.httpClient.post<PaymentPlanResponse>(
        `/payment-plans/${code}/activate`,
        payload
      )
    );
  }

  attachSignature(formData: FormData): Observable<AttachSignatureResponse> {
    return from(
      this.httpClient.post<AttachSignatureResponse>(
        `/payment-plans/attachment`,
        formData
      )
    );
  }

  savePaymentPlanFromTreatmentPlan(
    patientId: number,
    payload: SavePaymentPlanRequest
  ): Observable<SavePaymentPlanResponse> {
    return from(
      this.httpClient.post<SavePaymentPlanResponse>(
        `/payment-plans/${patientId}`,
        payload
      )
    );
  }
}
