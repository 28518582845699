import { Injectable } from '@angular/core';
import { PatientPortalVersion } from './patient-portal-version.model';

@Injectable({
  providedIn: 'root'
})
export class PatientPortalService {
  private patientPortalVersion: PatientPortalVersion = null;

  setPatientPortalVersion(version: PatientPortalVersion): void {
    this.patientPortalVersion = version;
  }

  getPatientPortalVersion(): PatientPortalVersion {
    return this.patientPortalVersion;
  }

  isInPatientPortal(): boolean {
    return !!this.getPatientPortalVersion();
  }
}
