import * as Sentry from '@sentry/browser';
import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { PracticeService } from '../common/practice';
import { nonEmpty } from '../common/utils/string-utils.helper';
import { KioskDeviceService } from '../kiosk/kiosk-device/kiosk-device.service';
import { VERSION } from '../../environments/version';
import { HttpErrorResponse } from '@angular/common/http';

let globalPracticeService: PracticeService;
let globalKioskDeviceService: KioskDeviceService;

Sentry.init({
  dsn: environment.sentry.DSN,
  environment: environment.envName,
  release: `modento-forms@${VERSION.version}-${VERSION.hash}`,
  beforeSend(event, hint): any {
    // Workaround about cases when rejected promise throws odd, undescriptive exception
    try {
      /* tslint:disable:no-string-literal only-arrow-functions */
      const isNonErrorException =
        event.exception.values[0].value.startsWith(
          'Non-Error exception captured'
        ) ||
        hint.originalException['message'].startsWith(
          'Non-Error exception captured'
        );
      /* tslint:enable:no-string-literal only-arrow-functions */

      if (isNonErrorException) {
        // We want to ignore those kind of errors
        console.log(event.exception.values[0].value);
        return null;
      }
    } catch (e) {}

    // add practice guid tag if possible
    const practiceGuid =
      globalPracticeService !== undefined
        ? globalPracticeService.getPracticeGuid()
        : undefined;
    if (nonEmpty(practiceGuid)) {
      if (event.tags === undefined) {
        event.tags = {};
      }
      event.tags.practice_guid = practiceGuid;
      if (globalKioskDeviceService.isInKiosk()) {
        if (event.extra === undefined) {
          event.extra = {};
        }
        const deviceId = globalKioskDeviceService.getDeviceId();
        event.extra.kiosk_device_id = nonEmpty(deviceId)
          ? deviceId.substr(0, 16) + '***'
          : undefined;
        event.extra.kiosk_device_name = globalKioskDeviceService.getDeviceName();
      }
    }

    return event;
  }
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(
    private practiceService: PracticeService,
    private kioskDeviceService: KioskDeviceService
  ) {
    globalPracticeService = this.practiceService;
    globalKioskDeviceService = this.kioskDeviceService;
  }

  extractError(error: any): Error | ErrorEvent | string {
    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    if (error && error.ngOriginalError) {
      error = error.ngOriginalError;
    }

    // We can handle messages and Error objects directly.
    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    // If it's http module error, extract as much information from it as we can.
    if (error instanceof HttpErrorResponse) {
      // The `error` property of http exception can be either an `Error` object, which we can use directly...
      if (error.error instanceof Error) {
        return error.error;
      }

      // ... or an`ErrorEvent`, which can provide us with the message but no stack...
      if (error.error && error.error.message !== undefined) {
        return error.error.message;
      }

      // ...or the request body itself, which we can use as a message instead.
      if (typeof error.error === 'string') {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }

      // If we don't have any detailed information, fallback to the request message itself.
      return error.message;
    }

    // Skip if there's no error, and let user decide what to do with it.
    return null;
  }

  handleError(error): Error {
    this.captureError(error);
    throw error;
  }

  captureError(error): void {
    const extractedError = this.extractError(error) || 'Handled unknown error';

    if (environment.sentry.DSN.length > 0) {
      Sentry.captureException(extractedError);
    } else {
      console.error(extractedError);
    }
  }
}
