import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ExtraRadioQuestion,
  ExtraType,
  RadioQuestionParams
} from '../../../forms/form/controls/radio-control/radio-question.model';
import { InputType } from '../../../forms/form/controls/input-control/input-question.model';
import { Materialize } from '../../../materialize';
import { AnswerOption } from '../../../forms/form/answer-option.model';
import { ProblemsMapperService } from '../problems-mapper/problems-mapper.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

declare const M: Materialize;

@Component({
  selector: 'app-edit-radio',
  templateUrl: './edit-radio.component.html',
  styleUrls: ['./edit-radio.component.scss']
})
export class EditRadioComponent implements OnInit {
  @Input() control: RadioQuestionParams;
  @Input() key: string;
  @Input() isPMSProblem: boolean;
  @Output() uiChange = new EventEmitter();
  extraEnabled: boolean;
  private hiddenExtra: ExtraRadioQuestion;

  constructor(public mapperService: ProblemsMapperService) {}

  ngOnInit(): void {
    this.extraEnabled =
      this.control.extra !== null && this.control.extra !== undefined;
    if (this.extraEnabled) {
      this.hiddenExtra = this.control.extra;
    }
  }

  onExtraChanged($event): void {
    if (this.extraEnabled) {
      this.control.extra =
        this.hiddenExtra !== undefined
          ? this.hiddenExtra
          : ({
              value: null,
              type: ExtraType.Input,
              hint: '',
              options: [],
              other: true,
              optional: true,
              input_type: InputType.Name,
              popup_title: 'Please select'
            } as ExtraRadioQuestion);
    } else {
      this.hiddenExtra = this.control.extra;
      this.control.extra = undefined;
    }
    this.uiChange.emit();
  }

  onDefaultChange(): void {
    if (this.control.default === 'true') {
      this.control.default = true;
    }
    if (this.control.default === 'false') {
      this.control.default = false;
    }
  }

  onExtraValueChange(): void {
    if (this.control.extra) {
      if (this.control.extra.value === 'true') {
        this.control.extra.value = true;
      }
      if (this.control.extra.value === 'false') {
        this.control.extra.value = false;
      }
    }
  }

  onExtraHintChanged(): void {
    if (this.control.extra) {
      if (this.control.extra.hint === '') {
        this.control.extra.hint = null;
      }
      if (this.control.extra.type === ExtraType.MultiSelect) {
        this.control.extra.popup_title = this.control.extra.hint;
      }
    }
  }

  onUIChanged(): void {
    this.uiChange.emit();
  }

  onDeleteOption(optionIndex: number): void {
    this.control.options.splice(optionIndex, 1);
  }

  onDeleteExtraOption(optionIndex: number): void {
    if (this.control.extra) {
      this.control.extra.options.splice(optionIndex, 1);
    }
  }

  onAddOption(): void {
    if (this.control.options == null) {
      this.control.options = [];
    }
    this.control.options.push({
      name: '',
      value: '',
      pms_id: undefined,
      pms_name: undefined
    } as AnswerOption);
    this.onUIChanged();
  }

  onAddExtraOption(): void {
    if (this.control.extra.options == null) {
      this.control.extra.options = [];
    }
    this.control.extra.options.push({
      name: '',
      value: '',
      pms_id: undefined,
      pms_name: undefined
    } as AnswerOption);
    this.onUIChanged();
  }

  drop(event: CdkDragDrop<string[]>, controlOptions): void {
    moveItemInArray(controlOptions, event.previousIndex, event.currentIndex);
  }
}
