import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SingleInputDialogData } from './single-input-dialog.model';

@Component({
  selector: 'app-single-input-dialog',
  templateUrl: './single-input-dialog.component.html',
  styleUrls: ['./single-input-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleInputDialogComponent {
  form: FormGroup = new FormGroup({
    itemName: new FormControl('', Validators.required)
  });

  constructor(
    public dialogRef: MatDialogRef<SingleInputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) readonly data: SingleInputDialogData
  ) {}

  createItem(): void {
    if (!this.form.valid) {
      return;
    }

    this.dialogRef.close(this.form.get('itemName').value);
  }
}
