import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrawingCanvasComponent } from './drawing-canvas.component';
import { DrawingCanvasMenuComponent } from './drawing-canvas-menu/drawing-canvas-menu.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [DrawingCanvasComponent, DrawingCanvasMenuComponent],
  imports: [CommonModule, MatSliderModule, MatButtonModule],
  exports: [DrawingCanvasComponent, DrawingCanvasMenuComponent]
})
export class DrawingCanvasModule {}
