import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import Quill from 'quill';
import { TableBlockEmbed } from '../custom-blocks';
import { QuillModules } from 'ngx-quill';

const QuillAlignClasses = Quill.import('attributors/style/align');

@Component({
  selector: 'app-html-view',
  templateUrl: './html-view.component.html',
  styleUrls: ['./html-view.component.scss', './../html-unify-styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HtmlViewComponent {
  @Input()
  content: string;

  modules: QuillModules = {
    clipboard: {
      matchVisual: false
    }
  };

  constructor() {
    Quill.register(QuillAlignClasses, false);
    Quill.register(TableBlockEmbed, true);
  }
}
