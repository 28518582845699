<div class="row">
  <div class="col s5">
    <div class="row">
      <div class="col s1">
        <div class="valign-wrapper">
          <a (click)="onDelete()" class="delete-icon center-align"
            ><i class="material-icons">delete_forever</i></a>
        </div>
      </div>
      <div class="input-field col s10">
        <input placeholder="Name"
               id="name"
               type="text"
               class="validate"
               [(ngModel)]="answerOption.name" />
        <label for="name">Name</label>
      </div>
      <div class="col s1">
        <div class="valign-wrapper">
          <a (click)="onCopyNameToValue()" class="copy-icon center-align"
            ><i class="material-icons">arrow_forward</i></a>
        </div>
      </div>
    </div>
  </div>
  <div class="input-field col s3">
    <input placeholder="Value (key)"
           id="value"
           type="text"
           class="validate"
           [(ngModel)]="answerOption.value"
           (ngModelChange)="onValueChange()" />
    <label for="value">Value (key)</label>
  </div>
  <div class="input-field col s1"
       *ngIf="isPMSProblem">
    <input placeholder="PMS ID"
           id="pms_id"
           type="text"
           class="validate"
           [(ngModel)]="answerOption.pms_id" />
    <label for="pms_id">PMS ID</label>
  </div>
  <div class="input-field col s2"
       *ngIf="isPMSProblem">
    <input placeholder="PMS Name"
           id="pms_name"
           type="text"
           class="validate"
           [(ngModel)]="answerOption.pms_name" />
    <label for="pms_name">PMS Name</label>
  </div>
  <div class="col s1"
       *ngIf="isPMSProblem">
    <a
      class="btn btn-primary btn-circle right"
      (click)="
        mapperService.showMapperForAnswerOption(answerOption, questionKey)
      "
    >
      <i class="material-icons">search</i>
    </a>
  </div>
</div>
