import { Component, Input, OnInit } from '@angular/core';
import { DeviceService } from '../../common/device-service/device.service';
import { PatientPortalService } from '../../common/patient-portal/patient-portal.service';

@Component({
  selector: 'app-all-completed',
  templateUrl: './all-completed.component.html',
  styleUrls: ['./all-completed.component.scss']
})
export class AllCompletedComponent implements OnInit {
  @Input() hasUserCheckedIn = false;
  hideDescriptionLabel = false;

  constructor(
    private readonly deviceService: DeviceService,
    private readonly patientPortalService: PatientPortalService
  ) {}

  ngOnInit(): void {
    this.hideDescriptionLabel =
      this.deviceService.isInApp() ||
      this.deviceService.isInKiosk() ||
      this.patientPortalService.isInPatientPortal();
  }
}
