import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SessionTimeoutService } from '../session-timeout/session-timeout.service';

@Component({
  selector: 'app-inactivity-bar',
  templateUrl: './inactivity-bar.component.html',
  styleUrls: ['./inactivity-bar.component.scss']
})
export class InactivityBarComponent implements OnInit {
  @Input()
  hideBar = false;

  sessionTime = this.sessionTimeoutService.getSessionTimeout() / 60;

  @Output()
  hideBarChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private sessionTimeoutService: SessionTimeoutService) {}

  hide(): void {
    this.hideBar = true;
    this.hideBarChange.emit(this.hideBar);
    this.sessionTimeoutService.barHidden$.next(true);
  }

  ngOnInit(): void {
    this.hideBarAfterMinute();
  }

  private hideBarAfterMinute(): void {
    setTimeout(() => {
      this.hide();
    }, 60000);
  }
}
