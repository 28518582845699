import { API_URL, CLIENT_ID, CLIENT_SECRET, SERVER_URL } from './app.config';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';

import { AcceptLanguageHeaderInterceptor } from './common/interceptors/accept-language-header-interceptor.service';
import { AppCommonModule } from './common/common.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ConsentsModule } from './consents/consents.module';
import { DeviceService } from './common/device-service/device.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ErrorMessageService } from './common/error-message/error-message.service';
import { KioskModule } from './kiosk/kiosk.module';
import { LOCATION_INITIALIZED } from '@angular/common';
import { ModentoClientHeaderInterceptor } from './common/interceptors/modento-client-header-interceptor.service';
import { PatientFormsModule } from './forms/patient-forms.module';
import { SentryModule } from './sentry/sentry.module';
import { ToolsModule } from './tools/tools.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { VERSION } from '../environments/version';
import { environment } from '../environments/environment';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(
    http,
    './assets/i18n/',
    '.json?v=' + VERSION.hash
  );
}

// inspiration from https://github.com/ngx-translate/core/issues/517
export function appInitializerFactory(
  translate: TranslateService,
  injector: Injector
): () => Promise<void> {
  return () =>
    new Promise<void>((resolve: any) => {
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null)
      );
      locationInitialized.then((data) => {
        translate.addLangs(['es']);
        translate.setDefaultLang('en');
        const langToSet =
          translate
            .getLangs()
            .find((lang) => lang === translate.getBrowserLang()) || 'en';
        translate.use(langToSet).subscribe(
          () => {
            console.log(`Successfully initialized '${langToSet}' language.'`);
          },
          (err) => {
            console.error(
              `Problem with '${langToSet}' language initialization.'`
            );
          },
          () => {
            resolve(null);
          }
        );
      });
    });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppCommonModule,
    PatientFormsModule,
    ConsentsModule,
    KioskModule,
    ToolsModule,
    BrowserModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    SentryModule,
    BrowserAnimationsModule,
    DragDropModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    },
    { provide: SERVER_URL, useValue: environment.serverUrl },
    { provide: API_URL, useValue: environment.apiUrl },
    { provide: CLIENT_ID, useValue: environment.clientId },
    { provide: CLIENT_SECRET, useValue: environment.clientSecret },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ModentoClientHeaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AcceptLanguageHeaderInterceptor,
      multi: true
    },
    DeviceService,
    ErrorMessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
