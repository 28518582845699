<div class="container">
  <div class="row mt-2">
    <div class="col s12">
      <form role="form"
            [formGroup]="formGroup">
        <br />
        <app-radio-control [question]="signAsQuestion"
                           [data]="initValue"
                           (output)="onFormDataChange('signature_entity', $event)"
                           (control)="onFormControlSet('signature_entity', $event)">
        </app-radio-control>
        <app-signature-control [question]="signatureQuestion"
                               [data]="null"
                               (output)="onFormDataChange('signature', $event)"
                               (control)="onFormControlSet('signature', $event)"
                               [note]="'FORMS.CONTROLS.SIGNATURE.NOTE' | translate">
        </app-signature-control>
      </form>
      <p>
        <label class="for-checkbox"
               for="copy_patient">
          <input id="copy_patient"
                 type="checkbox"
                 class="filled-in"
                 [name]="'copy_patient'"
                 [(ngModel)]="output.copy_patient" />
          <span>{{ 'CONSENTS.PATIENT_SIGN.COPY_PATIENT' | translate }}</span>
        </label>
      </p>
    </div>
  </div>
</div>
