import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppCommonModule } from '../../../../app/common/common.module';
import { ClearentCardPaymentModule } from '../../../../app/common/clearent/clearent-card-payment/clearent-card-payment.module';
import { CommonModule } from '@angular/common';
import { ConsentPaymentPlanSignComponent } from './consent-payment-plan-sign.component';
import { DrawingCanvasModule } from '../../drawing-canvas/drawing-canvas.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { NgModule } from '@angular/core';
import { PatientFormsModule } from '../../../../app/forms/patient-forms.module';
import { ReactiveComponentModule } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ConsentPaymentPlanSignComponent],
  exports: [ConsentPaymentPlanSignComponent],
  imports: [
    CommonModule,
    AppCommonModule,
    PatientFormsModule,
    DrawingCanvasModule,
    ReactiveComponentModule,
    MatCheckboxModule,
    MatRadioModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ClearentCardPaymentModule
  ]
})
export class ConsentPaymentPlanSignComponentModule {}
