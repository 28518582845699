import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {
  FORMAT_DISPLAY = 'display';
  FORMAT_E164 = 'e164';

  transform(value: string, format: string = this.FORMAT_DISPLAY): string {
    if (format === null || format === undefined) {
      format = this.FORMAT_DISPLAY;
    }

    return this.transformWithFormat(value, format);
  }

  transformWithFormat(value: string, format: string): string {
    if (value === null || value === undefined) {
      return value;
    }
    switch (format) {
      case this.FORMAT_DISPLAY:
        if (
          !(
            (value.length === (12 as number) && value.startsWith('+1')) ||
            (value.length === (10 as number) &&
              !value.startsWith('+1') &&
              !isNaN(Number(value)))
          )
        ) {
          return value;
        }
        const prefixLen: number = value.length === (12 as number) ? 2 : 0;
        const newValue: string =
          '(' +
          value.substr(prefixLen, 3) +
          ') ' +
          value.substr(3 + prefixLen, 3) +
          ' ' +
          value.substr(6 + prefixLen, value.length - 1);
        return newValue;
      case this.FORMAT_E164:
        let newE164Value;
        newE164Value = value.replace(/[^\d\+]/g, '');
        newE164Value = newE164Value.startsWith('+1')
          ? newE164Value
          : '+1' + newE164Value;
        return newE164Value;
      default:
        return null;
    }
  }
}
