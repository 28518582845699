import { Component, Input, OnInit } from '@angular/core';
import { Question } from '../../form/question.model';
import { LiteEditorService } from '../../../+lite-editor/services/lite-editor.service';

@Component({
  selector: 'app-lite-edit-control',
  templateUrl: './lite-edit-control.component.html',
  styleUrls: ['./lite-edit-control.component.scss']
})
export class LiteEditControlComponent {
  @Input() question: Question;

  constructor(public liteEditorService: LiteEditorService) {}
}
