import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MenuItemComponent } from './menu-item.component';

@NgModule({
  declarations: [MenuItemComponent],
  imports: [CommonModule, MatMenuModule],
  exports: [MenuItemComponent],
  providers: []
})
export class MenuItemModule {}
