<ng-container *ngIf="!showGetApp">
  <div *ngIf="isLoading"
       class="screen-full-modal"
       (click)="(false)">
    <app-spinner class="screen-centered"></app-spinner>
  </div>
  <div class="section no-pad-bot"
       [class.welcome--no-logo]="!practice?.big_logo"
       *ngIf="!isLoading">
    <div class="container">
      <div class="row">
        <div *ngIf="practice"
             class="col s12 center-align">
          <img *ngIf="practice.big_logo"
               src="{{ practice.big_logo }}"
               class="practice-logo" />
          <h4>
            <span class="welcome">{{
              'KIOSK.MAIN.WELCOME' | translate
              }}</span>
            <span class="practice-name"> {{ practice.name }}</span>
          </h4>
        </div>
      </div>
      <div class="row">
        <div class="col s8 offset-s2 center-align">
          <button type="button"
                  class="btn btn-primary btn-x-large"
                  (click)="onCheckIn()">
            {{ 'KIOSK.MAIN.CHECK_IN' | translate }}
          </button>
        </div>
      </div>
      <div class="row"
           *ngIf="practice.published && practice.app_url">
        <div class="col s6 offset-s3 center-align mt-3">
          <button type="button"
                  class="btn btn-primary"
                  (click)="onGetApp()">
            {{ 'KIOSK.MAIN.GET_APP' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="row">
      <div class="col s12 center-align">
        <span>
          <a href="https://www.dentalintel.com/terms" target="_blank">{{ 'KIOSK.MAIN.TERMS' | translate }}</a>
          {{ 'KIOSK.MAIN.AND' | translate }}
          <a href="https://www.dentalintel.com/privacy-policy" target="_blank">{{
            'KIOSK.MAIN.PRIVACY' | translate
            }}</a>
        </span>
      </div>
    </div>
  </div>
  <div class="device-info">
    {{ versionInfo }}
    <br />
    {{ deviceName }}
    <app-connection-status-badge *ngIf="!isLoading">
    </app-connection-status-badge>
    <br />
    <a
      class="btn btn-primary btn-circle right mt-1"
      title="{{ 'KIOSK.MAIN.RELOAD_HINT' | translate }}"
      (click)="onReload()"
    >
      <i class="material-icons">autorenew</i>
    </a>
  </div>
</ng-container>

<app-get-app *ngIf="showGetApp"
             (closeClick)="showGetApp = false"></app-get-app>
