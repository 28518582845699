import { Component, OnInit } from '@angular/core';
import { TermsQuestion } from './terms-question.model';
import { AbstractControlComponent } from '../abstract-control/abstract-control.component';
import { FormControl } from '@angular/forms';
import {
  ExtraRadioQuestion,
  ExtraType,
  RadioQuestion,
  RadioQuestionParams
} from '../radio-control/radio-question.model';
import { InputType } from '../input-control/input-question.model';
import { TranslateService } from '@ngx-translate/core';
import { nonEmpty } from '../../../../common/utils/string-utils.helper';
import { CustomValidators } from '../../../../common/validators/custom-validators.helper';
import { PlaceholdersService } from '../../../../common/placeholders-service/placeholders.service';

@Component({
  selector: 'app-terms-control',
  templateUrl: './terms-control.component.html',
  styleUrls: ['./terms-control.component.scss']
})
export class TermsControlComponent
  extends AbstractControlComponent<any, TermsQuestion, FormControl>
  implements OnInit {
  acceptDeclineQuestion: RadioQuestion;
  acceptDeclineValue: object;
  allowAcceptDecline: boolean;

  constructor(
    private placeholderService: PlaceholdersService,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.replacePlaceholders();
    this.allowAcceptDecline =
      this.question.optional && nonEmpty(this.question.control.decline_text);
    if (!this.allowAcceptDecline) {
      const value =
        typeof this.data === 'object'
          ? this.data.value !== undefined
            ? this.data.value
            : this.data.agreed !== undefined
            ? this.data.agreed
            : false
          : false;
      this.formControl = this.question.optional
        ? new FormControl(value)
        : new FormControl(value, CustomValidators.isTrue());
      this.control.emit(this.formControl);
    } else {
      this.acceptDeclineQuestion = this.toAcceptDeclineQuestion();
      // we will use radio control to confirm consent, which reports control on it's own
    }
  }

  protected emitOutput(): void {
    if (this.acceptDeclineQuestion) {
      // emit like from radio
      this.output.emit(this.acceptDeclineValue);
    } else {
      // default emit
      this.output.emit({ value: this.formControl.value, extra: null });
    }
  }

  onAcceptDeclineFormControlSet(acceptDeclineControl: FormControl): void {
    this.formControl = acceptDeclineControl;
    this.control.emit(this.formControl);
  }

  onAcceptDeclineDataChange(value: any): void {
    this.acceptDeclineValue = value;
    this.emitOutput();
  }

  private replacePlaceholders(): void {
    if (this.question.control.text) {
      this.question.control.text = this.placeholderService.replacePlaceholders(
        this.question.control.text
      );
    }
    if (this.question.control.html_text) {
      this.question.control.html_text = this.placeholderService.replacePlaceholders(
        this.question.control.html_text
      );
    }
  }

  private toAcceptDeclineQuestion(): RadioQuestion {
    // check if we should show extra question when user choose "decline" option
    const askDeclineReason =
      this.question.control.ask_decline_reason !== undefined &&
      this.question.control.ask_decline_reason !== null
        ? this.question.control.ask_decline_reason
        : true;
    const extra: ExtraRadioQuestion = askDeclineReason
      ? {
          value: false,
          type: ExtraType.Input,
          hint: this.translateService.instant(
            'FORMS.CONTROLS.CONSENT.DECLINE_REASON'
          ),
          optional: true,
          input_type: InputType.Name
        }
      : undefined;

    return {
      key: this.question.key,
      type: 'radio',
      section: null,
      title: this.translateService.instant('FORMS.CONTROLS.CONSENT.SELECT'),
      optional: false,
      control: {
        options: [
          {
            name: this.question.control.agree_text,
            value: true
          },
          {
            name: this.question.control.decline_text,
            value: false
          }
        ],
        extra
      } as RadioQuestionParams
    } as RadioQuestion;
  }

  onAgreeCheckboxChanged(): void {
    this.emitOutput();
  }
}
