import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'app-choose-later',
  templateUrl: './choose-later.component.html',
  styleUrls: ['choose-later.component.scss']
})
export class ChooseLaterComponent implements OnInit {
  @Input() paymentOption;
  @Input() active = false;
  @Output() activated: EventEmitter<null> = new EventEmitter<null>();

  constructor() {}

  ngOnInit(): void {}

  onClick(): void {
    this.active = true;
    this.activated.emit(null);
  }
}
