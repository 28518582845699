import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CreditCardDetail } from '../../clearent-card-payment/clearent-card-payment.types';

@Component({
  selector: 'app-payment-card-detail',
  templateUrl: './payment-card-detail.component.html',
  styleUrls: ['./payment-card-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentCardDetailComponent {
  @Input() card!: CreditCardDetail;
}
