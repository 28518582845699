import { Component, OnInit } from '@angular/core';
import { CookieOptions, CookieService } from 'ngx-cookie';
import { isEmpty } from '../../common/utils/string-utils.helper';
import { PracticeService } from '../../common/practice';
import { KioskDeviceService } from '../kiosk-device/kiosk-device.service';
import { RegistrationResult } from '../register/registration-result.model';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-kiosk-entry',
  templateUrl: './kiosk-entry.component.html',
  styleUrls: ['./kiosk-entry.component.scss']
})
export class KioskEntryComponent implements OnInit {
  private static COOKIE_OPTIONS = {
    expires: new Date(3000, 1),
    secure: environment.production,
    storeUnencoded: false
  } as CookieOptions;
  needRegister: boolean;
  oldDeviceId: string; // if upgrading from old Android kiosk
  oldDeviceName: string; // if upgrading from old Android kiosk

  constructor(
    private cookieService: CookieService,
    private practiceService: PracticeService,
    private kioskDeviceService: KioskDeviceService
  ) {}

  ngOnInit(): void {
    this.needRegister =
      isEmpty(this.kioskDeviceService.getDeviceId()) ||
      isEmpty(this.practiceService.getPracticeGuid());
    if (this.needRegister) {
      this.oldDeviceId = this.cookieService.get('old_device_id');
      this.oldDeviceName = this.cookieService.get('old_device_name');
    }
  }

  onRegistrationSuccess(result: RegistrationResult): void {
    this.cookieService.put(
      'practice_guid',
      result.practice_guid,
      KioskEntryComponent.COOKIE_OPTIONS
    );
    this.kioskDeviceService.setDeviceId(result.device_id);
    this.kioskDeviceService.setDeviceName(result.device_name);
    this.kioskDeviceService.setKioskDeviceConfig(result.config);
    this.practiceService.setPracticeGuid(result.practice_guid);
    this.needRegister = false;
  }
}
