<div *ngIf="isSubmitting"
     class="screen-full-modal"
     (click)="(false)">
  <app-spinner class="screen-centered"></app-spinner>
</div>

<div class="section header">
  <div class="container">
    <div class="row">
      <div class="valign-wrapper">
        <div class="col s4 m4">
          <a
            class="btn btn-primary btn-circle left"
            *ngIf="formSpec"
            (click)="onCloseFormSpecEdit()"
          >
            <i class="material-icons">close</i>
          </a>
        </div>
        <div class="col s4 m4">
          <h5 class="center header-text text-primary">
            <i class="material-icons" *ngIf="errors.length > 0">warning</i>
            FORMS EDITOR
          </h5>
        </div>
        <div class="col s4 m4">
          <ng-container *ngIf="formSpec">
            <a
              class="btn btn-primary btn-circle right"
              title="Preview current form live"
              (click)="onPreviewSpec()"
            >
              <i class="material-icons">ballot</i>
            </a>
            <a
              class="btn btn-primary btn-circle right"
              title="Preview JSON"
              (click)="onPreviewJSON()"
            >
              <i class="material-icons">search</i>
            </a>
            <a
              class="btn btn-primary btn-circle right"
              title="Download as JSON file"
              (click)="onDownloadSpec()"
            >
              <i class="material-icons">get_app</i>
            </a>
            <a
              class="btn btn-primary btn-circle right"
              title="Save to practice"
              *ngIf="editingForPractice"
              (click)="onTrySaveForm()"
            >
              <i class="material-icons">save</i>
            </a>
            <a
              class="btn btn-primary btn-circle right"
              *ngIf="editingForPractice && formSpec.type === 'health_history'"
              (click)="onShowMedicalConditions()"
            >
              <i class="material-icons">local_hospital</i>
            </a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<div #sectionBody
     class="section no-pad-bot body"
     cdkScrollable
     [class.form-spec]="formSpec">
  <div class="container">
    <div class="row"
         *ngIf="!formSpec">
      <div class="col s12">
        <p class="text-primary">Load forms JSON file</p>
        <div class="file-field input-field">
          <div class="btn">
            <span>LOAD</span>
            <input type="file"
                   id="form_json"
                   name="form_json"
                   (change)="onFileChange($event)" />
          </div>
          <div class="file-path-wrapper">
            <input class="file-path validate"
                   type="text" />
          </div>
        </div>
      </div>
    </div>
    <app-form-spec-picker *ngIf="!formSpec"
                          (formSpecPicked)="onFormSpecPicked($event)">
    </app-form-spec-picker>
    <ng-container *ngIf="formSpec">
      <app-existence (created)="onFormSpecLoaded()"></app-existence>
      <div class="row"
           *ngIf="editingForPractice">
        <div class="col s12">
          <p class="text-primary">
            <b>EDITING FOR PRACTICE</b>
            <br />
            {{ practice?.name }} ({{ practice?.guid }})
            <br />
            <b>{{ formSpec.title }}</b>
            {{ formSpec.for_minor ? 'MINOR' : '' }} v{{
              formSpec.json_version
            }}
            Language: {{ formSpec.language }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <div #questionList
               cdkDropList
               class="questions__list"
               [cdkDropListData]="formSpec.spec"
               (cdkDropListDropped)="drop($event)">
            <div *ngFor="
                let question of formSpec.spec;
                index as i;
                trackBy: trackQuestionsBy
              "
                 class="questions__item"
                 cdkDrag
                 [cdkDragDisabled]="editQuestion.isExpanded">
              <app-edit-question [question]="question"
                                 [index]="i"
                                 #editQuestion
                                 (uiStructureChange)="onUIStructureChange()"
                                 (deleteQuestion)="onDeleteQuestion($event)"
                                 (cloneQuestion)="onCloneQuestion($event)"
                                 (sectionChange)="onSectionChange(question, $event)">
              </app-edit-question>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col s2">
          <button class="btn waves-effect waves-primary"
                  (click)="onAddQuestion()">
            <i class="material-icons">add</i>
          </button>
        </div>
        <div class="col s2">
          <a
            class="btn waves-effect waves-primary"
            (click)="onAddYesNoQuestion()"
            >+ YES/NO</a>
        </div>
        <div class="col s3">
          <a
            class="btn waves-effect waves-primary"
            (click)="onAddYesNoMultiQuestion()"
            >+ YES/NO with MULTI</a>
        </div>
        <div class="col s2">
          <a
            class="btn waves-effect waves-primary"
            (click)="onAddConsentQuestion()"
            >+ CONSENT</a>
        </div>
        <div class="col s3">
          <a
            class="btn waves-effect waves-primary"
            (click)="onAddSignatureQuestion()"
            >+ SIGNATURE</a>
        </div>
      </div>
      <ng-container *ngIf="errors.length > 0">
        <div class="row">
          <div class="col s12">
            <h4 class="text-primary">ERRORS</h4>
            <p class="text-error"
               *ngFor="let error of errors">{{ error }}</p>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<app-modal id="doctor-prompt"
           (modalPluginSet)="jsonPreviewModal = $event">
  <pre>
  {{ jsonPreview }}
  </pre>
</app-modal>

<app-modal (modalPluginSet)="conditionsPreviewModal = $event"
           *ngIf="formSpec && formSpec.type === 'health_history'">
  <h4>All medical conditions from PMS</h4>
  <p>PROBLEMS</p>
  <table>
    <thead>
      <tr>
        <th>PMS ID</th>
        <th>PMS Name</th>
      </tr>
    </thead>
    <tbody>
      <tr
          *ngFor="let condition of mapperService.getMedicalConditionsForType(0)">
        <td>{{ condition.pmsId }}</td>
        <td>{{ condition.pmsName }}</td>
      </tr>
    </tbody>
  </table>
  <p>ALLERGIES</p>
  <table>
    <thead>
      <tr>
        <th>PMS ID</th>
        <th>PMS Name</th>
      </tr>
    </thead>
    <tbody>
      <tr
          *ngFor="let condition of mapperService.getMedicalConditionsForType(1)">
        <td>{{ condition.pmsId }}</td>
        <td>{{ condition.pmsName }}</td>
      </tr>
    </tbody>
  </table>
  <p>MEDICATIONS</p>
  <table>
    <thead>
      <tr>
        <th>PMS ID</th>
        <th>PMS Name</th>
      </tr>
    </thead>
    <tbody>
      <tr
          *ngFor="let condition of mapperService.getMedicalConditionsForType(2)">
        <td>{{ condition.pmsId }}</td>
        <td>{{ condition.pmsName }}</td>
      </tr>
    </tbody>
  </table>
</app-modal>

<app-confirm-modal (modalPluginSet)="saveConfirmModal = $event"
                   (confirm)="onSaveSpec()">
  <p>
    Are you sure you want to save the form for:
    <b>{{ practice ? practice?.name : '' }}</b>? Change will be live immediately
    and cannot be reverted.
  </p>
</app-confirm-modal>

<app-problems-mapper *ngIf="mapperService.showMapper"
                     [questionKey]="mapperService.questionKey"
                     [question]="mapperService.question"
                     [answer]="mapperService.answerOption"
                     [bulkAnswers]="mapperService.bulkAnswerOptions">
</app-problems-mapper>
