<div *ngIf="isSubmitting"
     class="screen-full-modal"
     (click)="(false)">
  <app-spinner class="screen-centered"></app-spinner>
</div>

<div class="section header">
  <div class="container">
    <div class="row">
      <div class="valign-wrapper">
        <div class="col offset-s3 s6">
          <h5 class="center header-text text-primary">
            CREATE NEW CONSENT SPEC
          </h5>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="section no-pad-bot body">
  <div class="container">
    <div class="row">
      <div class="input-field col s6">
        <input placeholder="Title"
               id="title"
               type="text"
               class="validate"
               [(ngModel)]="newConsent.title" />
        <label for="title">Title *</label>
      </div>
      <div class="input-field col s6">
        <input placeholder="consent_xyz (empty to create from Title)"
               id="type"
               type="text"
               class="validate"
               [(ngModel)]="newConsent.type" />
        <label for="title">Type</label>
      </div>
    </div>
    <div class="row">
      <div class="input-field col s6">
        <input placeholder="Language (en, es)"
               id="language"
               type="text"
               class="validate"
               [(ngModel)]="newConsent.language" />
        <label for="title">Language *</label>
      </div>
      <div class="input-field col s6">
        <label>
          <input type="checkbox"
                 class="filled-in"
                 [(ngModel)]="newConsent.for_minor"
                 [value]="true" />
          <span>MINOR version</span>
        </label>
      </div>
    </div>
    <div class="row">
      <div class="input-field col s6">
        <input placeholder="Category"
               id="category"
               type="text"
               class="validate"
               [(ngModel)]="newConsent.category" />
        <label for="title">Category</label>
      </div>
      <div class="input-field col s6">
        <input placeholder="Sub-category"
               id="sub_category"
               type="text"
               class="validate"
               [(ngModel)]="newConsent.sub_category" />
        <label for="title">Sub-category</label>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col offset-s3 s6 center-align">
        <button class="btn waves-effect waves-primary"
                (click)="onCreateConsent()">
          CREATE
        </button>
      </div>
    </div>
  </div>
</div>
