<div class="section header page-header">
  <div class="page-header__container">
    <div class="page-header__left-corner">
      <ng-content select="[left-corner]"></ng-content>
      <a
        class="btn btn-primary btn-circle waves-effect waves-primary left-corner__back-btn"
        *ngIf="canGoBack"
        (click)="onBack()"
      >
        <i class="material-icons">{{ backIconName }}</i>
      </a>
    </div>
    <div class="page-header__title">
      <p class="center header-text">
        <span class="form-title"
              [class.no-subtitle]="subtitle == null || subtitle.length === 0">{{ title }}</span>
        <ng-container *ngIf="subtitle != null && subtitle.length > 0">
          <span class="group-title">{{ subtitle }}</span>
        </ng-container>
      </p>
    </div>
    <div class="page-header__right-corner">
      <a
        class="btn btn-primary btn-circle waves-effect waves-primary right-corner__next-btn"
        *ngIf="canGoNext"
        (click)="onNext()"
      >
        <i class="material-icons">chevron_right</i>
      </a>
      <ng-container *ngIf="counter$ | async as counter">
        <div class="counter-button"
             *ngIf="showCounter && barHidden$ | async">
          <svg width="20"
               height="20"
               viewBox="0 0 20 20"
               xmlns="http://www.w3.org/2000/svg">
            <g fill="none"
               fill-rule="evenodd">
              <rect fill="#FFEEE4"
                    width="20"
                    height="20"
                    rx="4" />
              <path d="M9.063 5.5a.563.563 0 0 1 .065 1.121l-.066.004h-1.75a.313.313 0 0 0-.308.262L7 6.937v6.125c0 .156.113.285.262.309l.05.004h1.75a.563.563 0 0 1 .066 1.121l-.066.004h-1.75a1.438 1.438 0 0 1-1.434-1.343l-.003-.095V6.939c0-.763.593-1.386 1.343-1.435l.095-.003h1.75zm3.407 1.868.053.047 2.187 2.187c.02.02.038.041.055.063l.04.063.03.063.02.06.016.083.004.066-.002.042-.01.07-.016.064-.025.062-.03.055-.04.059-.042.046-2.187 2.187a.562.562 0 0 1-.843-.742l.047-.053 1.226-1.227h-3.89a.563.563 0 0 1-.066-1.122l.066-.004h3.891L11.727 8.21a.563.563 0 0 1-.047-.742l.047-.053a.563.563 0 0 1 .743-.047z"
                    fill="#C8500E"
                    fill-rule="nonzero" />
            </g>
          </svg>
          <span>
            {{counter}}
          </span>
        </div>
      </ng-container>
      <ng-content select="[right-corner]"></ng-content>
    </div>
  </div>
</div>
