import { MedicalCondition } from '../../medical-condition.model';
import { Question } from '../../../forms/form/question.model';
import { AnswerOption } from '../../../forms/form/answer-option.model';
import { Injectable } from '@angular/core';
import { OnboardingToolsService } from '../../onboarding-tools-service/onboarding-tools.service';
import { AlertsService } from '../../../common/alerts/alerts.service';
import { getConditionTypeForProblemQuestion } from '../questions-factory.helper';

@Injectable({
  providedIn: 'root'
})
export class ProblemsMapperService {
  private medicalConditions: MedicalCondition[] = [];
  private groupedMedicalConditions: object = {};
  showMapper = false;
  question: Question;
  answerOption: AnswerOption;
  questionKey: string;
  isSubmitting: boolean;
  bulkAnswerOptions: AnswerOption[];

  constructor(
    private onboardingToolsService: OnboardingToolsService,
    private alertsService: AlertsService
  ) {}

  setMedicalConditions(conditions: MedicalCondition[]): void {
    this.medicalConditions = conditions;
    this.groupMedicalConditions();
  }

  clearMedicalConditions(): void {
    this.medicalConditions = [];
    this.groupMedicalConditions();
  }

  getMedicalConditions(): MedicalCondition[] {
    return this.medicalConditions;
  }

  private groupMedicalConditions(): void {
    this.groupedMedicalConditions = {
      0: this.medicalConditions
        .filter((c) => c.type === 0)
        .sort((c1, c2) => c1.pmsName.localeCompare(c2.pmsName)),
      1: this.medicalConditions
        .filter((c) => c.type === 1)
        .sort((c1, c2) => c1.pmsName.localeCompare(c2.pmsName)),
      2: this.medicalConditions
        .filter((c) => c.type === 2)
        .sort((c1, c2) => c1.pmsName.localeCompare(c2.pmsName))
    };
  }

  getMedicalConditionsForType(type: number): MedicalCondition[] {
    return this.groupedMedicalConditions[type];
  }

  async create(name: string, questionKey: string): Promise<MedicalCondition> {
    this.isSubmitting = true;
    try {
      const condition = await this.onboardingToolsService.createMedicalCondition(
        {
          pmsName: name,
          type: getConditionTypeForProblemQuestion(questionKey)
        } as MedicalCondition
      );
      this.getMedicalConditions().push(condition);
      this.alertsService.showInfo('Medical condition created');
      return Promise.resolve(condition);
    } catch (e) {
      this.alertsService.showApiError(e);
      return Promise.resolve(undefined);
    } finally {
      // stop spinner
      this.isSubmitting = false;
    }
  }

  showMapperForQuestion(question: Question): void {
    this.question = question;
    this.questionKey = question.key;
    this.answerOption = undefined;
    this.bulkAnswerOptions = undefined;
    this.showMapper = true;
  }

  showMapperForAnswerOption(option: AnswerOption, key: string): void {
    this.question = undefined;
    this.questionKey = key;
    this.answerOption = option;
    this.bulkAnswerOptions = undefined;
    this.showMapper = true;
  }

  performDefaultMappingForAnswers(options: AnswerOption[], key: string): void {
    this.question = undefined;
    this.questionKey = key;
    this.answerOption = undefined;
    this.bulkAnswerOptions = options;
    this.showMapper = true;
  }

  hideMapper(): void {
    this.question = undefined;
    this.questionKey = undefined;
    this.answerOption = undefined;
    this.bulkAnswerOptions = undefined;
    this.showMapper = false;
  }
}
