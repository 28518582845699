import { Injectable } from '@angular/core';
import { nonEmpty } from '../../common/utils/string-utils.helper';
import { KioskDeviceConfig } from './kiosk-device-config.model';
import { CookieOptions, CookieService } from 'ngx-cookie';
import { VERSION } from '../../../environments/version';
import { environment } from '../../../environments/environment';
import { sessionStorageSetItemOptional } from '../../common/utils/storage-utils.helper';

@Injectable({
  providedIn: 'root'
})
export class KioskDeviceService {
  private static COOKIE_OPTIONS = {
    expires: new Date(3000, 1),
    secure: environment.production,
    storeUnencoded: false
  } as CookieOptions;

  private deviceId: string;
  private deviceName: string;
  private config: KioskDeviceConfig;

  constructor(private cookieService: CookieService) {
    this.deviceId = this.cookieService.get('device_id');
    this.deviceName = this.cookieService.get('device_name');
    const storedConfig = JSON.parse(sessionStorage.getItem('kioskConfig'));
    if (storedConfig) {
      this.config = storedConfig;
    }
  }

  setDeviceId(deviceId: string): void {
    this.deviceId = deviceId;
    this.cookieService.put(
      'device_id',
      deviceId,
      KioskDeviceService.COOKIE_OPTIONS
    );
  }

  getDeviceId(): string {
    return this.deviceId;
  }

  setDeviceName(deviceName: string): void {
    this.deviceName = deviceName;
    this.cookieService.put(
      'device_name',
      deviceName,
      KioskDeviceService.COOKIE_OPTIONS
    );
  }

  getDeviceName(): string {
    return this.deviceName;
  }

  isInKiosk(): boolean {
    return nonEmpty(this.deviceId);
  }

  setKioskDeviceConfig(config: KioskDeviceConfig): void {
    this.config = config;
    sessionStorageSetItemOptional('kioskConfig', JSON.stringify(this.config));

    // Change name of device if changed on backend
    if (
      this.config.device &&
      this.config.device.device_name !== this.deviceName
    ) {
      this.setDeviceName(this.config.device.device_name);
    }

    this.checkForceUpdate();
  }

  checkForceUpdate(): void {
    if (
      nonEmpty(this.config.forms_version_hash) &&
      this.config.forms_version_hash !== VERSION.hash
    ) {
      location.reload();
    }
  }

  getConfig(remoteOnly = false): KioskDeviceConfig {
    return this.config !== undefined || remoteOnly
      ? this.config
      : ({
          show_video: false,
          timeout: 600
        } as KioskDeviceConfig);
  }

  unregister(): void {
    delete this.deviceId;
    delete this.deviceName;
    delete this.config;
    this.cookieService.removeAll(KioskDeviceService.COOKIE_OPTIONS);
    sessionStorage.removeItem('kioskConfig');
    location.reload();
  }
}
