import { Component, Input } from '@angular/core';
import { InputQuestionParams } from '../../../forms/form/controls/input-control/input-question.model';

@Component({
  selector: 'app-edit-input',
  templateUrl: './edit-input.component.html',
  styleUrls: ['./edit-input.component.scss']
})
export class EditInputComponent {
  @Input() control: InputQuestionParams;
  @Input() key: string;

  onHintChanged(): void {
    if (this.control.hint === '') {
      this.control.hint = null;
    }
  }
}
