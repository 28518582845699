import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveComponentModule } from '@ngrx/component';
import { SkeletonModule } from '../../skeleton/skeleton.module';
import { ClearentCardPaymentComponent } from './clearent-card-payment.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { PaymentCardDetailsModule } from '../payment-card-details/payment-card-details.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { VERSION } from '../../../../environments/version';

@NgModule({
  declarations: [ClearentCardPaymentComponent],
  exports: [ClearentCardPaymentComponent],
  imports: [
    CommonModule,
    SkeletonModule,
    MatInputModule,
    MatFormFieldModule,
    TranslateModule,
    ReactiveComponentModule,
    ReactiveFormsModule,
    PaymentCardDetailsModule
  ]
})
export class ClearentCardPaymentModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(
      domSanitizer.bypassSecurityTrustResourceUrl(
        `./assets/payments.svg?v=${VERSION.hash}`
      )
    );
  }
}
