import { Question } from '../../question.model';
import { AnswerOption } from '../../answer-option.model';
import { InputType } from '../input-control/input-question.model';

export interface RadioQuestion extends Question {
  control?: RadioQuestionParams;
}

export interface RadioQuestionParams {
  options: AnswerOption[];
  default?: any;
  extra?: ExtraRadioQuestion;
}

export enum ExtraType {
  Input = 'input',
  MultiSelect = 'multi_select'
}

export interface ExtraRadioQuestion {
  value: any;
  type: ExtraType;
  hint?: string;
  options?: AnswerOption[];
  other?: boolean;
  optional?: boolean;
  input_type?: InputType;
  popup_title?: string;
  single_choice?: boolean;
}
