<div class="col s12">
  <div class="row">
    <div class="col s12">
      <div class="list-questions"
           cdkDropList
           (cdkDropListDropped)="drop($event, control.questions)">
        <div class="list-questions__item"
             *ngFor="let question of control.questions; index as i"
             cdkDrag>
          <app-edit-question [question]="question"
                             [index]="i"
                             [editOptions]="{
              editSection: false,
              editType: false,
              editOptional: false
            }"
                             (uiStructureChange)="uiChange.emit()"
                             (deleteQuestion)="onDeleteQuestion($event)"
                             (cloneQuestion)="onCloneQuestion($event)">
          </app-edit-question>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col s2">
      <a class="btn waves-effect waves-primary" (click)="onAddYesNoQuestion()"
        >+ YES/NO</a>
    </div>
    <div class="col s3">
      <a
        class="btn waves-effect waves-primary"
        (click)="onAddYesNoMultiQuestion()"
        >+ YES/NO with MULTI</a>
    </div>
  </div>
</div>
