import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-photo-preview',
  templateUrl: './photo-preview.component.html',
  styleUrls: ['./photo-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhotoPreviewComponent implements OnChanges {
  @Input()
  file: File;

  @Input()
  maxWidth: '100%';

  readerResult$: Subject<string> = new Subject<string>();

  constructor() {}

  ngOnChanges({ file }: SimpleChanges): void {
    if (file && file.currentValue) {
      const { currentValue } = file;
      const reader = new FileReader();
      reader.onload = () => {
        this.readerResult$.next(reader.result as string);
      };
      reader.readAsDataURL(currentValue as File);
    } else {
      this.readerResult$.next('');
    }
  }
}
