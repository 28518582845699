import { Component, OnChanges, OnInit } from '@angular/core';
import { MultiradioQuestion } from './multiradio-question.model';
import { AbstractControl, FormGroup } from '@angular/forms';
import { AbstractControlComponent } from '../abstract-control/abstract-control.component';

@Component({
  selector: 'app-multiradio-control',
  templateUrl: './multiradio-control.component.html',
  styleUrls: ['./multiradio-control.component.scss']
})
export class MultiradioControlComponent
  extends AbstractControlComponent<any, MultiradioQuestion, FormGroup>
  implements OnInit, OnChanges {
  private subFormControls: { [p: string]: AbstractControl } = {};
  nextSubQuestionKey: string = null;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.setupSubFormsGroup();
    setTimeout(() => this.checkNextSubQuestion(), 300);
  }

  ngOnChanges(): void {
    this.checkNextSubQuestion();
  }

  private checkNextSubQuestion(): void {
    const nextQuestion = this.question.control.questions.find((question) => {
      return (
        this.subFormControls[question.key] &&
        question.isVisible(this.data) &&
        !question.optional &&
        this.subFormControls[question.key].value[question.key] == null
      );
    });
    this.nextSubQuestionKey = nextQuestion ? nextQuestion.key : null;
  }

  private setupSubFormsGroup(): void {
    this.formControl = new FormGroup(this.subFormControls);
    this.control.emit(this.formControl);
  }

  emitOutput(): void {
    // do nothing; we emit per sub-question
  }

  onSubquestionDataChange(subquestionKey: string, value: any): void {
    this.output.emit({ [subquestionKey]: value });
  }

  onSubquestionFormControlSet(subquestionKey: string, value: any): void {
    this.subFormControls[subquestionKey] = value;
    this.setupSubFormsGroup();
  }
}
