import { Component, Input, OnInit } from '@angular/core';
import { nl2br, nonEmpty } from '../../../common/utils/string-utils.helper';
import { TextQuestionParams } from '../../../forms/form/controls/text-control/text-question.model';
import { environment } from 'src/environments/environment';
import * as htmlToText from 'html-to-text';
import { htmlToTextConfig } from '../edit-terms/html-to-text.config';

@Component({
  selector: 'app-edit-text',
  templateUrl: './edit-text.component.html',
  styleUrls: ['./edit-text.component.scss']
})
export class EditTextComponent implements OnInit {
  useHTML: boolean;
  @Input() control: TextQuestionParams;
  @Input() key: string;
  readonly environment: typeof environment = environment;

  ngOnInit(): void {
    this.useHTML = nonEmpty(this.control.html_text);
    this.control.temporary_html_text = `${this.control.html_text}`;
  }

  onUseHTMLChange(): void {
    if (this.useHTML) {
      this.control.html_text = this.control.text
        ? nl2br(this.control.text)
        : '';
      this.control.text = '';
      this.control.temporary_html_text = `${this.control.html_text}`;
    } else {
      /**
       * docs: https://github.com/html-to-text/node-html-to-text
       * default formatters templates: https://github.com/html-to-text/node-html-to-text/blob/master/lib/formatter.js
       */
      this.control.text = htmlToText.htmlToText(
        this.control.html_text,
        htmlToTextConfig
      );
      this.control.html_text = '';
    }
  }

  onEditorValueChange({ editorContent }, control): void {
    control.html_text = editorContent;
  }

  onPasteText(): void {
    setTimeout(() => {
      const textWithoutWordWrap = htmlToText.htmlToText(this.control.text, {
        wordwrap: null,
        preserveNewlines: true
      });

      this.control.text = textWithoutWordWrap.replace(
        new RegExp('\n\n', 'g'),
        '\n'
      ); // reduce unnecessary additional line spaces
    });
  }
}
