import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Question } from '../../question.model';
import { AbstractControlComponent } from '../abstract-control/abstract-control.component';
import { AbstractControl } from '@angular/forms';
import { getLinesCount } from '../../../../common/utils/string-utils.helper';
import { AccessibilityService } from '../../../../common/accessibility-service/accessibility.service';

@Component({ template: '' })
export class AbstractInputControlComponent<
    T,
    QUESTION extends Question,
    FORM_CONTROL extends AbstractControl
  >
  extends AbstractControlComponent<T, QUESTION, FORM_CONTROL>
  implements AfterViewInit {
  @ViewChild('label')
  private labelElement: ElementRef;
  fixedLabel: boolean;
  fixedLabelFocused: boolean;

  constructor(protected accessibilityService: AccessibilityService) {
    super();
  }

  ngAfterViewInit(): void {
    this.adjustLabelType();
    this.accessibilityService.getFontChangesObservable().subscribe(() => {
      this.adjustLabelType();
    });
  }

  protected adjustLabelType(): void {
    if (this.labelElement !== undefined) {
      // calculate if label (when bold) exceeds single line; if so, use other label instead
      const prevWeight = this.labelElement.nativeElement.style.fontWeight;
      this.labelElement.nativeElement.style.fontWeight = 'bold';
      if (getLinesCount(this.labelElement.nativeElement) > 1) {
        setTimeout(() => (this.fixedLabel = true), 0);
      } else {
        setTimeout(() => (this.fixedLabel = false), 0);
      }
      this.labelElement.nativeElement.style.fontWeight = prevWeight;
    }
  }
}
