import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ConsentRequest } from '../../consents-request/consent-request.model';
import { AbstractControl, FormGroup } from '@angular/forms';
import {
  ExtraType,
  RadioQuestion
} from '../../../forms/form/controls/radio-control/radio-question.model';
import { TranslateService } from '@ngx-translate/core';
import { ConsentSubmit } from '../../consents-request/consent-submit.model';
import { InputType } from '../../../forms/form/controls/input-control/input-question.model';
import { nonEmpty } from '../../../common/utils/string-utils.helper';
import { Materialize } from '../../../materialize';

declare const M: Materialize;

@Component({
  selector: 'app-consent-sign',
  templateUrl: './consent-sign.component.html',
  styleUrls: ['./consent-sign.component.scss']
})
export class ConsentSignComponent implements OnInit, AfterViewInit {
  @Input() consent: ConsentRequest;
  @Input() output: ConsentSubmit;
  @Output() control = new EventEmitter<AbstractControl>();
  initValue: any;
  signAsQuestion = {
    type: 'radio',
    key: 'signature_entity',
    optional: false,
    title: this.translationService.instant('CONSENTS.PATIENT_SIGN.SIGN_AS'),
    section: null,
    control: {
      options: [
        {
          name: this.translationService.instant(
            'CONSENTS.PATIENT_SIGN.PATIENT'
          ),
          value: 'patient'
        },
        {
          name: this.translationService.instant(
            'CONSENTS.PATIENT_SIGN.GUARDIAN'
          ),
          value: 'guardian'
        }
      ],
      extra: {
        value: 'guardian',
        type: ExtraType.Input,
        hint: this.translationService.instant(
          'CONSENTS.PATIENT_SIGN.GUARDIAN_NAME'
        ),
        input_type: InputType.Name
      }
    }
  } as RadioQuestion;
  signatureQuestion = {
    type: 'signature',
    key: 'signature',
    optional: false,
    title: this.translationService.instant('CONSENTS.PATIENT_SIGN.SIGNATURE'),
    section: null
  } as RadioQuestion;

  formGroup: FormGroup;

  constructor(private translationService: TranslateService) {}

  ngOnInit(): void {
    this.initValue = {
      value:
        this.output.guardian_name !== undefined
          ? nonEmpty(this.output.guardian_name)
            ? 'guardian'
            : 'patient'
          : null,
      extra: this.output.guardian_name
    };
    this.formGroup = new FormGroup({});
  }

  ngAfterViewInit(): void {
    M.updateTextFields();
  }

  onFormDataChange(key, data): void {
    switch (key) {
      case 'signature_entity':
        this.output.guardian_name =
          data.value === 'guardian' ? data.extra : null;
        break;
      case 'signature':
        this.output.patient_signature_guid = data;
        break;
    }
  }

  onFormControlSet(key, control): void {
    setTimeout(() => {
      if (this.formGroup.contains(key)) {
        this.formGroup.removeControl(key);
      }
      this.formGroup.addControl(key, control);
      this.control.emit(this.formGroup);
    }, 0);
  }
}
