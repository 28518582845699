import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Materialize, Modal } from '../../../materialize';
import { FormsRequestService } from '../../forms-request/forms-request.service';
import { AlertsService } from '../../../common/alerts/alerts.service';
import { FormFeedbackModel } from './form-feedback.model';
import { TranslateService } from '@ngx-translate/core';
import { nonEmpty } from '../../../common/utils/string-utils.helper';

declare const M: Materialize;

@Component({
  selector: 'app-form-feedback-tool',
  templateUrl: './form-feedback-tool.component.html',
  styleUrls: ['./form-feedback-tool.component.scss']
})
export class FormFeedbackToolComponent implements AfterViewInit {
  @Input() formSpecId: number;
  @Input() section: string;
  @Output() modalPluginSet = new EventEmitter<Modal>();
  @ViewChild('modal')
  private modal: ElementRef;
  isSubmitting = false;
  feedback: string;
  private modalPlugin: Modal;

  constructor(
    private formsService: FormsRequestService,
    private alertsService: AlertsService,
    private translateService: TranslateService
  ) {}

  ngAfterViewInit(): void {
    this.modalPlugin = M.Modal.init(this.modal.nativeElement);
    this.modalPluginSet.emit(this.modalPlugin);
  }

  async onSend(): Promise<any> {
    if (this.feedback == null || this.feedback.length === 0) {
      return;
    }

    this.isSubmitting = true;
    try {
      const feedbackRequest: FormFeedbackModel = {
        form_spec_id: this.formSpecId,
        section: this.section,
        text: this.feedback
      };
      await this.formsService.postPreviewFormFeedback(feedbackRequest);
      this.feedback = null;
      this.modalPlugin.close();
      this.alertsService.showInfo(
        this.translateService.instant('FORMS.FEEDBACK.SENT')
      );
    } catch (e) {
      this.alertsService.showApiError(e);
    } finally {
      this.isSubmitting = false;
    }
  }
}
