<app-spinner *ngIf="!consentRequest && !submitted && !cancelled"
             class="screen-centered"></app-spinner>
<app-check-patient-initials *ngIf="needToAuthorize"
                            [patient]="consentRequest?.patient"
                            (verified)="onVerifiedInitials($event)">
</app-check-patient-initials>
<app-check-qrcode *ngIf="consentRequest && showQRCode && !needToAuthorize"
                  [qrcodeUrl]="qrcodeUrl"
                  [headerTitle]="'CONSENTS.HEADER.TITLE' | translate"
                  (cancelled)="onCancelled()"></app-check-qrcode>
<app-consent *ngIf="showConsent"
             [consent]="consentRequest"
             (submitted)="onSubmitted()"
             (cancelled)="onCancelled()"></app-consent>
<app-consent-completed *ngIf="submitted"
                       class="screen-centered"></app-consent-completed>
<app-final-page *ngIf="cancelled"
                class="screen-centered"
                [titleLabel]="'CONSENTS.CANCELLED.TITLE' | translate"
                [descriptionLabel]="''"
                [closeLabel]="'CONSENTS.CANCELLED.CLOSE' | translate"
                [autoCloseAfter]="10"></app-final-page>
<app-modal (modalPluginSet)="timeoutModal = $event"
           (modalClosed)="onTimeoutModalClose()"
           (modalOpened)="onTimeoutModalOpen()">
  <ng-container *ngIf="timeoutModal?.isOpen === true">
    <h3>{{ 'COMMON.MODAL.TIMEOUT_SOON_TITLE' | translate }}</h3>
    <ng-container *ngrxLet="countDownSessionLeft$ as countDownSessionLeft">
      <p>
        {{ 'COMMON.MODAL.TIMEOUT_SOON' | translate: {sessionLeft: countDownSessionLeft} }}
      </p>
    </ng-container>
  </ng-container>
</app-modal>
<app-inactivity-bar *ngIf="showConsent"
                    [hideBar]="hideInactivityBar"
                    (hideBarChange)="onHideBarChange($event)">
</app-inactivity-bar>
