import Quill from 'quill';
import { HighlightBlockValue } from '../models/highlight-block-value.interface';
const BlockEmbed = Quill.import('blots/embed');

export class HighlightBlockEmbed extends BlockEmbed {
  static blotName = 'highlight';
  static tagName = 'mark';

  static create(value: string | HighlightBlockValue): HTMLElement {
    const node = super.create(value);

    if (typeof value === 'string') {
      value = { value, category: null };
    }

    const category = (value as HighlightBlockValue).category;

    node.setAttribute('data-placeholder', true);
    if (category) {
      node.classList.add(`category-${category}`);
    }

    node.innerText = (value as HighlightBlockValue).value ?? '';
    return node;
  }

  static value(node: HTMLElement): HighlightBlockValue {
    const placeholder =
      Array.from(node.childNodes).find((x) =>
        x.textContent.match(/{{[a-z0-9_]*}}/)
      ) || node;
    return {
      value: placeholder.textContent.trim(),
      category: node.classList.contains('category-undefined')
        ? undefined
        : node.classList[0]?.substring(9)
    };
  }

  static formats(): Record<string, string | number | boolean> {
    return {
      'data-placeholder': true
    };
  }
}
