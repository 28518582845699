<div class="col s12">
  <div class="row">
    <div class="input-field col s6">
      <select [(ngModel)]="control.language">
        <option value="en">English (default)</option>
        <option value="es">Spanish</option>
      </select>
      <label>Language</label>
    </div>
  </div>
  <div class="row">
    <div class="input-field col s6">
      <select [(ngModel)]="control.variant"
              (ngModelChange)="onVariantChanged()">
        <option value=""
                disabled
                selected
                hidden>Not specified</option>
        <option value="adult">Adult</option>
        <option value="adult_no_guardian_details">
          Adult (don't ask guardian details)
        </option>
        <option value="minor">Minor</option>
      </select>
      <label>Variant</label>
    </div>
  </div>
  <div class="row">
    <p class="text-primary">
      This is "block" type of question. It will be replaced with preconfigured
      template before filling.
    </p>
  </div>
</div>
