import { Injectable } from '@angular/core';
import { MobiatiHttpService } from '../../common/mobiati-http/mobiati-http.service';
import { FormsRequest } from './forms-request.model';
import { FormOutputModel } from '../form/form-output.model';
import { AttachmentResponse } from './attachment-response.model';
import { FormSpec } from '../form/form-spec.model';
import { HttpParams } from '@angular/common/http';
import { FormFeedbackModel } from '../form/form-feedback-tool/form-feedback.model';

@Injectable({
  providedIn: 'root'
})
export class FormsRequestService {
  private code: string;

  constructor(private http: MobiatiHttpService) {}

  setFormsRequestCode(code: string): void {
    this.code = code;
  }

  async getFormRequestData(): Promise<FormsRequest> {
    return this.http.get<FormsRequest>(`/f/${this.code}`);
  }

  async getFormData(formSpecId: number): Promise<object> {
    return this.http.get<object>(`/f/${this.code}/data/${formSpecId}`);
  }

  async getPlaceholdersMapByKeys(
    keys: string[],
    preview: boolean = false
  ): Promise<object> {
    if (keys.length === 0) {
      return Promise.resolve({});
    }
    return this.http.post<object>(`/preview-form/placeholders`, { keys });
  }

  async getPlaceholdersMap(formSpecId: number): Promise<object> {
    if (this.code) {
      return this.http.get<object>(
        `/f/${this.code}/placeholders/${formSpecId}`
      );
    } else {
      return this.http.get<object>(`/preview-form/placeholders/${formSpecId}`);
    }
  }

  async getPlaceholdersMapForPatient(
    formSpecId: number,
    patientId: number
  ): Promise<object> {
    return this.http.get<object>(
      `/patients/${patientId}/preview-form/placeholders/${formSpecId}`
    );
  }

  async saveAttachment(formData: FormData): Promise<AttachmentResponse> {
    return this.http.post<any>(`/f/${this.code}/attachment`, formData);
  }

  async saveForm(formOutput: FormOutputModel): Promise<any> {
    return this.http.post<any>(`/f/${this.code}`, formOutput);
  }

  async getPreviewFormSpec(
    type: string,
    minor: string,
    language: string
  ): Promise<FormSpec> {
    let params = new HttpParams();
    if (minor !== undefined) {
      params = params.set('minor', minor);
    }
    if (language !== undefined) {
      params = params.set('language', language);
    }
    return this.http.get<FormSpec>(`/preview-form/spec/${type}`, params);
  }

  async getPreviewPublishedFormSpec(
    type: string,
    minor: string,
    language: string
  ): Promise<FormSpec> {
    let params = new HttpParams();
    if (minor !== undefined) {
      params = params.set('minor', minor);
    }
    if (language !== undefined) {
      params = params.set('language', language);
    }
    return this.http.get<FormSpec>(
      `/preview-form/spec/${type}/published`,
      params
    );
  }

  async getPreviewFormPDF(formOutput: FormOutputModel): Promise<Blob> {
    return this.http.post<Blob>(`/preview-form/pdf`, formOutput, true);
  }

  async postPreviewFormFeedback(feedback: FormFeedbackModel): Promise<any> {
    return this.http.post<any>(`/preview-form/feedback`, feedback);
  }
}
