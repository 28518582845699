import { ClearentCardDetails } from '../../common/clearent/clearent-card-payment/clearent-card-payment.types';
import { SigningAs } from '../payment-plan-consent/payment-plan-consent.types';
export enum ConsentSubmitAction {
  REJECT = 'reject'
}

export interface RejectConsentPayload {
  answers: {
    consent: { value: boolean; extra: any };
    signature_entity: string;
    signature: string;
  };
}

export interface ConsentSubmit {
  agreed: boolean;
  patient_signature_guid: any;
  doctor_signature_guid?: any;
  guardian_name?: string;
  payment_option_id?: number;
  copy_patient?: boolean;
  card?: ClearentCardDetails;
  action?: ConsentSubmitAction;
  payment_plan_signature?: FormData;
  reject_consent_payload?: RejectConsentPayload;
  payment_plan_signing_as?: SigningAs;
}
