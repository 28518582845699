<app-page-header [title]="'KIOSK.GET_APP.TITLE' | translate"
                 [subtitle]=""
                 [canGoBack]="true"
                 [canGoNext]="false"
                 (goBack)="prevPage()"></app-page-header>
<div class="section no-pad-bot body"
     id="scroll-container">
  <div class="container">
    <div class="row">
      <div class="col s8 offset-s2 center-align">
        <h4>
          {{ 'KIOSK.GET_APP.INSTRUCTION' | translate }}
        </h4>
        <p class="text-primary mb-3">
          {{ 'KIOSK.GET_APP.INSTRUCTION_SCANNER' | translate }}
        </p>
        <qr-code [value]="appUrl"
                 [size]="250"></qr-code>

        <p class="font-increased mt-3">
          {{ 'KIOSK.GET_APP.OR' | translate }}
        </p>
        <p class="font-increased">
          {{ 'KIOSK.GET_APP.TYPE_LINK' | translate }}
        </p>
        <p class="app-link mt-3">{{ appUrl }}</p>
      </div>
    </div>
  </div>
</div>
