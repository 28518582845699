/**
 * Convert a base64 string in a Blob according to the data and contentType.
 */
function b64toBlob(
  b64Data: string,
  contentType: string = '',
  sliceSize: number = 512
): Blob {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}

function dataURLToBlob(dataURL: string): Blob {
  const block = dataURL.split(';');

  // Get the content type of the image
  const contentType = block[0].split(':')[1];

  // Get the real base64 content of the file
  const realData = block[1].split(',')[1];

  // Convert it to a blob
  return b64toBlob(realData, contentType);
}

export { b64toBlob, dataURLToBlob };
