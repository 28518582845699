import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Question } from '../../question.model';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { dataURLToBlob } from '../../../../common/toBlob/toBlob.helper';
import { AbstractControlComponent } from '../abstract-control/abstract-control.component';
import { FormControl } from '@angular/forms';
import { fromEvent } from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  map,
  tap
} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTimeAfterFirst } from '../../../../common/rxjs-pipes';

@UntilDestroy()
@Component({
  selector: 'app-signature-control',
  templateUrl: './signature-control.component.html',
  styleUrls: ['./signature-control.component.scss']
})
export class SignatureControlComponent
  extends AbstractControlComponent<FormData | string, Question, FormControl>
  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('signaturePad', { static: false })
  signaturePad: SignaturePad;
  @ViewChild('signatureContainer')
  signatureContainer: ElementRef;
  @Input() note = '';

  signaturePadOptions: object = {
    minWidth: 1,
    maxWidth: 3,
    canvasWidth: 300,
    canvasHeight: 250,
    backgroundColor: 'rgba(255,255,255,0)',
    penColor: 'rgb(0,0,0)',
    throttle: 0
  };
  signature: string;
  signatureMinComplexityPoints = 10;

  recalculateControl = false;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.setupSimpleFormControl('');
  }

  ngAfterViewInit(): void {
    this.recalculateSignaturePadOnResize();
  }

  ngOnDestroy(): void {}

  recalculateSignaturePadOnResize(): void {
    fromEvent(window, 'resize')
      .pipe(
        // @ts-ignore
        debounceTimeAfterFirst(500),
        map(() => window.innerWidth),
        distinctUntilChanged(),
        map(() => this.signaturePad.toDataURL()),
        tap(() => (this.recalculateControl = true)),
        tap(() => this.cdr.detectChanges()),
        tap(() => (this.recalculateControl = false)),
        delay(300),
        // tslint:disable-next-line:max-line-length
        filter(() => !!this.signaturePad), // if signaturePad will be not ready yet after recalculation then leave it like it is or wait it will be ready on next resize event iteration
        tap(() => {
          this.signaturePad.set(
            'canvasWidth',
            this.signatureContainer.nativeElement.clientWidth
          );
          this.signaturePad.set(
            'canvasHeight',
            this.signatureContainer.nativeElement.clientHeight
          );
        }),
        tap((value) => {
          this.signaturePad.fromDataURL(value, {
            width: this.signatureContainer.nativeElement.clientWidth,
            height: this.signatureContainer.nativeElement.clientHeight
          });
        }),
        untilDestroyed(this)
      )
      .subscribe();

    // trigger initialy resize event because on android there is initial resize and on computer browser not.
    // @ts-ignore
    const isIE11 = !!window.MSInputMethodContext && !!document?.documentMode;
    if (!isIE11) {
      window.dispatchEvent(new Event('resize')); // trigger initialy resize
    } else {
      const event = document.createEvent('Event');
      event.initEvent('resize', true, true);
    }
  }

  emitOutput(): void {
    if (this.signature !== null) {
      // Convert signature dataURL it to a blob
      const blob = dataURLToBlob(this.signature);
      const sig = new FormData();
      sig.append('attachment', blob, 'signature.png');

      this.output.emit(sig);
    } else {
      this.output.emit(null);
    }
  }

  /**
   * Signature draw start event handler
   * Will be notified of szimek/signature_pad's onBegin event
   */
  signatureDrawStartHandler(): void {
    return this.signatureUpdateHandler();
  }

  /**
   * Signature draw complete event handler
   * Will be notified of szimek/signature_pad's onEnd event
   */
  signatureDrawCompleteHandler(): void {
    return this.signatureUpdateHandler();
  }

  private setContainerOverflow(value: string): void {
    (document.getElementById('scroll-container') as any).style.setProperty(
      'overflow',
      value
    );
  }

  /**
   * Signature clear
   */
  signatureClear(): void {
    this.signaturePad.clear();
    this.signatureUpdateHandler();
  }

  /**
   * Update signature form field
   */
  signatureUpdateHandler(): void {
    this.signature = this.signaturePad.isEmpty()
      ? null
      : this.signaturePad.toDataURL('image/png;');
    this.formControl.setValue(this.signature);
    this.validSignature(this.getSignaturePadPointCount());
    this.emitOutput();
  }

  /**
   * get signature pad point count for validation
   */
  getSignaturePadPointCount(): number {
    const points = this.signaturePad.toData();
    return [].concat.apply([], points).length;
  }

  /**
   * validate signature complexity points
   */
  validSignature(signaturePadPointCount: number): void {
    if (signaturePadPointCount < this.signatureMinComplexityPoints) {
      this.formControl.setErrors({ signaturePoints: true });
    }
  }
}
