import {
  Component,
  ChangeDetectionStrategy,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  SimpleChanges
} from '@angular/core';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Patient } from '../../patient/patient.model';
import { CreditCardDetail } from '../clearent-card-payment/clearent-card-payment.types';
import { BehaviorSubject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { DeleteCardConfirmDialogService } from './delete-card-confirm-dialog/delete-card-confirm-dialog.service';

@UntilDestroy()
@Component({
  selector: 'app-payment-card-details',
  templateUrl: './payment-card-details.component.html',
  styleUrls: ['./payment-card-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'payment-card-details-overlay-panel' }
    }
  ]
})
export class PaymentCardDetailsComponent implements OnChanges {
  @Input() patient: Patient;
  @Input() cards: CreditCardDetail[];
  @Input() disabled: boolean;

  @Output() newMethodClicked: EventEmitter<void> = new EventEmitter();
  @Output() cardSelected: EventEmitter<CreditCardDetail> = new EventEmitter();
  @Output() cardDeleted: EventEmitter<number> = new EventEmitter();

  readonly cards$: BehaviorSubject<CreditCardDetail[]> = new BehaviorSubject<
    CreditCardDetail[]
  >([]);

  readonly cardControl: FormControl = new FormControl({
    value: null,
    disabled: true
  });

  constructor(
    private readonly translateService: TranslateService,
    private readonly confirmationDialogService: DeleteCardConfirmDialogService
  ) {}

  ngOnInit(): void {
    this.listenToControlValueChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.cards?.currentValue) {
      this.cards$.next(changes.cards.currentValue);
    }

    if (changes.disabled) {
      if (changes.disabled.currentValue) {
        this.cardControl.disable();
      } else {
        this.cardControl.enable();
      }
    }
  }

  deleteCard(cardDetail: CreditCardDetail): void {
    this.confirmationDialogService
      .open(cardDetail, {
        title: this.translateService.instant(
          'CLEARENT_CARD_PAYMENT.DELETE_CARD_DIALOG.TITLE'
        ),
        message: this.translateService.instant(
          'CLEARENT_CARD_PAYMENT.DELETE_CARD_DIALOG.MESSAGE'
        ),
        patientName: `${this.patient.first_name} ${this.patient.last_name}`
      })
      .subscribe((shouldDelete) => {
        if (shouldDelete) {
          if (cardDetail.id === this.cardControl.value?.id) {
            this.cardControl.reset();
          }

          this.cardDeleted.emit(cardDetail.id);
        }
      });
  }

  selectNewMethod(): void {
    this.newMethodClicked.emit();
  }

  trackById(index: number, card: CreditCardDetail): number {
    return card.id;
  }

  private listenToControlValueChange(): void {
    this.cardControl.valueChanges
      .pipe(
        filter((value) => value !== undefined),
        untilDestroyed(this)
      )
      .subscribe((value) => this.cardSelected.emit(value));
  }
}
