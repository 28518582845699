<div class="col s12">
  <div class="row">
    <div class="input-field col s6">
      <input placeholder="Agree text"
             id="{{ key + '-control-agree' }}"
             type="text"
             class="validate"
             [(ngModel)]="control.agree_text" />
      <label for="{{ key + '-control-agree' }}">Agree text</label>
    </div>
    <div class="input-field col s6">
      <input placeholder="Decline text"
             id="{{ key + '-control-decline' }}"
             type="text"
             class="validate"
             [(ngModel)]="control.decline_text" />
      <label for="{{ key + '-control-decline' }}">Decline text (for optional
        only)</label>
    </div>
  </div>
  <div class="row"
       *ngIf="control.decline_text">
    <div class="input-field col s6">
      <label>
        <input type="checkbox"
               class="filled-in"
               [(ngModel)]="!control.ask_decline_reason"
               [value]="false" />
        <span>Ask for decline reason</span>
      </label>
    </div>
  </div>
  <div class="row">
    <div class="col s12">
      <div class="row">
        <div class="input-field col s6">
          <label>
            <input type="checkbox"
                   class="filled-in"
                   [(ngModel)]="useHTML"
                   (change)="onUseHTMLChange()"
                   [value]="true" />
            <span>Provide as HTML <small>(will convert text to
                HTML)</small></span>
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col s12"
         *ngIf="useHTML; else nonHTML">
      <app-html-editor class="app-editor--autoheight"
                       [initialValue]="control?.temporary_html_text"
                       (valueChanges)="onEditorValueChange($event, control)">
      </app-html-editor>
    </div>
    <ng-template #nonHTML>
      <div class="col s12">
        <label for="{{ key + '-control-terms' }}"
               class="text-primary">Terms text</label>
        <textarea (paste)="onPasteText()"
                  #terms_area
                  id="{{ key + '-control-terms' }}"
                  class=""
                  [(ngModel)]="control.text"></textarea>
      </div>
    </ng-template>
  </div>
</div>
