<div class="modal"
     #modal>
  <div class="modal-content">
    <h4>{{ 'FORMS.FEEDBACK.TITLE' | translate }}</h4>
    <p>{{ 'FORMS.FEEDBACK.TEXT' | translate }}</p>
    <div class="row">
      <form class="col s12">
        <div class="row">
          <div class="input-field col s12">
            <textarea id="form_feedback"
                      class="materialize-textarea"
                      name="feedback"
                      [disabled]="isSubmitting"
                      [(ngModel)]="feedback"></textarea>
            <label for="form_feedback">{{
              'FORMS.FEEDBACK.INPUT_HINT' | translate
            }}</label>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <a
      class="modal-close waves-effect waves-green btn-flat"
      *ngIf="!isSubmitting"
      >{{ 'FORMS.FEEDBACK.CLOSE' | translate }}</a>
    <a
      (click)="onSend()"
      class="waves-effect waves-green btn-flat"
      *ngIf="!isSubmitting"
      >{{ 'FORMS.FEEDBACK.SEND' | translate }}</a>
  </div>
</div>
