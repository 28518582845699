import { Component, OnInit } from '@angular/core';
import { AbstractControlComponent } from '../abstract-control/abstract-control.component';
import { FormControl } from '@angular/forms';
import { TextQuestion } from './text-question.model';
import { PlaceholdersService } from '../../../../common/placeholders-service/placeholders.service';

@Component({
  selector: 'app-text-control',
  templateUrl: './text-control.component.html',
  styleUrls: ['./text-control.component.scss']
})
export class TextControlComponent
  extends AbstractControlComponent<any, TextQuestion, FormControl>
  implements OnInit {
  constructor(private placeholdersService: PlaceholdersService) {
    super();
  }

  ngOnInit(): void {
    this.replacePlaceholders();
  }

  private replacePlaceholders(): void {
    if (this.question.control.text) {
      this.question.control.text = this.placeholdersService.replacePlaceholders(
        this.question.control.text
      );
    }
    if (this.question.control.html_text) {
      this.question.control.html_text = this.placeholdersService.replacePlaceholders(
        this.question.control.html_text
      );
    }
  }
}
