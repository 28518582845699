import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { AuthService } from '../../common/authentication/auth.service';
import { ErrorMessageService } from '../../common/error-message/error-message.service';
import { PracticeService } from '../../common/practice';
import { KioskService } from '../kiosk-service/kiosk.service';
import { DeviceRegisterRequest } from '../kiosk-service/device-register-request.model';
import { v1 as uuidv1 } from 'uuid';
import { v4 as uuidv4 } from 'uuid';
import { DeviceService } from '../../common/device-service/device.service';
import { isEmpty, nonEmpty } from '../../common/utils/string-utils.helper';
import { RegistrationResult } from './registration-result.model';
import { RegisterDeviceResponse } from '../kiosk-service/register-device-response.model';
import { Materialize } from '../../materialize';
import { from, of, Subject, timer } from 'rxjs';
import {
  catchError,
  filter,
  switchMap,
  takeUntil,
  tap,
  timeout
} from 'rxjs/operators';
import { RegisterDeviceUsingPinCodeResponse } from '../kiosk-service/register-device-using-pin-code-response.model';
import { RegisterDeviceUsingPinCodeRequest } from '../kiosk-service/register-device-using-pin-code-request.model';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from '../../common/alerts/alerts.service';
import { TwoFaService } from '../../common/authentication/two-fa.service';

declare const M: Materialize;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss', './../consolidation-styles.scss']
})
export class RegisterComponent implements AfterViewInit, OnDestroy {
  @Input() deviceName: string; // use this name as default
  @Input() deviceId: string; // use this id instead of generating
  email: string;
  password: string;
  error: string;
  isLoading: boolean;
  pinCode: number;
  usePinCode: boolean;
  private stopPolling = new Subject();
  pinCodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  waitingForDeviceConfirmation: boolean;
  useCredentials = true;

  @Output() registerSuccess = new EventEmitter<RegistrationResult>();

  constructor(
    private authService: AuthService,
    private twoFaService: TwoFaService,
    private kioskService: KioskService,
    private errorMessageService: ErrorMessageService,
    private practiceService: PracticeService,
    private deviceService: DeviceService,
    private translateService: TranslateService,
    private alertsService: AlertsService
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => M.updateTextFields(), 0);
  }

  ngOnDestroy(): void {
    this.stopPolling.next();
  }

  async registerDeviceUsingCredentials(): Promise<any> {
    if (!this.validate()) {
      return;
    }

    this.isLoading = true;
    this.error = null;
    try {
      const loginResult = await this.authService.logInPassword(
        this.email,
        this.password,
        'staff',
        this.twoFaService.getDeviceUuid(this.email)
      );
      if (loginResult.device_uuid) {
        this.twoFaService.setDeviceUuid(loginResult.device_uuid, this.email);
      }
      this.practiceService.setPracticeGuid(loginResult.practice_guid);
      const deviceInfo = {
        platform: this.deviceService.getPlatform(),
        device_id: nonEmpty(this.deviceId)
          ? this.deviceId
          : String(uuidv1() + uuidv4()).replace(/-/g, ''),
        device_name: this.deviceName,
        device_model: this.deviceService.getFullModentoClientInfo(),
        device_version: '',
        app_version: this.deviceService.getModentoHostAppVersionInt()
      } as DeviceRegisterRequest;
      const registerResult: RegisterDeviceResponse = await this.kioskService.registerDevice(
        deviceInfo
      );

      if (registerResult.success) {
        // SUCCESS; store cookies and proceed
        this.registerSuccess.emit({
          device_id: deviceInfo.device_id,
          device_name: deviceInfo.device_name,
          practice_guid: loginResult.practice_guid,
          config: registerResult.config
        } as RegistrationResult);
      } else {
        this.error = registerResult.message;
      }
    } catch (e) {
      if (e.error && e.error.device_uuid) {
        this.twoFaService.setDeviceUuid(e.error.device_uuid, this.email);
      }
      this.error = this.errorMessageService.getErrorMessage(e);
    } finally {
      this.isLoading = false;
    }
  }

  async registerDeviceUsingPinCode(): Promise<any> {
    if (!this.validatePinCode()) {
      return;
    }

    this.isLoading = true;
    this.error = null;
    try {
      const registerDeviceUsingPinCodeRequest = {
        pin_code: this.pinCode,
        platform: this.deviceService.getPlatform(),
        device_id: nonEmpty(this.deviceId)
          ? this.deviceId
          : String(uuidv1() + uuidv4()).replace(/-/g, ''),
        device_name: this.deviceName,
        device_model: this.deviceService.getFullModentoClientInfo(),
        device_version: '',
        app_version: this.deviceService.getModentoHostAppVersionInt()
      } as RegisterDeviceUsingPinCodeRequest;

      const registerResult: RegisterDeviceUsingPinCodeResponse = await this.kioskService.registerDeviceUsingPinCode(
        registerDeviceUsingPinCodeRequest
      );

      if (registerResult.success) {
        this.alertsService.showInfo(registerResult.message);
        this.pinCode = null;
        this.usePinCode = false;
        this.waitingForDeviceConfirmation = true;
        timer(4000, 2000)
          .pipe(
            switchMap(() =>
              from(
                this.kioskService.pairDeviceUsingPinCode(
                  registerResult.kiosk_device_id
                )
              ).pipe(
                filter((response) => response.status === 201),
                tap((response) => {
                  this.stopPolling.next();
                  this.registerSuccess.emit({
                    device_id: response.device_id,
                    device_name: response.device_name,
                    practice_guid: response.practice_guid,
                    config: response.config
                  } as RegistrationResult);
                })
              )
            ),
            timeout(500000), // 5 minutes
            catchError((error) => {
              this.error = this.errorMessageService.getErrorMessage(error);
              return of(error);
            }),
            takeUntil(this.stopPolling)
          )
          .subscribe();
      }
    } catch (e) {
      console.log(e);
      this.error = this.errorMessageService.getErrorMessage(e);
    } finally {
      this.isLoading = false;
    }
  }

  private validate(): boolean {
    if (
      isEmpty(this.email) ||
      isEmpty(this.password) ||
      isEmpty(this.deviceName)
    ) {
      this.error = 'Please fill all the fields.';
      return false;
    }
    return true;
  }

  private validatePinCode(): boolean {
    if (isEmpty(this.pinCode) || this.pinCode.toString().length !== 6) {
      this.error = this.translateService.instant(
        'KIOSK.REGISTER.PIN_CODE_VALIDATION_MSG'
      );
      return false;
    }
    return true;
  }

  onPasswordKeyDown($event): void {
    if ($event.key === 'Enter') {
      this.registerDeviceUsingCredentials();
    }
  }

  usePinCodeChange(): void {
    setTimeout(() => M.updateTextFields(), 0);
    this.error = null;
    this.usePinCode = !this.usePinCode;
    this.useCredentials = !this.useCredentials;
  }

  backToPinCode(): void {
    this.waitingForDeviceConfirmation = false;
    this.usePinCode = true;
    this.useCredentials = false;
    this.stopPolling.next();
  }
}
