import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownQuestionParams } from '../../../forms/form/controls/dropdown-control/dropdown-question.model';
import { AnswerOption } from '../../../forms/form/answer-option.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-edit-dropdown',
  templateUrl: './edit-dropdown.component.html',
  styleUrls: ['./edit-dropdown.component.scss']
})
export class EditDropdownComponent {
  @Input() control: DropdownQuestionParams;
  @Input() key: string;
  @Output() uiChange = new EventEmitter();

  onDeleteOption(optionIndex: number): void {
    this.control.options.splice(optionIndex, 1);
  }

  onAddOption(): void {
    if (this.control.options == null) {
      this.control.options = [];
    }
    this.control.options.push({
      name: '',
      value: '',
      pms_id: undefined,
      pms_name: undefined
    } as AnswerOption);
    this.uiChange.emit();
  }

  onDefaultChange(): void {
    if (this.control.default === 'true') {
      this.control.default = true;
    }
    if (this.control.default === 'false') {
      this.control.default = false;
    }
  }

  onHintChanged(): void {
    if (this.control.hint) {
      if (this.control.hint === '') {
        this.control.hint = null;
      }
    }
  }

  drop(event: CdkDragDrop<string[]>, controlOptions): void {
    moveItemInArray(controlOptions, event.previousIndex, event.currentIndex);
  }
}
