import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DrawingCanvasService {
  isDrawingMode = new Subject<boolean>();
  lineThickness = new Subject<number>();
  readonly lineColor = new Subject<string>();
  readonly clear = new Subject<void>();

  constructor() {}

  updateDrawingMode(value: boolean): void {
    this.isDrawingMode.next(value);
  }

  getIsDrawingMode(): Observable<boolean> {
    return this.isDrawingMode.asObservable();
  }

  updateLineThickness(value: number): void {
    this.lineThickness.next(value);
  }

  getLineThickness(): Observable<number> {
    return this.lineThickness.asObservable();
  }
}
