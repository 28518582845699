import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, of } from 'rxjs';
import {
  catchError,
  finalize,
  map,
  shareReplay,
  switchMap,
  tap
} from 'rxjs/operators';
import { CreditCardDetail } from './clearent-card-payment.types';
import { ClearentCardPaymentDataService } from './data-access/clearent-card-payment-data.service';
import { AlertsService } from '../../alerts/alerts.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ClearentCardPaymentService {
  private readonly clearentKey$$: ReplaySubject<void> = new ReplaySubject<void>(
    1
  );
  private readonly cards$$: ReplaySubject<number> = new ReplaySubject<number>(
    1
  );

  readonly clearentKey$: Observable<string> = this.clearentKey$$.pipe(
    switchMap(() =>
      this.clearentCardPaymentDataService.getClearentPublicKey().pipe(
        catchError((err: HttpErrorResponse) => {
          this.alertsService.showApiError(err);
          throw err;
        })
      )
    ),
    map((response) => response.public_key),
    shareReplay(1)
  );

  readonly cards$: Observable<CreditCardDetail[]> = this.cards$$.pipe(
    tap(() => {
      this.loading$.next(true);
      this.loaded$.next(false);
    }),
    switchMap((id) =>
      this.clearentCardPaymentDataService.getCards(id).pipe(
        catchError((err: HttpErrorResponse) => {
          this.alertsService.showApiError(err);
          throw err;
        }),
        finalize(() => this.loading$.next(false))
      )
    ),
    tap(() => this.loaded$.next(true)),
    map((response) => response.card_details),
    shareReplay(1)
  );

  readonly loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  readonly loaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(
    private readonly alertsService: AlertsService,
    private readonly clearentCardPaymentDataService: ClearentCardPaymentDataService
  ) {}

  loadClearentKey(): void {
    this.clearentKey$$.next();
  }

  loadCards(id: number): void {
    this.cards$$.next(id);
  }

  deleteCard(cardId: number, patientId: number): void {
    this.loading$.next(true);

    this.clearentCardPaymentDataService
      .deleteCard(cardId, patientId)
      .pipe(
        tap(() => {
          this.loadCards(patientId);
        }),
        catchError((err: HttpErrorResponse) => {
          this.alertsService.showApiError(err);
          return of(err);
        }),
        finalize(() => this.loading$.next(false))
      )
      .subscribe();
  }
}
