<div class="row component-container">
  <div class="col s12">
    <p class="question-title">
      {{ question.title }}
    </p>
    <div>
      <!-- prettier-ignore -->
      <p class="block-text no-html"
         *ngIf="question.control.text">{{ question.control.text }}
      </p>
      <app-html-view *ngIf="question.control.html_text"
                     [content]="question.control.html_text">
      </app-html-view>
      <br />
      <app-radio-control *ngIf="allowAcceptDecline; else agreeCheckbox"
                         [question]="acceptDeclineQuestion"
                         [data]="data"
                         (output)="onAcceptDeclineDataChange($event)"
                         (control)="onAcceptDeclineFormControlSet($event)">
      </app-radio-control>
      <ng-template #agreeCheckbox>
        <p>
          <label class="for-checkbox"
                 for="{{ question.key }}">
            <input id="{{ question.key }}"
                   type="checkbox"
                   class="filled-in"
                   [name]="question.key"
                   (change)="onAgreeCheckboxChanged()"
                   [formControl]="formControl" />
            <span>{{ question.control.agree_text?.trim() }}</span>
            <span class="required"
                  *ngIf="!question.optional">*</span>
          </label>
        </p>
      </ng-template>
    </div>
    <span class="helper-text light"
          *ngIf="question.text">{{
      question.text
    }}</span>
    <div class="row row-extra"
         *ngIf="
        formControl !== undefined &&
        !formControl.valid &&
        (formControl.touched || formControl.dirty) &&
        formControl.errors
      ">
      <span class="helper-text invalid"
            *ngIf="formControl.errors.isTrue">{{
        'FORMS.VALIDATION.TERMS.REQUIRED' | translate
      }}</span>
    </div>
  </div>
</div>
