import { Component, OnDestroy, OnInit } from '@angular/core';
import { AccessibilityService } from '../accessibility-service/accessibility.service';

@Component({
  selector: 'app-font-size-toggle',
  templateUrl: './font-size-toggle.component.html',
  styleUrls: ['./font-size-toggle.component.scss']
})
export class FontSizeToggleComponent implements OnDestroy {
  constructor(public accessibilityService: AccessibilityService) {}

  ngOnDestroy(): void {
    this.accessibilityService.setBigFont(false);
  }
}
