<div class="row"
     *ngIf="!compact"
     [ngClass]="{ highlight: highlight }">
  <div class="mb-1 col s12"
       [formGroup]="formControl">
    <p class="question-title">
      {{ question.title?.trim()
      }}<span class="required"
            *ngIf="!question.optional">*</span>
    </p>
    <span class="helper-text light question-extra-text"
          *ngIf="question.text">{{
      question.text
    }}</span>
    <div *ngFor="let option of question.control.options; index as i"
         class="radio-col">
      <div>
        <label>
          <input class="with-gap"
                 type="radio"
                 [name]="question.key"
                 [value]="option.value"
                 (change)="emitOutput()"
                 [formControlName]="question.key" />
          <span>{{ option.name }}</span>
        </label>
      </div>
    </div>

    <div class="row-extra"
         *ngIf="showExtra">
      <app-input-control *ngIf="question.control.extra.type === 'input'"
                         [compact]="true"
                         [question]="toExtraInputQuestion()"
                         [data]="extra"
                         (output)="onExtraDataChange($event)"
                         (control)="onExtraFormControlSet($event)">
      </app-input-control>
      <app-dropdown-control *ngIf="question.control.extra.type === 'multi_select'"
                            [compact]="true"
                            [question]="toExtraDropdownQuestion()"
                            [data]="extra"
                            (output)="onExtraDataChange($event)"
                            (control)="onExtraFormControlSet($event)">
      </app-dropdown-control>
    </div>
  </div>
</div>
<div class="row compact"
     *ngIf="compact"
     [ngClass]="{ highlight: highlight }">
  <div class="valign-wrapper">
    <div class="question-descriptions">
      <p class="question-title">{{ question.title }}</p>
      <span class="helper-text light"
            *ngIf="question.text">{{
        question.text
      }}</span>
    </div>
    <div class="input-col"
         [formGroup]="formControl">
      <div class="row-no-margin"
           *ngFor="let option of question.control.options; index as i">
        <div class="radio-option">
          <label>
            <input class="with-gap"
                   type="radio"
                   style="visibility: hidden;"
                   [name]="question.key"
                   [value]="option.value"
                   (change)="emitOutput()"
                   [formControlName]="question.key" />
            <span>{{ option.name }}</span>
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="row row-no-margin row-extra"
       *ngIf="showExtra">
    <app-input-control *ngIf="question.control.extra.type === 'input'"
                       [compact]="true"
                       [question]="toExtraInputQuestion()"
                       [data]="extra"
                       (output)="onExtraDataChange($event)"
                       (control)="onExtraFormControlSet($event)">
    </app-input-control>
    <app-dropdown-control *ngIf="question.control.extra.type === 'multi_select'"
                          [compact]="true"
                          [question]="toExtraDropdownQuestion()"
                          [data]="extra"
                          (output)="onExtraDataChange($event)"
                          (control)="onExtraFormControlSet($event)">
    </app-dropdown-control>
  </div>
</div>

<div class="row row-extra mt-1"
     *ngIf="!formControl.valid && formControl.touched">
  <ng-container *ngIf="formControl.controls[question.key].errors">
    <span class="helper-text invalid"
          *ngIf="formControl.controls[question.key].errors.required">{{ 'FORMS.VALIDATION.RADIO.REQUIRED' | translate }}</span>
  </ng-container>
</div>
