import { Component, Input, OnInit } from '@angular/core';
import { PhotoQuestionParams } from '../../../forms/form/controls/photo-control/photo-question.model';

@Component({
  selector: 'app-edit-photo',
  templateUrl: './edit-photo.component.html',
  styleUrls: ['./edit-photo.component.scss']
})
export class EditPhotoComponent {
  @Input() control: PhotoQuestionParams;
  @Input() key: string;

  onSizeChanged(): void {
    this.control.longer_size = Number(this.control.longer_size);
  }
}
