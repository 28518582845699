<div class="modal dark-forms"
     #modal>
  <div class="modal-content"
       *ngIf="question">
    <h4>{{ 'FORMS.LITE_EDITOR.EDIT_QUESTION.TITLE' | translate }}</h4>
    <div class="row">
      <div class="input-field col s6">
        <input placeholder="Question"
               id="{{ question.key + '-title' }}"
               type="text"
               class="validate"
               [(ngModel)]="question.title" />
        <label for="{{ question.key + '-title' }}">Question</label>
      </div>
    </div>
    <p>
      <strong><small>{{
          'FORMS.LITE_EDITOR.EDIT_QUESTION.SAVE_HINT' | translate
        }}</small></strong>
    </p>
  </div>
  <div class="modal-footer">
    <a class="modal-close waves-effect waves-green btn-flat">{{
      'FORMS.LITE_EDITOR.EDIT_QUESTION.CLOSE' | translate
    }}</a>
  </div>
</div>
