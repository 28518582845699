import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DropdownControlComponent } from '../dropdown-control/dropdown-control.component';
import { DeviceService } from '../../../../common/device-service/device.service';
import { TranslateService } from '@ngx-translate/core';
import { AnswerOption } from '../../answer-option.model';
import { Practice, PracticeService } from '../../../../common/practice';
import { nonEmpty } from '../../../../common/utils/string-utils.helper';
import { AccessibilityService } from '../../../../common/accessibility-service/accessibility.service';

@Component({
  selector: 'app-state-control',
  templateUrl: '../dropdown-control/dropdown-control.component.html',
  styleUrls: ['../dropdown-control/dropdown-control.component.scss']
})
export class StateControlComponent
  extends DropdownControlComponent
  implements OnInit, AfterViewInit {
  private readonly states = [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'VI',
    'WA',
    'WV',
    'WI',
    'WY'
  ];

  constructor(
    protected deviceService: DeviceService,
    protected accessibilityService: AccessibilityService,
    private practiceService: PracticeService,
    private translateService: TranslateService
  ) {
    super(deviceService, accessibilityService, translateService);
  }

  ngOnInit(): void {
    const practiceState = this.retrievePracticeState();
    if (nonEmpty(practiceState)) {
      this.question.control.default = practiceState;
    }
    this.question.control.options = this.createStatesOptions();
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.writeDefaultValueToInput();
  }

  createStatesOptions(): AnswerOption[] {
    return this.states.map((state) => {
      return {
        name: this.translateService.instant('FORMS.CONTROLS.STATES.' + state),
        value: state
      } as AnswerOption;
    });
  }

  /**
   * Try to determine default state from practice address info.
   */
  private retrievePracticeState(): string {
    const practice: Practice = this.practiceService.getPractice();
    if (practice) {
      const address = this.practiceService.getPractice().address;
      if (nonEmpty(address)) {
        const lastCommaIdx = address.lastIndexOf(',');
        if (lastCommaIdx >= 0) {
          const lastPart = address.substring(lastCommaIdx);
          const stateIdx = lastPart.search(/[A-Z][A-Z]/);
          if (stateIdx > 0) {
            const state = lastPart.substr(stateIdx, 2);
            if (this.states.find((s) => s === state)) {
              return state;
            }
          }
        }
      }
    }
    return undefined;
  }

  private writeDefaultValueToInput(): void {
    const defaultValueName = this.question.control.options.find((option) => {
      return option.value === this.question.control.default;
    })?.name;

    if (defaultValueName) {
      this.autocomplete.nativeElement.value = defaultValueName;
      setTimeout(() => {
        this.emitOutput();
      });
    }
  }
}
