import { Injectable } from '@angular/core';
import { MobiatiHttpService } from '../../../mobiati-http/mobiati-http.service';
import {
  AddPaymentPayload,
  ClearentPublicKeyResponse,
  CreditCardDetailsResponse
} from './clearent-card-payment-data.types';
import { Observable, from } from 'rxjs';
import { ActionResponse } from '../../../mobiati-http/action-response.model';

@Injectable({
  providedIn: 'root'
})
export class ClearentCardPaymentDataService {
  constructor(private readonly httpClient: MobiatiHttpService) {}

  getClearentPublicKey(): Observable<ClearentPublicKeyResponse> {
    return from(
      this.httpClient.get<ClearentPublicKeyResponse>('/clearent/public-key')
    );
  }

  sendPayment(payload: AddPaymentPayload): Observable<ActionResponse> {
    return from(
      this.httpClient.post<ActionResponse>(
        `/staff/terminal-payments/clearent/${payload.patient_id}/payment`,
        payload
      )
    );
  }

  getCards(patientId: number): Observable<CreditCardDetailsResponse> {
    return from(
      this.httpClient.get<CreditCardDetailsResponse>(
        `/patients/${patientId}/card-details`
      )
    );
  }

  deleteCard(cardId: number, patientId: number): Observable<ActionResponse> {
    return from(
      this.httpClient.delete<ActionResponse>(
        `/patients/${patientId}/card-details/${cardId}`
      )
    );
  }
}
