<mat-menu #menu>
  <ng-container *ngFor="let item of items; trackBy: trackByItemIdx">
    <button *ngIf="!item.children; else branch"
            mat-menu-item
            (click)="itemClicked(item)">
      {{ item.name }}
    </button>
    <ng-template #branch>
      <button mat-menu-item
              [matMenuTriggerFor]="nestedMenu.menu">
        {{ item.name }}
      </button>
      <app-menu-item #nestedMenu
                     [items]="item.children"
                     (itemSelected)="itemSelected.next($event)"></app-menu-item>
    </ng-template>
  </ng-container>
</mat-menu>
