import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RejectTpConsentComponent } from './reject-tp-consent.component';
import { RefuseConsentPreviewModule } from './refuse-consent-preview/refuse-consent-preview.module';

@NgModule({
  declarations: [RejectTpConsentComponent],
  exports: [RejectTpConsentComponent],
  imports: [CommonModule, RefuseConsentPreviewModule]
})
export class RejectTpConsentModule {}
