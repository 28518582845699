import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ConsentRequest } from '../../consents-request/consent-request.model';
import { AbstractControl, FormControl } from '@angular/forms';
import { CustomValidators } from '../../../common/validators/custom-validators.helper';
import { ConsentSubmit } from '../../consents-request/consent-submit.model';
import { ConsentsRequestService } from '../../consents-request/consents-request.service';
import { PusherService } from '../../../common/pusher/pusher.service';
// tslint:disable-next-line:max-line-length
import { WisetackLoanApplicationDoneEvent } from '../consent-payment-plans/payment-option/wistetack-loan-pre-qualification-done-event.model';
import { AlertsService } from '../../../common/alerts/alerts.service';
import { from, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-consent-payment-option-selection-hook',
  templateUrl: './consent-payment-option-selection-hook.component.html',
  styleUrls: ['./consent-payment-option-selection-hook.component.scss']
})
export class ConsentPaymentOptionSelectionHookComponent
  implements OnInit, OnDestroy {
  @Output() control: EventEmitter<AbstractControl> = new EventEmitter<
    AbstractControl
  >();
  formControl: FormControl;
  @Input() consent: ConsentRequest;
  @Input() output: ConsentSubmit;
  loading = true;
  hook = '';
  @Output() nextPageDisabledEvent = new EventEmitter<boolean>();
  @Output() loanApplicationDoneEvent = new EventEmitter<
    WisetackLoanApplicationDoneEvent
  >();
  @Output() showWisetackWarning = new EventEmitter<boolean>();

  constructor(
    private consentRequestService: ConsentsRequestService,
    private readonly pusherService: PusherService,
    private readonly alertsService: AlertsService
  ) {}

  ngOnInit(): void {
    this.formControl = new FormControl(true, CustomValidators.isTrue());
    this.formControl.setValue(true);
    this.control.emit(this.formControl);
    this.showWisetackWarning.next(true);

    // request payment option pre hook
    const formData = new FormData();
    formData.append(
      'payment_option_id',
      this.output.payment_option_id.toString()
    );
    // this.nextPageDisabledEvent.emit(true);

    from(this.consentRequestService.getPrePaymentOptionSelectionHook(formData))
      .pipe(
        tap((response) => {
          this.hook = response.link;
          this.loading = false;
        }),
        switchMap(() =>
          this.pusherService.createLoanPreQualifiedEventStream(this.consent)
        ),
        switchMap(() => this.pusherService.loanPreQualifiedArrived$),
        tap((event) => {
          if (event.pre_qualification_done) {
            this.nextPageDisabledEvent.next(false);
            if (event.accepted) {
              this.showWisetackWarning.next(false);
            }
            this.loanApplicationDoneEvent.next({
              accepted: event.accepted,
              preQualificationDone: event.pre_qualification_done,
              maxAmount: event.max_qualified_amount
            });
          }
        }),
        catchError((error) => {
          this.loading = false;
          this.alertsService.showApiError(error);
          this.nextPageDisabledEvent.next(false);
          this.showWisetackWarning.next(false);
          this.loanApplicationDoneEvent.next({
            accepted: false,
            preQualificationDone: true,
            maxAmount: null,
            error: true
          });
          return of({});
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.nextPageDisabledEvent.next(false);
    this.showWisetackWarning.next(false);
    this.pusherService.disconnect();
  }
}
