import { Component, Input } from '@angular/core';
import { LinkQuestionParams } from '../../../forms/form/controls/link-control/link-question.model';

@Component({
  selector: 'app-edit-link',
  templateUrl: './edit-link.component.html',
  styleUrls: ['./edit-link.component.scss']
})
export class EditLinkComponent {
  @Input() control: LinkQuestionParams;
  @Input() key: string;
}
