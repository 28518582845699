<div class="row">
  <div class="col s12">
    <p class="question-title"
       *ngIf="question.title">{{ question.title }}</p>
    <span class="helper-text light"
          *ngIf="question.text">{{
      question.text
    }}</span>
    <div>
      <p class="block-text no-html"
         *ngIf="question.control.text">
        {{ question.control.text.trim() }}
      </p>
      <app-html-view *ngIf="question.control.html_text"
                     [content]="question.control.html_text">
      </app-html-view>
    </div>
  </div>
</div>
