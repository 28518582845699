import { Component, OnInit } from '@angular/core';
import { KioskRequestsService } from '../kiosk-service/kiosk-requests.service';

@Component({
  selector: 'app-connection-status-badge',
  templateUrl: './connection-status-badge.component.html',
  styleUrls: ['./connection-status-badge.component.scss']
})
export class ConnectionStatusBadgeComponent implements OnInit {
  connectionState: string;

  constructor(private kioskRequestsService: KioskRequestsService) {}

  ngOnInit(): void {
    this.connectionState = this.kioskRequestsService.getConnectionState();
    this.kioskRequestsService
      .connectionStatusObservable()
      .subscribe((newState) => {
        this.connectionState = newState;
      });
  }
}
