<div *ngIf="isLoading"
     class="screen-full-modal"
     (click)="false">
  <app-spinner class="screen-centered"></app-spinner>
</div>
<div class="container scrolling">
  <div class="row">
    <div class="col s12 center-align">
      <img src="assets/logo.svg"
           class="modento-logo">
    </div>
  </div>
  <div class="row">
    <div class="col s12 center-align">
      <h4 i18n>Kiosk App</h4>
      <p *ngIf="useCredentials">
        {{ 'KIOSK.REGISTER.REGISTER_DEVICE_CREDENTIALS_INFO' | translate }}
      </p>
      <p *ngIf="usePinCode"
         [innerHTML]="'KIOSK.REGISTER.REGISTER_DEVICE_PIN_CODE_INFO' | translate">
      </p>
      <p *ngIf="waitingForDeviceConfirmation">
        {{ 'KIOSK.REGISTER.REGISTER_DEVICE_WAITING_FOR_CONFIRMATION_INFO' | translate }}
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col s10 offset-s1 m6 offset-m3 center-align">
      <div *ngIf="useCredentials">
        <div class="input-field">
          <input id="email"
                 type="email"
                 class="validate"
                 name="email"
                 [(ngModel)]="email"
                 required>
          <label for="email"
                 i18n="">Email / username</label>
        </div>
        <div class="input-field">
          <input id="password"
                 type="password"
                 class="validate"
                 name="password"
                 [(ngModel)]="password"
                 (keydown)="onPasswordKeyDown($event)"
                 required>
          <label for="password"
                 i18n="">Password</label>
        </div>
        <div class="input-field">
          <input id="deviceName"
                 type="text"
                 autocapitalize="words"
                 class="validate"
                 name="deviceName"
                 [(ngModel)]="deviceName"
                 required>
          <label for="deviceName"
                 i18n="">Device Name</label>
        </div>
      </div>

      <div *ngIf="usePinCode"
           class="input-field">
        <input id="pinCode"
               type="text"
               class="validate"
               name="pinCode"
               [(ngModel)]="pinCode"
               [textMask]="{ mask: pinCodeMask }"
               required>
        <label for="pinCode">{{ 'KIOSK.REGISTER.PIN_CODE' | translate }}</label>
      </div>

      <button *ngIf="useCredentials"
              type="button"
              class="btn btn-primary register mt-2"
              (click)="registerDeviceUsingCredentials()">
        {{ 'KIOSK.REGISTER.REGISTER_DEVICE' | translate }}</button>
      <button *ngIf="usePinCode"
              type="button"
              class="btn btn-primary mt-2"
              (click)="registerDeviceUsingPinCode()">
        {{ 'KIOSK.REGISTER.REGISTER_DEVICE' | translate }}</button>
      <span class="helper-text invalid mt-2"
            *ngIf="error != null && error.length > 0">{{error}}</span>

      <div class="mt-4">
        <ng-container *ngIf="useCredentials || usePinCode">
          <button type="button"
                  class="btn btn-primary"
                  (click)="usePinCodeChange()">
            <span
                  *ngIf="!usePinCode">{{ 'KIOSK.REGISTER.PAIR_USING_PIN' | translate }}</span><span
                  *ngIf="usePinCode">{{ 'KIOSK.REGISTER.PAIR_USING_CREDENTIALS' | translate }}</span>
          </button>
        </ng-container>
        <ng-container *ngIf="waitingForDeviceConfirmation">
          <button type="button"
                  class="btn btn-primary mt-2"
                  (click)="backToPinCode()">
            <span>{{ 'COMMON.BUTTONS.BACK' | translate }}</span>
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
