import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { AlertsService } from '../alerts/alerts.service';
import { DeviceService } from '../device-service/device.service';
import { PatientPortalService } from '../patient-portal/patient-portal.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-final-page',
  templateUrl: './final-page.component.html',
  styleUrls: ['./final-page.component.scss']
})
export class FinalPageComponent implements OnInit, OnDestroy {
  @Input() titleLabel: string;
  @Input() descriptionLabel: string;
  @Input() useHtmlDescription: boolean = false;
  @Input() closeLabel: string;
  @Input() autoCloseAfter: number = undefined;
  private timeoutId: any;
  shouldClose = false;

  constructor(
    private router: Router,
    private deviceService: DeviceService,
    private alertsService: AlertsService,
    private translateService: TranslateService,
    private patientPortalService: PatientPortalService
  ) {}

  ngOnInit(): void {
    // auto close after X seconds
    if (this.autoCloseAfter !== undefined) {
      this.timeoutId = setTimeout(
        () => this.onClose(),
        this.autoCloseAfter * 1000
      );
    }
  }

  ngOnDestroy(): void {
    if (this.timeoutId !== undefined) {
      clearTimeout(this.timeoutId);
      delete this.timeoutId;
      this.translateService.use(this.translateService.getDefaultLang());
    }
  }

  isInApp(): boolean {
    return this.deviceService.isInApp();
  }

  isInKiosk(): boolean {
    return this.deviceService.isInKiosk();
  }

  onClose(): void {
    if (this.patientPortalService.isInPatientPortal()) {
      parent.postMessage('sessionClosed', '*');
    } else if (this.deviceService.isInKiosk()) {
      this.router.navigate(['kiosk'], { replaceUrl: true });
    } else if (this.isInApp()) {
      // hook that will be catched by custom web view in the app
      window.location.replace('/__DONE__');
    } else {
      try {
        window.close();
        this.shouldClose = true;
      } catch (e) {
        this.alertsService.showInfo(
          this.translateService.instant('ERROR.CANT_CLOSE_WINDOW')
        );
      }
    }
  }
}
