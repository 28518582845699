import { Injectable } from '@angular/core';
import { MobiatiHttpService } from '../../common/mobiati-http/mobiati-http.service';
import { Modal } from '../../materialize';
import { Question } from '../../forms/form/question.model';
import { LiteEditorResource } from '../resources/lite-editor.resource';
import { AlertsService } from '../../common/alerts/alerts.service';
import { FormSpec } from '../../forms/form/form-spec.model';
import { CookieService } from 'ngx-cookie';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LiteEditorService {
  editMode: boolean;
  editControlModal: Modal;
  confirmSaveModal: Modal;
  editedQuestion: Question = undefined;
  isSubmitting: boolean;
  private editModeAuthSet: boolean;

  constructor(
    private liteEditorResource: LiteEditorResource,
    private cookieService: CookieService,
    private alertsService: AlertsService,
    private http: MobiatiHttpService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    // TODO remove - this is temporary left for allowing old lite editor to be open before we first new version will be released
    if (this.router.url.startsWith('/p')) {
      this.activatedRoute.queryParams.subscribe((params: Params) => {
        this.editMode =
          (params.edit !== undefined && this.editModeAuthSet) ||
          environment.development.forceFormEditorInPreview;
      });
    }
  }

  initEditModeAuth(): boolean {
    const editFormsCookie = this.cookieService.get('modento.edit_forms');
    if (editFormsCookie) {
      const editForms = JSON.parse(editFormsCookie);
      if (editForms.access_token) {
        this.http.setAuthToken(editForms.access_token);
        this.editModeAuthSet = true;
        return true;
      }
      return false;
    } else {
      return false;
    }
  }

  editQuestion(question: Question): void {
    this.editedQuestion = question;
    this.editControlModal.open();
  }

  saveForm(): void {
    this.confirmSaveModal.open();
  }

  async saveFormConfirmed(formSpec: FormSpec): Promise<any> {
    try {
      this.isSubmitting = true;
      const response = await this.liteEditorResource.saveFormSpec(formSpec);
      this.alertsService.showInfo(response.message);
    } catch (e) {
      this.alertsService.showApiError(e);
    } finally {
      this.isSubmitting = false;
    }
  }
}
