<div class="input-field col s6">
  <input placeholder="Max dimension saved (px)"
         id="{{ key + '-control-longer-size' }}"
         type="text"
         class="validate"
         [(ngModel)]="control.longer_size"
         (ngModelChange)="onSizeChanged()" />
  <label for="{{ key + '-control-longer-size' }}">Max dimension saved
    (px)</label>
</div>
