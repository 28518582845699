<div class="loader">
  <svg width="48px"
       height="48px"
       viewBox="0 0 48 48"
       version="1.1"
       xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink">
    <g stroke="none"
       stroke-width="1"
       fill="none"
       fill-rule="evenodd">
      <g transform="translate(-348.000000, -672.000000)"
         fill="currentColor"
         fill-rule="nonzero">
        <g id="chat"
           transform="translate(187.000000, 329.000000)">
          <g id="Group"
             transform="translate(161.000000, 281.000000)">
            <path d="M35.194752,63.4666688 C40.573632,59.7566336 48,63.5128717 48,69.9434432 L48,69.9434432 L48,102.056576 C48,108.48704 40.573632,112.243328 35.194752,108.533312 L35.194752,108.533312 L24,100.811648 L12.8052096,108.533312 C7.4264448,112.243328 0,108.48704 0,102.056576 L0,102.056576 L0,69.9434432 C0,63.5128698 7.4264448,59.7566336 12.8052096,63.4666669 L12.8052096,63.4666669 L24,71.1883712 Z M44.030016,69.9434432 C44.030016,66.6567104 40.234368,64.7368448 37.48512,66.6330944 L37.48512,66.6330944 L14.2066368,82.689728 C11.8654848,84.304448 11.8654848,87.695552 14.2066368,89.310272 L14.2066368,89.310272 L37.48512,105.366848 C40.234368,107.263232 44.030016,105.343232 44.030016,102.056576 L44.030016,102.056576 Z M29.317248,74.8559168 L36.083712,79.5232256 C40.664256,82.682624 40.664256,89.317376 36.083712,92.476736 L29.317248,97.144064 L15.4559424,87.583232 C14.336256,86.810816 14.336256,85.189184 15.4559424,84.416768 L29.317248,74.8559168 Z"
                  id="logo_modento"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>

  <div class="lds-ellipsis">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
