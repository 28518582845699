import { Inject, Injectable } from '@angular/core';
import { API_URL } from '../../app.config';
import { PracticeService } from '../../common/practice';
import { AuthService } from '../../common/authentication/auth.service';
import { MobiatiHttpService } from '../../common/mobiati-http/mobiati-http.service';
import { KioskDeviceService } from '../kiosk-device/kiosk-device.service';
import { KioskService } from '../kiosk-service/kiosk.service';
import { CheckinEligiblePatient } from './checkin-eligible-patient.model';
import { CheckinRequest } from './checkin-request.model';
import { CheckinResponse } from './checkin-response.model';
import { HttpParams } from '@angular/common/http';
import { FormsRequestResponse } from './forms-request-response.model';
import { FormsRequestRequest } from './forms-request-request.model';
import { CookieService } from 'ngx-cookie';

@Injectable({
  providedIn: 'root'
})
export class KioskCheckinService extends KioskService {
  constructor(
    http: MobiatiHttpService,
    protected practiceService: PracticeService,
    protected kioskDeviceService: KioskDeviceService,
    protected authService: AuthService,
    protected cookieService: CookieService,
    @Inject(API_URL) protected apiUrl: string
  ) {
    super(
      http,
      practiceService,
      kioskDeviceService,
      authService,
      cookieService,
      apiUrl
    );
  }

  async getCheckInPatients(search: string): Promise<CheckinEligiblePatient[]> {
    this.http.setAuthToken(await this.getKioskAuthToken());
    return this.http.get<CheckinEligiblePatient[]>(
      this.coreUrl() + 'check-in',
      new HttpParams().set('search', search)
    );
  }

  async checkInPatient(request: CheckinRequest): Promise<CheckinResponse> {
    this.http.setAuthToken(await this.getKioskAuthToken());
    return this.http.post<CheckinResponse>(
      this.coreUrl() + 'check-in',
      request
    );
  }

  async createPatientFormsRequest(
    request: FormsRequestRequest,
    userToken: string
  ): Promise<FormsRequestResponse> {
    this.http.setAuthToken(userToken);
    return this.http.post<FormsRequestResponse>(
      `${this.apiUrl}/${this.practiceService.getPracticeGuid()}/form-request`,
      request
    );
  }
}
