import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PracticeService } from '../../common/practice';

@Component({
  selector: 'app-get-app',
  templateUrl: './get-app.component.html',
  styleUrls: ['./get-app.component.scss']
})
export class GetAppComponent implements OnInit {
  @Output() closeClick = new EventEmitter<boolean>();
  appUrl: string;

  constructor(private practiceService: PracticeService) {}

  ngOnInit(): void {
    this.appUrl = this.practiceService.getPractice().app_url;
    this.appUrl = this.appUrl.replace(/^(https?):\/\//, '');
  }

  prevPage(): void {
    this.closeClick.emit(true);
  }
}
