import { Component, EventEmitter, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { CheckinEligiblePatient } from './checkin-eligible-patient.model';
import { CheckinResponse } from './checkin-response.model';
import { KioskDeviceService } from '../kiosk-device/kiosk-device.service';
import { SessionTimeoutService } from '../../common/session-timeout/session-timeout.service';
import { Modal } from '../../materialize';
import { Observable } from 'rxjs';
import { countdown } from '../../common/utils/countdown.helper';

@Component({
  selector: 'app-kiosk-checkin',
  templateUrl: './kiosk-checkin.component.html',
  styleUrls: [
    './kiosk-checkin.component.scss',
    './../consolidation-styles.scss'
  ]
})
export class KioskCheckinComponent implements OnInit {
  currentPageIdx = 0;
  selectedPatient: CheckinEligiblePatient;
  isSubmitting: boolean;
  checkInResult: CheckinResponse;
  verifyNextPage = new EventEmitter();
  showGetApp: boolean;
  timeoutModal: Modal;

  countDownSessionLeft$: Observable<number> = countdown(30);

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private kioskDeviceService: KioskDeviceService,
    private sessionTimeoutService: SessionTimeoutService
  ) {}

  ngOnInit(): void {
    if (this.kioskDeviceService.isInKiosk()) {
      this.sessionTimeoutService.startSessionForTimeouts(
        ['kiosk', 'video'],
        () => {
          if (!this.sessionTimeoutService.isOnlyManualTimeoutResetActive()) {
            this.openTimeoutModal();
          }
        }
      );
    } else {
      this.sessionTimeoutService.startSessionForTimeouts(['timeout'], () =>
        this.openTimeoutModal()
      );
    }
  }

  currentPageSubtitle(): string {
    const key = [
      'KIOSK.CHECKIN.SEARCH.SUBTITLE',
      'KIOSK.CHECKIN.VERIFY.SUBTITLE',
      'KIOSK.CHECKIN.CHECKED_IN.SUBTITLE'
    ][this.currentPageIdx];
    return this.translateService.instant(key);
  }

  prevPage(): void {
    if (this.currentPageIdx === 0 || this.currentPageIdx === 2) {
      this.router.navigate(['kiosk']);
    } else if (this.currentPageIdx === 1) {
      this.currentPageIdx--;
    }
  }

  nextPage(): void {
    // special handling of going next from verify page
    if (this.currentPageIdx === 1 /*&& this.handleVerify()*/) {
      // verify page is handling NEXT logic on itself
      this.verifyNextPage.emit();
      return;
    }
    this.currentPageIdx++;
  }

  onSelectedPatient(patient: CheckinEligiblePatient): void {
    this.selectedPatient = patient;
  }

  onCheckInResult(result: CheckinResponse): void {
    this.checkInResult = result;
    if (this.checkInResult !== undefined && this.currentPageIdx === 1) {
      // move to final page
      this.currentPageIdx++;
    }
  }

  onTimeoutModalOpen(): void {
    this.sessionTimeoutService.allowResetTimeoutOnlyManually();
  }

  onTimeoutModalClose(): void {
    this.sessionTimeoutService.resetTimeoutManually();
  }

  private openTimeoutModal(): void {
    if (!this.timeoutModal) {
      return;
    }

    this.timeoutModal.open();
  }
}
