import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Practice, PracticeService } from '../../common/practice';
import { KioskDeviceService } from '../kiosk-device/kiosk-device.service';
import { SessionTimeoutService } from '../../common/session-timeout/session-timeout.service';
import { KioskService } from '../kiosk-service/kiosk.service';
import { KioskRequestsService } from '../kiosk-service/kiosk-requests.service';
import { VERSION } from '../../../environments/version';
import { NOT_FOUND } from '../../app.config';
import { SentryErrorHandler } from '../../sentry/sentry.service';

@Component({
  selector: 'app-kiosk-main',
  templateUrl: './kiosk-main.component.html',
  styleUrls: ['./kiosk-main.component.scss', './../consolidation-styles.scss']
})
export class KioskMainComponent implements OnInit {
  practice: Practice;
  isLoading = true;
  deviceName: string;
  versionInfo: string;
  showGetApp: boolean;

  constructor(
    private router: Router,
    private practiceService: PracticeService,
    private kioskService: KioskService,
    private kioskDeviceService: KioskDeviceService,
    private kioskRequestsService: KioskRequestsService,
    private sessionTimeoutService: SessionTimeoutService,
    private errorHandler: SentryErrorHandler
  ) {}

  async ngOnInit(): Promise<any> {
    try {
      await this.loadPractice();
      await this.loadDeviceConfig();
      await this.kioskRequestsService.init();
    } catch (e) {
      this.errorHandler.captureError(e);
      this.router.navigate([''], { replaceUrl: true });
      return;
    }
    this.deviceName = this.kioskDeviceService.getDeviceName();
    this.versionInfo = VERSION.version + ' (' + VERSION.hash + ')';
    this.isLoading = false;
    this.sessionTimeoutService.endSession();
    this.router.initialNavigation();

    await this.kioskRequestsService.checkPendingRequests();
  }

  onCheckIn(): void {
    this.router.navigate(['kiosk', 'checkin'], {});
  }

  onGetApp(): void {
    this.showGetApp = true;
  }

  onReload(): void {
    location.reload();
  }

  private async loadPractice(): Promise<any> {
    this.practice = await this.practiceService.lazyRefreshPracticeData();
  }

  private async loadDeviceConfig(): Promise<any> {
    try {
      // perform lazy load, so wait only if there was no remote config yet
      if (!this.kioskDeviceService.getConfig(true)) {
        this.kioskDeviceService.setKioskDeviceConfig(
          await this.kioskService.getDeviceConfig()
        );
        this.deviceName = this.kioskDeviceService.getDeviceName(); // could have changed
      } else {
        this.kioskService
          .getDeviceConfig()
          .then((config) => {
            this.kioskDeviceService.setKioskDeviceConfig(config);
            this.deviceName = this.kioskDeviceService.getDeviceName(); // could have changed
          })
          .catch((e) => {
            // if got 404 it means that device is not available on backend anymore -> unregister
            if (e && e.status === NOT_FOUND) {
              this.kioskDeviceService.unregister();
            }
          });
      }
    } catch (e) {
      // if got 404 it means that device is not available on backend anymore -> unregister
      if (e && e.status === NOT_FOUND) {
        this.kioskDeviceService.unregister();
      }
    }
  }
}
