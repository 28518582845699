import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormSpec } from 'src/app/forms/form/form-spec.model';

@Injectable({
  providedIn: 'root'
})
export class FormSpecPickerService {
  private readonly formSpec: BehaviorSubject<FormSpec> = new BehaviorSubject<
    FormSpec
  >(null);

  readonly formSpec$: Observable<FormSpec> = this.formSpec.asObservable();

  constructor() {}

  setFormSpec(formSpec: FormSpec): void {
    this.formSpec.next(formSpec);
  }
}
