import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { MenuItem } from 'src/app/common/menu-item/menu-item.model';
import { FormSpec } from 'src/app/forms/form/form-spec.model';
import { FormSpecPickerService } from '../../form-spec-picker/form-spec-picker.service';
import { DropdownPlaceholder } from './placeholder.model';
import {
  consentsAutomatedConsents,
  consentsCustomPlaceholder,
  consentsStandardPlaceholders,
  DROPDOWN_PLACEHOLDERS
} from './placeholders.provider';
import { PracticeService } from '../../../../common/practice';

@Injectable({
  providedIn: 'root'
})
export class DropdownService {
  readonly placeholders$ = this.formSpecPickerService.formSpec$.pipe(
    filter((f: FormSpec): f is FormSpec => !!f),
    map(({ is_consent = false }) => is_consent),
    switchMap((isConsent: boolean) => {
      let placeholders = [];
      if (isConsent) {
        placeholders = [consentsStandardPlaceholders];

        if (this.practiceService.getPractice().dashboard_consent_automation) {
          placeholders = [...placeholders, consentsAutomatedConsents];
        }

        placeholders = [...placeholders, consentsCustomPlaceholder];
      } else {
        placeholders = [...this.placeholders];
      }
      return of(this.prepareItemsStructure(placeholders, 'all'));
    }),
    shareReplay(1)
  );

  constructor(
    private readonly translateService: TranslateService,
    private readonly formSpecPickerService: FormSpecPickerService,
    @Inject(DROPDOWN_PLACEHOLDERS)
    private readonly placeholders: DropdownPlaceholder[],
    private readonly practiceService: PracticeService
  ) {}

  private prepareItemsStructure(
    items: DropdownPlaceholder[],
    category: string = null
  ): MenuItem[] {
    return items.map((item) => {
      const menuItem = {
        name: item.translateKey
          ? this.translateService.instant(item.translateKey)
          : item.name,
        value: item.value || null,
        children:
          item.data && item.data.length
            ? this.prepareItemsStructure(item.data)
            : null,
        category: item.category || category
      };

      return {
        ...menuItem,
        ...(item.isOpenable && { isOpenable: true }),
        ...(item.shouldFlattenItems &&
          Object.assign({}, ...menuItem.children, { children: null }))
      };
    });
  }

  highlightPlaceholders(html: string): string {
    /* negative lookbehind (?<!) does not work in Safari, this is an alternative solution */
    return html.replace(/{{[a-z0-9_]*}}/g, (match, _, idx) => {
      const hasSpanBefore =
        html.substring(idx - 30, idx) === '<span contenteditable="false">';

      if (!hasSpanBefore) {
        return `<mark data-placeholder="true" class="category-${this.getCategoryForPlaceholder(
          match
        )}"><span contenteditable="false">${match}</span></mark>`;
      }

      return match;
    });
  }

  getCategoryForPlaceholder(value: string): string {
    const standardPlaceholders = consentsStandardPlaceholders.data.flatMap(
      (placeholder) => placeholder.data
    );

    const automatedPlaceholders = consentsAutomatedConsents.data.flatMap(
      (placeholder) => ({
        category: placeholder.category,
        value: placeholder.value
      })
    );

    const placeholders = [...standardPlaceholders, ...automatedPlaceholders];

    const matchingPlaceholder = placeholders.find(
      (placeholder) => placeholder.value === value.replace(/{{|}}/g, '')
    );

    return matchingPlaceholder?.category || 'custom';
  }
}
