import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeleteCardConfirmDialogData } from './delete-card-confirm-dialog.types';
import { CreditCardDetail } from '../../clearent-card-payment/clearent-card-payment.types';

@Component({
  selector: 'app-delete-card-confirm-dialog',
  templateUrl: './delete-card-confirm-dialog.component.html',
  styleUrls: ['./delete-card-confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteCardConfirmDialogComponent {
  cardOnFile: CreditCardDetail;
  title: string;
  message: string;
  patientName: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: DeleteCardConfirmDialogData,
    private readonly dialogRef: MatDialogRef<
      DeleteCardConfirmDialogComponent,
      boolean
    >
  ) {
    this.cardOnFile = data.cardOnFile;
    this.title = data.options.title;
    this.message = data.options.message;
    this.patientName = data.options.patientName;
  }

  public cancelClicked(): void {
    this.dialogRef.close(false);
  }

  public confirmClicked(): void {
    this.dialogRef.close(true);
  }
}
