import { Injectable } from '@angular/core';
import { MobiatiHttpService } from '../../common/mobiati-http/mobiati-http.service';
import { FormSpec } from '../../forms/form/form-spec.model';
import { ActionResponse } from '../../common/mobiati-http/action-response.model';
import { FormType, GetRulesResponse } from '../models';
import { from, Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LiteEditorResource {
  constructor(private http: MobiatiHttpService) {}

  /**
   * handle publishing form spec + in addition it will remove related form spec draft
   */
  async saveFormSpec(formSpec: FormSpec): Promise<ActionResponse> {
    return this.http.patch(
      `/staff/practice/settings/forms/form-spec`,
      formSpec
    );
  }

  saveFormSpecAsDraft(
    formSpec: FormSpec
  ): Observable<ActionResponse & { draft_form_spec_id: number }> {
    return from(
      this.http.patch<ActionResponse & { draft_form_spec_id: number }>(
        '/staff/practice/settings/forms/draft-form-spec',
        formSpec
      )
    );
  }

  getFormSpecHistory(
    type: string,
    forMinor: boolean,
    language: string
  ): Observable<any> {
    let params = new HttpParams();
    if (forMinor) {
      params = params.set('minor', FormType.MINOR);
    }
    if (language !== undefined) {
      params = params.set('language', language);
    }
    return from(this.http.get(`/staff/form-spec/history/${type}`, params));
  }

  loadFormSpecVersion(id: number): Observable<FormSpec> {
    return from(this.http.get<FormSpec>(`/staff/form-spec/${id}`));
  }

  loadDraftFormSpec(id: number): Observable<FormSpec> {
    return from(this.http.get<FormSpec>(`/staff/draft-form-spec/${id}`));
  }

  loadDefaultFormSpec(id: number): Observable<FormSpec> {
    return from(this.http.get<FormSpec>(`/staff/default-form-spec/${id}`));
  }

  getRules(type?: string): Promise<GetRulesResponse> {
    return this.http.get(`/preview-form/spec-edit-rules/${type}`);
  }
  // tslint:disable-next-line:max-file-line-count
}
