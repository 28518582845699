<div #body>
  <header class="form-container__header container"
          *ngIf="groups.length > 1">
    <div class="form-container__title">
      {{ groups[currentGroupIdx].groupTitle }}
    </div>
    <div class="form-container__progress-step">
      {{ 'FORMS.STEP' | translate }}
      {{ currentGroupIdx + 1 }}<small>/{{groups.length}}</small>
    </div>
  </header>
  <div class="container form-container__card">
    <div class="row">
      <form class="col s12"
            *ngFor="let question of groups[currentGroupIdx].items"
            role="form">
        <app-control [question]="question"
                     [formData]="formData"
                     (output)="onFormDataChange(question.key, $event)"
                     (control)="onFormControlSet(question.key, $event)">
        </app-control>
      </form>
    </div>
    <div class="row"
         *ngIf="currentGroupIdx === groups.length - 1">
      <div class="form-container__mandatory-fields-explanation">
        {{ 'FORMS.FEEDBACK.MANDATORY_FIELDS' | translate }}
      </div>
    </div>
    <div class="row">
      <div class="col s12 form-container__nav-buttons">
        <button *ngIf="currentGroupIdx > 0"
                type="button"
                class="modento-btn text nav-buttons__prev"
                (click)="prevSection()">
          <i class="material-icons prefix">west</i>
          {{ 'FORMS.BUTTONS.BACK' | translate }}
        </button>
        <button type="button"
                class="modento-btn primary-variant nav-buttons__next"
                (click)="nextSection()">
          {{'FORMS.BUTTONS.CONTINUE' | translate}}
          <i
            *ngIf="currentGroupIdx < groups.length - 1"
            class="material-icons suffix"
          >east</i>
        </button>
      </div>
    </div>
  </div>
</div>
