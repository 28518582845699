import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { DropdownQuestion } from './dropdown-question.model';
import { FormSelect, Materialize } from '../../../../materialize';
import { AnswerOption } from '../../answer-option.model';
import { DeviceService } from '../../../../common/device-service/device.service';
import { AbstractInputControlComponent } from '../abstract-input-control/abstract-input-control.component';
import { FormControl } from '@angular/forms';
import { withGuessedType } from '../../../../common/utils/string-utils.helper';
import { AccessibilityService } from '../../../../common/accessibility-service/accessibility.service';
import { TranslateService } from '@ngx-translate/core';

declare const M: Materialize;

@Component({
  selector: 'app-dropdown-control',
  templateUrl: './dropdown-control.component.html',
  styleUrls: [
    './dropdown-control.component.scss',
    '../abstract-input-control/abstract-input-control.scss'
  ]
})
export class DropdownControlComponent
  extends AbstractInputControlComponent<
    string | object,
    DropdownQuestion,
    FormControl
  >
  implements OnInit, AfterViewInit, AfterContentChecked {
  OTHER_VALUE = '__OTHER__';

  @Input() compact = false;
  @Input() autocompleteEnabled = false;

  @ViewChild('select')
  select: ElementRef;
  @ViewChild('autocomplete')
  autocomplete: ElementRef;
  @ViewChild('firstDisabledOpt')
  firstDisabledOpt: ElementRef;
  @ViewChild('otherInput')
  otherInput: ElementRef;
  autocompletePlugin: any;
  private selectPlugin: FormSelect;
  private showingOther = false;
  currentSelection: any;
  showOther = false;
  showDropdownClose = false;
  other: string;

  constructor(
    protected deviceService: DeviceService,
    protected accessibilityService: AccessibilityService,
    private translate: TranslateService
  ) {
    super(accessibilityService);
  }

  ngOnInit(): void {
    const initData =
      this.data !== null && this.data !== undefined
        ? this.data
        : this.question.control.default !== null &&
          this.question.control.default !== undefined
        ? this.question.control.default
        : null;
    this.setupSimpleFormControl(initData);
    this.setupOther();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.initializeSelectOrAutocomplete();
  }

  onAutocompleteChange({ value }: HTMLInputElement): void {
    const selectedOption = this.question.control?.options.find(
      ({ name }) => name === value
    );

    this.currentSelection = selectedOption ? selectedOption.value : null;
    this.emitOutput();
  }

  onAutocompleteBlur(): void {
    if (!this.formControl.touched) {
      this.formControl.markAsTouched();
    }
  }

  applyDropdownFixForMobile(): void {
    // slice.call to convert NodeList ot Array so we are sure we have forEach available
    Array.prototype.slice
      .call(document.querySelectorAll('li[id^="select-options"]'))
      .forEach((item) => {
        item.removeEventListener('touchend', this.stopEventPropagation);
        item.addEventListener('touchend', this.stopEventPropagation);
      });
  }

  applyDropdownFixForOptionOtherTranslation(): void {
    // @ts-ignore suppressed error: Type 'NodeListOf ' must have a '[Symbol.iterator]()' method that returns an iterator.
    const options = [...this.selectPlugin.dropdownOptions.childNodes];
    const otherOption = options.find(({ innerText }) => !innerText.trim());

    if (otherOption) {
      otherOption.innerHTML = `<span>${this.translate.instant(
        'FORMS.CONTROLS.DROPDOWN.OTHER'
      )}</span>`;
    }
  }

  stopEventPropagation(e): void {
    e.stopPropagation();
  }

  ngAfterContentChecked(): void {
    if (this.showingOther && this.otherInput) {
      this.otherInput.nativeElement.focus();
      this.showingOther = false;
    }
  }

  private showDropdownCloseButton(show: boolean): void {
    if (this.deviceService.isMobile()) {
      this.showDropdownClose = show;
    }
  }

  /**
   * Any value we get that is not in options list we assume is "other" option
   */
  private setupOther(): void {
    const selection = this.formControl.value;
    if (selection == null || selection === '') {
      return;
    } else if (Array.isArray(selection)) {
      const selectionArray: string[] = selection;
      const missingOptions = selectionArray.filter(
        (item) =>
          undefined ===
          this.question.control.options.find(
            (opt: AnswerOption) => opt.value === item
          )
      );
      this.currentSelection = selectionArray.filter(
        (item) =>
          undefined !==
          this.question.control.options.find(
            (opt: AnswerOption) => opt.value === item
          )
      );

      if (missingOptions.length > 0) {
        this.showOther = true;
        this.other = missingOptions.join(', ');
      }
    } else {
      if (
        undefined ===
        this.question.control.options.find(
          (opt: AnswerOption) => opt.value === selection
        )
      ) {
        this.showOther = true;
        this.other = selection;
        this.currentSelection = this.OTHER_VALUE;
      } else {
        this.currentSelection = selection;
      }
    }
  }

  selectionChanged(target: any): void {
    if (this.question.control.multi) {
      const materializeSelect: FormSelect = target.M_FormSelect;
      const selectedValues = materializeSelect.getSelectedValues();
      this.currentSelection = selectedValues.length > 0 ? selectedValues : null;
      this.firstDisabledOpt.nativeElement.selected =
        selectedValues.length === 0;
    } else {
      this.currentSelection = withGuessedType(target.value);
      this.closeDropdown();
    }

    // check if we should show other option input
    const prevShowOther = this.showOther;
    const newShowOther = this.isOptionSelected(this.OTHER_VALUE);
    this.showingOther = newShowOther && prevShowOther !== newShowOther;
    this.showOther = newShowOther;

    // if other options was just selected, close the dropdown
    if (
      this.showOther &&
      prevShowOther !== this.showOther &&
      this.question.control.multi
    ) {
      this.closeDropdown();
    }

    this.emitOutput();
  }

  emitOutput(): void {
    let value = this.currentSelection;

    // replace OTHER option from dropdown with actual input text
    if (this.showOther) {
      const selection = value;
      if (Array.isArray(selection)) {
        const selectionArray: string[] = selection;
        if (this.other) {
          value = [
            ...selectionArray.filter((it) => it !== this.OTHER_VALUE),
            this.other
          ];
        } else {
          value = selectionArray.filter((it) => it !== this.OTHER_VALUE);
        }
        value = value.length > 0 ? value : null;
      } else {
        value = this.other;
      }
    }
    this.formControl.setValue(value);
    super.emitOutput();
  }

  isOptionSelected(optionValue): boolean {
    const selection = this.currentSelection;
    if (selection == null || selection === '') {
      return optionValue == null || optionValue === '';
    } else if (Array.isArray(selection)) {
      return (selection as string[]).indexOf(optionValue) !== -1;
    } else {
      return selection === optionValue;
    }
  }

  onOtherChanged(event): void {
    this.other = event.target.value;
    this.emitOutput();
  }

  closeDropdown(): void {
    this.selectPlugin.dropdown.close();
  }

  trackOptionsByValue(index, option: AnswerOption): any {
    return option.value;
  }

  private initializeSelectOrAutocomplete(): void {
    this.autocompleteEnabled
      ? this.initializeAutocomplete()
      : this.initializeSelect();
  }

  private initializeSelect(): void {
    // slice.call to convert NodeList ot Array so we are sure we have forEach available
    Array.prototype.slice
      .call(this.select.nativeElement.childNodes)
      .forEach((opt) => {
        if (opt.tagName === 'OPTION') {
          opt.selected =
            this.isOptionSelected(withGuessedType(opt.value)) ||
            (opt.value === this.OTHER_VALUE && this.showOther);
        }
      });
    this.selectPlugin = M.FormSelect.init(this.select.nativeElement, {
      dropdownOptions: {
        coverTrigger: false,
        onOpenStart: () => {
          this.showDropdownCloseButton(true);
          this.applyDropdownFixForMobile();
          this.applyDropdownFixForOptionOtherTranslation();
        },
        onCloseStart: () => {
          this.showDropdownCloseButton(false);
        }
      }
    });
  }

  private initializeAutocomplete(): void {
    this.autocompletePlugin = M.Autocomplete.init(
      this.autocomplete.nativeElement,
      {
        data: this.question.control.options.reduce(
          (acc, { name, value }: AnswerOption) => {
            return {
              ...acc,
              [name]: null
            };
          },
          {}
        ),
        minLength: 0
      }
    );
  }
}
