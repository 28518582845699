import { QuestionCondition } from './question-condition.model';
import { isEmpty, nonEmpty } from '../../common/utils/string-utils.helper';

export class Question {
  id?: number;
  key: string;
  type: string;
  section?: string;
  title: string;
  text?: string;
  optional?: boolean;
  disabled?: boolean;
  control?: any;
  meta?: any;
  if?: QuestionCondition[];
  // tslint:disable-next-line:variable-name
  if_or?: QuestionCondition[];

  /**
   * Check if question can be shown according to conditional logic from form spec.
   */
  isVisible(formData: object): boolean {
    const andConditions: QuestionCondition[] = this.if ? this.if : [];
    const orConditions: QuestionCondition[] = this.if_or ? this.if_or : [];

    return (
      andConditions.every((cond: QuestionCondition) =>
        this.evaluateCondition(cond, formData)
      ) &&
      (orConditions.length === 0 ||
        orConditions.some((cond: QuestionCondition) =>
          this.evaluateCondition(cond, formData)
        ))
    );
  }

  private evaluateCondition(
    cond: QuestionCondition,
    formData: object
  ): boolean {
    const complexValue = formData[cond.key] === Object(formData[cond.key]);
    const value = complexValue ? formData[cond.key].value : formData[cond.key];
    const extra = complexValue ? formData[cond.key].extra : null;
    if (cond.op === '!=') {
      return (
        ((cond.value === null && nonEmpty(value)) ||
          (cond.value !== null && value !== cond.value)) &&
        (cond.extra == null || extra !== cond.extra)
      );
    } else {
      // default to equals ==
      return (
        ((cond.value === null && isEmpty(value)) ||
          (cond.value !== null && value === cond.value)) &&
        (cond.extra == null || extra === cond.extra)
      );
    }
  }
}
