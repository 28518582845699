import { Component, Input } from '@angular/core';
import { LinkQuestionModel } from './link-question.model';

@Component({
  selector: 'app-link-control',
  templateUrl: './link-control.component.html',
  styleUrls: ['./link-control.component.scss']
})
export class LinkControlComponent {
  @Input() question: LinkQuestionModel;
}
