<div class="container"
     (window:resize)="onResize()">
  <div class="company-detail row"
       *ngIf="practice">
    <div class="col s12 m6"
         *ngIf="practice.big_logo">
      <div class="logo">
        <img class="logo-img"
             src="{{ practice.big_logo }}"
             alt="{{ practice.name }}" />
      </div>
    </div>
    <div class="company-detail-wrapper col s12 m6">
      <div class="address">
        <h4>{{ practice.name }}</h4>
        <span>{{ practice.address }}</span>
      </div>
      <div class="contact">
        <span *ngIf="practice.phone"
              [innerHTML]="'tel.&nbsp;' + (practice.phone | phone | noBreaks)"
              class="phone"></span>
        <span *ngIf="practice.www_url"
              class="www">{{
          practice.www_url | wwwUrl
          }}</span>
      </div>
    </div>
  </div>
  <div class="row row-no-margin position-relative"
       id="main-row">
    <ng-container *ngIf="loadCanvas">
      <app-drawing-canvas class="position-absolute"
                          [parentElement]="CanvasParentElement">
      </app-drawing-canvas>
    </ng-container>
    <iframe #iframeContent
            (load)="onIframeLoaded()"
            src="about:blank"
            width="100%"
            scrolling="no"
            frameborder="0"
            marginwidth="0"
            marginheight="0"
            [ngClass]="{
        'with-padding': !isTreatmentPlan,
        'white-bg': !isTreatmentPlan
      }"></iframe>
  </div>

  <ng-container *ngIf="canRefuseTP; else acceptOnly">
    <div class="radios-wrapper">
      <div class="radio-col">
        <div>
          <label>
            <input type="radio"
                   name="tp-acceptation"
                   [value]="true"
                   [formControl]="tpRejectedControl">
            <span>{{'CONSENTS.REJECT_TP.ACCEPT' | translate}}</span>
          </label>
        </div>
      </div>
      <div class="radio-col">
        <div>
          <label>
            <input type="radio"
                   name="tp-acceptation"
                   [value]="false"
                   [formControl]="tpRejectedControl">
            <span>{{'CONSENTS.REJECT_TP.REJECT' | translate}}</span>
          </label>
        </div>
      </div>
    </div>
    <div class="row row-extra"
         *ngIf="!tpRejectedControl.valid && tpRejectedControl.touched">
      <span
            class="helper-text invalid">{{ 'CONSENTS.REJECT_TP.REQUIRED' | translate }}</span>
    </div>
  </ng-container>
  <ng-template #acceptOnly>
    <div class="consent-agree-container">
      <label class="for-checkbox consent-agree"
             for="consent_agree">
        <input id="consent_agree"
               type="checkbox"
               class="filled-in"
               name="consent_agree"
               [formControl]="agreeFormControl" />
        <span>{{ 'CONSENTS.CONTENT.CHECKBOX' | translate }}</span>
      </label>
    </div>
    <div class="row row-extra"
         *ngIf="!agreeFormControl.valid && agreeFormControl.touched">
      <span class="helper-text invalid"
            *ngIf="agreeFormControl.errors.isTrue">{{ 'CONSENTS.VALIDATION.CONTENT.REQUIRED' | translate }}</span>
    </div>
  </ng-template>
</div>
