<div *ngIf="!hideBar"
     class="scale-transition inactivity-bar__container container">
  <div class="row row-no-margin valign-wrapper">
    <div class="col s11 m11">
      <p>
        {{ 'INACTIVITY_BAR.EXPIRE_SESSION_INFO_1ST_PART' | translate }}
        <strong>{{ sessionTime }}
          {{ 'INACTIVITY_BAR.EXPIRE_SESSION_INFO_MINUTES' | translate }}</strong>
        {{ 'INACTIVITY_BAR.EXPIRE_SESSION_INFO_2ND_PART' | translate }}
      </p>
    </div>
    <div class="inactivity-bar__close-btn-wrapper col s1 m1 right-align">
      <div class="inactivity-bar__close-btn"
           (click)="hide()">
        <i class="material-icons"> close </i>
      </div>
    </div>
  </div>
</div>
