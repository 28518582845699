import {
  Component,
  ElementRef,
  Inject,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormSpec } from '../../forms/form/form-spec.model';
import { Question } from '../../forms/form/question.model';
import { Materialize, Modal } from '../../materialize';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, delay, tap } from 'rxjs/operators';
import clonedeep from '../../../../node_modules/lodash.clonedeep';
import {
  buildConsentQuestion,
  buildQuestion,
  buildSignatureQuestion,
  buildYesNoMultiQuestion,
  buildYesNoQuestion
} from './questions-factory.helper';
import { OnboardingToolsService } from '../onboarding-tools-service/onboarding-tools.service';
import { Practice, PracticeService } from '../../common/practice';
import { AlertsService } from '../../common/alerts/alerts.service';
import { ProblemsMapperService } from './problems-mapper/problems-mapper.service';
import { MultiradioQuestion } from '../../forms/form/controls/multiradio-control/multiradio-question.model';
import { AnswerOption } from '../../forms/form/answer-option.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormSpecPickerService } from './form-spec-picker/form-spec-picker.service';
import { DOCUMENT } from '@angular/common';

declare const M: Materialize;

@UntilDestroy()
@Component({
  selector: 'app-edit-forms',
  templateUrl: './edit-forms.component.html',
  styleUrls: ['./edit-forms.component.scss']
})
export class EditFormsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('sectionBody') sectionBody: ElementRef;
  @ViewChild('questionList') questionList: ElementRef;
  formSpec: FormSpec;
  fileName: string;
  jsonPreview: string;
  jsonPreviewModal: Modal;
  conditionsPreviewModal: Modal;
  private refreshSubject = new Subject();
  errors: string[] = [];
  editingForPractice: boolean;
  practice: Practice;
  saveConfirmModal: Modal;
  isSubmitting: boolean;

  scrollToBottom$: Subject<void> = new Subject<void>();

  onScrollToBottom$ = this.scrollToBottom$
    .pipe(
      delay(30),
      tap((data) => {
        this.questionList.nativeElement.scrollTop = this.questionList.nativeElement.scrollHeight;
      }),
      untilDestroyed(this)
    )
    .subscribe();

  constructor(
    private router: Router,
    private practiceService: PracticeService,
    private alertsService: AlertsService,
    private toolsService: OnboardingToolsService,
    public mapperService: ProblemsMapperService,
    private formSpecPickerService: FormSpecPickerService,
    private readonly renderer2: Renderer2,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  async ngOnInit(): Promise<any> {
    this.renderer2.addClass(document.body, 'gradient-background');

    this.initMaterialize();
    this.refreshSubject
      .asObservable()
      .pipe(debounceTime(200))
      .subscribe((e) => this.initMaterialize());
  }

  ngOnDestroy(): void {
    this.renderer2.removeClass(document.body, 'gradient-background');
  }

  initMaterialize(): void {
    const collapsibles = document.querySelectorAll('.collapsible');
    M.Collapsible.init(collapsibles);
    M.updateTextFields();
    const selects = document.querySelectorAll('select');
    M.FormSelect.init(selects);
  }

  onFileChange(event): void {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = event.target.files[0];
      this.fileName = file.name;
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const questions: Question[] = JSON.parse(fileReader.result.toString());
        // reset practice setting, as this json is not in practice context
        this.practiceService.clearPracticeInfo();
        delete this.practice;
        this.formSpec = {
          id: 0,
          title: '',
          type: '',
          spec: questions
        } as FormSpec;
        this.initMaterialize();
      };
      fileReader.readAsText(file);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refreshSubject.next(true);
  }

  onUIStructureChange(): void {
    this.refreshSubject.next(true);
  }

  onDownloadSpec(): void {
    if (!this.validate()) {
      this.alertsService.showError(
        'Form has validation errors. Check bottom of the page.'
      );
      return;
    }

    const blob = new Blob([JSON.stringify(this.formSpec.spec)], {
      type: 'text/json'
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = this.fileName !== undefined ? this.fileName : 'form.json';
    // start download
    a.click();
  }

  onFormSpecPicked(formSpec: FormSpec): void {
    this.formSpec = formSpec;
    this.formSpecPickerService.setFormSpec(formSpec);
  }

  onPreviewSpec(): void {
    if (!this.validate()) {
      this.alertsService.showError(
        'Form has validation errors. Check bottom of the page.'
      );
      return;
    }

    // clear id, so we don't ever refer to actual model stored on backend as this form is being edited
    const previewSpec: FormSpec = clonedeep(this.formSpec);
    previewSpec.id = undefined;
    sessionStorage.setItem('tools.preview-spec', JSON.stringify(previewSpec));
    window.open(this.router.url + '/preview');
  }

  onPreviewJSON(): void {
    if (!this.validate()) {
      this.alertsService.showError(
        'Form has validation errors. Check bottom of the page.'
      );
      return;
    }

    this.jsonPreview = JSON.stringify(this.formSpec.spec, null, 2);
    this.jsonPreviewModal.open();
  }

  onAddQuestion(): void {
    this.formSpec.spec.push(buildQuestion());
    this.scrollToBottom$.next();
  }

  onCloseFormSpecEdit(): void {
    this.formSpec = null;
    this.errors = [];
  }

  drop(event: CdkDragDrop<Question[]>): void {
    moveItemInArray(
      event.container.data,
      event.previousIndex,
      event.currentIndex
    );
  }

  onAddYesNoQuestion(): void {
    this.formSpec.spec.push(buildYesNoQuestion());
    this.scrollToBottom$.next();
  }

  onAddYesNoMultiQuestion(): void {
    this.formSpec.spec.push(buildYesNoMultiQuestion());
    this.scrollToBottom$.next();
  }

  onAddConsentQuestion(): void {
    this.formSpec.spec.push(buildConsentQuestion());
    this.scrollToBottom$.next();
  }

  onAddSignatureQuestion(): void {
    this.formSpec.spec.push(buildSignatureQuestion());
    this.scrollToBottom$.next();
  }

  validate(): boolean {
    this.errors = [];
    const duplicates = this.findKeyDuplicates();
    if (duplicates.length > 0) {
      this.errors.push('Key duplicates: ' + duplicates.join(', '));
    }

    this.validateSignature();
    this.validateNullEmptyValues();

    return this.errors.length === 0;
  }

  trackQuestionsBy(index: number, element): any {
    return element;
  }

  private findKeyDuplicates(): string[] {
    const counts = {};
    this.formSpec.spec.forEach((question) => {
      if (counts[question.key] === undefined) {
        counts[question.key] = 1;
      } else {
        counts[question.key]++;
      }
      // for multiradio, get all sub-sequestions into check
      if (question.type === 'multiradio') {
        (question as MultiradioQuestion).control.questions.forEach(
          (subQuestion) => {
            if (counts[subQuestion.key] === undefined) {
              counts[subQuestion.key] = 1;
            } else {
              counts[subQuestion.key]++;
            }
          }
        );
      }
    });
    const duplicates = [];
    Object.keys(counts).forEach((key) => {
      if (counts[key] > 1) {
        duplicates.push(key);
      }
    });
    return duplicates;
  }

  private validateSignature(): void {
    const signatureQuestions = this.formSpec.spec.filter((q: Question) => {
      return q.type === 'signature' || q.type === 'block_signature';
    });
    if (signatureQuestions.length > 1) {
      this.errors.push(
        'There can be only one signature control in whole form.'
      );
    }
    if (
      signatureQuestions.length === 1 &&
      signatureQuestions[0].key !== 'signature'
    ) {
      this.errors.push("Signature control Key must be exactly 'signature'");
    }
  }

  private validateNullEmptyValues(): void {
    this.formSpec.spec.forEach((question) => {
      this.validateNullEmptyOptionValuesInQuestion(question);
      // for multiradio, get all sub-sequestions into check
      if (question.type === 'multiradio') {
        (question as MultiradioQuestion).control.questions.forEach(
          (subQuestion) => {
            this.validateNullEmptyOptionValuesInQuestion(subQuestion);
          }
        );
      }
    });
  }

  private validateNullEmptyOptionValuesInQuestion(question: Question): void {
    if (
      question.control &&
      question.control.options &&
      Array.isArray(question.control.options)
    ) {
      this.validateNullEmptyOptionValuesForAnswerOptions(
        question.control.options,
        question.key
      );

      if (
        question.control.extra &&
        question.control.extra.options &&
        Array.isArray(question.control.extra.options)
      ) {
        this.validateNullEmptyOptionValuesForAnswerOptions(
          question.control.extra.options,
          question.key
        );
      }
    }
  }

  private validateNullEmptyOptionValuesForAnswerOptions(
    options: AnswerOption[],
    questionKey: string
  ): void {
    options.forEach((option: AnswerOption) => {
      if (
        option.value === undefined ||
        option.value === null ||
        option.value === ''
      ) {
        this.errors.push(
          `Option '${option.name}' of question key '${questionKey}' is empty and it cannot be.`
        );
      }
    });
  }

  onDeleteQuestion(index: number): void {
    this.formSpec.spec.splice(index, 1);
  }

  onCloneQuestion(index: number): void {
    const clonedQuestionKey = this.formSpec.spec[index].key;
    const clonedQuestionInstances = this.formSpec.spec.filter(({ key }) =>
      key.startsWith(clonedQuestionKey)
    ).length;
    this.formSpec.spec.splice(index + 1, 0, {
      ...clonedeep(this.formSpec.spec[index]),
      key: this.formSpec.spec[index].key + '_' + clonedQuestionInstances
    });
  }

  onSectionChange(question: Question, section: string): void {
    // if question has sub-questions, their section should change in-sync
    if (
      question.hasOwnProperty('control') &&
      question.control.hasOwnProperty('questions')
    ) {
      question.control.questions.forEach((subQuestion) => {
        subQuestion.section = section;
      });
    }
  }

  onFormSpecLoaded(): void {
    this.sectionBody.nativeElement.scrollTop = 0;
    this.onUIStructureChange();
    this.errors = [];
    this.mapperService.clearMedicalConditions();
    setTimeout(() => {
      this.practice = this.practiceService.getPractice();
      this.editingForPractice =
        this.toolsService.isAuthorized() && this.practice !== undefined;
      if (this.editingForPractice && this.formSpec.type === 'health_history') {
        this.onLoadMedicalConditions();
      }
    }, 0);
  }

  async onSaveSpec(): Promise<any> {
    this.isSubmitting = true;
    try {
      // force json_version 2 because we may have used v2-only features
      this.formSpec.json_version = 2;
      await this.toolsService.saveFormSpec(this.formSpec);
      this.alertsService.showInfo('Form saved!');
      this.formSpec = undefined;
    } catch (e) {
      this.alertsService.showApiError(e);
    } finally {
      // stop spinner
      this.isSubmitting = false;
    }
  }

  async onLoadMedicalConditions(): Promise<any> {
    this.isSubmitting = true;
    try {
      this.mapperService.setMedicalConditions(
        await this.toolsService.getMedicalConditions()
      );
    } catch (e) {
      this.alertsService.showApiError(e);
    } finally {
      // stop spinner
      this.isSubmitting = false;
    }
  }

  onShowMedicalConditions(): void {
    this.conditionsPreviewModal.open();
  }

  onTrySaveForm(): void {
    if (!this.validate()) {
      this.alertsService.showError(
        'Form has validation errors. Check bottom of the page.'
      );
      return;
    }

    this.saveConfirmModal.open();
  }
  // tslint:disable-next-line:max-file-line-count
}
