export function sessionStorageSetItemOptional(
  key: string,
  value: string
): void {
  try {
    sessionStorage.setItem(key, value);
  } catch (e) {
    // ignore; if we can't store (e.g. safari in private mode), we try to live with it
  }
}
