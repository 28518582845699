import { PaymentOption } from '../consents-request/consent-request.model';

export interface PaymentOptionHook {
  paymentOption: PaymentOption;
  activated: boolean;
}

export enum ConsentFlowPage {
  CONTENT,
  PATIENT_SIGN,
  PAYMENT_OPTIONS,
  WISETACK,
  REJECTED,
  CLEARENT_PAYMENT_PLAN
}
