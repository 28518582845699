<div class="row">
  <div class="col s12">
    <p class="question-title">
      {{ question.title?.trim()
      }}<span class="required"
            *ngIf="!question.optional">*</span>
      <small *ngIf="note"
             class="center-block mt-1">
        {{ note }}
      </small>
    </p>
    <span class="helper-text light"
          *ngIf="question.text">{{
      question.text
    }}</span>
    <div>
      <div class="canvas-pad"
           #signatureContainer>
        <signature-pad #signaturePad
                       [options]="signaturePadOptions"
                       (onBeginEvent)="signatureDrawStartHandler()"
                       (onEndEvent)="signatureDrawCompleteHandler()">
        </signature-pad>
        <i
          *ngIf="signature != null"
          (click)="signatureClear()"
          title="{{ 'FORMS.CONTROLS.SIGNATURE.REDRAW' | translate }}"
          class="material-icons redraw"
          >delete_forever</i>
      </div>
      <ng-container *ngIf="!formControl.valid && formControl.touched">
        <span class="helper-text invalid"
              *ngIf="formControl.errors.required">{{
          'FORMS.VALIDATION.SIGNATURE.REQUIRED' | translate
        }}</span>
        <span class="helper-text invalid"
              *ngIf="
            formControl.errors.signaturePoints && !formControl.errors.required
          ">
          {{ 'FORMS.VALIDATION.SIGNATURE.MIN_POINTS' | translate }}
        </span>
      </ng-container>
      <p class="text-primary mt-0">
        <small>{{ 'FORMS.CONTROLS.SIGNATURE.LEGAL' | translate }}</small>
      </p>
    </div>
  </div>
</div>
