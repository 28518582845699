import { Question } from '../../question.model';

export enum CameraFacing {
  front,
  back
}

export interface PhotoQuestion extends Question {
  control?: PhotoQuestionParams;
}

export interface PhotoQuestionParams {
  longer_size?: number;
  preferred_camera?: CameraFacing;
}
