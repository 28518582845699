<div class="row multiradio-row">
  <div class="col s12"
       [formGroup]="formControl">
    <p class="center-align mb-3 question-title">{{ question.title }}</p>
    <span class="helper-text light center-align mb-3"
          *ngIf="question.text">{{
      question.text
    }}</span>

    <div *ngFor="let subquestion of question.control.questions; index as i">
      <div>
        <ng-container
                      *ngIf="subquestion.isVisible(data) && subquestion.key; else noControl">
          <app-radio-control [compact]="true"
                             [highlight]="subquestion.key === nextSubQuestionKey"
                             [question]="subquestion"
                             [data]="data[subquestion.key]"
                             (output)="onSubquestionDataChange(subquestion.key, $event)"
                             (control)="onSubquestionFormControlSet(subquestion.key, $event)"
                             *ngIf="subquestion.type === 'radio'">
          </app-radio-control>
        </ng-container>
        <ng-template #noControl> </ng-template>
      </div>
    </div>
  </div>
</div>
