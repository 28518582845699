<div class="section no-pad-bot body"
     id="scroll-container">
  <div class="container">
    <div class="row">
      <div class="col s10 offset-s1 center-align">
        <h4>{{ 'COMMON.INITIALS.TITLE' | translate }}</h4>
        <p class="subtitle">{{ 'COMMON.INITIALS.SUBTITLE' | translate }}</p>
        <div class="input-field col s2 offset-s5 center-align">
          <input id="initials"
                 type="text"
                 maxlength="2"
                 autocapitalize="characters"
                 autofocus
                 (input)="onInputChange($event.target.value)" />
        </div>
      </div>
    </div>
    <span *ngIf="errorText"
          class="helper-text invalid mt-2">{{ errorText }}</span>
  </div>
</div>
