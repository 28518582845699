import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { getConditionTypeForProblemQuestion } from '../questions-factory.helper';
import { MedicalCondition } from '../../medical-condition.model';
import { Question } from '../../../forms/form/question.model';
import { Materialize, Modal } from '../../../materialize';
import { nonEmpty } from '../../../common/utils/string-utils.helper';
import { AnswerOption } from '../../../forms/form/answer-option.model';
import { ProblemsMapperService } from './problems-mapper.service';

declare const M: Materialize;

@Component({
  selector: 'app-problems-mapper',
  templateUrl: './problems-mapper.component.html',
  styleUrls: ['./problems-mapper.component.scss']
})
export class ProblemsMapperComponent implements OnInit, AfterViewInit {
  @Input() question: Question;
  @Input() answer: AnswerOption;
  @Input() bulkAnswers: AnswerOption[] = undefined;
  @Input() questionKey: string;

  @ViewChild('condition_autocomplete')
  private conditionAutocomplete: ElementRef;
  private conditions: MedicalCondition[];
  modal: Modal;
  searchInput: string;
  bulkProgress = 0;

  constructor(public mapperService: ProblemsMapperService) {}

  async ngOnInit(): Promise<any> {
    this.loadConditionsForType();
    if (this.bulkAnswers !== undefined) {
      await this.mapBulkAnswers();
    } else {
      const initValue = this.getInitValue();
      this.searchInput = initValue.replace(/\?/g, '');
    }
  }

  private getInitValue(): string {
    if (this.answer !== undefined) {
      return this.getAnswerInitValue(this.answer);
    } else {
      const initValue = nonEmpty(this.question.meta.pms_name)
        ? this.question.meta.pms_name
        : nonEmpty(this.question.meta.problem)
        ? this.question.meta.problem
        : this.question.title;
      return initValue.trim();
    }
  }

  private getAnswerInitValue(answer: AnswerOption): string {
    return (nonEmpty(answer.pms_name) ? answer.pms_name : answer.name).trim();
  }

  private loadConditionsForType(): void {
    const conditionType = getConditionTypeForProblemQuestion(this.questionKey);
    this.conditions = this.mapperService
      .getMedicalConditions()
      .filter((c) => c.type === conditionType);
  }

  ngAfterViewInit(): void {
    // only used for single question/answer operation
    if (this.bulkAnswers !== undefined) {
      return;
    }

    if (this.onMappingSave()) {
      // have immediate mapping, don't show choice
      return;
    }

    const autocompleteData = {};
    this.conditions.forEach((c) => (autocompleteData[c.pmsName] = null));

    const instance = M.Autocomplete.init(
      this.conditionAutocomplete.nativeElement,
      {
        data: autocompleteData,
        onAutocomplete: () => {
          this.searchInput = this.conditionAutocomplete.nativeElement.value;
          this.onMappingSave();
        }
      }
    );

    M.updateTextFields();
  }

  onMappingSave(): boolean {
    // only used for single question/answer operation
    if (this.bulkAnswers !== undefined) {
      return;
    }

    const name = this.searchInput.trim();
    const selectedCondition = this.getConditionByName(name);
    if (selectedCondition) {
      setTimeout(() => {
        if (this.answer !== undefined) {
          this.answer.pms_id = selectedCondition.pmsId;
          this.answer.pms_name = selectedCondition.pmsName;
        } else {
          this.question.meta.pms_id = selectedCondition.pmsId;
          this.question.meta.pms_name = selectedCondition.pmsName;
        }
        this.modal.close();
      }, 0);
      return true;
    } else {
      return false;
    }
  }

  private getConditionByName(name: string): MedicalCondition {
    const exactMatch: MedicalCondition = this.conditions.find(
      (c) => c.pmsName === name
    );
    return exactMatch !== undefined
      ? exactMatch
      : this.conditions.find(
          (c) => c.pmsName.toLowerCase() === name.toLowerCase()
        );
  }

  async createInPMS(): Promise<any> {
    const condition: MedicalCondition = await this.mapperService.create(
      this.searchInput,
      this.questionKey
    );
    if (condition) {
      this.loadConditionsForType();
      this.onMappingSave();
    }
  }

  private async mapBulkAnswers(): Promise<any> {
    for (const answer of this.bulkAnswers) {
      const name = this.getAnswerInitValue(answer);
      let condition = this.getConditionByName(name);
      if (!condition) {
        condition = await this.mapperService.create(name, this.questionKey);
      }
      if (condition) {
        setTimeout(() => {
          answer.pms_id = condition.pmsId;
          answer.pms_name = condition.pmsName;
        });
      }
      this.bulkProgress++;
    }
    setTimeout(() => this.mapperService.hideMapper());
  }
}
