import {
  AddCardPayload,
  UpdateCardPayload
} from '../../../common/clearent/clearent-card-payment/clearent-card-payment.types';

export enum PaymentPlanScheduleItemType {
  Row = 'row',
  Separator = 'separator'
}

export interface PaymentPlanScheduleItem {
  text: string;
  amount: number;
  kind?: PaymentPlanScheduleItemType;
}

export enum PaymentPlanFrequency {
  Weekly = 'weekly',
  Monthly = 'monthly'
}

export interface SavePaymentPlanRequest
  extends Partial<AddCardPayload & UpdateCardPayload> {
  signature: string;
  frequency: PaymentPlanFrequency;
  number_of_payments: number;
  start_date: string;
  total_balance: number;
  patient_id: number;
  down_payment: number;
}
