import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Materialize, Modal } from '../../../materialize';
import { Question } from '../../form/question.model';

declare const M: Materialize;

@Component({
  selector: 'app-lite-edit-control-modal',
  templateUrl: './lite-edit-control-modal.component.html',
  styleUrls: ['./lite-edit-control-modal.component.scss']
})
export class LiteEditControlModalComponent implements AfterViewInit {
  @Input() formSpecId: number;
  @Input() question: Question;
  @Output() modalPluginSet = new EventEmitter<Modal>();
  @ViewChild('modal')
  private modal: ElementRef;
  private modalPlugin: Modal;

  ngAfterViewInit(): void {
    this.modalPlugin = M.Modal.init(this.modal.nativeElement);
    this.modalPluginSet.emit(this.modalPlugin);
  }
}
