import { Injectable } from '@angular/core';
import * as Pusher from 'pusher-js';
import { environment } from '../../../environments/environment';
import { PracticeService } from '../../common/practice';
import { Observable, Subject } from 'rxjs';
import { KioskDeviceService } from '../../kiosk/kiosk-device/kiosk-device.service';
import { KioskService } from '../../kiosk/kiosk-service/kiosk.service';
import { ConsentRequest } from '../../consents/consents-request/consent-request.model';

@Injectable()
export class PusherService {
  // Get env settings
  readonly environment: typeof environment = environment;

  // Pusher instance
  pusher: Pusher;

  // Pusher options
  protected options: any;

  // Pusher form request arrived event stream
  formRequestArrived$: Observable<any>;

  // Pusher consent request arrived event stream
  consentRequestArrived$: Observable<any>;

  // Pusher pair device arrived event stream
  pairDeviceArrived$: Observable<any>;

  // Pusher connection state changes event stream
  connectionState$: Subject<string> = new Subject<string>();

  // Pusher load pre qualified event stream
  loanPreQualifiedArrived$: Observable<any>;

  constructor(
    protected kioskService: KioskService,
    private practiceService: PracticeService,
    private kioskDeviceService: KioskDeviceService
  ) {
    // Debug mode flag
    Pusher.logToConsole = environment.pusher.logToConsole;

    // Debug mode handler
    Pusher.log = (message) => {
      console.log(message);
    };
  }

  /**
   * Initialize service
   */
  async init(): Promise<any> {
    if (this.pusher !== undefined) {
      // already initialized
      return Promise.resolve();
    }

    this.options = await this.getOptions();
    this.connect();

    const deviceId = this.kioskDeviceService.getDeviceId();
    const practiceGuid = this.practiceService.getPracticeGuid();

    // Create  "practice message created" event stream
    this.formRequestArrived$ = this.createPusherObservable(
      `private-practice-${practiceGuid}-device-${deviceId}-forms`,
      'form-request'
    );

    // Create  "patient call" event stream
    this.consentRequestArrived$ = this.createPusherObservable(
      `private-practice-${practiceGuid}-device-${deviceId}-consents`,
      'consent-request'
    );

    return Promise.resolve();
  }

  /**
   * Connect to socket
   */
  protected connect(): void {
    this.pusher = new Pusher(environment.pusher.APP_KEY, this.options);
    this.pusher.connection.bind('state_change', (states) => {
      this.connectionState$.next(states.current);
    });
  }

  // Disconnect from socket
  disconnect(): void {
    this.pusher?.disconnect();
    delete this.pusher;
  }

  /**
   * Get pusher options
   */
  protected async getOptions(): Promise<any> {
    return Promise.resolve({
      ...environment.pusher.options,
      authEndpoint: `${environment.serverUrl}/broadcasting/auth/kiosk`,
      auth: {
        headers: {
          Authorization: `Bearer ${await this.kioskService.getKioskAuthToken()}`,
          Accept: 'application/json, text/plain, */*'
        }
      }
    });
  }

  protected createPusherObservable(
    channel: string,
    eventName: string
  ): Observable<any> {
    return new Observable((observer) => {
      const channelListener = this.pusher.subscribe(channel);

      channelListener.bind(eventName, (data) => {
        observer.next(data);
      });

      return () => {
        // unsubscribe event
        channelListener.unsubscribe();
      };
    });
  }

  getConnectionState(): string {
    if (this.pusher !== undefined) {
      return this.pusher.connection.state;
    } else {
      return 'disconnected';
    }
  }

  async createLoanPreQualifiedEventStream(
    consentRequest: ConsentRequest
  ): Promise<void> {
    if (!this.pusher) {
      this.options = await this.getOptions();
      this.connect();
    }

    this.loanPreQualifiedArrived$ = this.createPusherObservable(
      `private-practice-${this.practiceService.getPracticeGuid()}-consent-${
        consentRequest.id
      }-wisetack-loan-pre-qualified`,
      'wisetack-loan-pre-qualified'
    );

    return Promise.resolve();
  }
}
