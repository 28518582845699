<ng-container *ngIf="!showGetApp">
  <div *ngIf="isSubmitting"
       class="screen-full-modal"
       (click)="(false)">
    <app-spinner class="screen-centered"></app-spinner>
  </div>

  <app-page-header [title]="'KIOSK.CHECKIN.TITLE' | translate"
                   [subtitle]="currentPageSubtitle()"
                   [canGoBack]="true"
                   [canGoNext]="selectedPatient !== undefined && currentPageIdx < 2"
                   (goBack)="prevPage()"
                   (goNext)="nextPage()"></app-page-header>
  <div class="section no-pad-bot body"
       id="scroll-container">
    <div class="container">
      <div class="row">
        <div class="col s12">
          <app-checkin-search *ngIf="currentPageIdx === 0"
                              (continue)="nextPage()"
                              (selectedPatient)="onSelectedPatient($event)">
          </app-checkin-search>
          <app-checkin-verify *ngIf="currentPageIdx === 1"
                              [nextPageEvents]="verifyNextPage"
                              [selectedPatient]="selectedPatient"
                              (checkInResult)="onCheckInResult($event)">
          </app-checkin-verify>
          <app-checked-in *ngIf="currentPageIdx === 2"
                          [checkInResult]="checkInResult"
                          (getAppClicked)="showGetApp = true"
                          (submitting)="isSubmitting = $event"></app-checked-in>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<app-get-app *ngIf="showGetApp"
             (closeClick)="showGetApp = false"></app-get-app>
<app-modal (modalPluginSet)="timeoutModal = $event"
           (modalClosed)="onTimeoutModalClose()"
           (modalOpened)="onTimeoutModalOpen()">
  <ng-container *ngIf="timeoutModal?.isOpen === true">
    <h3>{{ 'COMMON.MODAL.TIMEOUT_SOON_TITLE' | translate }}</h3>
    <ng-container *ngrxLet="countDownSessionLeft$ as countDownSessionLeft">
      <p>
        {{ 'COMMON.MODAL.TIMEOUT_SOON' | translate: {sessionLeft: countDownSessionLeft} }}
      </p>
    </ng-container>
  </ng-container>
</app-modal>
