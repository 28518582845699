import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeleteCardConfirmDialogOptions } from './delete-card-confirm-dialog.types';
import { DeleteCardConfirmDialogComponent } from './delete-card-confirm-dialog.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CreditCardDetail } from '../../clearent-card-payment/clearent-card-payment.types';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root'
})
export class DeleteCardConfirmDialogService {
  constructor(private readonly matDialog: MatDialog) {}

  open(
    cardOnFile: CreditCardDetail,
    options: DeleteCardConfirmDialogOptions
  ): Observable<boolean> {
    const dialogRef = this.matDialog.open(DeleteCardConfirmDialogComponent, {
      data: {
        cardOnFile,
        options
      },
      autoFocus: false,
      scrollStrategy: new NoopScrollStrategy(),
      panelClass: 'cdk-overlay-custom-dialog'
    });

    return dialogRef.afterClosed().pipe(map((confirmed) => !!confirmed));
  }
}
