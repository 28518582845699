export type FeatureFlag = '1' | null;

export interface Practice {
  guid: string;
  name: string;
  logo: string;
  big_logo: string;
  address: string;
  phone: string;
  email: string;
  latitude: string;
  longitude: string;
  availability: string;
  direct_email: string;
  www_url: string;
  fb_url: string;
  yelp_url: string;
  google_url: string;
  foursquare_url: string;
  fb_review_url: string;
  yelp_review_url: string;
  app_url: string;
  published: boolean;
  patient_portal: string;
  late_cancel_fee: string;
  membership_url: string;
  dashboard_consent_automation?: FeatureFlag;
  payments_operator: PaymentOperators;
  refuse_tp_enabled?: FeatureFlag;
}

export enum PaymentOperators {
  STRIPE = 'payments_stripe',
  APEX = 'payments_apex',
  MOOLAH = 'payments_moolah',
  CLEARENT = 'payments_clearent'
}
