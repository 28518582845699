import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { KioskDeviceService } from '../../kiosk/kiosk-device/kiosk-device.service';
import { VERSION } from '../../../environments/version';
import { nonEmpty } from '../utils/string-utils.helper';
import { PatientPortalService } from '../patient-portal/patient-portal.service';
import { PatientPortalVersion } from '../patient-portal/patient-portal-version.model';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  private modentoUserAgents = [
    'Kiosk Android',
    'Kiosk iOS',
    'Modento Android',
    'Modento iOS'
  ];

  constructor(
    private deviceDetectorService: DeviceDetectorService,
    private kioskDeviceService: KioskDeviceService,
    private readonly patientPortalService: PatientPortalService
  ) {}

  isInApp(): boolean {
    const userAgent = this.deviceDetectorService.userAgent;
    let isApp = false;
    this.modentoUserAgents.forEach((modentoUA) => {
      if (userAgent.includes(modentoUA)) {
        isApp = true;
      }
    });
    return isApp;
  }

  getModentoHostAppInfo(): string {
    const patientPortalVersion: PatientPortalVersion = this.patientPortalService.getPatientPortalVersion();

    if (patientPortalVersion) {
      return `Patient Portal/${patientPortalVersion.version} (${patientPortalVersion.hash})`;
    }

    const userAgent = this.deviceDetectorService.userAgent;
    let hostAppInfo = '';
    this.modentoUserAgents.forEach((modentoUA) => {
      if (userAgent.includes(modentoUA)) {
        const match = userAgent.match(
          new RegExp(modentoUA + '/.* ?\\(?.*\\)? ?')
        );
        if (match) {
          hostAppInfo = match.toString().trim();
        } else {
          hostAppInfo = modentoUA;
        }
      }
    });
    return hostAppInfo;
  }

  getModentoHostAppVersionInt(): number {
    const versionMatch = this.getModentoHostAppInfo().match(/\([0-9]*\)/);
    const versionStr = versionMatch != null ? versionMatch.toString() : null;
    if (versionStr != null) {
      const version: number = Number(versionStr.replace(/[\(\)]/g, ''));
      if (!isNaN(version) && version > 100) {
        return version;
      }
    }
    // fallback to 100, as this is first web-kiosk version we expect
    return 100;
  }

  getFullModentoClientInfo(): string {
    const version = VERSION.version + ' (' + VERSION.hash + ')';
    const hostAppInfo: string = this.getModentoHostAppInfo();
    return (
      'Forms/' +
      version +
      (nonEmpty(hostAppInfo) ? ` ${hostAppInfo.trim()}` : '') +
      '@' +
      this.getDeviceInfo()
    );
  }

  isInKiosk(): boolean {
    return this.kioskDeviceService.isInKiosk();
  }

  isMobile(): boolean {
    return this.deviceDetectorService.isMobile();
  }

  getDeviceName(): string {
    const deviceInfo = this.deviceDetectorService.getDeviceInfo();
    return deviceInfo.device + ' ' + deviceInfo.os;
  }

  getDeviceVersion(): string {
    return this.deviceDetectorService.getDeviceInfo().os_version;
  }

  getDeviceInfo(): string {
    const deviceInfo = this.deviceDetectorService.getDeviceInfo();
    return `${deviceInfo.os} ${deviceInfo.os_version} ${deviceInfo.browser} ${deviceInfo.browser_version}`.replace(
      /Unknown/g,
      ''
    );
  }

  getPlatform(): string {
    const userAgent = this.deviceDetectorService.userAgent;
    if (userAgent.includes('Kiosk Android')) {
      return 'web_android';
    } else if (userAgent.includes('Kiosk iOS')) {
      return 'web_ios';
    } else {
      return 'web_browser';
    }
  }
}
