<div class="row"
     [ngClass]="{ compact: compact }">
  <div class="input-field col s12">
    <p class="fixed-label question-title"
       *ngIf="fixedLabel"
       [ngClass]="{ focused: fixedLabelFocused }">
      {{ question.title?.trim()
      }}<span class="required"
            *ngIf="!question.optional && !compact">*</span>
    </p>
    <ng-container
                  *ngIf="question.control.input_type === inputType.Zip; else noZip">
      <input id="{{ question.key }}"
             type="text"
             [maxLength]="maxLength"
             [onlyNumber]="true"
             inputmode="numeric"
             class="validate"
             [ngClass]="{ uppercase: autocapitalize !== null }"
             [name]="question.key"
             (change)="emitOutput()"
             (focusin)="fixedLabelFocused = true"
             (focusout)="fixedLabelFocused = false"
             [attr.placeholder]="question.control.hint"
             [autocapitalize]="autocapitalize"
             [formControl]="formControl" />
    </ng-container>
    <ng-template #noZip>
      <input *ngIf="
        question.control.input_type !== 'phone' &&
        question.control.input_type !== 'ssn'
      "
             id="{{ question.key }}"
             type="{{ toInputElementType(question.control.input_type) }}"
             [maxLength]="maxLength"
             [onlyNumber]="question.control.input_type === inputType.Zip"
             class="validate"
             [ngClass]="{ uppercase: autocapitalize !== null }"
             [name]="question.key"
             (change)="emitOutput()"
             (focusin)="fixedLabelFocused = true"
             (focusout)="fixedLabelFocused = false"
             [attr.placeholder]="question.control.hint"
             [autocapitalize]="autocapitalize"
             [formControl]="formControl" />
      <input *ngIf="
        question.control.input_type === 'phone' ||
        question.control.input_type === 'ssn'
      "
             id="{{ question.key }}"
             type="text"
             inputmode="numeric"
             [maxLength]="maxLength"
             class="validate"
             [name]="question.key"
             (ngModelChange)="emitOutput()"
             (focusin)="fixedLabelFocused = true"
             (focusout)="fixedLabelFocused = false"
             [textMask]="{ mask: mask }"
             [attr.placeholder]="question.control.hint"
             [formControl]="formControl" />
    </ng-template>
    <label #label
           class="question-title"
           *ngIf="!fixedLabel"
           for="{{ question.key }}">
      {{ (question.title || '') + (!question.optional ? '*' : '')}}
    </label>
    <span class="helper-text light"
          *ngIf="question.text">{{
      question.text
    }}</span>
    <button *ngIf="question.control.input_type === 'initials' && typedInitials"
            (click)="fillOutInitials(question.key)"
            class="btn btm-tap-initials">
      <span>{{ 'CONSENTS.INITIALS.TAP_BUTTON' | translate }}</span>
    </button>
    <ng-container *ngIf="!formControl.valid && formControl.touched">
      <span class="helper-text invalid"
            *ngIf="formControl.errors.required">{{
        'FORMS.VALIDATION.INPUT.REQUIRED' | translate
      }}</span>
      <span class="helper-text invalid"
            *ngIf="formControl.errors.exactLength">{{
          'FORMS.VALIDATION.INPUT.EXACT_LENGTH'
            | translate
              : { length: formControl.errors.exactLength.requiredLength }
        }}</span>
      <span class="helper-text invalid"
            *ngIf="formControl.errors.zip">{{
        'FORMS.VALIDATION.INPUT.ZIP' | translate
      }}</span>
      <span class="helper-text invalid"
            *ngIf="formControl.errors.initials">{{
        'FORMS.VALIDATION.INPUT.INITIALS' | translate
      }}</span>
      <span class="helper-text invalid"
            *ngIf="formControl.errors.ssn">{{
        'FORMS.VALIDATION.INPUT.SSN' | translate
      }}</span>
      <span class="helper-text invalid"
            *ngIf="formControl.errors.isNumber">{{
        'FORMS.VALIDATION.INPUT.IS_NUMBER' | translate
      }}</span>
      <span class="helper-text invalid"
            *ngIf="formControl.errors.phone">{{
        'FORMS.VALIDATION.INPUT.PHONE' | translate
      }}</span>
      <span class="helper-text invalid"
            *ngIf="formControl.errors.email">{{
        'FORMS.VALIDATION.INPUT.EMAIL' | translate
      }}</span>
    </ng-container>
  </div>
</div>
