import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { FormSpec } from '../../../forms/form/form-spec.model';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-reject-tp-consent',
  templateUrl: './reject-tp-consent.component.html',
  styleUrls: ['./reject-tp-consent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RejectTpConsentComponent {
  @Input() refuseConsent: FormSpec;
  @Input() accepted = false;

  @Output() consentConfirmed = new EventEmitter<FormControl>();
  @Output() submitClicked = new EventEmitter<void>();

  onConsentConfirmed(value: boolean): void {
    this.consentConfirmed.next(new FormControl(value, Validators.requiredTrue));
  }

  onSubmitClicked(): void {
    this.submitClicked.next();
  }
}
