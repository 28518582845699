import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[onlyNumber]'
})
export class OnlyNumberDirective {
  @Input() onlyNumber: boolean;

  @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent): void {
    if (this.onlyNumber) {
      const key46 = 'Delete';
      const key8 = 'Backspace';
      const key9 = 'Tab';
      const key27 = 'Escape';
      const key13 = 'Enter';
      const key110 = 'DECIMAL_POINT';
      const key190 = 'F8';
      const key65 = 'a';
      const key67 = 'c';
      const key88 = 'x';
      const key35 = 'End';
      const key36 = 'Home';
      const key37 = 'ArrowLeft';
      const key38 = 'ArrowUp';
      const key39 = 'ArrowRight';
      const key40 = 'ArrowDown';
      const key86 = 'v';

      if (
        [key46, key8, key9, key27, key13, key110, key190].indexOf(e.key) !==
          -1 ||
        // Allow: Ctrl+A
        (e.key === key65 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+V
        (e.key === key86 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+C
        (e.key === key67 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+X
        (e.key === key88 && (e.ctrlKey || e.metaKey)) ||
        // Allow: home, end, left, right
        [key35, key36, key37, key38, key39, key40].indexOf(e.key) !== -1
      ) {
        // let it happen, don't do anything
        return;
      }
      if (isNaN(+e.key)) {
        e.preventDefault();
      }
    }
  }
}
