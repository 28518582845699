<div *ngIf="isSubmitting"
     class="screen-full-modal"
     (click)="(false)">
  <app-spinner class="screen-centered"></app-spinner>
</div>

<div class="row"
     *ngIf="!authorized">
  <div class="col s10 offset-s1 m6 offset-m3 center-align">
    <h5 class="text-primary">
      Log in with Admin / Onboarding manager credentials
    </h5>
    <div class="input-field">
      <input id="email"
             type="email"
             class="validate"
             name="email"
             [(ngModel)]="email"
             required />
      <label for="email"
             i18n="">Email</label>
    </div>
    <div class="input-field">
      <input id="password"
             type="password"
             class="validate"
             name="password"
             [(ngModel)]="password"
             (keydown)="onPasswordKeyDown($event)"
             required />
      <label for="password"
             i18n="">Password</label>
    </div>
    <button type="button"
            class="btn btn-primary waves-effect waves-primary mt-2"
            (click)="onLogIn()">
      Log In
    </button>
  </div>
</div>

<div class="row"
     *ngIf="authorized">
  <div class="col s12">
    <div class="row">
      <div class="input-field col s5">
        <input id="practice_guid"
               type="text"
               class="validate"
               [(ngModel)]="practiceGuid" />
        <label for="practice_guid">Practice slug/guid</label>
      </div>
      <div class="col s4">
        <button type="button"
                class="btn btn-primary waves-effect waves-primary mt-2"
                (click)="onLoadPractice()">
          Load
        </button>
        <div class="switch ml-1">
          <label>
            Forms
            <input type="checkbox"
                   [(ngModel)]="loadConsents"
                   (change)="onLoadConsentsChanged()" />
            <span class="lever"></span>
            Consents
          </label>
        </div>
      </div>
      <div class="col offset-s1 s2">
        <button type="button"
                class="btn btn-primary waves-effect waves-primary mt-2"
                (click)="logOut()">
          Log out
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col s12">
        <table>
          <thead>
            <th style="width: 50%">Title / Type</th>
            <th>Category / Sub-category</th>
            <th>For minor</th>
            <th>Lang</th>
            <th>V.</th>
            <th>Saved at</th>
            <th style="width: 15%"></th>
          </thead>
          <tbody>
            <tr *ngFor="let formSpec of practiceFormSpecs">
              <td>
                {{ formSpec.title }}<br /><code><small>{{ formSpec.type }}</small></code>
              </td>
              <td>
                <small>{{ formSpec.category
                  }}{{
                    formSpec.sub_category ? ' / ' + formSpec.sub_category : ''
                  }}</small>
              </td>
              <td>{{ formSpec.for_minor ? 'MINOR' : '' }}</td>
              <td>{{ formSpec.language }}</td>
              <td>v{{ formSpec.json_version }}</td>
              <td>
                <small>{{
                  formSpec.created_at | date: 'MMM d, yyyy h:mm a'
                }}</small>
              </td>
              <td>
                <button type="button"
                        class="btn btn-small btn-primary waves-effect waves-primary mr-1"
                        title="Edit form"
                        (click)="onLoadSpec(formSpec)">
                  <i class="material-icons">edit</i>
                </button>
                <button type="button"
                        class="btn btn-small btn-primary waves-effect waves-primary mr-1"
                        title="Preview form"
                        (click)="onPreviewSpec(formSpec)">
                  <i class="material-icons">search</i>
                </button>
                <button type="button"
                        class="btn btn-small btn-primary waves-effect waves-primary mr-1"
                        title="Restore type to defaults"
                        (click)="onRestoreDefaultSpec(formSpec)">
                  <i class="material-icons">settings_backup_restore</i>
                </button>
                <button type="button"
                        class="btn btn-small btn-primary waves-effect waves-primary"
                        title="Delete form"
                        (click)="onDeleteSpec(formSpec)">
                  <i class="material-icons">delete</i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row"
         *ngIf="loadConsents">
      <div class="col s12">
        <button type="button"
                class="btn btn-primary waves-effect waves-primary mt-2"
                (click)="onCreateConsent()">
          Create New Consent
        </button>
      </div>
    </div>
  </div>
</div>
<app-confirm-modal (modalPluginSet)="deleteConfirmModal = $event"
                   (confirm)="onDeleteSpecConfirmed()"
                   (cancel)="formSpecToDelete = undefined">
  <p>
    Are you sure you want to delete form spec:
    <b>{{
      formSpecToDelete
        ? formSpecToDelete.title +
          (formSpecToDelete.for_minor ? ' (MINOR)' : '')
        : ''
    }}</b>? It will not be available any more. You should never delete default
    english variant if minor/other language variants are remaining active.
  </p>
</app-confirm-modal>
<app-confirm-modal (modalPluginSet)="restoreConfirmModal = $event"
                   (confirm)="onRestoreDefaultSpecConfirmed()"
                   (cancel)="formSpecToRestore = undefined">
  <p>
    Are you sure you want to restore default form spec for type:
    <b>{{ formSpecToRestore ? formSpecToRestore.title : '' }}</b>?
    <b>ALL VARIANTS</b> of this type will be restored to defaults (minor and
    other languages).
  </p>
</app-confirm-modal>
