import { Inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  defer,
  fromEvent,
  iif,
  of
} from 'rxjs';
import {
  Clearent,
  ClearentCallbackMethod,
  ClearentEntryModeType,
  ClearentInitialMode,
  ClearentPayloadData,
  ClearentValidationMessageType,
  TerminalType
} from './clearent.types';
import { WINDOW } from '../providers/window';
import { TranslateService } from '@ngx-translate/core';
import { delay, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CLEARENT_STYLES } from './clearent.styles';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ClearentService {
  private readonly initialized$$: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean>(false);

  private readonly loading$$: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);

  private readonly error$$: BehaviorSubject<string> = new BehaviorSubject<
    string
  >(null);

  private readonly terminalTypeChanged$$: Subject<TerminalType> = new Subject<
    TerminalType
  >();

  private readonly clearentErrors: ReadonlyMap<
    ClearentValidationMessageType,
    string
  > = new Map<ClearentValidationMessageType, string>([
    [
      ClearentValidationMessageType.CARD_NUMBER_REQUIRED,
      this.translateService.instant(
        'CLEARENT_SDK.CLEARENT_VALIDATION_MESSAGES.CARD_NUMBER_REQUIRED'
      )
    ],
    [
      ClearentValidationMessageType.CARD_NUMBER_INVALID,
      this.translateService.instant(
        'CLEARENT_SDK.CLEARENT_VALIDATION_MESSAGES.CARD_NUMBER_INVALID'
      )
    ],
    [
      ClearentValidationMessageType.EXPIRATION_REQUIRED,
      this.translateService.instant(
        'CLEARENT_SDK.CLEARENT_VALIDATION_MESSAGES.EXPIRATION_REQUIRED'
      )
    ],
    [
      ClearentValidationMessageType.EXPIRATION_INVALID,
      this.translateService.instant(
        'CLEARENT_SDK.CLEARENT_VALIDATION_MESSAGES.EXPIRATION_INVALID'
      )
    ],
    [
      ClearentValidationMessageType.CSC_REQUIRED,
      this.translateService.instant(
        'CLEARENT_SDK.CLEARENT_VALIDATION_MESSAGES.CSC_REQUIRED'
      )
    ],
    [
      ClearentValidationMessageType.CSC_INVALID,
      this.translateService.instant(
        'CLEARENT_SDK.CLEARENT_VALIDATION_MESSAGES.CSC_INVALID'
      )
    ]
  ]);

  private clearent: Clearent;

  constructor(
    @Inject(WINDOW) private readonly window: Window,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly translateService: TranslateService
  ) {}

  get initialized$(): Observable<boolean> {
    return this.initialized$$.asObservable();
  }

  get loading$(): Observable<boolean> {
    return this.loading$$.asObservable();
  }

  get error$(): Observable<string> {
    return this.error$$.asObservable();
  }

  get error(): string {
    return this.error$$.getValue();
  }

  get terminalTypeChanged$(): Observable<TerminalType> {
    return this.terminalTypeChanged$$.asObservable();
  }

  initialize(key: string, terminalType: TerminalType): void {
    iif(() => !!this.clearent, of(this.clearent), this.loadScript())
      .pipe(
        tap(() => {
          this.initialized$$.next(false);

          const initialMode =
            terminalType === TerminalType.PHYSICAL
              ? ClearentInitialMode.READER
              : ClearentInitialMode.MANUAL;

          this.clearent.reset();
          this.clearent.init({
            baseUrl: environment.clearent.baseUrl,
            pk: key,
            initialMode,
            enableReader: true,
            deviceType: 'IDTECH',
            styles: CLEARENT_STYLES,
            validationCallback:
              ClearentCallbackMethod.CLEARENT_VALIDATION_MESSAGES,
            entryModeChangeCallback:
              ClearentCallbackMethod.CLEARENT_ON_ENTRY_MODE_CHANGE,
            showValidationMessages: false
          });
        }),
        switchMap(() =>
          fromEvent(this.clearent.frame, 'load').pipe(
            take(1),
            tap(() => this.initialized$$.next(true))
          )
        )
      )
      .subscribe();
  }

  reset(): void {
    if (this.clearent?.initialized) {
      this.clearent.reset();
    }
  }

  getCardPayload(): Observable<ClearentPayloadData> {
    this.loading$$.next(true);

    return iif(
      () => this.clearent?.initialized,
      defer(() => this.clearent.getPaymentToken()),
      of(null)
    ).pipe(
      map((response) => ({
        enabled: this.clearent?.initialized,
        payload: response
          ? {
              token: response.payload['mobile-jwt'].jwt,
              expiry: response.payload['mobile-jwt']['exp-date']
            }
          : null
      })),
      finalize(() => this.loading$$.next(false))
    );
  }

  addCallbacks(): void {
    this.window[ClearentCallbackMethod.CLEARENT_VALIDATION_MESSAGES] = (
      messages: string[]
    ): void => {
      if (!messages?.length) {
        this.error$$.next(null);
        return;
      }

      this.error$$.next(this.combineErrors(messages));
    };

    this.window[ClearentCallbackMethod.CLEARENT_ON_ENTRY_MODE_CHANGE] = (
      mode: ClearentEntryModeType
    ): void => {
      this.terminalTypeChanged$$.next(
        mode === ClearentEntryModeType.EMV_KB_READER
          ? TerminalType.PHYSICAL
          : TerminalType.VIRTUAL
      );
    };
  }

  removeCallbacks(): void {
    this.window[ClearentCallbackMethod.CLEARENT_VALIDATION_MESSAGES] = null;
    this.window[ClearentCallbackMethod.CLEARENT_ON_ENTRY_MODE_CHANGE] = null;
  }

  private loadScript(): Observable<void> {
    return new Observable<void>((observer) => {
      const script: HTMLScriptElement = this.document.createElement('script');
      script.src = environment.clearent.sdk;
      script.onload = (): void => {
        observer.next();
        observer.complete();
      };
      script.onerror = (err): void => {
        observer.error(err);
      };
      this.document.body.appendChild(script);
    }).pipe(
      delay(0),
      tap(() => (this.clearent = this.window['ClearentSDK']))
    );
  }

  private combineErrors(messages: string[]): string {
    return messages
      .reduce((acc, message) => {
        const errorKey = Array.from(this.clearentErrors.keys()).find((key) =>
          message.includes(key)
        );

        return acc.push(this.clearentErrors.get(errorKey)), acc;
      }, [])
      .join(' ');
  }
}
