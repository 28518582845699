import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { KioskCheckinService } from '../kiosk-checkin.service';
import { CheckinEligiblePatient } from '../checkin-eligible-patient.model';
import { ErrorMessageService } from '../../../common/error-message/error-message.service';

@Component({
  selector: 'app-checkin-search',
  templateUrl: './checkin-search.component.html',
  styleUrls: ['./checkin-search.component.scss']
})
export class CheckinSearchComponent implements OnInit {
  search: string;
  error: string;
  searchResults: CheckinEligiblePatient[];
  selectedPatientId: number;
  isLoading = false;
  private searchInputChanged: Subject<string> = new Subject<string>();

  @Output() selectedPatient = new EventEmitter<CheckinEligiblePatient>();
  @Output() continue = new EventEmitter<any>();

  constructor(
    private checkinService: KioskCheckinService,
    private errorService: ErrorMessageService
  ) {}

  ngOnInit(): void {
    this.initSearchInput();
    setTimeout(() => this.selectedPatient.emit(undefined), 0);
  }

  private initSearchInput(): void {
    this.searchInputChanged
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(async (searchValue) => {
        const actualSearch =
          searchValue === '' || searchValue.length < 2 ? null : searchValue;
        if (actualSearch !== null) {
          this.isLoading = true;
          delete this.error;
          delete this.selectedPatientId;
          try {
            this.searchResults = await this.checkinService.getCheckInPatients(
              actualSearch
            );
          } catch (e) {
            this.error = this.errorService.getErrorMessage(e);
          }
          this.isLoading = false;
        } else {
          delete this.searchResults;
        }
      });
  }

  onSelectionChange(): void {
    if (this.selectedPatientId) {
      const patient = this.searchResults.find(
        (p: CheckinEligiblePatient) => p.id === this.selectedPatientId
      );
      this.selectedPatient.emit(patient);
    }
  }

  onContinue(): void {
    this.continue.emit();
  }

  onSearchChange(text: string): void {
    this.searchInputChanged.next(text);
  }
}
