<div class="row mt-2">
  <div class="col s12 m10 offset-m1 center-align">
    <div class="input-field">
      <p class="fixed-label">
        {{ 'KIOSK.CHECKIN.SEARCH.INSTRUCTION' | translate }}
      </p>
      <input id="search"
             type="text"
             class="validate"
             name="search"
             [(ngModel)]="search"
             (ngModelChange)="onSearchChange($event)"
             autofocus
             autocapitalize="words" />
    </div>

    <div class="row"
         *ngIf="isLoading">
      <div class="col s12 center-align">
        <app-spinner></app-spinner>
      </div>
    </div>

    <form class="col s12 left-align"
          role="form"
          *ngIf="!isLoading && searchResults != null && searchResults.length > 0">
      <p *ngFor="let result of searchResults">
        <label for="patient_{{ result.id }}">
          <input class="with-gap"
                 name="patient_id"
                 id="patient_{{ result.id }}"
                 type="radio"
                 [value]="result.id"
                 (change)="onSelectionChange()"
                 [(ngModel)]="selectedPatientId" />
          <span>{{ result.short_name }}</span>
        </label>
      </p>
    </form>

    <p *ngIf="!isLoading && searchResults != null && searchResults.length === 0"
       [innerHTML]="'KIOSK.CHECKIN.SEARCH.NO_RESULTS' | translate"></p>

    <button type="button"
            class="btn btn-primary mt-2"
            (click)="onContinue()"
            *ngIf="selectedPatientId">
      {{ 'KIOSK.CHECKIN.SEARCH.CONTINUE' | translate }}
    </button>
    <span class="helper-text invalid mt-2"
          *ngIf="error != null && error.length > 0">{{ error }}</span>
  </div>
</div>
