<div class="video"
     (click)="skipVideo()"
     *ngIf="showVideo">
  <video autoplay
         loop
         width="100%"
         height="100%"
         [muted]="true">
    <source src="{{ videoUrl }}"
            type="video/mp4" />
    {{ 'ERROR.BROWSER_VIDEO' | translate }}
  </video>
</div>
